import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { AddressSearchComponent } from './search/address-search/address-search.component';
import { IAddressV2 } from './domain/address-v2';
import { AsyncPipe, NgIf } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { AddressProvider } from './domain/address-provider';
import { AddressResultComponent } from './search/address-result/address-result.component';
import { AddressEditorComponent } from './search/address-editor/address-editor.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { AddressLookupV2Presenter } from './address-lookup-v2.presenter';
import { AddressLookupV2Vm, ControlContext } from './address-lookup-v2.vm';

@Component({
  selector: 'jump-tech-frontend-address-lookup-v2',
  templateUrl: './address-lookup-v2.component.html',
  imports: [
    AsyncPipe,
    NgIf,
    AddressSearchComponent,
    AddressResultComponent,
    AddressEditorComponent,
    CoreComponentsAngularModule
  ],
  providers: [AddressLookupV2Presenter],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['address-lookup-v2.component.scss']
})
export class AddressLookupV2Component implements OnInit {
  @Input() countryCodes: string[];
  @Input() label: string;
  @Input() address: IAddressV2 | null;
  @Input() provider: AddressProvider;
  @Input() context: ControlContext = ControlContext.Mobile;
  @Input() disabled?: boolean;
  @Input() required = false;
  @Input() errorMessage: string;
  @Output() addressUpdate: EventEmitter<{ address: IAddressV2; location: string } | null> = new EventEmitter<{
    address: IAddressV2;
    location: string;
  } | null>();

  private readonly presenter = inject(AddressLookupV2Presenter);

  public manualEdit$ = new BehaviorSubject<boolean>(false);
  public vm$ = new BehaviorSubject<AddressLookupV2Vm | null>(null);

  ngOnInit() {
    this.presenter.load(this.vm$, this.countryCodes, this.label, this.provider, this.address);
    this.addressSelected(this.vm$.value?.address);
  }

  addressSelected(address?: IAddressV2 | null) {
    if (address) {
      this.address = address;
      this.updateAddressOutput(address);
    } else {
      this.address = null;
      this.updateAddressOutput(null);
    }
  }

  private updateAddressOutput(address: IAddressV2 | null) {
    if (!address) {
      this.addressUpdate.next(null);
    } else {
      this.addressUpdate.next({ address, location: [address?.latitude, address?.longitude].join(',') });
    }
  }

  addressUpdatedManually(address: IAddressV2 | null) {
    this.address = address;
    this.updateAddressOutput(address);
    this.manualEdit$.next(false);
  }

  toggleEditAddress() {
    this.manualEdit$.next(!this.manualEdit$.value);
  }

  protected readonly ControlContext = ControlContext;
}
