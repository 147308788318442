<ng-container *ngIf="vm$ | async as vm">
  <div *ngIf="(vm.enableRecording$ | async) === false" class="video-player-controls">
    <div class="video-player-controls__control-buttons">
      <ng-container *ngIf="vm.showRecordControls || (vm.enableDeletion$ | async) === true">
        <jui-icon
          *ngIf="(vm.recordingState$ | async) === 'recording' || (vm.recordingState$ | async) === 'paused'"
          size="xl"
          color="danger"
          id="stop"
          name="stop"
          (click)="stop()"
          [attr.data-qa]="'videoStop'"
        >
        </jui-icon>
        <jui-icon
          *ngIf="(vm.recordingState$ | async) === 'recording'"
          size="xl"
          color="low"
          id="pause"
          name="pause"
          (click)="pause()"
          [attr.data-qa]="'videoPause'"
        >
        </jui-icon>
        <jui-icon
          *ngIf="(vm.recordingState$ | async) === 'paused'"
          size="xl"
          color="danger"
          id="resume"
          name="pause"
          (click)="resume()"
          [attr.data-qa]="'videoResume'"
        >
        </jui-icon>
        <question-video-player-timer
          class="video-player-controls__control-buttons--timer"
          [recordingState]="vm.recordingState$"
          [timeLimit]="60"
          (timerFinished)="stop()"
        ></question-video-player-timer>
        <jui-icon
          *ngIf="(vm.enableDeletion$ | async) === true"
          class="video-player-controls__control-buttons--delete"
          size="xl"
          color="danger"
          id="idle"
          name="delete_outline"
          (click)="remove()"
          [attr.data-qa]="'videoRemove'"
        >
        </jui-icon>
      </ng-container>
    </div>
  </div>
  <ng-container *ngIf="vm.showRecordControls && (vm.enableRecording$ | async) === true">
    <div class="video-player-empty">
      <jui-button size="md" color="primary" id="start" (click)="start()" [attr.data-qa]="'videoStart'">
        <span slot="icon" class="material-icons">video_call</span>
        {{ vm.translations.startRecording }}
      </jui-button>
    </div>
  </ng-container>
</ng-container>
