import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { HttpImageComponent } from '@jump-tech-frontend/cards';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { PinchZoomComponent, PinchZoomModule } from '@meddv/ngx-pinch-zoom';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { NgxSpinnerModule } from 'ngx-spinner';
import * as XRegExp from 'xregexp';
import { CamelToReadablePipe } from '../shared/pipes/camel-to-readable.pipe';
@Component({
  selector: 'app-images-modal',
  templateUrl: 'installation-images-modal-v2.component.html',
  styleUrls: ['project-detail.component.scss'],
  imports: [
    NgxSpinnerModule,
    PinchZoomModule,
    TranslocoModule,
    CamelToReadablePipe,
    HttpImageComponent,
    CoreComponentsAngularModule
  ]
})
export class InstallationImagesModalV2Component implements OnInit {
  @Input() galleryImages: any[];
  @Input() projectData: any;
  @Input() currentImage: string;
  currentImageKey = '';
  currentImageIndex: number;

  @ViewChild('pinchZoom') pinchZoom: PinchZoomComponent;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.currentImageIndex = this.galleryImages.indexOf(this.currentImage);
    this.currentImageKey = this.galleryImages[this.currentImageIndex] || '';
  }

  galleryLeft() {
    this.currentImageIndex === 0 ? (this.currentImageIndex = this.galleryImages.length - 1) : this.currentImageIndex--;
    this.currentImage = this.galleryImages[this.currentImageIndex];
    this.currentImageKey = this.galleryImages[this.currentImageIndex] || '';
    this.resetImage();
  }

  galleryRight() {
    this.currentImageIndex === this.galleryImages.length - 1 ? (this.currentImageIndex = 0) : this.currentImageIndex++;
    this.currentImage = this.galleryImages[this.currentImageIndex];
    this.currentImageKey = this.galleryImages[this.currentImageIndex] || '';
    this.resetImage();
  }

  getSrc() {
    const indexMatch = XRegExp(/_(\d+)$/);
    if (indexMatch.test(this.currentImage)) {
      const index = this.currentImage.match(indexMatch)[1];
      return this.projectData[this.currentImage.replace(indexMatch, '')][index];
    } else {
      return this.projectData[this.galleryImages[this.currentImageIndex]];
    }
  }
  getPreviewImageKey(index: number) {
    return this.galleryImages[index];
  }
  getPreviewImageSrc(index: number) {
    const indexMatch = XRegExp(/_(\d+)$/);
    const image = this.galleryImages[index];
    if (indexMatch.test(image)) {
      const index2 = image.match(indexMatch)[1];
      return this.projectData[image.replace(indexMatch, '')][index2];
    } else {
      return this.projectData[this.galleryImages[index]];
    }
  }
  setCurrentImage(index: number) {
    this.currentImageIndex = index;
    this.currentImage = this.galleryImages[this.currentImageIndex];
    this.currentImageKey = this.galleryImages[this.currentImageIndex] || '';
    this.resetImage();
  }
  isCurrentImage(index: number) {
    return this.currentImageIndex === index;
  }
  getImageStateClass(index: number) {
    return this.isCurrentImage(index) ? 'http-image-wrapper__preview-border' : 'http-image-wrapper__no-preview-border';
  }
  resetImage() {
    if (this.pinchZoom.isZoomedIn) {
      this.pinchZoom.toggleZoom();
    }
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowUp') {
      this.galleryLeft();
    } else if (event.key === 'ArrowRight' || event.key === 'ArrowDown') {
      this.galleryRight();
    }
  }
}
