import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DisableProjectAutoAssignmentModalPresenter } from './disable-project-auto-assignment-modal.presenter';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ProjectAutoAssignmentRepository } from '../project-auto-assignment.repository';
import { IDisableProjectAutoAssignmentModalVm } from '../project-auto-assignment.model';

@Component({
  selector: 'pathway-disable-project-auto-assignment-modal',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './disable-project-auto-assignment-modal.component.html',
  imports: [NgIf, AsyncPipe, CoreComponentsAngularModule, ReactiveFormsModule, NgbModule, AlertModule],
  providers: [DisableProjectAutoAssignmentModalPresenter, ProjectAutoAssignmentRepository],
  styleUrls: ['./disable-project-auto-assignment-modal.component.scss']
})
export class DisableProjectAutoAssignmentModalComponent implements OnInit {
  public vm$ = new BehaviorSubject<IDisableProjectAutoAssignmentModalVm>(null);
  constructor(private presenter: DisableProjectAutoAssignmentModalPresenter, private activeModal: NgbActiveModal) {}

  async ngOnInit(): Promise<void> {
    await this.presenter.load(this.vm$);
  }

  submit() {
    this.activeModal.close();
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
