import { Component, computed, input } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { QuestionHintPopoverComponent } from './question-hint-popover.component';
import { NgIf } from '@angular/common';
import { QuestionFormErrorComponent } from './question-form-error.component';
import { I18nKeys } from '@jump-tech-frontend/app-config';

@Component({
    selector: 'question-label-content',
    template: `
    <div class="label-content" *ngIf="showLabelHint()">
      <span class="label-content__text">
        {{ question().label + (question().required ? ' *' : '') }}
        <span class="label-content__error">
          <question-form-error
            *ngIf="isInvalid()"
            [question]="question()"
            [message]="question().errorMessage"
            [i18ns]="i18ns()"
          ></question-form-error>
        </span>
      </span>
      <span class="label-content__icon" *ngIf="!hideHint()">
        <question-hint-popover [question]="question()"></question-hint-popover>
      </span>
    </div>
    <span class="label-content label-content--no-hint" *ngIf="!showLabelHint()">
      {{ question().label + (question().required ? ' *' : '') }}
      <span class="label-content__error">
        <question-form-error
          *ngIf="isInvalid()"
          [question]="question()"
          [message]="question().errorMessage"
          [i18ns]="i18ns()"
        ></question-form-error>
      </span>
    </span>
  `,
    imports: [NgIf, QuestionHintPopoverComponent, QuestionFormErrorComponent]
})
export class QuestionLabelContentComponent {
  question = input.required<QuestionBase<string>>();
  hideHint = input(false);
  isInvalid = input(false);
  i18ns = input<I18nKeys>();

  showLabelHint = computed(() => !!this.question().hint && !this.question().permanentHint);

  get icon(): string {
    return this.question().icon || 'info';
  }
}
