import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, ViewChild, inject, input } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  NguCarousel,
  NguCarouselConfig,
  NguCarouselDefDirective,
  NguTileComponent,
  NguCarouselNextDirective,
  NguCarouselPrevDirective
} from '@ngu/carousel';
import * as JmesPath from 'jmespath';
import { Card } from '../../domain/card';
import { CarouselAction } from '../../domain/card-actions/carousel.action';
import { Datasource } from '../../domain/datasource';
import { I18nKeys } from '../../domain/i18n-keys';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';
import { QuestionsComponent } from '../../core/questions.component';
import { NgIf } from '@angular/common';
import { getDataSourceData } from '../../core/list-data-source';
import { BehaviorSubject } from 'rxjs';
import { BaseQuestionComponent } from '../../core/question.component';

@Component({
  selector: 'crds-carousel-form',
  templateUrl: './carousel-form.component.html',
  styles: [
    `
      :host ::ng-deep ngu-item {
        margin-top: 0.25rem;
      }
    `
  ],
  imports: [
    NgIf,
    NguTileComponent,
    NguCarousel,
    NguCarouselDefDirective,
    NguCarouselNextDirective,
    NguCarouselPrevDirective,
    ReactiveFormsModule,
    QuestionsComponent
  ]
})
export class CarouselFormComponent extends BaseQuestionComponent implements OnChanges, AfterViewInit {
  @Input() override form: UntypedFormGroup;
  card = input.required<Card<CarouselAction>>();
  @Input() i18ns: I18nKeys;
  @ViewChild('carouselMain') carouselMain: NguCarousel<any>;
  private untilDestroyed = untilDestroyed();
  private sourceData = getDataSourceData();
  private sanitizer = inject(DomSanitizer);
  public currentMainSlide: number;
  public carouselMainTile: NguCarouselConfig;
  action: CarouselAction | undefined;

  get initialised() {
    return this.initialisedSubject.getValue();
  }
  initialisedSubject = new BehaviorSubject<boolean>(false);

  async ngOnChanges(changes: SimpleChanges) {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card') || !changes['card'].firstChange) {
      return;
    }

    this.action = this.card().action;

    if (this.action?.datasource) {
      if (!this.action.datasource.transform) {
        this.action.datasource.transform = '[*].{ text: name, name: name, content: content }';
      }
      this.mapData(await this.sourceData.getData(this.action.datasource), this.action.datasource, this.card());
    }

    this.setCurrentMainSlide(this.getCurrentMainSlideFromForm());
    this.updateTargetField();
    this.carouselMainTile = {
      grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
      slide: 1,
      point: {
        visible: false
      },
      load: 5,
      touch: true,
      loop: false
    };
    this.initialisedSubject.next(true);
  }

  ngAfterViewInit() {
    this.initialisedSubject
      .asObservable()
      .pipe(this.untilDestroyed())
      .subscribe(initialised => {
        if (initialised) {
          setTimeout(() => {
            this.carouselMain.moveTo(this.getCurrentMainSlideFromForm(), false);
          }, 10);
        }
      });
  }

  mapData(result: unknown[], datasource: Datasource, card?: Card<CarouselAction>) {
    const slides: [] = JmesPath.search(result, datasource?.transform ?? '') || [];

    if (slides && slides.length && card?.action) {
      card.action.mainSlides = slides;
    } else if (card?.action && (!card.action.mainSlides || !card.action.mainSlides.length)) {
      card.action.mainSlides = [];
    }
  }

  updateMainSlide(currentSlide: number) {
    if (currentSlide !== this.currentMainSlide) {
      this.setCurrentMainSlide(currentSlide);
      this.updateTargetField();
    }
  }

  updateTargetField() {
    setTimeout(() => {
      this.form.get(this.action?.targetField as string)?.patchValue(this.getCurrentMainSlideName());
    });
  }

  toSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  getCurrentMainSlideName() {
    return this.card().action?.mainSlides?.[this.currentMainSlide].text ?? '';
  }

  setCurrentMainSlide(slide: number) {
    this.currentMainSlide = slide;
  }

  protected getCurrentMainSlideFromForm() {
    const currentFormValue = this.form.get(this.action?.targetField as string)?.value ?? null;
    if (!currentFormValue) {
      return 0;
    }
    const currentMainSlideValue = currentFormValue.split(' - ')[0];
    const slideIndex =
      this.card().action?.mainSlides?.findIndex(mainSlide => mainSlide.text === currentMainSlideValue) ?? -1;
    return slideIndex > -1 ? slideIndex : 0;
  }
}
