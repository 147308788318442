import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ImageError, ImageManipulationService } from '../../core/image-manipulation.service';
import { OcrService, TextDetection } from '../../core/ocr.service';
import { Card, ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { OcrAction, OcrActionRegex } from '../../domain/card-actions/ocr.action';
import { QuestionFormErrorComponent } from '@jump-tech-frontend/question-components';
import { NgIf } from '@angular/common';
import { QuestionsComponent } from '../../core/questions.component';
import { ImageAssetComponent } from '../../core/image-asset.component';
import { I18nKeys } from '@jump-tech-frontend/app-config';

@Component({
    selector: 'crds-ocr-form',
    templateUrl: './ocr-form.component.html',
    imports: [
        ImageAssetComponent,
        NgxSpinnerModule,
        ReactiveFormsModule,
        QuestionsComponent,
        NgIf,
        QuestionFormErrorComponent
    ]
})
export class OcrFormComponent implements OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() card: Card<OcrAction>;
  @Input() i18ns: I18nKeys;
  action: OcrAction;
  description: SafeHtml;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;
  uploadError: string;
  imageError: ImageError | null;

  constructor(
    private spinnerService: NgxSpinnerService,
    private ocrService: OcrService,
    private sanitizer: DomSanitizer,
    private imageService: ImageManipulationService,
    private imageAssetFactory: ImageAssetFactory
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.action = this.card.action;
    this.images = this.imageAssetFactory.create(this.card.image, this.form);
    this.description = this.card.description;
  }

  async setFromOcr(event: any) {
    this.clearOcrTarget();
    const file: File = event.target.files[0];
    if (!file) {
      return; // User cancelled
    }

    this.imageError = this.imageService.checkFileTypeAndSize(file);
    if (this.imageError) {
      this.uploadError = `${this.imageError.fileName}: ${this.i18ns[this.imageError.type]}`;
      return;
    }

    const targetFormControl = this.form.get(this.action.targetField);

    await this.spinnerService.show();
    try {
      const tempImage = await this.imageService.resizeImageFromFile(file);
      const results = await this.ocrService.recognise(tempImage);
      const filteredResult = this.filterResults(results);
      this.patchOcrTarget(filteredResult);
      if (!filteredResult?.Geometry) {
        return Promise.resolve(tempImage);
      }
      const croppedImage = await this.imageService.cropImage(tempImage, filteredResult);
      targetFormControl.patchValue([croppedImage]);
      this.resetFileInput();
    } catch (error) {
      console.log('Failed image upload', error);
      // Cannot use instanceof
      if (error.name === 'ImageUploadError') {
        this.uploadError = 'Error: cannot upload the image';
      }
    } finally {
      await this.spinnerService.hide();
    }
  }

  private filterResults(results: TextDetection[]): TextDetection | null {
    let returnedResult: TextDetection = null;

    this.action.regexs.forEach((regex: OcrActionRegex) => {
      switch (regex.type) {
        case 'standard':
          const standardFilteredResults = results.filter(result => result.Type === 'WORD');
          const standardResult = this.standardFilter(standardFilteredResults, regex);
          if (standardResult) {
            returnedResult = standardResult;
          }
          break;
        case 'split':
          const splitFilteredResults = results.filter(result => result.Type === 'LINE');
          const splitResult = this.splitFilter(splitFilteredResults, regex);
          if (splitResult) {
            returnedResult = splitResult;
          }
          break;
        default:
          returnedResult = null;
      }
    });

    return returnedResult;
  }

  /**
   * Attempt to find the index of the first pattern in the results then try to match the subsequent
   * results against the subsequent patterns.
   * @returns TextDetection | null
   * @param results
   * @param regex
   */
  private splitFilter(results: TextDetection[], regex: OcrActionRegex): TextDetection | null {
    let match: TextDetection = null;

    results.forEach(result => {
      if (result.DetectedText.match(regex.patterns[0]) !== null) {
        match = result;
      }
    });

    return match;
  }

  private standardFilter(results: TextDetection[], regex: OcrActionRegex): TextDetection | null {
    const filteredResults = results.filter(result => result.DetectedText.match(regex.patterns[0]) !== null);
    return filteredResults.length ? filteredResults[0] : null;
  }

  private patchOcrTarget(result: TextDetection) {
    const target = this.form.get(this.action.targetOcr);
    if (result !== null) {
      const match = result.DetectedText.match(/(([1-2][0-9]|3[0-2]) ?[0-9]{4} ?[0-9]{4} ?[0-9]{3}).*/);
      target.patchValue(match.length ? match[1].replace(/ /g, '') : '');
    } else {
      target.patchValue('');
    }
  }

  private clearOcrTarget() {
    this.form.get(this.action.targetOcr).reset('');
  }

  clickFile() {
    document.getElementById('ocr').click();
  }

  resetFileInput() {
    const input = document.getElementById('ocr');
    input['value'] = '';

    if (!/safari/i.test(navigator.userAgent)) {
      input['type'] = '';
      input['type'] = 'file';
    }
  }
}
