import { Component, EventEmitter, Input, Output } from '@angular/core';
import { StatusChangeInfoVm } from '../support-bar.model';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
    selector: 'status-change-popover',
    templateUrl: 'status-change-popover.component.html',
    styleUrls: ['status-change-popover.component.scss'],
    imports: [ReactiveFormsModule, NgSelectModule, CoreComponentsAngularModule]
})
export class StatusChangePopoverComponent {
  @Input() vm: StatusChangeInfoVm;
  @Output() projectStatusChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() changeStatusButtonClicked: EventEmitter<void> = new EventEmitter<void>();
}
