import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { GlobalErrorsVm } from './global-errors.model';
import { GlobalErrorsPresenter } from './global-errors.presenter';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
  selector: 'global-errors',
  standalone: true,
  imports: [AsyncPipe, CoreComponentsAngularModule],
  providers: [GlobalErrorsPresenter],
  templateUrl: 'global-errors.component.html',
  styleUrls: ['global-errors.component.scss']
})
export class GlobalErrorsComponent implements OnInit {
  public vm$: BehaviorSubject<GlobalErrorsVm> = new BehaviorSubject<GlobalErrorsVm>(null);

  constructor(private presenter: GlobalErrorsPresenter) {}

  public ngOnInit(): void {
    this.presenter.load(this.vm$);
  }

  public clearErrors(): void {
    this.presenter.clearErrors();
  }

  public toggleDetail(): void {
    this.presenter.toggleDetail();
  }
}
