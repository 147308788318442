import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import * as JmesPath from 'jmespath';
import { NgxSpinnerService } from 'ngx-spinner';
import { Datasource } from '../../domain/datasource';
import { I18nKeys } from '../../domain/i18n-keys';
import { getDataSourceData } from '../list-data-source';
import { ScrollService } from '../scroll.service';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { QuestionLabelContentComponent } from '@jump-tech-frontend/question-components';
import { InlineSpinnerComponent } from '../inline-spinner-component/inline-spinner.component';
import { NgIf, NgSwitch, NgSwitchCase, NgFor, NgSwitchDefault } from '@angular/common';
import { BaseQuestionComponent } from '../question.component';

@Component({
    selector: `crds-question-checkbox-button-group`,
    template: `
    <div class="crds-question-button-group" *ngIf="optionsPopulated && show">
      <div *ngIf="!initialised">
        <label *ngIf="question.label" class="form-label" [attr.for]="question.key">
          {{ question.label }}
          <span *ngIf="question.required"> *</span>
        </label>
        <crds-inline-spinner
          [name]="question.key"
          [qaHook]="question.loadingQaHook"
          [type]="question.loadingType"
          [message]="question.loadingMessage"
          [colour]="question.loadingColour"
          [bgColour]="question.loadingBgColour"
          [textColour]="question.loadingTextColour"
          [center]="question.loadingCenter"
        >
        </crds-inline-spinner>
      </div>

      <div *ngIf="initialised">
        <label *ngIf="question.label" class="form-label" [attr.for]="question.key">
          <question-label-content
            [question]="question"
            [isInvalid]="isInvalid"
            [i18ns]="i18ns"
          ></question-label-content>
          <div [formGroup]="checkboxGroupForm" *ngIf="initialised" [ngSwitch]="style" [attr.data-qa]="question.key">
            <div *ngSwitchCase="'vertical-buttons'">
              <div *ngFor="let opt of question['options']" class="chbx-btn-group">
                <div class="btn-group btn-group-toggle chbx-btn-group__btn">
                  <label
                    [class]="'btn chbx-btn-group__lbl button-group-spaced ' + getButtonClass(opt)"
                    [attr.data-qa]="opt.key"
                    ngbButtonLabel
                  >
                    <input
                      type="checkbox"
                      [value]="opt.key"
                      [formControlName]="opt.key"
                      [attr.data-qa]="question.key + 'Input'"
                      ngbButton
                      #buttonGroupInput
                    />{{ opt.value }}
                  </label>
                </div>
                <div *ngIf="opt.description" class="chbx-btn-group__description">
                  <div>{{ opt.description }}</div>
                </div>
              </div>
            </div>
            <div *ngSwitchCase="'show-checkbox'">
              <div *ngFor="let opt of question['options']" class="explicit-checkbox-container">
                <label class="explicit-checkbox">
                  {{ opt.value }}
                  <input
                    type="checkbox"
                    [value]="opt.key"
                    [formControlName]="opt.key"
                    [attr.data-qa]="question.key + 'Input'"
                  />
                  <span class="checkmark"></span>
                </label>
                <div class="vertical-description">
                  <div>{{ opt.description }}</div>
                </div>
              </div>
            </div>
            <div *ngSwitchDefault class="btn-group btn-group-toggle">
              <label
                *ngFor="let opt of question['options']"
                [class]="'btn button-group-spaced ' + getButtonClass(opt)"
                ngbButtonLabel
              >
                <input
                  type="checkbox"
                  [value]="opt.key"
                  [formControlName]="opt.key"
                  [attr.data-qa]="opt.key"
                  ngbButton
                  #buttonGroupInput
                />{{ opt.value }}
              </label>
            </div>
          </div>
        </label>

        <!-- QUESTION HINT -->
        <question-hint [question]="question"></question-hint>

        <div *ngFor="let key of formValues">
          <p *ngIf="alert(key)">
            <jui-alert-block iconName="info" size="sm"> {{ question.alert[key] }}</jui-alert-block>
          </p>
        </div>
      </div>
    </div>
  `,
    styles: [
        `
      .chbx-btn-group {
        display: flex;
        align-items: center;
        width: 100%;
        margin-bottom: 0.2rem;
      }
      .chbx-btn-group__btn {
        flex-basis: auto;
        min-width: 45%;
      }
      .chbx-btn-group__lbl {
        white-space: pre-wrap;
        width: 100%;
      }
      .chbx-btn-group__description {
        margin-left: 1rem;
      }

      .vertical-description {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: 1em;
      }

      .explicit-checkbox {
        display: inline;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 20px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      /* Hide the browser's default checkbox */
      .explicit-checkbox input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        border-radius: 4px;
        border: 2px solid var(--secondary-dark-color);
      }

      /* On mouse-over, add a grey background color */
      .explicit-checkbox:hover input ~ .checkmark {
        background-color: #ccc;
      }

      /* When the checkbox is checked, add a blue background */
      .explicit-checkbox input:checked ~ .checkmark {
        background-color: var(--secondary-dark-color);
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: '';
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .explicit-checkbox input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .explicit-checkbox .checkmark:after {
        left: 6px;
        top: 1px;
        width: 9px;
        height: 15px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      .btn-group {
        display: block;
      }
      .btn-group > .btn {
        margin-bottom: 2px;
      }
    `
    ],
    imports: [
        NgIf,
        InlineSpinnerComponent,
        QuestionLabelContentComponent,
        ReactiveFormsModule,
        NgSwitch,
        NgSwitchCase,
        NgFor,
        NgbButtonsModule,
        NgSwitchDefault,
        QuestionHintComponent,
        CoreComponentsAngularModule
    ]
})
export class CheckboxButtonGroupQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  @ViewChild('buttonGroupInput') input;
  type: string;
  checkboxGroupForm: UntypedFormGroup;
  initialised: boolean;
  style: string;
  private untilDestroyed = untilDestroyed();
  private sourceData = getDataSourceData();

  constructor(private scrollService: ScrollService, private spinnerService: NgxSpinnerService) {
    super();
  }

  /**
   * Initialise the default form values.
   */
  async ngOnChanges(changes: SimpleChanges) {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    this.question['options'] = this.question['options'] || [];
    this.setStyle();
    if (this.question.datasource) {
      if (!this.question.datasource.transform) {
        this.question.datasource.transform =
          '[].{ text: join(`" - "`, [parent.name, name]), value: join(`" - "`, [parent.name, name]) }';
      }
      await this.spinnerService.show(this.question.key);
      this.mapData(await this.sourceData.getData(this.question.datasource), this.question.datasource, this.question);
      this.render();
      await this.spinnerService.hide(this.question.key);
    } else {
      this.render();
    }
  }

  render() {
    const values = (this.value && this.value.split(',')) || [];
    const checkboxModel: { [key: string]: UntypedFormControl } = {};
    for (const option of this.question?.options ?? []) {
      checkboxModel[option.key] = new UntypedFormControl(values.includes(option.value));
    }
    this.checkboxGroupForm = new UntypedFormGroup(checkboxModel);
    this.checkboxGroupForm.valueChanges.pipe(this.untilDestroyed()).subscribe(c => {
      const selectedValues = Object.keys(c).filter(value => c[value] === true);
      this.form?.get(this.question.key)?.patchValue(selectedValues.join(','));
      if (this.question.fullValuesKey) {
        const fullValues = (this.question?.options ?? []).filter(opt => selectedValues.includes(opt.value));
        const fullValuesField = this.form.get(this.question.fullValuesKey);
        if (fullValuesField) {
          fullValuesField.patchValue(fullValues);
        } else {
          this.form.addControl(this.question.fullValuesKey, new UntypedFormControl(fullValues));
        }
      }
    });

    this.initialised = true;
    this.scrollService.scrollObservable.pipe(this.untilDestroyed()).subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
  }

  public mapData(result: any[], datasource: Datasource, question?: QuestionBase<string>) {
    const options = [];
    const transformed = JmesPath.search(result, datasource.transform);
    transformed
      .sort((a, b) => (typeof a.order === 'number' && typeof b.order === 'number' ? a.order - b.order : 0))
      .forEach(item => {
        const initialText = item.text;
        const initialValue = item.value;

        if (typeof initialValue === 'string') {
          options.push({
            key: initialValue,
            value: initialText
          });
        } else if (Array.isArray(initialValue)) {
          for (const child of initialValue) {
            options.push({
              key: `${initialText} - ${child}`,
              value: `${initialText} - ${child}`
            });
          }
        }
      });

    if (question) {
      if (options && options.length > 0) {
        question.options = options;
      } else {
        if (!question.options || !question.options.length) {
          question.options = [];
        }
      }
    }
  }

  get formValues() {
    return (this.value && this.value.split(',')) || [];
  }

  get value() {
    return this.form?.get(this.question.key)?.value || null;
  }

  get optionsPopulated(): boolean {
    if (this.question.datasource) {
      return true;
    }
    return this.question.options && this.question.options.length > 0;
  }

  get isInvalid() {
    return this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid;
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }

  alert(key: string) {
    return this.question.alert && this.question.alert[key];
  }

  getButtonClass(opt) {
    return this.checkboxGroupForm.get(opt.key).value ? 'btn-primary' : 'btn-default';
  }

  private setStyle() {
    if (this.question.style) {
      this.style = this.question.style;
    } else if (this.question.vertical) {
      this.style = 'vertical-buttons';
    } else {
      this.style = 'horizontal-buttons';
    }
  }
}
