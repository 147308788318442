import { state, style, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Card, I18nKeys } from '@jump-tech-frontend/cards';
import { RelayParameters } from '../../domain/relay-parameters';
import { FormPresenter } from './form.presenter';
import { FormViewModel } from './form.view.model';
import { AbstractControl, FormGroup } from '@angular/forms';
import { SpinnerService } from '../../spinner.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ActionsComponent } from '../actions/actions.component';
import { CardComponent } from '@jump-tech-frontend/cards';
import { QuestionGroupComponent } from '@jump-tech-frontend/question-components';
import { SummaryComponent } from '../summary/summary.component';
import { LogoComponent } from '../logo/logo.component';
import { NgIf, NgClass } from '@angular/common';
import { UploadProgressComponent } from '../upload-progress/upload-progress.component';
import { ErrorComponent } from '../error/error.component';
import { EnvironmentToken } from '@jump-tech-frontend/app-config';
import { RelayEnvironment } from '../../domain/relay-environment';

@Component({
  selector: 'relay-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  animations: [
    trigger('fixedProgress', [
      state(
        'fixed',
        style({
          position: 'fixed',
          top: 0,
          width: '100%',
          'z-index': 10
        })
      ),
      state(
        'inline',
        style({
          position: 'block'
        })
      )
    ]),
    trigger('cardsTop', [
      state(
        'fixed',
        style({
          position: 'relative',
          top: '8px'
        })
      ),
      state(
        'inline',
        style({
          position: 'inline'
        })
      )
    ])
  ],
  imports: [
    ErrorComponent,
    UploadProgressComponent,
    NgIf,
    LogoComponent,
    SummaryComponent,
    NgClass,
    QuestionGroupComponent,
    CardComponent,
    ActionsComponent,
    NgxSpinnerModule
  ],
  providers: [FormPresenter, SpinnerService]
})
export class FormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() readOnly = false;
  @Input() demo = false;
  @Input() data?: Record<string, unknown>;
  @Input() cards?: Card<unknown>[];
  @Input() currentCard: number;
  @Input() tenant: string;
  @Input() i18ns: I18nKeys;
  @Input() viewingTenant: string;

  @ViewChild('boxEl') boxEl: ElementRef;
  @ViewChild('actionsEl') actionsEl: ElementRef;

  private scrolling = false;
  private isNucleusPreview = false;
  private isDesktop = false;

  vm: FormViewModel;

  constructor(
    private formPresenter: FormPresenter,
    private spinnerService: SpinnerService,
    @Inject(EnvironmentToken) private environment: RelayEnvironment,
    private el: ElementRef
  ) {}

  private static scrollTop() {
    if (window && window.pageYOffset) {
      setTimeout(() => {
        window.scroll(0, 0);
      }, 400);
    }
  }

  ngOnInit() {
    this.isNucleusPreview = location.hash.indexOf('/settings/relay-forms') !== -1;
    this.isDesktop = window.innerWidth >= 1440; // laptop screen width

    this.formPresenter.load(vm => {
      this.vm = vm;
      this.vm.readOnly = this.readOnly;
      this.vm.demo = this.demo;
      this.vm.wrapperClass = this.readOnly && !this.demo ? 'readOnly' : '';
      this.vm.showClose = this.readOnly && !this.demo;
      const loaderEl$ = document.getElementById('loading');
      if (loaderEl$) {
        loaderEl$.style.display = 'none';
      }
      this.spinnerService.hideSpinner();
      this.computePosition();
    });
  }

  private computePosition(): void {
    if (!this.isDesktop && !this.isNucleusPreview) {
      const $boxElement = this.boxEl?.nativeElement;
      if ($boxElement) {
        const $actionsElementHeight = this.actionsEl.nativeElement.querySelector('.js-survey-actions').offsetHeight;
        const $logoHeight = $boxElement.querySelector('#logoComponent').offsetHeight;

        const scrollPosition = window.pageYOffset;

        if (scrollPosition > $logoHeight) {
          this.vm.state = 'fixed';
          $boxElement.style.paddingBottom = `${$actionsElementHeight + 16}px`;
        } else {
          this.vm.state = 'inline';
          $boxElement.style.paddingBottom = `${$actionsElementHeight + 16}px`;
        }
      }
    }
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (
      !this.tenant ||
      (!Object.prototype.hasOwnProperty.call(changes, 'cards') &&
        !Object.prototype.hasOwnProperty.call(changes, 'currentCard'))
    ) {
      return;
    }
    let parameters: RelayParameters = null;
    if (this.cards) {
      parameters = {
        tenant: this.tenant,
        viewingTenant: this.viewingTenant,
        data: this.data,
        cards: this.cards,
        currentCard: this.currentCard,
        i18nKeys: this.i18ns,
        ignoreShowIf: this.demo,
        readOnly: this.readOnly
      };
    }
    this.formPresenter.setParameters(parameters);
  }

  ngOnDestroy(): void {
    this.formPresenter.cleanup();
  }

  async cardChanged() {
    FormComponent.scrollTop();
  }

  /**
   * Check if the Progress component is in view and
   * switch between fixed / relative css display
   */
  @HostListener('window:scroll')
  checkScroll() {
    if (this.scrolling) {
      return;
    }

    this.scrolling = true;
    if (!this.boxEl || !this.actionsEl) {
      return;
    }
    this.computePosition();
    this.scrolling = false;
  }

  asFormGroup(control: AbstractControl) {
    return control as FormGroup;
  }
}
