
<div class="schedule-event-selected-job-details__tradesperson">
  <div class="schedule-event-selected-job-details__tradesperson-data">
    {{ vm.assignedToDisplayName }}
    <span *ngIf="vm.isLeadTradesperson" class="schedule-event-selected-job-details__lead">{{ vm.i18nLead }}</span>
  </div>
  @if (!vm.readonly) {
    <div class="schedule-event-selected-job-details__tradesperson-data">
    <span class="schedule-event-selected-job-details__tradesperson-icon">
      <jui-icon
        name="person"
        [type]="vm.type"
        [color]="vm.color"
        size="xs"
        [attr.data-qa]="vm.qaSetLeadEngineerBtn"
        [title]="vm.titleText"
        (click)="setLeadTradesperson.emit(vm)"
      ></jui-icon>
    </span>
      <span class="schedule-event-selected-job-details__tradesperson-icon">
        <jui-icon
          name="delete"
          color="high"
          size="xs"
          [title]="vm.i18nRemoveTradespersonBtn"
          [disabled]="vm.removeDisabled"
          [attr.data-qa]="vm.qaRemoveEngineerBtn"
          (click)="removeTradesperson.emit(vm)"
        ></jui-icon>
      </span>
    </div>
  }
</div>
@if (vm.overlapDetails.length) {
  <div class="schedule-event-selected-job-details__overlaps-container">
    <schedule-event-selected-job-details-overlaps [overlapDetails]="vm.overlapDetails" [overlapLabel]="vm.i18nOverlapLabel"></schedule-event-selected-job-details-overlaps>
  </div>
}
