<project-audit-logs-errors></project-audit-logs-errors>
<div *ngIf="vm$ | async as vm">
  <div class="audit-table-container" [ngClass]="vm.displayProjectId ? 'audit-table-container--wider' : ''">
    <div class="table-entry heading">{{ 'common.status' | transloco }}</div>
    <div *ngIf="vm.displayProjectId" class="table-entry heading">Project ID</div>
    <div class="table-entry heading">{{ 'common.date' | transloco }}</div>
    <div class="table-entry heading no-wrap">{{ 'auditLogs.eventName' | transloco }}</div>
    <div class="table-entry heading no-wrap">{{ 'auditLogs.actionedBy' | transloco }}</div>
    <div class="table-entry heading no-wrap">{{ 'auditLogs.details' | transloco }}</div>

    @if (vm.showLoader) {
      @for (i of loaderRange; track $index) {
        <ng-container>
          <div class="audit-log-row">
            <div class="table-entry">
              <jui-skeleton-loader height="25px" type="circle" random="false" width="25px" color="success"></jui-skeleton-loader>
            </div>
            <div class="table-entry">
              <jui-skeleton-loader height=".75rem" random="true"></jui-skeleton-loader>
            </div>
            <div class="table-entry">
              <jui-skeleton-loader height=".75rem" random="true"></jui-skeleton-loader>
            </div>
            <div class="table-entry">
              <jui-skeleton-loader height=".75rem" random="true"></jui-skeleton-loader>
            </div>
            <div class="table-entry">
              <jui-skeleton-loader height=".75rem" random="true"></jui-skeleton-loader>
            </div>
          </div>
        </ng-container>
      }
    } @else {
      <ng-container *ngFor="let auditLog of vm.filteredAuditLogs">
        <div
          [ngSwitch]="!(auditLog.messageOptions === undefined || auditLog.messageOptions === null)"
          class="audit-log-row"
        >
          <div *ngSwitchCase="false" [ngSwitch]="auditLog.status" class="table-entry">
            <jui-icon
              *ngSwitchCase="AuditLogStatus.SUCCESS"
              isButton="false"
              color="success"
              name="check_circle"
              titleText="{{ auditLog.status }}"
            ></jui-icon>
            <jui-icon
              *ngSwitchCase="AuditLogStatus.FAILURE"
              isButton="false"
              color="danger"
              name="warning"
              titleText="{{ auditLog.status }}"
            ></jui-icon>
            <jui-icon *ngSwitchDefault isButton="false" color="info" name="help"></jui-icon>
          </div>
          <div *ngSwitchCase="true" [ngSwitch]="auditLog.status" class="table-entry">
            <jui-icon
              *ngSwitchCase="AuditLogStatus.SUCCESS"
              isButton="false"
              color="success"
              name="check_circle"
              titleText="{{ localeContext + '.' + auditLog.status.toLowerCase() | transloco }}"
            ></jui-icon>
            <jui-icon
              *ngSwitchCase="AuditLogStatus.FAILURE"
              isButton="false"
              color="danger"
              name="warning"
              titleText="{{ localeContext + '.' + auditLog.status.toLowerCase() | transloco }}"
            ></jui-icon>
            <jui-icon *ngSwitchDefault isButton="false" color="info" name="help"></jui-icon>
          </div>
          <div *ngIf="vm.displayProjectId" class="table-entry">
            {{ auditLog.projectId }}
            <div class="open-project-btn">
              <jui-button size="sm" color="primary" (click)="goToProject(auditLog.projectId)">Go to project</jui-button>
            </div>
          </div>
          <div class="table-entry no-wrap" [attr.data-qa]="'auditLogCreatedOn'">
            {{ displayLocalisedDateValue(auditLog.created_on) }}
          </div>
          <div *ngSwitchCase="false" class="table-entry" [attr.data-qa]="'auditLogEventName'">
            {{ auditLog.eventName }}
          </div>
          <div *ngSwitchCase="true" class="table-entry" [attr.data-qa]="'auditLogEventName'">
            {{ displayEventName(auditLog) }}
          </div>
          <div class="table-entry" [attr.data-qa]="'auditLogUserName'">{{ auditLog.userName }}</div>
          <div
            *ngSwitchCase="false"
            class="table-entry audit-log-data-container"
            [attr.data-qa]="'auditLogDataContainer'"
          >
            <div *ngFor="let dataItem of auditLog.data" class="audit-log-data" [attr.data-qa]="'auditLogData'">
              <strong>{{ dataItem.key | camelToReadable }}</strong
              >&nbsp;{{ dataItem.value | sanitiseValue }}
            </div>
          </div>
          <div *ngSwitchCase="true" class="table-entry audit-log-data-container" [attr.data-qa]="'auditLogDataContainer'">
            <div
              *ngFor="let dataItem of auditLog.messageOptions.data"
              class="audit-log-data"
              [attr.data-qa]="'auditLogData'"
            >
              <div *ngIf="dataItem.value">
                <strong>{{ displayDataItemKey(dataItem.key) }}</strong
                >&nbsp;{{ displayDataItemValue(dataItem) }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    }
  </div>
</div>
