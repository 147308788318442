import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { AssignedTradesPeopleVm } from '../../../../schedule.model';

@Component({
    selector: 'schedule-event-selected-job-details-add-tradesperson-form',
    templateUrl: 'schedule-event-selected-job-details-add-tradesperson-form.component.html',
    styleUrls: ['../../../schedule-event-selected-job-details/schedule-event-selected-job-details.component.scss'],
    imports: [CoreComponentsAngularModule, NgSelectModule, ReactiveFormsModule, NgIf]
})
export class ScheduleEventSelectedJobDetailsAddTradespersonFormComponent {
  @ViewChild('addTradespersonForm')
  public addEngineerFormEl: ElementRef;
  @Input() vm: AssignedTradesPeopleVm;
  @Output() confirmAddTradesperson: EventEmitter<void> = new EventEmitter<void>();
  @Output() cancelAddTradesperson: EventEmitter<void> = new EventEmitter<void>();
  @Output() addTradesperson: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

  constructor() {}
}
