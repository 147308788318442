import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MyOrdersViewPresenter } from './order-view.presenter';
import { MyOrdersVm, OrderViewVm, ProductResource } from '../my-orders.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { MyOrdersFulfilmentComponent } from '../components/order-fulfilment/order-fulfilment.component';
import { MyOrdersSummaryComponent } from '../components/order-summary/order-summary.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
  selector: 'pathway-my-orders-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MyOrdersSummaryComponent, MyOrdersFulfilmentComponent, CoreComponentsAngularModule, AsyncPipe],
  providers: [MyOrdersViewPresenter]
})
export class MyOrdersViewComponent implements OnChanges {
  @Input() refresh = false;
  vm$: BehaviorSubject<OrderViewVm> = new BehaviorSubject<OrderViewVm>(null);

  constructor(private presenter: MyOrdersViewPresenter) {}

  ngOnChanges(): void {
    this.presenter.load(this.vm$);
  }
  cancelOrder(vm): void {
    this.presenter.cancelOrder(vm.id);
  }
}
