import { Component, inject, input, signal } from '@angular/core';
import { Router } from '@angular/router';
import { NewProjectModalComponent } from '../../core/new-project/new-project-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
    selector: 'app-add-project-button',
    template: `
    @if (isProjectCreationAllowed()) {
    <div *ngIf="full()" class="action-button-wrapper">
      <jui-button size="sm" (click)="addProject()" [attr.data-qa]="'newProjectButton'">
        <span slot="icon" class="material-icons">playlist_add</span>
        {{ 'common.newProject' | transloco }}
      </jui-button>
    </div>
    <div *ngIf="!full()" class="action-button-wrapper">
      <jui-icon name="playlist_add" size="sm" (click)="addProject()" [titleText]="'common.newProject' | transloco">
      </jui-icon>
    </div>
    }
  `,
    styleUrls: ['dashboard-action-buttons.scss'],
    imports: [NgIf, TranslocoModule, CoreComponentsAngularModule]
})
export class AddProjectButtonComponent {
  full = input<boolean>(false);
  private readonly featureAccessService: AccessService = inject(AccessService);
  private readonly modalService: NgbModal = inject(NgbModal);
  private readonly router: Router = inject(Router);

  public isProjectCreationAllowed = signal(
    this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.ProjectCreation, true)
  );

  addProject() {
    const modalRef = this.modalService.open(NewProjectModalComponent, { backdrop: 'static' });
    modalRef.result.then(async (result: { id: string }) => {
      await this.router.navigate([`/project/${result.id}`]);
    });
  }
}
