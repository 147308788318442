import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { AuditLog, AuditLogStatus, JumptechDate } from '@jump-tech-frontend/domain';
import { ProjectAuditLogsPresenter } from './project-audit-logs.presenter';
import { BehaviorSubject } from 'rxjs';
import { ProjectAuditLogsVm, LOCALE_CONTEXT } from './project-audit-logs.model';
import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { TranslocoModule, TranslocoPipe } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-project-last-audit-log',
  templateUrl: 'project-last-audit-log.component.html',
  styleUrls: ['project-last-audit-log.component.scss'],
  imports: [CoreComponentsAngularModule, NgbAlertModule, TranslocoModule, AsyncPipe, NgSwitch, NgSwitchCase, NgIf],
  providers: [ProjectAuditLogsPresenter, TranslocoPipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectLastAuditLogComponent implements OnInit, OnDestroy, OnChanges {
  isLoading: boolean;
  private initialised = false;
  public localeContext = LOCALE_CONTEXT;

  public vm$: BehaviorSubject<ProjectAuditLogsVm> = new BehaviorSubject<ProjectAuditLogsVm>(null);

  constructor(private presenter: ProjectAuditLogsPresenter, private transloco: TranslocoPipe) {}

  @Input() projectId: string;
  @Input() set loading(loading) {
    this.isLoading = loading;
  }

  @Output() auditLogs: EventEmitter<AuditLog[] | null> = new EventEmitter<AuditLog[] | null>();

  async ngOnInit() {
    await this.presenter.load(this.projectId, this.vm$);
    this.vm$.subscribe(vm => {
      this.emitAuditLogs(vm?.allAuditLogs || null);
    });
    this.initialised = true;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (this.initialised && changes['projectId'] !== undefined) {
      this.ngOnDestroy();
      await this.presenter.load(this.projectId, this.vm$);
    }
  }
  ngOnDestroy() {
    this.presenter.unload();
  }

  private emitAuditLogs(auditLogs: AuditLog[] | null) {
    this.auditLogs.emit(auditLogs);
  }

  public displayAuditStatus(status: AuditLogStatus) {
    return this.transloco.transform(`${LOCALE_CONTEXT}.${status.toLowerCase()}`);
  }

  getDate(date: string): string {
    return JumptechDate.from(date).toDateTimeFormat();
  }
}
