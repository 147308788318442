import * as XRegExp from 'xregexp';
import { CardLayoutItem } from '../domain/card-layout-item';
import { get } from 'lodash';

export type IfCheck = {
  $and?: IfDefinition[];
  $or?: IfDefinition[];
  $eq?: IfTarget;
  $ne?: IfTarget;
  $gt?: IfTarget;
  $lt?: IfTarget;
  $gte?: IfTarget;
  $lte?: IfTarget;
  $mr?: IfTarget;
  $nmr?: IfTarget;
  $in?: IfTarget;
  $nin?: IfTarget;
  $aom?: IfDefinition;
  $aonm?: IfDefinition;
};

export type IfTarget = string | number | { property: string } | unknown;

export type IfDefinition = {
  [key: string]: string | IfCheck | IfDefinition[] | IfTarget;
};

const isInList = (actual: any, targetListSting: string) => {
  return targetListSting
    .split(',')
    .map(targetItem => targetItem.trim())
    .includes(actual);
};

// Only use this method - it covers both new and old formats
export const checkIfExpression = (expression: IfDefinition, target: any, actualValue?: any): boolean => {
  if (!expression) {
    return true;
  }

  const key = (expression as IfDefinition).key;
  const value = (expression as IfDefinition).value;

  if (expression && key && typeof key === 'string') {
    actualValue = getPropertyValue(target, key);
  }

  if (expression && value && typeof value === 'string') {
    return actualValue === value;
  }

  let expressionOrCheck: IfDefinition = expression;
  if (typeof value === 'object') {
    expressionOrCheck = value as IfCheck;
  }

  return evaluateExpression(expressionOrCheck, target, actualValue);
};

const evaluateExpression = (expression: IfDefinition | IfCheck, target: any, actualValue?: any): boolean => {
  if (typeof expression !== 'object') {
    return false;
  }

  const keys = Object.keys(expression);
  const propertyOrOperator = keys[0];
  const targetValue = getTargetValue(target, expression[propertyOrOperator]);
  if (propertyOrOperator) {
    switch (propertyOrOperator) {
      case '$or': {
        return (
          Array.isArray(targetValue) && targetValue.some(thisExpression => evaluateExpression(thisExpression, target))
        );
      }
      case '$and': {
        return (
          Array.isArray(targetValue) && targetValue.every(thisExpression => evaluateExpression(thisExpression, target))
        );
      }
      case '$eq': {
        return actualValue === targetValue || (!!actualValue && targetValue === '*');
      }
      case '$ne': {
        return actualValue !== targetValue;
      }
      case '$gt': {
        return Array.isArray(actualValue) ? actualValue.length > targetValue : actualValue > targetValue;
      }
      case '$gte': {
        return Array.isArray(actualValue) ? actualValue.length >= targetValue : actualValue >= targetValue;
      }
      case '$lt': {
        return Array.isArray(actualValue) ? actualValue.length < targetValue : actualValue < targetValue;
      }
      case '$lte': {
        return Array.isArray(actualValue) ? actualValue.length <= targetValue : actualValue <= targetValue;
      }
      case '$in': {
        return isInList(actualValue, targetValue);
      }
      case '$nin': {
        return !isInList(actualValue, targetValue);
      }
      case '$mr': {
        return XRegExp(targetValue, 'gi').test(actualValue);
      }
      case '$nmr': {
        return !XRegExp(targetValue, 'gi').test(actualValue);
      }
      case '$inc': {
        return Array.isArray(actualValue) ? actualValue.includes(targetValue) : false;
      }
      case '$ninc': {
        return !Array.isArray(actualValue) ? actualValue.includes(targetValue) : true;
      }
      case '$aom': {
        return (
          Array.isArray(actualValue) &&
          actualValue.some(av => {
            return evaluateExpression(targetValue, av);
          })
        );
      }
      case '$aonm': {
        return (
          !Array.isArray(actualValue) ||
          !actualValue.some(av => {
            return evaluateExpression(targetValue, av);
          })
        );
      }
      default: {
        const actualValue = getPropertyValue(target, propertyOrOperator);
        return evaluateExpression(targetValue, target, actualValue);
      }
    }
  }
  return false;
};

function isEmpty(value) {
  return value === undefined || value === null || value.length === 0;
}

export const getTargetValue = (target, targetValue: any) => {
  if (targetValue && typeof targetValue === 'object' && targetValue.property) {
    return getPropertyValue(target, targetValue.property);
  }

  return targetValue;
};

export const getPropertyValue = (value: any, propertyKey: string) => {
  try {
    const resolved = get(
      {
        ...value,
        ...(value?.data ?? {})
      },
      propertyKey
    );
    if (!isEmpty(resolved)) {
      return resolved;
    }
  } catch (e) {
    console.log('Path does not exist', propertyKey);
  }

  return '';
};

/**
 * If the item has a showIf condition, then evaluate it, else follow show,
 * if defined; default to showing (true).
 * @param item
 * @param data
 */
export const showCardItem = (item: CardLayoutItem, data: any): boolean => {
  if (item?.editConfig?.showIf !== undefined) {
    return checkIfExpression(item.editConfig.showIf, data);
  }

  if (item?.editConfig?.show !== undefined) {
    return item.editConfig.show;
  }

  return true;
};

export const getExpressionValue = (
  expression: IfDefinition,
  result?: Record<string, unknown>
): Record<string, unknown> => {
  result = result ?? {};

  if (!expression) {
    return result;
  }

  const key = (expression as IfDefinition).key;
  const value = (expression as IfDefinition).value;

  if (key && typeof key === 'string' && value && typeof value === 'string') {
    result[key] = value;
    return result;
  }

  let expressionOrCheck: IfDefinition = expression;
  if (typeof value === 'object') {
    expressionOrCheck = value as IfCheck;
  }

  return evaluateValue(expressionOrCheck, result);
};

const evaluateValue = (
  expression: IfDefinition | IfCheck,
  result: Record<string, unknown>,
  property?: string
): Record<string, unknown> => {
  if (typeof expression !== 'object') {
    return result;
  }

  const keys = Object.keys(expression);
  const propertyOrOperator = keys[0];
  const targetValue = expression[propertyOrOperator];
  const targetProperty = getTargetProperty(expression[propertyOrOperator]);

  if (propertyOrOperator) {
    switch (propertyOrOperator) {
      case '$or': {
        result = evaluateValue(targetValue[0], result);
        break;
      }
      case '$and': {
        for (const targetAndValue of targetValue) {
          result = evaluateValue(targetAndValue, result);
        }
        break;
      }
      case '$eq': {
        result = setValue(property, targetValue, result);
        if (targetProperty) {
          result = setValue(targetProperty, targetValue, result);
        }
        break;
      }
      case '$ne': {
        result = setValue(property, `NOT_${targetValue}`, result);
        if (targetProperty) {
          result = setValue(targetProperty, `NOT_MATCHING_${targetValue}`, result);
        }
        break;
      }
      case '$gt': {
        result = setValue(property, targetValue + 1, result);
        if (targetProperty) {
          result = setValue(targetProperty, targetValue + 2, result);
        }
        break;
      }
      case '$gte': {
        result = setValue(property, targetValue, result);
        if (targetProperty) {
          result = setValue(targetProperty, targetValue, result);
        }
        break;
      }
      case '$lt': {
        result = setValue(property, targetValue ? targetValue - 1 : 0, result);
        if (targetProperty) {
          result = setValue(targetProperty, targetValue ? targetValue - 2 : -1, result);
        }
        break;
      }
      case '$lte': {
        result = setValue(property, targetValue, result);
        if (targetProperty) {
          result = setValue(targetProperty, targetValue, result);
        }
        break;
      }
      case '$in': {
        result = setValue(property, targetValue, result);
        if (targetProperty) {
          result = setValue(targetProperty, targetValue, result);
        }
        break;
      }
      case '$nin': {
        result = setValue(property, `NOT_${targetValue}`, result);
        if (targetProperty) {
          result = setValue(targetProperty, `NOT_MATCHING_${targetValue}`, result);
        }
        break;
      }
      // case '$mr':
      //   return XRegExp(targetValue, 'gi').test(actualValue);
      // case '$nmr':
      //   return !XRegExp(targetValue, 'gi').test(actualValue);
      case '$inc': {
        result = setValue(property, [targetValue], result);
        if (targetProperty) {
          result = setValue(targetProperty, [targetValue], result);
        }
        break;
      }
      case '$aom': {
        let aomResult: Record<string, unknown> = {};
        aomResult = evaluateValue(targetValue, aomResult);
        result = setValue(property, [aomResult], result);
        if (targetProperty) {
          result = setValue(targetProperty, [aomResult], result);
        }
        break;
      }
      case '$aonm': {
        let aonmResult: Record<string, unknown> = {};
        aonmResult = evaluateValue(targetValue, aonmResult);
        const aKeys = Object.keys(aonmResult);
        aonmResult[aKeys[0]] = `NOT_${aonmResult[aKeys[0]]}`;
        result = setValue(property, [aonmResult], result);
        if (targetProperty) {
          const other = { ...aonmResult };
          other[aKeys[0]] = `NOT_${other[aKeys[0]]}`;
          result = setValue(targetProperty, [other], result);
        }
        break;
      }
      default: {
        return evaluateValue(targetValue, result, propertyOrOperator);
      }
    }
  }
  return result;
};

export const getTargetProperty = (targetValue: any) => {
  if (targetValue && typeof targetValue === 'object' && targetValue.property) {
    return targetValue.property;
  }
  return null;
};

export const setValue = (property: string, value: unknown, result: Record<string, unknown>) => {
  const parts = property.split('.');
  if (parts.length === 1) {
    result[property] = value;
    return result;
  }
  let local = {};
  let localValue: Record<string, unknown> | unknown = value;
  for (const part of parts.reverse()) {
    const partValue = {};
    partValue[part] = localValue;
    localValue = partValue;
  }

  local = localValue;
  result = { ...result, ...local };

  return result;
};
