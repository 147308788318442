import { Component, OnInit } from '@angular/core';
import { SearchResultsInlinePresenter } from './search-results-inline-presenter';
import { ISearchDataVm } from '../search.model';
import { SearchResultInlineComponent } from './search-result-inline.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-search-results-inline',
    template: `
    <div *ngIf="vm.showResults" class="search-results-wrapper">
      <div class="card">
        <div class="card-header">
          <div class="search-data">
            {{ vm.resultFoundText }}:
            <span class="search-data__count"> {{ vm.results.length }} </span>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <app-search-result-top *ngFor="let searchResult of vm.results" [vm]="searchResult"></app-search-result-top>
        </ul>
      </div>
    </div>

    <div *ngIf="vm.showNoResults" class="search-results-wrapper">
      <div class="card">
        <div class="card-header">
          {{ vm.resultFoundText }}
        </div>
      </div>
    </div>
  `,
    styleUrls: ['../search.component.scss'],
    imports: [NgIf, NgFor, SearchResultInlineComponent],
    providers: [SearchResultsInlinePresenter]
})
export class SearchResultsInlineComponent implements OnInit {
  vm: ISearchDataVm = {} as ISearchDataVm;

  constructor(private presenter: SearchResultsInlinePresenter) {}

  async ngOnInit() {
    await this.presenter.load(generatedViewModel => {
      this.vm = generatedViewModel;
    });
  }
}
