@if (vm$ | async; as vm) {
  @if (vm.visible) {
    <div class="project-status-change">
      <label
        class="project-status-change__label"
        for="project-status-change-select-label"
      >
        {{ vm.i18nProjectStatusChangeLabel }}
      </label>
      <ng-select
        labelForId="project-status-change-select-label"
        class="jui-ng-select"
        [placeholder]="vm.i18nProjectStatusChangePlaceholder"
        [items]="vm.projectStatuses"
        [searchable]="false"
        (change)="changeProjectStatus($event)"
        [(ngModel)]="vm.selectedStatus"
        [disabled]="vm.disabled"
        [attr.data-qa]="'projectStatusChangeSelect'"
      >
      </ng-select>
    </div>
  }
}
