import { Injectable } from '@angular/core';
import { debounceTime, fromEvent, map, Observable, startWith } from 'rxjs';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';

@Injectable({
  providedIn: 'root'
})
export class LayoutComponentColumnsService {
  private readonly minProjectDetailCols = 2;
  private readonly maxProjectDetailCols = 4;
  private readonly maxProjectColWidth = 500;
  private readonly nonTabsWidth = 500;
  private readonly untilDestroyed = untilDestroyed();

  public subscribe(layoutsLength = 1, id: string): Observable<number> {
    if (layoutsLength < this.minProjectDetailCols) {
      layoutsLength = this.minProjectDetailCols;
    }
    const getTabsWidth = () =>
      Math.max(window.innerWidth - this.nonTabsWidth, document?.getElementById(id)?.offsetWidth);
    const currentCols = () =>
      Math.min(Math.ceil(getTabsWidth() / this.maxProjectColWidth), Math.min(layoutsLength, this.maxProjectDetailCols));
    return fromEvent(window, 'resize').pipe(
      debounceTime(300),
      startWith(currentCols()),
      this.untilDestroyed(),
      map(() => currentCols())
    );
  }
}
