import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { NgFor, NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { EnvironmentService } from '../../../environments/environment.service';
import { ApiEnvironmentListItem, PathwayApiEnvironment } from '../../../environments/pathway-api-environment';
import * as Analytics from '../../app.analytics';
import {
  BETA_REPORTS_PATH,
  CONFIGURATION_PATH,
  PLANNING_MAP_PATH,
  PROJECTS_PATH,
  REPORTS_PATH,
  SEARCH_PATH,
  TASKS_PATH,
  TENANT_AUDIT_PATH,
  USER_MANAGEMENT_PATH
} from '../../app.routes';
import { PathwayFeature } from '../../auth/services/access.service';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { RouteAccessService } from '../../auth/services/route-access.service';
import { UserService } from '../../auth/services/user.service';
import { User, UserPreferenceType } from '../domain/user';
import { FeatureFlagService } from '../feature-flag/feature-flag.service';
import { FeatureFlagDirective } from '../feature-flag/featureFlagDirective';
import { UserPreferencesService } from '../user-preferences.service';
import { UserThemeService } from '../user-theme.service';
import { MenuItem, MenuItemComponent } from './menu-item/menu-item.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  imports: [
    NgIf,
    NgbTooltipModule,
    CoreComponentsAngularModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    NgxIntlTelInputModule,
    MenuItemComponent,
    NgFor,
    FeatureFlagDirective,
    TranslocoModule
  ]
})
export class NavbarComponent implements OnInit {
  @Input() isSmallScreen: boolean;
  @Input() isPreview: boolean;
  @Input() isLocal: boolean;
  @Input() branchName: string;
  @Input() branchShortName: string;
  @Output() navToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onBranchReset: EventEmitter<void> = new EventEmitter<void>();

  private destroyRef: DestroyRef = inject(DestroyRef);

  menuOpen = true;
  isClosed = false;
  activeUrl: string = null;
  private user: User;
  PathwayFeature = PathwayFeature;
  navPreference = UserPreferenceType.NavShrink;
  menuList: MenuItem[];
  activeTheme: string;
  logoSrc = 'assets/Jumptech-Digital-Logo-V2-512px.png';
  apiEnvironmentName: string;
  apiEnvironmentList: ApiEnvironmentListItem[];
  selectedApiEnvironment: ApiEnvironmentListItem;
  apiEnvironment: PathwayApiEnvironment;

  ngOnInit() {
    this.isClosed = this.userPref.getUserPreference(this.navPreference);
    if (!this.isUserSignedIn()) {
      this.isClosed = false;
    }

    this.userService.userObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.isClosed = this.userPref.getUserPreference(this.navPreference);
    });

    this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event: unknown) => {
      if (event?.['url']) {
        this.activeUrl = event['url'].toString();
      }
    });

    this.apiEnvironment = this.environmentService.getCurrentApiEnvironment();
    this.apiEnvironmentName = this.apiEnvironment.name;
    this.apiEnvironmentList = this.environmentService.getApiEnvironmentList();
    this.selectedApiEnvironment = this.apiEnvironmentList.find(env => env.id === this.apiEnvironmentName);
  }

  onNavToggle() {
    this.isClosed = !this.isClosed;
    this.navToggle.emit(this.isClosed);
  }

  onChangeEnvironment() {
    this.apiEnvironmentName = this.selectedApiEnvironment.id;
    this.environmentService.setApiEnvironment(this.apiEnvironmentName);
  }

  isRouteActive(route: string): boolean {
    return this.router.url === route;
  }

  constructor(
    private authenticationService: AuthenticationService,
    private routeAccessService: RouteAccessService,
    private router: Router,
    private userService: UserService,
    private featureFlagService: FeatureFlagService,
    private userPref: UserPreferencesService,
    public translocoService: TranslocoService,
    private themeService: UserThemeService,
    private environmentService: EnvironmentService
  ) {
    this.userService.userObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user: User) => {
      if (user !== null) {
        this.user = user;
      }
    });
    this.featureFlagService.featureFlag$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.buildMenuList().then();
    });

    this.themeService.theme$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(theme => {
      this.activeTheme = theme;
    });
  }

  isUserSignedIn() {
    return this.authenticationService.isUserSignedIn;
  }

  isAccessAllowed(route: string, allowAdmin = false) {
    return this.routeAccessService.isRouteAccessAllowed(route, allowAdmin);
  }

  isSuperAdmin() {
    return this.user && this.user.isSuperAdmin;
  }

  openExternalLink(url) {
    Analytics.logNavigation(url);
    window.open(url, '_blank');
  }

  async buildMenuList() {
    const scheduleMenuItem: MenuItem = {
      label: `${this.translocoService.translate('navbar.schedule')}`,
      icon: 'today',
      hasAccess: this.isAccessAllowed('schedule'),
      qaHook: 'menuItemSchedule',
      routeLink: '/schedule'
    };

    const rollingMonthViewEnabled = await this.featureFlagService.isFeatureEnabled('rolling-month-schedule-view');
    const schedulerV3Enabled = await this.featureFlagService.isFeatureEnabled('scheduler-v3');

    if (schedulerV3Enabled) {
      scheduleMenuItem.qaHook += 'V3';
      scheduleMenuItem.routeLink += '-v3';
      scheduleMenuItem.useFeatureToggle = 'scheduler-v3';
      scheduleMenuItem.target += 'v3';
    } else if (!schedulerV3Enabled && rollingMonthViewEnabled) {
      scheduleMenuItem.qaHook += 'V2';
      scheduleMenuItem.routeLink += '-v2';
      scheduleMenuItem.useFeatureToggle = 'rolling-month-schedule-view';
      scheduleMenuItem.target += 'v2';
    }

    this.menuList = [
      {
        label: this.translocoService.translate('navbar.projects'),
        icon: 'ballot',
        qaHook: 'menuItemProjects',
        routeLink: `/${PROJECTS_PATH}`,
        hasAccess: this.isAccessAllowed(PROJECTS_PATH),
        target: 'projects'
      },
      {
        label: this.translocoService.translate('navbar.taskManagement'),
        icon: 'task',
        qaHook: 'menuItemTaskManagement',
        hasAccess: true,
        routeLink: `/${TASKS_PATH}`,
        isBetaFeature: true,
        useFeatureToggle: 'task-management',
        target: 'task'
      },
      {
        label: this.translocoService.translate('navbar.searchProjects'),
        icon: 'search',
        qaHook: 'menuItemSearchProjects',
        hasAccess: this.isAccessAllowed(SEARCH_PATH),
        routeLink: `/${SEARCH_PATH}`,
        target: 'search'
      },
      scheduleMenuItem,
      {
        label: this.translocoService.translate('navbar.map'),
        icon: 'room',
        qaHook: 'menuItemPlanningMap',
        hasAccess: this.isAccessAllowed(PLANNING_MAP_PATH),
        routeLink: `/${PLANNING_MAP_PATH}`,
        isBetaFeature: false,
        target: 'map'
      },
      {
        label: this.translocoService.translate('navbar.reports'),
        icon: 'bar_chart',
        qaHook: 'menuItemReports',
        hasAccess: this.isAccessAllowed(REPORTS_PATH),
        routeLink: `/${REPORTS_PATH}`,
        target: 'reports'
      },
      {
        label: this.translocoService.translate('navbar.reports'),
        icon: 'bar_chart',
        qaHook: 'menuItemReports',
        hasAccess: true,
        routeLink: `/${BETA_REPORTS_PATH}`,
        isBetaFeature: true,
        useFeatureToggle: 'beta-reports',
        target: 'reportsV2'
      },
      {
        label: this.translocoService.translate('navbar.userManagement'),
        icon: 'group',
        qaHook: 'menuItemUserManagement',
        hasAccess: this.isAccessAllowed(USER_MANAGEMENT_PATH, true),
        routeLink: `/${USER_MANAGEMENT_PATH}`,
        target: 'userManagement'
      },
      {
        label: this.translocoService.translate('navbar.settings'),
        icon: 'settings',
        qaHook: 'menuItemConfiguration',
        hasAccess: this.isAccessAllowed(CONFIGURATION_PATH, true),
        routeLink: `/${CONFIGURATION_PATH}`,
        target: 'settings'
      },
      {
        label: this.translocoService.translate('navbar.tenantAudit'),
        icon: 'history',
        qaHook: 'menuItemTenantAudit',
        hasAccess: this.isSuperAdmin(),
        routeLink: `/${TENANT_AUDIT_PATH}`,
        useFeatureToggle: 'tenant-audit',
        target: 'tenantAudit'
      },
      {
        label: this.translocoService.translate('navbar.support'),
        icon: 'contact_support',
        qaHook: 'menuItemSupport',
        hasAccess: true,
        externalLink: this.apiEnvironment.pathwaySupportUrl
      }
    ];
  }

  openMenuItem(event, menuItem: MenuItem) {
    // check ctrl or cmd key / middle mouse click to open in new tab
    if (menuItem.target && (event.ctrlKey || event.metaKey || event.button === 1 || event.wheelDelta)) {
      event.preventDefault();
      event.stopPropagation();

      window.open(menuItem.routeLink, menuItem.target);
    } else {
      this.router.navigate([menuItem.routeLink]);
    }
  }
}
