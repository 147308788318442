import { ChangeDetectionStrategy, Component, inject, input, OnInit, Output, EventEmitter, Signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormUpdate, IQuestionComponent } from '../question.model';
import { TextareaPresenter } from './textarea.presenter';
import { NgIf } from '@angular/common';
import { QUESTIONS } from '../questions.token';
import { TextareaQuestionParams, TextareaQuestionViewModel } from './textarea.model';

@Component({
  selector: 'question-textarea',
  templateUrl: './textarea.component.html',
  imports: [ReactiveFormsModule, NgIf],
  providers: [TextareaPresenter, { provide: QUESTIONS, useExisting: TextareaComponent, multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      textarea::placeholder {
        user-select: none;
      }
    `
  ]
})
export class TextareaComponent implements OnInit, IQuestionComponent {
  params = input.required<TextareaQuestionParams>();
  @Output() formChange: EventEmitter<FormUpdate> = new EventEmitter<FormUpdate>();

  private readonly presenter = inject(TextareaPresenter);
  public vm: Signal<TextareaQuestionViewModel>;

  ngOnInit() {
    this.vm = this.presenter.load(this.params(), this.formChange);
  }
}
