import { Component, ViewChild, Input, AfterViewInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Angular2SignaturePadComponent, untilDestroyed } from '@jump-tech-frontend/angular-common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
  selector: 'crds-signature-pad',
  template: `
    <signature-pad
      [id]="'signature_' + key"
      class="signature"
      [options]="signaturePadOptions"
      onfocus="blur()"
      (beginSignatureEvent)="drawStart()"
      (endSignatureEvent)="drawComplete()"
    >
    </signature-pad>
    <div class="clearSignature">
      <jui-button size="lg" color="low" (click)="clear()">Clear</jui-button>
    </div>
  `,
  standalone: true,
  imports: [Angular2SignaturePadComponent, CoreComponentsAngularModule]
})
export class SignaturePadComponent implements AfterViewInit {
  @Input() form: UntypedFormGroup;
  @Input() key: string;
  @ViewChild(Angular2SignaturePadComponent) signaturePad: Angular2SignaturePadComponent;

  private untilDestroyed = untilDestroyed();

  signaturePadOptions = {
    // passed through to szimek/signature_pad constructor
    canvasHeight: 125,
    backgroundColor: 'rgb(250,250,250)'
  };

  constructor() {
    // no-op
  }

  ngAfterViewInit() {
    this.update();
    fromEvent(window, 'resize')
      .pipe(debounceTime(200), this.untilDestroyed())
      .subscribe(() => {
        this.update();
      });
  }

  update() {
    const clientWidth = document.getElementsByClassName('card-wrapper')?.[0]?.clientWidth;
    this.signaturePad.set('canvasWidth', clientWidth ? clientWidth - 32 : 300);
    this.fromDataUrl();
    this.signaturePad.clear();
  }

  clear() {
    this.signaturePad.clear();
    this.form?.get(this.key)?.reset();
  }

  fromDataUrl() {
    const elementById = document.getElementById(`signature_${this.key}`);
    if (elementById) {
      const myCanvas = elementById.children[0];
      const ctx = (myCanvas as HTMLCanvasElement).getContext('2d');
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        ctx?.drawImage(img, 0, 0); // Or at whatever offset you like
      };
      const imgSrc = this.form.get(this.key)?.value;
      img.src = Array.isArray(imgSrc) ? imgSrc[0] : imgSrc;
    }
  }

  drawComplete() {
    // will be notified of szimek/signature_pad's onEnd event
    const signatureData = this.signaturePad.toDataURL();
    console.log(`form[${this.key}]=`, signatureData);
    this.form.get(this.key)?.setValue([signatureData]);
  }

  drawStart() {
    /**
     * Enable is we need the keyboard to be closed on start of signature
     */
    const emailElement = document.getElementById('email');
    if (emailElement) {
      emailElement.blur();
    }
  }
}
