import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { BehaviorSubject, combineLatest, firstValueFrom, of, Subject, take } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import * as XRegExp from 'xregexp';
import { NgxSpinnerService } from 'ngx-spinner';
import { InlineSpinnerComponent } from './inline-spinner-component/inline-spinner.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { AsyncPipe, NgIf } from '@angular/common';

@Component({
  selector: 'crds-http-image',
  template: ` <div class="http-image-wrapper">
    <ng-container *ngIf="!!latestValue">
      <img
        (click)="clicked.emit(true)"
        [id]="id"
        [class]="imageClass + ' http-image-wrapper__image'"
        [src]="latestValue"
        [alt]="alt"
        [attr.data-qa]="qaHook"
        (load)="loaded$.next(true)"
        (mouseenter)="hoveringImage$.next(true)"
        (mouseleave)="leaveImageHover()"
      />
    </ng-container>
    <ng-container *ngIf="showDownload$ | async">
      <jui-button
        color="primary"
        class="http-image-wrapper__download"
        size="xs"
        display="contained"
        [loading]="downloadingImage$ | async"
        (click)="onImageDownloadEvent()"
        [attr.data-qa]="'downloadImageButton'"
        (mouseenter)="hoveringDownload$.next(true)"
        (mouseleave)="hoveringDownload$.next(false)"
      >
        <span slot="icon" class="material-icons">download</span>
      </jui-button>
    </ng-container>
    <ng-container *ngIf="!latestValue">
      <crds-inline-spinner
        *ngIf="!skeletonLoaderHeight"
        [name]="uuid"
        qaHook="inlineSpinner"
        type="ball-clip-rotate"
      ></crds-inline-spinner>
      <jui-skeleton-loader
        height="{{ skeletonLoaderHeight }}px"
        width="100%"
        [attr.data-qa]="'skeletonLoader'"
      ></jui-skeleton-loader>
    </ng-container>
  </div>`,
  styleUrls: ['./http-image.component.scss'],
  imports: [NgIf, CoreComponentsAngularModule, InlineSpinnerComponent, AsyncPipe]
})
export class HttpImageComponent implements OnInit, OnChanges, OnDestroy {
  private readonly _uuid: string;
  private _id: string;
  private _src: string | SafeUrl | null;
  private _alt: string;
  private _imageClass: string;
  private _qaHook: string;
  private _latestValue: string | SafeUrl | null = null;
  private transformValue = new BehaviorSubject<string | SafeUrl | null>(null);

  private _unsubscribe$ = new Subject();
  private _errorSrc: string;
  private _useHttp: boolean;

  private apiPattern = /https:\/\/api\..*\.?jumptech.co.uk\/store/;

  downloadingImage$ = new BehaviorSubject<boolean>(false);
  loaded$ = new BehaviorSubject<boolean>(false);
  hoveringImage$ = new BehaviorSubject<boolean>(false);
  hoveringDownload$ = new BehaviorSubject<boolean>(false);
  showDownload$ = new BehaviorSubject<boolean>(false);

  get id(): string {
    return this._id || this.uuid;
  }

  @Input() set id(value: string) {
    this._id = value;
  }

  get src(): string | SafeUrl | null {
    return this._src;
  }

  @Input() set src(value: string | SafeUrl | null) {
    this._src = this.domSanitizer.sanitize(4, value);
  }

  get imageClass(): string {
    return this._imageClass;
  }

  @Input() set imageClass(value: string) {
    this._imageClass = value;
  }

  get alt(): string {
    return this._alt || '';
  }

  @Input() set alt(value: string) {
    this._alt = value;
  }

  get qaHook(): string {
    return this._qaHook;
  }

  @Input() set qaHook(value: string) {
    this._qaHook = value;
  }

  get latestValue(): string | SafeUrl | null {
    // console.log('latest value', this._latestValue);
    return this._latestValue;
  }

  set latestValue(value: string | SafeUrl | null) {
    this._latestValue = value;
  }

  get errorSrc(): string {
    return this._errorSrc;
  }

  @Input() set errorSrc(value: string) {
    this._errorSrc = value;
  }

  get uuid() {
    return this._uuid;
  }

  @Input() downloadUrl: string;
  @Input() skeletonLoaderHeight = 0;
  @Input() allowDownload = true;
  @Input() fileName: string | null = null;

  @Output() clicked = new EventEmitter<boolean>();

  constructor(
    private httpClient: HttpClient,
    private domSanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private spinnerService: NgxSpinnerService
  ) {
    this._uuid = this.getUniqueId(4);
    this.setUpSubscription();
  }

  ngOnInit(): void {
    this.transform(this.src);
    if (this.allowDownload) {
      combineLatest([this.loaded$, this.hoveringImage$, this.hoveringDownload$])
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe(([loaded, hoveringImage, hoveringDownload]) => {
          this.showDownload$.next(!!this.downloadUrl && loaded && (hoveringImage || hoveringDownload));
        });
    }
  }

  leaveImageHover() {
    this.hoveringDownload$.pipe(debounceTime(10), take(1)).subscribe(hoveringDownload => {
      if (!hoveringDownload) {
        this.hoveringImage$.next(false);
      }
    });
  }

  ngOnChanges(): void {
    this.transform(this.src);
  }

  ngOnDestroy() {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: Event) {
    event.preventDefault();
  }

  private transform(imagePath: string | SafeUrl | null) {
    if (!imagePath) {
      return;
    }

    if (this._useHttp === false || !XRegExp(this.apiPattern).test(imagePath.toString())) {
      this.latestValue = imagePath;
      return;
    }

    this.transformValue.next(imagePath.toString());
  }

  private setUpSubscription(): void {
    const thatSpinnerService = this.spinnerService;
    this.transformValue
      .asObservable()
      .pipe(
        takeUntil(this._unsubscribe$),
        filter((v): v is string => !!v),
        distinctUntilChanged(),
        switchMap((imagePath: string) => {
          thatSpinnerService.show(this.uuid).then();
          // add skip header to prevent pathway error interceptor from handling errors
          return this.httpClient
            .get(imagePath, {
              headers: { skip: 'true' },
              observe: 'response',
              responseType: 'blob'
            })
            .pipe(
              map((response: HttpResponse<Blob>) => {
                thatSpinnerService.hide(this.uuid).then();
                if (!response?.body) {
                  return '';
                }
                return URL.createObjectURL(response.body);
              }),
              map((unsafeBlobUrl: string) => this.domSanitizer.bypassSecurityTrustUrl(unsafeBlobUrl)),
              filter(blobUrl => blobUrl !== this.latestValue),
              // if the request errors out we return the error image's path value
              catchError(() => {
                thatSpinnerService.hide(this.uuid).then();
                return of(this.errorSrc || '');
              })
            );
        }),
        tap((imagePath: string | SafeUrl) => {
          this.latestValue = imagePath;
          this.cdr.markForCheck();
        })
      )
      .subscribe();
  }

  getUniqueId(parts: number): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  async onImageDownloadEvent() {
    this.downloadingImage$.next(true);
    try {
      const downloadHelper = document.createElement('a');
      if (this.downloadUrl.indexOf('base64,') !== -1) {
        this.downloadBase64Image(this.downloadUrl);
      } else {
        const response: HttpResponse<Blob> = await firstValueFrom(
          this.httpClient.get(this.downloadUrl + (this.downloadUrl.endsWith('&') ? 'size=o' : '&size=o'), {
            headers: { skip: 'true' },
            observe: 'response',
            responseType: 'blob'
          })
        );
        this.downloadImage(response, downloadHelper);
      }
    } finally {
      this.downloadingImage$.next(false);
    }
  }

  downloadBase64Image(encodedImage: string): void {
    const a = document.createElement('a');
    const fileExt = encodedImage.split(';')[0].split('/')[1] ?? 'jpeg';
    a.href = encodedImage;
    a.download = this.fileName ? `${this.fileName}.${fileExt}` : 'temp-image.jpeg';
    a.click();
  }

  downloadImage(response: HttpResponse<Blob>, downloadHelper: HTMLAnchorElement) {
    if (response?.body) {
      downloadHelper.href = URL.createObjectURL(response.body);
      const fileName = this.getFileNameFromDownloadUrl();
      downloadHelper.setAttribute('download', fileName);
      downloadHelper.setAttribute('target', '_blank');
      document.body.appendChild(downloadHelper);
      downloadHelper.click();
    }
  }

  private getFileNameFromDownloadUrl() {
    const downloadAttrR = new RegExp('%2F(.+)\\?signature');
    const downloadAttrMatch: RegExpMatchArray | null = this.downloadUrl.match(downloadAttrR);
    const fullFileName = downloadAttrMatch?.[1] ?? 'download';
    return fullFileName.replace(/_[0-9]{2,}/, '');
  }
}
