import { Component, DestroyRef, HostListener, inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import {
  APP_VERSION_FILE,
  CLEAR_SITE_DATA,
  LOGIN_PATH,
  REGISTER_PATH,
  RESET_PASSWORD_PATH,
  SETUP_ATOM_PATH
} from './app.routes';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet
} from '@angular/router';
import { environment } from '../environments/environment';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { filter, flatMap, map, startWith, switchMap, tap } from 'rxjs';
import { HomeService } from './core/home.service';
import { AuthenticationService } from './auth/services/authentication.service';
import { PathwayConfigurationService } from './auth/services/pathway-configuration.service';
import { HttpCancelService } from './core/http-cancel.service';
import { UserService } from './auth/services/user.service';
import { User, UserPreferenceType } from './core/domain/user';
import { setReturnUrl } from './core/utils/location.helper';
import { UserPreferencesService } from './core/user-preferences.service';
import { SearchTarget } from './core/search/search.model';
import { SearchBarService } from './core/search/search-bar/search-bar.service';
import { UserThemeService } from './core/user-theme.service';
import { CookieService } from 'ngx-cookie-service';
import { EnvironmentService } from '../environments/environment.service';
import { datadogLogs } from '@datadog/browser-logs';
import { HttpGateway } from './core/http-gateway.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavbarComponent } from './core/navbar/navbar.component';
import { SearchBarComponent } from './core/search/search-bar/search-bar.component';
import { UserPanelComponent } from './core/user-panel/user-panel.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ToasterContainerComponent } from './toast/toaster-container.component';
import { SupportBarComponent } from './support-login/support-bar/support-bar.component';
import { LocalStorageGateway } from './core/local-storage-gateway.service';
import { GlobalErrorsComponent } from './error/global-errors/global-errors.component';
import { GlobalErrorsService } from './core/services/global-errors.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    NgIf,
    RouterOutlet,
    RouterLink,
    UserPanelComponent,
    SearchBarComponent,
    NavbarComponent,
    AsyncPipe,
    TranslocoModule,
    CoreComponentsAngularModule,
    ToasterContainerComponent,
    SupportBarComponent,
    GlobalErrorsComponent
  ]
})
export class AppComponent implements OnInit {
  cssUrl: SafeResourceUrl;
  fontUrl?: SafeResourceUrl;
  logoUrl?: SafeResourceUrl;
  searchResults: any[] = [];
  redirecting = false;
  appName = 'Pathway';
  loadingKey = 'common.loading';
  menuClosed = false;
  navPreference = UserPreferenceType.NavShrink;
  SearchTarget = SearchTarget;
  isFreeLeadsApp = false;
  isDemoTenant = false;
  useDemoLogo = false;
  projectId = null;

  isAppUpdateRequired = false;
  appUpdateInProgress = false;
  APP_UPDATE_STORAGE_KEY = 'jtAppUpdates';

  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    private configurationService: PathwayConfigurationService,
    public sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translationService: TranslocoService,
    private titleService: Title,
    private searchBarService: SearchBarService,
    private userHistoryService: HomeService,
    private httpCancelService: HttpCancelService,
    private userPrefs: UserPreferencesService,
    public themeService: UserThemeService,
    private cookieService: CookieService,
    private environmentService: EnvironmentService,
    private localStorageGateway: LocalStorageGateway,
    private gateway: HttpGateway,
    private globalErrorsService: GlobalErrorsService
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    return this.isSmallScreen;
  }

  get isSmallScreen(): boolean {
    return window.innerWidth <= 700;
  }

  get isProduction(): boolean {
    return this.buildEnvironmentName === 'prod';
  }

  get isPreview(): boolean {
    return this.buildEnvironmentName === 'preview';
  }

  get isLocal(): boolean {
    return this.buildEnvironmentName === 'local';
  }

  get buildEnvironmentName(): string {
    return this.environmentService.getBuildTimeEnvironment();
  }

  get buildEnvironmentDisplayName(): string {
    if (this.isPreview) {
      return this.localStorageGateway.getItem('previewBranch') || '';
    }
    return this.buildEnvironmentName === 'production' ? '' : `(${this.buildEnvironmentName})`;
  }

  get buildEnvironmentShortDisplayName(): string {
    if (this.buildEnvironmentName !== 'preview') {
      return this.buildEnvironmentName === 'production' ? '' : `(${this.buildEnvironmentName})`;
    }
    let name = this.buildEnvironmentDisplayName;
    if (name.length > 20) {
      name = `${name.substring(0, 14)}...${name.slice(-3)}`;
    }
    return `[${name}]`;
  }

  async ngOnInit(): Promise<void> {
    this.initUserTheme();
    this.initDefaultRoute();

    setReturnUrl(<any>window?.location?.pathname);
    this.setupPreviewBranch();
    this.configurationService.hostConfigurationObservable
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(hostConfiguration => {
        if (!hostConfiguration) {
          return;
        }
        this.isDemoTenant = hostConfiguration.tenant === 'demo';
        this.cssUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.localStorageGateway.getItem(`${hostConfiguration.tenant}_${environment.name}_stylesUrl`) ||
            hostConfiguration.configuration.styles
        );
        this.logoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.localStorageGateway.getItem(`${hostConfiguration.tenant}_${environment.name}_logoUrl`) ||
            hostConfiguration.configuration.logo
        );
        if (hostConfiguration.configuration.font) {
          this.fontUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            `https://fonts.googleapis.com/css?family=${hostConfiguration.configuration.font}`
          );
        }
      });
    await this.configurationService.configure();
    this.authenticationService.initAuthEventListener();
    this.authenticationService.signOutObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.httpCancelService.cancelPendingRequests();
      this.clearAppUpdateVersions();
    });

    this.userService.userObservable
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(user => user !== null)
      )
      .subscribe((user: User) => {
        this.menuClosed = this.userPrefs.getUserPreference(this.navPreference);
        this.useDemoLogo = (this.isDemoTenant && !user.subTenant) || (user.tenant === 'demo' && !user.subTenant);
        this.initPendo(user);
      });

    /**
     * t(pageTitles.login, pageTitles.resetPassword, pageTitles.home, pageTitles.map, pageTitles.register, pageTitles.projects, pageTitles.dashboard, pageTitles.search, pageTitles.details, pageTitles.schedule, pageTitles.reports, pageTitles.userManagement, pageTitles.settings, pageTitles.settings:api, pageTitles.settings:listEditor, pageTitles.settings:projectEvents, pageTitles.settings:relayEditor, pageTitles.settings:scheduledEvents)
     */
    //  update page titles on navigation end
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(e => e instanceof NavigationEnd),
        startWith(this.getRouteTitle(this.activatedRoute.snapshot)),
        map(() => this.getRouteTitle(this.activatedRoute.snapshot)),
        tap(() => {
          this.checkForAppUpdate();
        }),
        flatMap(pageName => this.translationService.selectTranslate(pageName))
      )
      .subscribe(translatedTitle => {
        const title = translatedTitle ? translatedTitle : this.translationService.translate(this.loadingKey);
        this.setTitle(`${title} | ${this.appName}`);
        this.searchBarService.closeResults();
      });

    // save route history && update menu state
    // on navigation end separately
    // as the selectTranslate above can emit multiple times
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(e => e instanceof NavigationEnd),
        startWith(this.getRouteTitle(this.activatedRoute.snapshot)),
        map(() => this.getRouteTitle(this.activatedRoute.snapshot))
      )
      .subscribe(pageName => {
        const translatedTitle = this.translationService.translate(pageName);
        this.saveRouteHistory(this.router.url, translatedTitle);
        this.updateMainMenuState(this.router.url);
        this.userPrefs.setUserPreference(UserPreferenceType.ProjectDetailOpenCards, {});
        this.globalErrorsService.notifyGlobalError(null);
      });

    // keep active projectId for support features
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(e => e instanceof NavigationEnd),
        switchMap(() => this.activatedRoute.firstChild.params)
      )
      .subscribe(params => {
        this.projectId = params['projectId'];
      });
  }

  updateMainMenuState(url) {
    if (!this.authenticationService.isUserSignedIn && url.indexOf(LOGIN_PATH)) {
      this.menuClosed = false;
    }
  }

  private getRouteTitle(route: ActivatedRouteSnapshot): string {
    while (route.firstChild) {
      route = route.firstChild;
    }
    return route.data.title;
  }

  toggleNavigation(on: boolean) {
    this.menuClosed = on;
    this.userPrefs.setUserPreference(this.navPreference, on);
  }

  get loggedIn() {
    const isUserSignedIn = this.authenticationService.isUserSignedIn;
    if (!isUserSignedIn && this.router.url.indexOf(SETUP_ATOM_PATH) !== 1) {
      this.redirectToLogin();
    }
    return isUserSignedIn;
  }

  onActivate() {
    window.scroll(0, 0);
  }

  setResults(searchResults: any) {
    this.searchResults = searchResults.results;
  }

  redirectToLogin() {
    if (this.redirecting) {
      return;
    }
    this.redirecting = true;
    setTimeout(() => {
      if (!this.authenticationService.isUserSignedIn && this.isRedirectableRoute) {
        setTimeout(() => {
          this.redirecting = false;
        }, 2000);
        this.router.navigate([LOGIN_PATH]);
      }
    }, 1000);
  }

  get isRedirectableRoute() {
    // It's redirectable unless it is one of these routes starting with /
    const result = [LOGIN_PATH, RESET_PASSWORD_PATH, REGISTER_PATH, SETUP_ATOM_PATH].find(
      route => this.router.url.indexOf(route) === 1
    );
    return !result;
  }

  public setTitle(title: string) {
    this.titleService.setTitle(title);
  }

  public saveRouteHistory(route, title) {
    this.userHistoryService.saveRouteHistory(route, title);
  }

  public initPendo(user: User) {
    if (user && window.__env.pendoId) {
      const tenant = this.configurationService.tenant;
      const hostConfiguration = this.configurationService.hostConfiguration;
      if (!hostConfiguration) {
        return;
      }
      const host = hostConfiguration.host;
      const subTenant = user?.accessInfo?.subTenants?.[0]?.configuration?.name;

      const id = subTenant ?? tenant;
      let teamName = null;
      let roleList = null;

      const teams = user.accessInfo.teams.filter(team => {
        return team.id === user.team;
      });

      if (teams.length) {
        teamName = teams.pop().name;
      }

      if (user.accessInfo.roles.length) {
        roleList = user.accessInfo.roles.map(role => {
          return `${role.name} : ${role.id}`;
        });
      }

      if (!teamName || !roleList) {
        datadogLogs.logger.error(`Pendo: Unable to init!`, { team: teamName, roles: roleList });
        return;
      }

      if (window.pendo) {
        window.pendo.initialize({
          visitor: {
            id: user.userName,
            email: user.email,
            full_name: user.label,
            team: user.team,
            teamName: teamName,
            userRoles: roleList,
            admin: user.isAdmin,
            superAdmin: user.isSuperAdmin,
            locale: user.accessInfo.configuration.locale,
            timezone: user.accessInfo.configuration.timezone
          },

          account: {
            id,
            name: id,
            tenant,
            isSubTenant: !!subTenant,
            host: host
          }
        });
      }
    }
  }

  private initUserTheme() {
    this.themeService.init();
  }

  private initDefaultRoute() {
    const defaultRoute = this.localStorageGateway.getItem(UserPreferenceType.DefaultRoute);
    if (!defaultRoute) {
      this.localStorageGateway.setItem(UserPreferenceType.DefaultRoute, 'home');
    }
  }

  // Check for a cookie where there is no localStorage setting for the branch.
  // This allows the branch to be set for all tenants
  private setupPreviewBranch() {
    if (this.isPreview) {
      if (!this.localStorageGateway.getItem('previewBranch')) {
        const branch = this.cookieService.get('previewBranch');
        this.localStorageGateway.setItem('previewBranch', branch);
      }
    }
  }
  // Reset the preview branch to allow the user to select a new one.
  resetBranch() {
    const topLevelDomain = window.document.location.hostname.substring(window.document.location.hostname.indexOf('.'));
    this.cookieService.delete('previewBranch', '/', topLevelDomain);
    this.localStorageGateway.removeItem('previewBranch');
    window.document.location = LOGIN_PATH;
    window.document.location.reload();
  }

  public async invalidateAppCache(): Promise<void> {
    this.appUpdateInProgress = true;
    try {
      const response = await this.gateway.getStaticResponse(CLEAR_SITE_DATA, { skip: 'true' });
      if (response.status === 200) {
        console.log(`%cClear site cache successful, reloading`, 'color: limegreen;');
        window.location.reload();
      }
    } catch (e) {
      console.error('Error invalidating Pathway cache', e);
      this.appUpdateInProgress = false;
    }
  }

  private hasForcedUpdate(version: string): boolean {
    // check if we have attempted to already force an update to this version
    const forcedVersions = JSON.parse(this.localStorageGateway.getItem(this.APP_UPDATE_STORAGE_KEY)) ?? [];
    const forcedVersion = forcedVersions.find(x => x === version);

    if (!forcedVersion) {
      console.log(`%cAttempting to force an app update to version: ${version}`, 'color: hotpink;');
      forcedVersions.push(version);
      this.localStorageGateway.setItem(this.APP_UPDATE_STORAGE_KEY, JSON.stringify(forcedVersions));
      return false;
    }
    // fallback to manual update after failed force attempt
    console.log(`%cForce update attempt failed, manual update required to: ${version}`, 'color: orange;');
    this.isAppUpdateRequired = true;
    return true;
  }

  private async checkForAppUpdate(): Promise<void> {
    this.isAppUpdateRequired = false;
    try {
      const thisAppVersion = environment.appVersion;
      const currentAppVersion = await this.gateway.get(APP_VERSION_FILE, { skip: 'true' });

      if (thisAppVersion !== currentAppVersion.appVersion) {
        console.log(`%cPathway update required to version: ${currentAppVersion.appVersion}`, 'color: hotpink;');
        if (currentAppVersion.forceUpdate) {
          const hasAttemptedForcedUpdate = this.hasForcedUpdate(currentAppVersion.appVersion);
          if (!hasAttemptedForcedUpdate) {
            await this.invalidateAppCache();
          }
        } else {
          this.isAppUpdateRequired = true;
        }
      } else {
        console.log(`%cPathway running on latest version: ${currentAppVersion.appVersion}`, 'color: limegreen;');
      }
    } catch (e) {
      console.error('Error getting current Pathway version', e);
    }
  }

  private clearAppUpdateVersions(): void {
    this.localStorageGateway.removeItem(this.APP_UPDATE_STORAGE_KEY);
  }
}
