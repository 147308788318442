import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { DocumentPackResponsePresenter } from './document-pack-response.presenter';
import { IResponseVm } from '../document-pack.model';
import { DocumentPackProgressComponent } from '../document-pack-progress/document-pack-progress.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf } from '@angular/common';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';

@Component({
    selector: 'app-document-pack-response',
    templateUrl: './document-pack-response.component.html',
    styleUrls: ['./document-pack-response.component.scss'],
    imports: [NgIf, CoreComponentsAngularModule, DocumentPackProgressComponent, ReadOnlyDisableDirective],
    providers: [DocumentPackResponsePresenter]
})
export class DocumentPackResponseComponent implements OnInit {
  @Input() fileInput: HTMLInputElement;
  @Input() outputEvent: EventEmitter<any>;

  vm: IResponseVm = null;

  constructor(private presenter: DocumentPackResponsePresenter) {}

  ngOnInit() {
    this.presenter.load(generatedVm => {
      this.vm = generatedVm;
    });
  }

  closeModal(reason) {
    this.presenter.closeModal(reason);
  }

  complete() {
    this.presenter.complete(this.vm);
  }

  upload(event) {
    this.presenter.uploadEvidence(event, this.vm, this.outputEvent);
  }

  revise() {
    this.presenter.revise(this.vm);
  }
}
