import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Card } from '../../domain/card';
import { I18nKeys } from '../../domain/i18n-keys';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { QuestionsComponent } from '../../core/questions.component';
import { NgIf } from '@angular/common';

@Component({
    selector: `crds-consent-form`,
    template: `
    <div>
      <div class="card-wrapper">
        <div class="card__title-header">
          <div class="card__title">{{ card.label }}</div>
          <div class="card__step">{{ card.stepProgress }}</div>
        </div>
        <div class="card__description" *ngIf="description">
          <div class="card-text" [innerHTML]="description" [attr.data-qa]="'description'"></div>
        </div>
      </div>

      <div class="card__description card__description--secondary" *ngIf="description2">
        <div class="card-text" [innerHTML]="description2"></div>
      </div>

      <div class="form-wrapper" *ngIf="hasQuestionsToShow && card">
        <form [formGroup]="questionFormGroup">
          <crds-questions [form]="questionFormGroup" [card]="card" [i18ns]="i18ns"></crds-questions>
        </form>
      </div>
      <jui-alert-block *ngIf="description3" color="low" size="sm"
        ><span [innerHTML]="description3"></span
      ></jui-alert-block>
    </div>
  `,
    imports: [NgIf, ReactiveFormsModule, QuestionsComponent, CoreComponentsAngularModule]
})
export class ConsentFormComponent implements OnChanges {
  @Input() card: Card<null>;
  @Input() form: UntypedFormGroup;
  @Input() formKey: string;
  @Input() i18ns: I18nKeys;

  questionFormGroup: UntypedFormGroup;
  hasQuestionsToShow = false;

  description: SafeHtml | null;
  description2: SafeHtml | null;
  description3: SafeHtml | null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.questionFormGroup = this.form.get(this.formKey) as UntypedFormGroup;

    this.hasQuestionsToShow = !!(this.card.questions?.length && !!this.card.questions.find(q => q.show));

    this.description = this.getCardDescription(this.card.description);
    this.description2 = this.getCardDescription(this.card.description2);
    this.description3 = this.getCardDescription(this.card.description3);
  }

  getCardDescription(cardDesc: string | undefined) {
    if (cardDesc) {
      const match = cardDesc.match(/\{(.+)\}/);
      if (!match) {
        return this.sanitizer.bypassSecurityTrustHtml(cardDesc);
      }
      const formRouteParts = match[1].split('.');
      let formControl = this.form.get(formRouteParts[0]);

      if (formControl && formRouteParts.length === 2) {
        formControl = formControl.get(formRouteParts[1]);
      }

      const formValue = formControl && formControl.value ? formControl.value : '';
      const description = cardDesc.replace(/\{.+\}/, formValue);
      return this.sanitizer.bypassSecurityTrustHtml(description);
    }
    return null;
  }
}
