import { Component, Input } from '@angular/core';
import { OrderViewVm } from '../../my-orders.model';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'pathway-orders-order-fulfilment',
    templateUrl: './order-fulfilment.component.html',
    styleUrls: ['../../order-view/order-view.component.scss'],
    imports: [NgFor, NgIf]
})
export class MyOrdersFulfilmentComponent {
  @Input() vm: OrderViewVm;
}
