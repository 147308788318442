import { Component, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { FeatureFlagService } from '../../../core/feature-flag/feature-flag.service';
import { CommonModule } from '@angular/common';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'schedule-key',
    templateUrl: 'schedule-key.component.html',
    styleUrls: ['schedule-key.component.scss'],
    imports: [TranslocoModule, CommonModule]
})
export class ScheduleKeyComponent implements OnInit {
  public provisionalSchedulingEnabled: boolean;

  constructor(private featureFlagService: FeatureFlagService) {}

  async ngOnInit() {
    this.provisionalSchedulingEnabled = await this.featureFlagService.isFeatureEnabled('provisional-scheduling');
  }
}
