<jui-card size="sm" [attr.data-qa]="layout.label">
  <div slot="header" class="list-layout__header" [attr.data-qa]="layout.key + 'CardHeader'" (click)="toggleOpen(layout.key)">
    <div class="list-layout__header--main">
      <div class="list-layout__header-title" [attr.data-qa]="layout.key">{{ layout.label }}</div>
      <div class="list-layout__header-actions">
        @if (!isReadOnly) {
          <span><jui-icon readOnlyDisable name="edit" size="xs" color="current" [attr.data-qa]="layout.key + '_editIcon'" (click)="setForm($event)"></jui-icon></span>
        }
        <span><jui-icon [name]="expandIconName" color="current" [attr.data-qa]="layout.key + '_expandIcon'"></jui-icon></span>
      </div>
    </div>
  </div>
  <div class="list-layout__content" [attr.data-qa]="layout.key + 'CardContent'" [class.list-layout__content--expanded]="isOpen">
    @if (!!layout.edit) {
      <ng-content></ng-content>
      <div class="list-layout__edit">
        @for (item of layoutItems; track $index) {
          @if (item.editConfig.controlType === 'mapMarker') {
            <app-charge-point-location-map [item]="item"></app-charge-point-location-map>
          } @else {
            @if (item['editConfig'] && form()) {
              <app-edit-layout-questions
                [question]="item['editConfig']"
                [form]="form()"
                [project]="project"
              >
              </app-edit-layout-questions>
            }
          }
        }
      </div>
    }
    @if (!layout.edit) {
      <div>
        @for (item of layoutItems; track $index) {
          <div>
            @if (item.editConfig.controlType === 'mapMarker') {
              <div>
                <app-charge-point-location-map [item]="item"></app-charge-point-location-map>
              </div>
            } @else {
              @if (showCardItem(item)) {
                <div class="list-layout__row">
                  @if (item.key) {
                    <div
                      class="list-layout__row-key"
                      [attr.data-qa]="item.editConfig && item.editConfig.key ? item.editConfig.key : item.key"
                    >
                      {{ getLabel(item.key) }}
                    </div>
                  }
                  @if (item.content && htmlInputSupportedCardKeys.includes(layout.key)) {
                    <div
                      class="list-layout__row-value"
                      [innerHtml]="item.content"
                      [attr.data-qa]="
                  item.editConfig && item.editConfig.key ? item.editConfig.key + '_value' : item.key + '_value'
                "
                    ></div>
                  }
                  @if (item.content && !htmlInputSupportedCardKeys.includes(layout.key)) {
                    <div
                      class="list-layout__row-value"
                      [innerText]="item.content"
                      [attr.data-qa]="
                  item.editConfig && item.editConfig.key ? item.editConfig.key + '_value' : item.key + '_value'
                "
                    ></div>
                  }
                  @if (isStaticImage(item)) {
                    <div class="list-layout__row-value">
                      <crds-http-image [src]="item.src" [alt]="item.key" imageClass="img-static-small"></crds-http-image>
                    </div>
                  }
                  @if (isSingleImage(item)) {
                    <div>
                      <crds-http-image
                        [src]="item.src"
                        [alt]="item.key"
                        [downloadUrl]="getOriginalUrl(item.src)"
                        imageClass="card-img-bottom img-thumbnail-small img-single"
                        style="cursor: pointer"
                        (clicked)="showGalleryForImage(item, layout)"
                      ></crds-http-image>
                    </div>
                  }
                  @if (isImageArray(item)) {
                    <div>
                      <div *ngFor="let src of item.src; index as i" class="img-container">
                        <crds-http-image
                          *ngIf="src"
                          [src]="src"
                          [downloadUrl]="getOriginalUrl(src)"
                          [alt]="item.key"
                          imageClass="card-img-bottom img-thumbnail-small img-list"
                          style="cursor: pointer"
                          (clicked)="showGalleryForImage(item, layout, i)"
                          [qaHook]="item.key + '_projectImg'"
                        ></crds-http-image>
                      </div>
                    </div>
                  }
                </div>
                @if (isListView(item)) {
                  <question-list-view [listData]="form()?.get(item.editConfig.key)?.value"></question-list-view>
                }
                @if (isVideoArray(item)) {
                  <div class="list-layout__img-array">
                    <div *ngFor="let src of item.src; index as i" class="img-container">
                      <question-video-player
                        [params]="{
                    readOnly: true,
                    showHint: false,
                    qaHook: item.key + '_projectVid',
                    type: 'video/mp4',
                    videoClass: 'img-static-small',
                    videoSource: getOriginalUrl(item.src)
                  }"
                      ></question-video-player>
                    </div>
                  </div>
                }
              }
            }
          </div>
        }
        @if (staticMapUrl()) {
          <img class="list-layout__card-img" [src]="staticMapUrl()" alt="" />
        }
      </div>
    }
  </div>
  @if (!!layout.edit && showSave && isOpen) {
    <div slot="footer" class="list-layout__footer">
      <jui-button size="sm" color="low" [attr.data-qa]="'cancelButton'" (click)="cancelEdit()">Cancel</jui-button>
      <jui-button
        readOnlyDisable
        [readOnlyDisableIsDisabled]="form().invalid"
        size="sm"
        [color]="form().invalid ? 'low' : 'primary'"
        *ngIf="form()"
        (click)="save(form())"
        [attr.data-qa]="'saveButton'"
      >
        <span slot="icon" class="material-icons">check</span>
        {{ okAction || ('common.save' | transloco) }}
      </jui-button>
    </div>
  }
</jui-card>
