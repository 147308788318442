@if (vm$ | async; as vm) {
  <div class="ena-actions">
    @if (vm.displayUndo) {
      <div class="ena-actions__action">
        <jui-button
          size="sm"
          (click)="undoChanges()"
          color="low"
          [disabled]="vm.disableUndo"
          [attr.data-qa]="vm.qaUndoButton"
        >
          {{ vm.undoLabel }}
        </jui-button>
      </div>
    }
    @if (vm.displaySave) {
      <div class="ena-actions__action">
        <jui-button
          size="sm"
          (click)="saveApplication()"
          [disabled]="vm.disableSave"
          [loading]="vm.saveInProgress"
          [attr.data-qa]="vm.qaSaveButton"
        >
          {{ vm.saveLabel }}
        </jui-button>
      </div>
    }
    @if (vm.displaySubmit) {
      <div class="ena-actions__action">
        <jui-button
          size="sm"
          [disabled]="submitDisabled()"
          (click)="submitApplication()"
          [loading]="vm.submitInProgress"
          [attr.data-qa]="vm.qaSubmitButton"
        >
          {{ vm.submitLabel }}
        </jui-button>
      </div>
    }
    @if (vm.displayCancelApplication) {
      <div class="ena-actions__action">
        <jui-button
          size="sm"
          [disabled]="vm.disableCancelApplication"
          (click)="cancelApplication()"
          [loading]="vm.cancelApplicationInProgress"
          [attr.data-qa]="vm.qaCancelApplicationButton"
        >
          {{ vm.cancelApplicationLabel }}
        </jui-button>
      </div>
    }
    @if (vm.displayManuallyReject) {
      <div class="ena-actions__action">
        <jui-button
          size="sm"
          [disabled]="vm.disableManuallyReject"
          (click)="manuallyReject()"
          [loading]="vm.rejectInProgress"
          [attr.data-qa]="vm.qaManuallyRejectButton"
        >
          {{ vm.manuallyRejectLabel }}
        </jui-button>
      </div>
    }
    @if (vm.displayManuallyApprove) {
      <div class="ena-actions__action">
        <jui-button
          size="sm"
          [disabled]="vm.disableManuallyApprove"
          (click)="manuallyApprove()"
          [loading]="vm.approveInProgress"
          [attr.data-qa]="vm.qaManuallyApproveButton"
        >
          {{ vm.manuallyApproveLabel }}
        </jui-button>
      </div>
    }
  </div>
}
