<div *ngIf="vm$ | async as vm" class="layout-tab">
  @if (vm.showFallback) {
    <div class="layout-tab__fallback">
      <jui-alert-block color="high" size="sm">
        {{ vm.tabLayout.fallbackMessage }}
      </jui-alert-block>
    </div>
  } @else {
    <div class="layout-tab__columns">
      @for (column of vm.layoutColumns; track $index) {
        <div class="layout-tab__column" [attr.data-qa]="'layoutTabColumn_' + $index">
          @for (layoutCollection of column; let idx = $index; track $index) {
            @for (layout of layoutCollection.layouts; track $index) {
              <app-list-layout
                class="layout-tab__column-item"
                [project]="vm?.project"
                [data]="layout.data"
                [readOnly]="vm?.readOnly"
                [layout]="layout"
                [layoutIndex]="idx"
                [narrowDisplay]="vm.narrowDisplay"
                (saved)="onSave($event)"
              >
                @if (layout.type === 'Quote') {
                  <div>
                    <app-standard-quote-select-component
                      [projectType]="vm?.project.type"
                      (standardQuoteChanged)="onStandardQuoteChanged(layout, $event)"
                    ></app-standard-quote-select-component>
                  </div>
                }
              </app-list-layout>
            }
          }
        </div>
      }
    </div>
  }
</div>
