import { Injectable } from '@angular/core';
import { TextDetection } from './ocr.service';
import { MimeTypeService } from './mime-type.service';
import { ImageErrorType } from '../domain/image-errors';

export interface ImageError {
  fileName: string;
  type: ImageErrorType;
}

export interface IResizeImageOptions {
  maxSize: number;
  b64: string;
}

export class ImageUploadError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'ImageUploadError';
  }
}

const defaultMaxFileSizeBytes = 20000000; // 20MB

@Injectable({ providedIn: 'root' })
export class ImageManipulationService {
  readonly maxImageSize = 2000;

  constructor(private mimeTypeService: MimeTypeService) {}

  /**
   * Resizes and image from a File descriptor.
   * @param file
   * @param maxSize
   */
  public async resizeImageFromFile(file: File, maxSize?: number): Promise<string> {
    const b64 = await this.readImageAsB64(file);
    const options: IResizeImageOptions = {
      maxSize: maxSize ?? this.maxImageSize,
      b64
    };
    return await this.resizeImage(options);
  }

  /**
   * Resizes a base-64 encoded image.
   * @param settings
   */
  resizeImage(settings: IResizeImageOptions): Promise<string> {
    const b64 = settings.b64;
    const maxSize = settings.maxSize;
    const image = new Image();
    const canvas = document.createElement('canvas');

    const resize = () => {
      let width = image.width;
      let height = image.height;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }

      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d')?.drawImage(image, 0, 0, width, height);
      return canvas.toDataURL('image/jpeg');
    };

    return new Promise(resolve => {
      image.onload = () => resolve(resize());
      image.src = b64;
    });
  }

  cropImage(base64Image: string, filteredResult: TextDetection) {
    const canvasOriginal = document.createElement('canvas');
    const ctx = canvasOriginal.getContext('2d');
    const imageObj = new Image();

    return new Promise(resolve => {
      const self = this;
      imageObj.onload = function () {
        const polygon = filteredResult?.Geometry?.Polygon;
        resolve(self.clipIt(canvasOriginal, ctx, polygon, imageObj));
      };
      imageObj.src = base64Image;
    });
  }

  clipIt(
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D | null,
    points: { X: number; Y: number }[] | undefined = [],
    img: HTMLImageElement
  ) {
    // calculate the size of the user's clipping area
    let minX = 10000;
    let minY = 10000;
    let maxX = -10000;
    let maxY = -10000;
    const cw = (canvas.width = img.width);
    const ch = (canvas.height = img.height);

    for (let i = 1; i < points.length; i++) {
      const p = points[i];
      if (p.X * cw < minX) {
        minX = p.X * cw;
      }
      if (p.Y * ch < minY) {
        minY = p.Y * ch;
      }
      if (p.X * cw > maxX) {
        maxX = p.X * cw;
      }
      if (p.Y * ch > maxY) {
        maxY = p.Y * ch;
      }
    }

    console.log(JSON.stringify(points));
    const width = maxX - minX;
    const height = maxY - minY;

    // clip the image into the user's clipping area
    if (ctx) {
      ctx.save();
      ctx.clearRect(0, 0, cw, ch);
      ctx.beginPath();
      ctx.moveTo(points[0].X * cw, points[0].Y * ch);
      for (let i = 1; i < points.length; i++) {
        ctx.lineTo(points[i].X * cw, points[i].Y * ch);
      }
      ctx.closePath();
      ctx.clip();
      ctx.drawImage(img, 0, 0);
      ctx.restore();
    }

    // create a new canvas
    const c = document.createElement('canvas');
    const cx = c.getContext('2d');

    // resize the new canvas to the size of the clipping area
    c.width = width;
    c.height = height;

    // draw the clipped image from the main canvas to the new canvas
    cx?.drawImage(canvas, minX, minY, width, height, 0, 0, width, height);

    return c.toDataURL();
  }

  checkFileTypeAndSize(file: File, accept = 'image/*', maxSize = defaultMaxFileSizeBytes): ImageError | null {
    if (!this.mimeTypeService.isAllowedFileType(file, accept)) {
      return { type: ImageErrorType.FileTypeNotAllowedError, fileName: file.name };
    }
    if (file.size > maxSize) {
      return { type: ImageErrorType.FileTooLarge, fileName: file.name };
    }
    return null;
  }

  /**
   * Read an image and return it as a base-64 encoded string.
   * @param image
   */
  private async readImageAsB64(image: File): Promise<string> {
    if (typeof image === 'undefined') {
      throw new ImageUploadError('No file to upload');
    }

    if (image instanceof ArrayBuffer) {
      throw new ImageUploadError('Invalid file reference');
    }

    const reader = new FileReader();

    return new Promise(resolve => {
      reader.onload = (readerEvent: any) => {
        resolve(readerEvent.target.result);
      };
      reader.readAsDataURL(image);
    });
  }
}
