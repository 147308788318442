import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { EnaAttachment, EnaAttachmentsVm } from '../ena.model';
import { EnaApplicationAttachmentsPresenter } from './ena-application-attachments.presenter';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { TranslocoModule } from '@ngneat/transloco';
import { UploadFileInput } from '../../../core/directives/upload-file-input';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'ena-application-attachments',
  imports: [
    CommonModule,
    CoreComponentsAngularModule,
    TranslocoModule,
    UploadFileInput,
    ReadOnlyDisableDirective,
    ReactiveFormsModule
  ],
  templateUrl: './ena-application-attachments.component.html',
  styleUrl: './ena-application-attachments.component.scss'
})
export class EnaApplicationAttachmentsComponent implements OnInit {
  @Input() groupForm: FormGroup;
  @Output() attachmentChecked: EventEmitter<void> = new EventEmitter<void>();

  vm$: BehaviorSubject<EnaAttachmentsVm> = new BehaviorSubject<EnaAttachmentsVm>(null);

  constructor(private presenter: EnaApplicationAttachmentsPresenter) {}
  ngOnInit() {
    this.presenter.load(this.vm$, this.groupForm, this.attachmentChecked);
  }

  async downloadFile(attachment: EnaAttachment) {
    await this.presenter.downloadFile(attachment);
  }

  chooseFile(checked: boolean, fileName: string) {
    this.presenter.handleFileSelection(checked, fileName);
  }

  uploadFile() {
    this.presenter.upload();
  }

  onFileUploadEvent(event: Event) {
    this.presenter.handleFileUploadEvent(event);
  }
}
