<div class="ena-application__groups">
  @for (grp of vm.groups; track grp.key) {

  <!--  card -->
  <form [formGroup]="grp.form">
    <jui-card class="ena-application__group" size="sm" inverse="true" borderless="true" [hug]="grp.useHug" [attr.data-qa]="grp.qaEnaApplicationGroupName">
      <div slot="header" class="ena-application__header" (click)="toggleGroup(grp)">
        <div class="ena-application__header--main">
          <span class="ena-application__header-title">
            {{ grp.title }}
            @if (grp.numberOfItems) {
              <span [attr.data-qa]="grp.qaEnaApplicationNumberOfItems">{{grp.numberOfItems}}</span>
            }
          </span>
          <div class="ena-application__header-actions">
            @if (grp.showSavedState) {
              <span [class]="grp.savedStateCss" [attr.data-qa]="grp.qaEnaApplicationGroupStatus">
                {{ grp.savedState }}
              </span>
            } @else {
              <span [class]="getStateCss(grp)" [attr.data-qa]="grp.qaEnaApplicationGroupStatus">{{ getState(grp) }}</span>
            }
            <span class="ena-application__header-accordion">
              <jui-icon name="expand_more" [name]="grp.toggleClass" size="sm"></jui-icon>
            </span>
          </div>
        </div>
        @if (grp.subHeading && grp.isOpen) {
          <div class="ena-application__header--sub">
            <span class="ena-application__group-subheading">{{ grp.subHeading }}</span>
          </div>
        }
      </div>
      <div
        class="ena-application__group-content"
        #collapse="ngbCollapse"
        [ngbCollapse]="!grp.isOpen"
        (ngbCollapseChange)="grp.isOpen = $event"
      >
        <!-- ITEMS -->
        @if (grp.items) {
        <div [formArrayName]="grp.key" class="ena-devices">
          @for(itm of grp.items; track i; let i = $index) {
          <div
            class="ena-devices__item"
            [class.ena-devices__item--new]="itm.isNew"
            [class.ena-devices__item--last]="itm.isLastItem"
          >
            @for (sec of itm.sections; track sec.uuid) {
            <ng-container [formGroupName]="i">
              @if (!sec.hidden) {
              <!--  section -->
              <div class="ena-application__section">
                @if (sec.name) {
                <div class="ena-application__section-title" [attr.data-qa]="sec.qaEnaApplicationSectionHeader">
                  {{ sec.name }}
                </div>
                }
                <ena-form-controls
                  [vm]="vm"
                  [section]="sec"
                  [group]="grp"
                  [item]="itm"
                  [itemIdx]="i"
                ></ena-form-controls>
              </div>
              }
            </ng-container>
            }
            <div class="ena-devices__actions" *ngIf="!vm.inputsDisabled">
              <div class="ena-devices__action">
                <jui-button
                  size="sm"
                  display="ghost"
                  color="high"
                  [attr.data-qa]="itm.qaEnaItemDuplicateDevice"
                  (click)="duplicateDevice(i)"
                  >{{ vm.i18nDuplicateDeviceLabel }}</jui-button
                >
              </div>
              <div class="ena-devices__action">
                <jui-button
                  size="sm"
                  display="ghost"
                  color="high"
                  [attr.data-qa]="itm.qaEnaItemRemoveDevice"
                  [disabled]="itm.removeDisabled"
                  (click)="removeDevice(i)"
                  >{{ vm.i18nRemoveDeviceLabel }}</jui-button
                >
              </div>
              @if (itm.manualEntry) {
              <div class="ena-devices__action">
                <jui-button
                  size="sm"
                  color="high"
                  [attr.data-qa]="itm.qaEnaItemBackToDeviceSearch"
                  (click)="toggleManualEntry(itm, false)"
                >
                  {{ vm.i18nSearchDeviceLabel }}</jui-button
                >
              </div>
              }
              @if (!vm.inputsDisabled  && !itm.manualEntry) {
                <div class="ena-devices__action">
                  <jui-button
                    size="sm"
                    color="high"
                    [attr.data-qa]="itm.qaEnaItemManualEntry"
                    (click)="toggleManualEntry(itm, true)"
                    [disabled]="vm.inputsDisabled"
                  >{{ vm.i18nManualEntryDeviceLabel }}</jui-button
                  >
                </div>
              }
            </div>
          </div>
          }

          @if (vm.showAddDevice) {
            <div class="ena-devices__group-action">
              <jui-button size="sm" [attr.data-qa]="grp.qaEnaApplicationAddDeviceBtn" (click)="addDevice()">
                <span class="material-icons" slot="icon">add</span>
                {{ vm.i18nAddDeviceLabel }}
              </jui-button>
            </div>
          }
        </div>

        } @else { @for (sec of grp.sections; track sec.uuid) {
        <!--  section -->
        <div class="ena-application__section">
          @if (sec.name) {
          <div class="ena-application__section-title" [attr.data-qa]="sec.qaEnaApplicationSectionHeader">
            {{ sec.name }}
          </div>
          }
          <ena-form-controls [vm]="vm" [section]="sec" [group]="grp"></ena-form-controls>
        </div>
        @if (sec.id === 'additionalAttachments') {
        <div class="ena-application__section">
          <div class="ena-application__control ena-application__control--file">
            <!-- attachment layout -->
            <ena-application-attachments [groupForm]="grp.form" (attachmentChecked)="handleAttachmentChecked()"></ena-application-attachments>
          </div>
        </div>
        } } }
      </div>
    </jui-card>
  </form>
  }
</div>
