import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { LogoPresenter } from './logo.presenter';
import { LogoViewModel } from './logo.view.model';
import { NgIf } from '@angular/common';

@Component({
    selector: 'logo-component',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    imports: [NgbDropdownModule, NgIf],
    providers: [LogoPresenter, NgbActiveModal]
})
export class LogoComponent implements OnInit {
  @Input() showClose = false;
  vm: LogoViewModel;

  constructor(private logoPresenter: LogoPresenter, private activeModal: NgbActiveModal) {}

  ngOnInit(): void {
    this.logoPresenter.load(vm => {
      this.vm = vm;
      this.vm.showClose = this.showClose;
    });
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
