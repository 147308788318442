<ng-container *ngIf="vm$ | async as vm">
  <question-video-player-controls
    *ngIf="!vm.readOnly"
    [canDelete]="vm.canDelete"
    [canRecord]="vm.canRecord"
    [showRecordControls]="vm.showRecordControls"
    (startRecording)="startRecording()"
    (stopRecording)="stopRecording()"
    (pauseRecording)="pauseRecording()"
    (resumeRecording)="resumeRecording()"
    (removeRecording)="removeRecording()"
  ></question-video-player-controls>
  <video
    *ngIf="vm.showVideo"
    [id]="vm.id"
    width="100%"
    [class]="vm.videoClass"
    [attr.data-qa]="vm.qaHook"
    controls
    playsinline
  ></video>
  <div class="max-length-hint" *ngIf="vm.showHint">{{ vm.translations?.maximumLengthHint }}</div>
  <ng-container *ngIf="loading">
    <jui-skeleton-loader
      color="info"
      inline="true"
      height="50px"
      width="100%"
      class="video-player-loading"
    ></jui-skeleton-loader>
  </ng-container>
</ng-container>
