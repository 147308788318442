import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ScheduleFilterInformation } from '../../utils/schedule-types';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { MultipleSelectionDropdownComponent } from '../../../../shared/form-components/multiple-selection-dropdown.component';

@Component({
    selector: 'schedule-filters-popover-v3',
    templateUrl: 'schedule-filters-popover.component.html',
    styleUrls: ['schedule-filters-popover.component.scss'],
    imports: [TranslocoModule, CommonModule, MultipleSelectionDropdownComponent]
})
export class ScheduleFiltersPopoverComponent {
  @Input() filterInfo: ScheduleFilterInformation;

  @Output() jobTypesSelected: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() absenceTypesSelected: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() jobStatusesSelected: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() engineersSelected: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() popoverClosed: EventEmitter<void> = new EventEmitter<void>();
}
