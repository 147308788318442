import { Injectable } from '@angular/core';
import { ProjectAuditLogsRepository } from '../project-audit-logs.repository';
import { BehaviorSubject } from 'rxjs';
import { ProjectAuditLogsError } from '../project-audit-logs.model';

@Injectable()
export class ProjectAuditLogsErrorsPresenter {
  constructor(private repository: ProjectAuditLogsRepository) {}

  load(vmSubject$: BehaviorSubject<ProjectAuditLogsError>): void {
    this.repository.getErrors((dm: ProjectAuditLogsError) => {
      const viewModel = {
        ...dm
      };
      vmSubject$.next(viewModel);
    });
  }

  clearErrors(): void {
    this.repository.clearErrors();
  }
}
