import { Component, Input, OnInit } from '@angular/core';
import { Card } from '@jump-tech-frontend/domain';
import { Project } from '../../core/domain/project';
import { ApiService } from '../../core/api.service';
import { NgbActiveModal, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';
import { FormComponent } from '@jump-tech-frontend/relay/shared';

@Component({
    selector: 'app-relay-project',
    template: `
    <ng-container *ngIf="initialised">
      <div class="relay-overlay"></div>
      <div class="device">
        <nav class="phoneheader navbar">
          <i class="material-icons pl-2">wifi</i>
          <i class="material-icons ml-2 r90">battery_charging_full</i>
          <i class="material-icons pl-2 spacer"></i>
        </nav>
        <div class="screen">
          <relay-form
            [readOnly]="true"
            [demo]="false"
            [tenant]="project.tenant"
            [cards]="cards"
            [data]="project.data"
            [currentCard]="0"
            [viewingTenant]="viewingTenant"
          ></relay-form>
        </div>
      </div>
    </ng-container>
  `,
    providers: [ApiService],
    imports: [NgIf, NgbDropdownModule, FormComponent]
})
export class RelayComponent implements OnInit {
  private _project: Project;
  private _cards: Card<any>[];
  private _initialised: boolean;
  private readonly _activeModal: NgbActiveModal;
  private _relayConfiguration: any;
  private _readOnly = true;
  private _viewingTenant: string;

  @Input() set project(project: Project) {
    this._project = project;
  }

  get project(): Project {
    return this._project;
  }

  @Input() set viewingTenant(tenant: string) {
    this._viewingTenant = tenant;
  }

  get viewingTenant(): string {
    return this._viewingTenant;
  }

  @Input() set cards(cards: Card<any>[]) {
    this._cards = cards;
  }

  get cards(): Card<any>[] {
    return this._cards;
  }

  @Input() set readOnly(readOnly: boolean) {
    this._readOnly = readOnly;
  }

  get readOnly(): boolean {
    return this._readOnly;
  }

  get activeModal(): NgbActiveModal {
    return this._activeModal;
  }

  get initialised(): any {
    return this._initialised;
  }

  constructor(private apiService: ApiService, activeModal: NgbActiveModal) {
    this._activeModal = activeModal;
  }

  async ngOnInit() {
    await this.getData();
    this._initialised = true;
  }

  async getData() {
    const configs: any[] = await this.apiService.getRelayConfigurations(this.project.type, this.project.tenant);
    const foundRelayConfiguration = configs.find(x => {
      if (x.applicableTenants?.length) {
        const found = x.applicableTenants.find(tenant => tenant === this.project.tenant);
        if (found) {
          return true;
        }
      }
      return (x.projectType || x.type) === this.project.type;
    });

    if (!foundRelayConfiguration) {
      return;
    }

    this._relayConfiguration = await this.apiService.getRelayConfiguration(foundRelayConfiguration.id);

    this.cards = this._relayConfiguration.configuration.cards.map(x => {
      x.disabled = true;
      return x;
    });
  }
}
