// These env files are now used as a partial configuration for the environment service.
// This allows us to switch the environment configuration at runtime, without having to rebuild the application.
import { PathwayApiEnvironment } from './pathway-api-environment';
import * as appVersion from '../app-version.json';
const versionFile = appVersion;

export const environment: Partial<PathwayApiEnvironment> = {
  production: false,
  name: 'local',
  appVersion: versionFile.appVersion,
  translationPath: 'local',
  bucketSuffix: 'local',
  analytics: false,
  supportEmail: 'support@jumptech.co.uk',
  pathwaySupportUrl: 'https://support.jumptech.co.uk/',
  domain: 'jumptech.co.uk',
  apiRoot: 'https://api.local.jumptech.co.uk',
  launchDarkly: {
    clientToken: '62a0b8e29d3ec614fcd338e8'
  },
  httpRetryConfig: {
    initialInterval: 500,
    maxRetries: 7,
    resetOnSuccess: false
  },
  socketRetryConfig: {
    initialInterval: 1000,
    maxRetries: 10,
    resetOnSuccess: true
  },
  staticDistribution: 'https://static.local.jt.eco'
};
