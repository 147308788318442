import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormUpdate, IQuestionComponent } from '../question.model';
import { BehaviorSubject } from 'rxjs';
import { LikertPresenter } from './likert.presenter';
import { AsyncPipe, NgIf } from '@angular/common';
import { QUESTIONS } from '../questions.token';
import { LikertQuestionParams, LikertQuestionViewModel } from './likert.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
    selector: 'question-likert',
    templateUrl: './likert.component.html',
    imports: [NgIf, AsyncPipe, ReactiveFormsModule, CoreComponentsAngularModule],
    providers: [LikertPresenter, { provide: QUESTIONS, useExisting: LikertComponent, multi: true }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./likert.component.scss']
})
export class LikertComponent implements OnInit, IQuestionComponent {
  @Input() params: LikertQuestionParams;
  @Output() formChange: EventEmitter<FormUpdate> = new EventEmitter<FormUpdate>();

  private readonly presenter = inject(LikertPresenter);

  public readonly vm$: BehaviorSubject<LikertQuestionViewModel | null> =
    new BehaviorSubject<LikertQuestionViewModel | null>(null);

  ngOnInit() {
    this.presenter.load(this.vm$, this.params, this.formChange);
  }

  selectionChanged(selection: string) {
    this.vm$.value?.form?.get(this.params.key)?.setValue(selection);
  }
}
