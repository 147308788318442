@if (vm$ | async; as vm) {
  @if (vm.message) {
    <jui-alert-block
      size="sm"
      color="danger"
      [clearable]="true"
      (juiDismiss)="clearErrors()"
    >
      {{ vm.message }}
    </jui-alert-block>
  }
}
