import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

import { LegacyEnaApplicationPresenter } from '../legacy-ena-application.presenter';
import { LegacyEnaActionsVm } from '../legacy-ena.model';

@Component({
    selector: 'legacy-ena-application-actions',
    templateUrl: './legacy-ena-application-actions.component.html',
    styleUrl: './legacy-ena-application-actions.component.scss',
    imports: [AsyncPipe, CoreComponentsAngularModule]
})
export class LegacyEnaApplicationActionsComponent implements OnInit {
  vm$: BehaviorSubject<LegacyEnaActionsVm> = new BehaviorSubject<LegacyEnaActionsVm>(null);

  constructor(private presenter: LegacyEnaApplicationPresenter) {}

  ngOnInit(): void {
    this.presenter.loadActions(this.vm$);
  }

  async manuallyApprove(): Promise<void> {
    await this.presenter.manuallyApproveApplication();
  }

  async manuallyReject(): Promise<void> {
    await this.presenter.manuallyRejectApplication();
  }

  async resubmitApplication(): Promise<void> {
    await this.presenter.resubmitApplication();
  }
}
