import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ActionPresenter } from './actions.presenter';
import { ActionsBarViewModel, ActionViewModel } from './actionsBarViewModel';
import { BehaviorSubject } from 'rxjs';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { AsyncPipe, NgForOf, NgIf } from '@angular/common';

@Component({
    selector: 'actions-component',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss'],
    providers: [ActionPresenter],
    imports: [CoreComponentsAngularModule, AsyncPipe, NgIf, NgForOf],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsComponent implements OnInit {
  @Input() readOnly = false;
  @Input() demo = false;
  @Output() actionClicked: EventEmitter<void> = new EventEmitter<void>();

  private readonly actionPresenter = inject(ActionPresenter);

  vm$ = new BehaviorSubject<ActionsBarViewModel>(null);

  ngOnInit() {
    this.actionPresenter.load(this.readOnly, this.demo, this.vm$);
  }

  executeAction(action: ActionViewModel) {
    if (action.disabled) {
      return;
    }
    action.disabled = true;
    this.actionPresenter[action.action](this.readOnly, action.data);
    if (this.actionClicked) {
      this.actionClicked.emit();
    }
  }

  async resetCards() {
    await this.actionPresenter.reset();
  }
}
