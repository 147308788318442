import { EventEmitter, Injectable } from '@angular/core';
import { firstValueFrom, Subject, Subscription } from 'rxjs';
import { EnaAttachment, EnaAttachmentInputs, EnaAttachmentsDm, EnaFormI18n } from '../ena.model';
import { TranslocoService } from '@ngneat/transloco';
import { AccessService, PathwayFeature } from '../../../auth/services/access.service';
import { UserService } from '../../../auth/services/user.service';
import { User } from '../../../core/domain/user';
import { environment } from '../../../../environments/environment';
import { EnaAttachmentsService } from '../services/ena-attachments.service';
import { AttachmentsHelper } from '../../../shared/attachments.helper';
import { ProjectAttachment } from '../../project-attachments/project-attachments.component';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { ApiService } from '../../../core/api.service';
import { PresignedPost } from 'aws-sdk/clients/s3';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class EnaApplicationAttachmentsRepository {
  enaAttachments$: Subject<EnaAttachmentsDm>;
  enaAttachmentsSubscription: Subscription;
  enaAttachmentSettingsSubscription: Subscription;
  i18n: EnaFormI18n = {};
  currentSettings: EnaAttachmentInputs;
  defaultDm: EnaAttachmentsDm = null;
  hasRoleOrTeamPermission = false;
  user: User;
  bucketName = `pathway-attachments-${environment.bucketSuffix}`;
  form: FormGroup;
  attachmentChecked: EventEmitter<void>;

  constructor(
    private i18nService: TranslocoService,
    private featureAccessService: AccessService,
    private userService: UserService,
    private apiService: ApiService,
    private enaAttachmentsService: EnaAttachmentsService,
    private attachmentsHelper: AttachmentsHelper,
    private fb: FormBuilder
  ) {
    this.init();
  }

  init(): void {
    this.hasRoleOrTeamPermission = this.featureAccessService.isFeatureAccessAllowed(
      PathwayFeature.AttachmentsAccess,
      true
    );
    this.userService.userObservable.subscribe(user => {
      this.user = user;
    });
    this.enaAttachments$ = new Subject();
    this.initI18ns();

    this.defaultDm = {
      attachments: [],
      form: this.fb.group({}),
      i18ns: this.i18n,
      uploadInProgress: false,
      uploadProgress: { fileName: null, progress: null, roundedProgress: '' },
      canEditFile: false,
      canUploadFile: false,
      acceptedFileTypes: this.attachmentsHelper.getAcceptedMimeTypes(),
      selectionDisabled: false
    };
  }

  load(groupForm: FormGroup, attachmentCheckedEventEmitter: EventEmitter<void>, cb): void {
    this.form = groupForm;
    this.enaAttachmentSettingsSubscription?.unsubscribe();
    this.enaAttachmentSettingsSubscription = this.enaAttachmentsService.attachmentSettings$.subscribe(settings => {
      this.currentSettings = settings;
      this.defaultDm = {
        ...this.defaultDm,
        uploadProgress: { fileName: null, progress: null, roundedProgress: '' },
        canUploadFile: !this.currentSettings.readonly && this.hasRoleOrTeamPermission,
        selectionDisabled: this.currentSettings.readonly
      };
      this.parseAndNotify();
    });
    this.enaAttachmentsSubscription?.unsubscribe();
    this.enaAttachmentsSubscription = this.enaAttachments$.subscribe(cb);
    this.attachmentChecked = attachmentCheckedEventEmitter;
    this.parseAndNotify();
  }

  async downloadFile(attachment: EnaAttachment) {
    const result = await this.apiService.getAttachmentOrUrl(attachment.url).toPromise();
    const anchor = document.createElement('a');
    anchor.href = result;
    anchor.setAttribute('download', attachment.key);
    anchor.setAttribute('target', '_blank');
    document.body.appendChild(anchor);
    anchor.click();
  }

  handleFileSelection(checked: boolean, fileName: string): void {
    this.defaultDm.form.controls[fileName].patchValue(checked);
    if (this.form.controls[fileName]) {
      this.form.controls[fileName].patchValue(checked);
    }
    this.attachmentChecked.emit();
  }

  updateProgress(currentProgress: number): void {
    this.defaultDm = {
      ...this.defaultDm,
      uploadProgress: {
        ...this.defaultDm.uploadProgress,
        progress: currentProgress,
        roundedProgress: currentProgress.toFixed(1)
      }
    };
    this.enaAttachments$.next(this.defaultDm);
  }

  private setNewAttachmentAsChecked(): void {
    const uploadedFileName = this.defaultDm.attachments[this.defaultDm.attachments.length - 1].safeFormControlName;
    this.defaultDm.form.controls[uploadedFileName].patchValue(true);
  }

  async validateAndUploadFile(fileInput: Event): Promise<boolean> {
    const file = (fileInput.target as HTMLInputElement).files[0];
    if (!this.attachmentsHelper.checkIsFileTypeAndSizeOK(file)) {
      return false;
    }

    const { uploadDate, key, fileName, fileType } = this.attachmentsHelper.getFileUploadDetail(
      file,
      this.currentSettings.projectId
    );
    this.defaultDm.uploadInProgress = true;
    this.defaultDm.uploadProgress = { fileName: fileName, progress: 0, roundedProgress: '' };

    try {
      const presignedPost: PresignedPost = await firstValueFrom(
        this.apiService.getSignedPostUrl(key, this.bucketName, fileType)
      );
      this.attachmentsHelper
        .postAndTrackProgress(presignedPost, file, null, this.updateProgress.bind(this))
        .subscribe(() => {
          this.addOrUpdateAttachment(fileName, uploadDate);
          this.buildAttachmentForm();
          this.setNewAttachmentAsChecked();
          this.enaAttachmentsService.notifyModifiedAttachmentSubs(this.defaultDm.attachments);
          this.defaultDm.uploadInProgress = false;
          this.enaAttachments$.next(this.defaultDm);
          return true;
        });
    } catch (e) {
      this.attachmentsHelper.onPostToS3ErrorEvent(this.i18n.postToS3Error);
    }
  }

  private addOrUpdateAttachment(fileName: string, uploadDate: number): void {
    const index = this.currentSettings.attachments.findIndex(attachment => attachment.key === fileName);
    if (index > -1) {
      const attachment = { ...this.currentSettings.attachments[index], uploadDate };
      this.defaultDm.attachments[index] = this.generateEnaAttachment(attachment);
    } else {
      // build new attachment
      const tempNewEnaAttachment = {
        key: fileName,
        uploadDate: uploadDate,
        uploadedBy: this.user.id,
        uploadedByLabel: this.user.label,
        uploadedByTenant: this.currentSettings.tenant
      };
      const newEnaAttachment = this.generateEnaAttachment(tempNewEnaAttachment);
      this.defaultDm.attachments.push(newEnaAttachment);
    }
  }

  private generateEnaAttachment(attachment: ProjectAttachment): EnaAttachment {
    return {
      ...attachment,
      safeFormControlName: attachment.key.replace(/.([^.]*)$/, '__$1'),
      fileDisplayName: this.attachmentsHelper.getSanitisedFilename(attachment.key),
      uploadedByDisplayName: this.createUploadedByDisplayName(attachment),
      uploadDateDisplay: JumptechDate.from(attachment.uploadDate).toDateTimeFormat(),
      showDownload: !!attachment.url
    };
  }

  private buildAttachmentForm(): void {
    this.defaultDm.attachments.forEach(attachment => {
      const isChecked =
        this.defaultDm.form.controls[attachment.safeFormControlName]?.value ||
        this.form.controls[attachment.safeFormControlName]?.value;
      if (this.form.get(attachment.safeFormControlName)) {
        this.form.get(attachment.safeFormControlName).patchValue(isChecked);
      } else {
        this.form.addControl(attachment.safeFormControlName, new FormControl(!!isChecked));
      }
    });
    this.defaultDm.form = this.form;
  }

  private parseAndNotify(): void {
    if (!this.currentSettings.attachments) {
      return;
    }

    this.defaultDm.attachments = this.currentSettings.attachments.map(attachment => {
      return this.generateEnaAttachment(attachment);
    });

    this.buildAttachmentForm();
    this.enaAttachments$.next(this.defaultDm);
  }

  private createUploadedByDisplayName(attachment: ProjectAttachment) {
    const uploadedByTenantString =
      attachment?.uploadedByTenant !== this.currentSettings.tenant ? `(${attachment.uploadedByTenant})` : '';
    return `${attachment.uploadedByLabel || this.i18n.unknown} ${uploadedByTenantString}`;
  }

  private initI18ns(): void {
    this.i18n.fileName = this.i18nService.translate('common.fileName');
    this.i18n.uploadDate = this.i18nService.translate('common.uploadDate');
    this.i18n.upload = this.i18nService.translate('common.upload');
    this.i18n.uploadedBy = this.i18nService.translate('common.uploadedBy');
    this.i18n.actions = this.i18nService.translate('common.actions');
    this.i18n.unknown = this.i18nService.translate('common.unknown');
    this.i18n.download = this.i18nService.translate('common.download');
    this.i18n.delete = this.i18nService.translate('common.delete');
    this.i18n.projectSpecificAttachments = this.i18nService.translate('projectDetail.projectSpecificAttachments');
    this.i18n.postToS3Error = this.i18nService.translate('common.modals.invalidGeneralPostFile.messages.unknownError');
  }
}
