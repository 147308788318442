import { Component, Input } from '@angular/core';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
  selector: 'schedule-event-selected-job-details-overlaps',
  templateUrl: 'schedule-event-selected-job-details-overlaps.component.html',
  styleUrls: ['../../../schedule-event-selected-job-details/schedule-event-selected-job-details.component.scss'],
  imports: [CoreComponentsAngularModule]
})
export class ScheduleEventSelectedJobDetailsOverlapsComponent {
  @Input() overlapDetails: string[];
  @Input() overlapLabel: string;
}
