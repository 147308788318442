import { Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { InlineSpinnerComponent } from '@jump-tech-frontend/cards';

@Component({
    selector: 'app-sign-in-success',
    templateUrl: './sign-in-success.component.html',
    styleUrls: ['../../login.component.scss'],
    imports: [TranslocoModule, InlineSpinnerComponent]
})
export class SignInSuccessComponent {
  constructor() {}
}
