import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ImageAsset, ImageAssetType, ImageError, ImageManipulationService } from '@jump-tech-frontend/cards';
import { I18nKeys } from '@jump-tech-frontend/app-config';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApiService } from '../api.service';
import { NgForOf, NgIf } from '@angular/common';
import { QuestionFormErrorComponent } from '@jump-tech-frontend/question-components';

@Component({
    selector: 'app-multi-image-upload-component',
    template: `
    <ng-container *ngIf="uploadError">
      <div class="file-upload-error" *ngFor="let err of uploadError">
        <question-form-error [message]="err" [i18ns]="i18ns" class="file-upload-error"></question-form-error>
      </div>
    </ng-container>
    <div
      class="upload-box"
      [class.upload-box--dropping]="isDropping"
      (drop)="dropHandler($event)"
      (dragover)="dragOverHandler($event)"
      (dragenter)="dragEnterHandler($event)"
      (dragleave)="dragLeaveHandler($event)"
    >
      <input
        [id]="'image_' + question.key"
        [name]="'image_' + question.key"
        (change)="setFromImage($event)"
        class="imageCapture"
        multiple
        style="display:none;"
        type="file"
        [accept]="accept + ',text/plain'"
      />

      <ng-container *ngIf="isUploading">
        <div class="upload-box__name">{{ item.key }}</div>
        <jui-icon name="upload" color="high" isButton="false"></jui-icon>
        <div class="upload-box__msg">{{ i18ns.uploadInProgress }}</div>
        <div class="upload-box__upload-btn" *ngIf="showButton">
          <jui-button size="sm" expand="true" color="low" loading="true" disabled="true">{{
            i18ns.uploading
          }}</jui-button>
        </div>
      </ng-container>

      <ng-container *ngIf="!isUploading">
        <ng-container *ngIf="isDropping">
          <div class="upload-box__name">{{ item.key }}</div>
          <jui-icon name="upload" color="high" isButton="false"></jui-icon>
          <div class="upload-box__msg">{{ i18ns.dropToUpload }}</div>
          <div class="upload-box__upload-btn" *ngIf="showButton">
            <jui-button size="sm" expand="true" (click)="beginUpload(question.key)">{{ i18ns.upload }}</jui-button>
          </div>
        </ng-container>

        <ng-container *ngIf="!isDropping">
          <div class="upload-box__name">{{ item.key }}</div>
          <jui-icon name="upload" color="high" isButton="false"></jui-icon>
          <div class="upload-box__msg">{{ i18ns.dropFilesHere }}</div>
          <div class="upload-box__upload-btn" *ngIf="showButton">
            <jui-button size="sm" expand="true" display="ghost" (click)="beginUpload(question.key)">{{
              i18ns.browseFiles
            }}</jui-button>
          </div>
        </ng-container>
      </ng-container>
    </div>
  `,
    imports: [CoreComponentsAngularModule, NgIf, QuestionFormErrorComponent, NgForOf],
    styleUrls: ['./multi-image-upload-component.scss']
})
export class MultiImageUploadComponent implements OnInit {
  @Input() question: any;
  @Input() form: UntypedFormGroup;
  @Input() project: any;
  @Input() item: any;
  @Input() data: any;
  @Input() existingFiles: string[];
  @Input() showButton: boolean = true;
  @Input() i18ns: I18nKeys;
  @Output() imageUploaded: EventEmitter<string> = new EventEmitter<string>();
  @Output() processingFiles: EventEmitter<number> = new EventEmitter<number>();
  @Output() saveSuccess: EventEmitter<any> = new EventEmitter<any>();

  private destroyRef: DestroyRef = inject(DestroyRef);

  uploadError: string[] = [];
  imageError: ImageError | null;
  type: string;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;
  isDropping = false;
  isUploading = false;
  enterTarget = null;

  readonly accept = 'image/*';
  constructor(private imageService: ImageManipulationService, private apiService: ApiService) {}

  ngOnInit(): void {
    this.setForm(this.item);
  }

  clearErrors(): void {
    this.uploadError = [];
  }

  async dropHandler(evt): Promise<void> {
    evt.preventDefault();
    this.clearErrors();

    let fileList = [];
    if (evt.dataTransfer.items) {
      [...evt.dataTransfer.items].forEach((item, i) => {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          fileList.push(file);
        }
      });
      this.isUploading = true;
      await this.setFromImage(null, fileList);
      this.isDropping = false;
      evt.target.classList.remove('upload-box--dropping');
    } else {
      [...evt.dataTransfer.files].forEach((file, i) => {
        console.log(`… file[${i}].name = ${file.name}`);
      });
    }
  }

  dragEnterHandler(evt): void {
    evt.preventDefault();
    evt.stopPropagation();
    this.enterTarget = evt.target;
    evt.target.classList.add('upload-box--dropping');
    this.isDropping = true;
  }
  dragLeaveHandler(evt): void {
    evt.preventDefault();
    evt.stopPropagation();

    if (this.enterTarget == evt.target) {
      evt.target.classList.remove('upload-box--dropping');
      this.isDropping = false;
    }
  }
  dragOverHandler(evt): void {
    evt.preventDefault();
  }

  beginUpload(questionKey): void {
    this.clearErrors();
    (document.getElementById(`image_${questionKey}`) as HTMLElement).click();
  }

  setForm(galleryItem) {
    const formControls = {};
    if (galleryItem.editConfig) {
      formControls[galleryItem.editConfig.key] = new UntypedFormControl(
        this.getFormValue(galleryItem.editConfig.key) || ''
      );
    }
    this.form = new UntypedFormGroup(formControls);
  }

  getFormValue(editConfigKey: string) {
    if (editConfigKey.indexOf('.') === -1) {
      return this.data[editConfigKey];
    }
    const parts = editConfigKey.split('.');
    return this.data[parts[0]][parts[1]];
  }

  async setFromImage(event, droppedFiles?) {
    this.isUploading = true;
    let files: File[];
    if (droppedFiles) {
      files = droppedFiles;
    } else {
      files = [];
      for (let i = 0; i < Object.keys(event.target.files).length; i++) {
        files.push(event.target.files[i]);
      }
    }

    if (!files) {
      return; // User cancelled
    }

    files.forEach(f => {
      this.imageError = this.imageService.checkFileTypeAndSize(f, this.accept);
      if (this.imageError) {
        this.uploadError.push(`${this.imageError.fileName}: ${this.i18ns[this.imageError.type]}`);
      }
    });

    if (this.uploadError.length) {
      this.isUploading = false;
      return;
    }

    try {
      this.processingFiles.emit(files.length);
      const calls = [];
      files.forEach(file => {
        calls.push(this.imageService.resizeImageFromFile(file));
      });

      const resizedImages = await Promise.all(calls);
      if (this.existingFiles) {
        this.save(this.form, [...this.existingFiles, ...resizedImages]);
      } else {
        this.save(this.form, resizedImages);
      }

      this.isUploading = false;
    } catch (error) {
      this.isUploading = false;
      // Swallowing error as likely comes from Cancelled upload
      console.log('Failed image upload', error);
      // Cannot use instanceof
      if (error.name === 'ImageUploadError') {
        this.uploadError.push(this.i18ns.uploadImageError);
      }
    }
  }

  save(form: UntypedFormGroup, fileStrings): void {
    const payload = {
      [this.question.key]: fileStrings
    };

    this.apiService
      .updateProject(this.project, { data: payload })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        Object.assign(this.project.data, payload);
        this.saveSuccess.emit(payload);
        this.processingFiles.emit(0);
      });
  }
}
