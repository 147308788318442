export const getCommonsTranslation = (commonsI18ns: any, key: string) => {
  if (commonsI18ns) {
    if (key.indexOf('.') === -1) {
      return commonsI18ns[key] ?? key;
    } else {
      // resolve nested
      const keys = key.split('.');
      return keys.reduce((previousValue: string, currentValue: string) => {
        return previousValue[currentValue] ?? key;
      }, commonsI18ns);
    }
  }
};
