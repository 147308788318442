import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormUpdate, IQuestionComponent } from '../question.model';
import { BehaviorSubject } from 'rxjs';
import { PhoneNumberPresenter } from './phone-number.presenter';
import { AsyncPipe, NgIf } from '@angular/common';
import { QUESTIONS } from '../questions.token';
import { PhoneNumberQuestionParams, PhoneNumberQuestionViewModel } from './phone-number.model';
import { NgxIntlTelInputModule, SearchCountryField } from 'ngx-intl-tel-input';

@Component({
    selector: 'question-phone-number',
    templateUrl: './phone-number.component.html',
    imports: [NgIf, AsyncPipe, ReactiveFormsModule, NgxIntlTelInputModule],
    providers: [PhoneNumberPresenter, { provide: QUESTIONS, useExisting: PhoneNumberComponent, multi: true }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./phone-number.component.scss']
})
export class PhoneNumberComponent implements OnInit, IQuestionComponent {
  private readonly presenter = inject(PhoneNumberPresenter);

  public readonly vm$: BehaviorSubject<PhoneNumberQuestionViewModel | null> =
    new BehaviorSubject<PhoneNumberQuestionViewModel | null>(null);

  @Input() params: PhoneNumberQuestionParams;
  @Output() formChange: EventEmitter<FormUpdate> = new EventEmitter<FormUpdate>();

  async ngOnInit() {
    await this.presenter.load(this.vm$, this.params, this.formChange);
  }

  protected readonly SearchCountryField = SearchCountryField;
}
