import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IDocumentVm, IStageVm } from '../../document-pack.model';
import { DocumentPackDocumentRowComponent } from '../document-pack-document-row/document-pack-document-row.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-document-pack-document-stage',
    templateUrl: './document-pack-document-stage.component.html',
    styleUrls: ['../../document-pack.component.scss'],
    imports: [NgIf, CoreComponentsAngularModule, NgbCollapseModule, NgFor, DocumentPackDocumentRowComponent]
})
export class DocumentPackDocumentStageComponent {
  @Input() vm: IStageVm;
  @Input() projectId;
  @Input() acceptedFileTypes;
  @Input() actionButtonDisabled;
  @Input() inProgress;
  @Input() locked = false;

  @Output() actionClick: EventEmitter<{ fileInput: HTMLInputElement; docVm: IDocumentVm }> = new EventEmitter();
  @Output() requiredToggle: EventEmitter<IDocumentVm> = new EventEmitter();
  @Output() fileChange: EventEmitter<{ event: any; docVm: IDocumentVm }> = new EventEmitter();
  @Output() onAttachmentSaved: EventEmitter<any> = new EventEmitter();
  @Output() toggleStage: EventEmitter<IStageVm> = new EventEmitter();
}
