import { Component, EventEmitter, Output } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { InlineSpinnerComponent } from '@jump-tech-frontend/cards';

@Component({
    selector: 'app-token-refresh',
    templateUrl: './token-refresh.component.html',
    styleUrls: ['../../login.component.scss'],
    imports: [CoreComponentsAngularModule, TranslocoModule, InlineSpinnerComponent]
})
export class TokenRefreshComponent {
  @Output() goToRouteEvent: EventEmitter<null> = new EventEmitter<null>();

  constructor() {}

  goBackOrHome(): void {
    this.goToRouteEvent.emit();
  }
}
