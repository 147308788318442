import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ScheduleJobsDisplayVm } from '../../schedule.model';
import { NumberToArrayPipe } from '../../../shared/pipes/number-to-array.pipe';

@Component({
    selector: 'schedule-jobs-loader',
    templateUrl: './schedule-jobs-loader.component.html',
    styleUrls: ['../schedule-jobs-display/schedule-jobs-display.component.scss'],
    imports: [TranslocoModule, CommonModule, CoreComponentsAngularModule, NumberToArrayPipe]
})
export class ScheduleJobsLoaderComponent {
  @Input() vm: ScheduleJobsDisplayVm;
}
