import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

import { LegacyEnaApplicationPresenter } from './legacy-ena-application.presenter';
import { LegacyEnaVm } from './legacy-ena.model';
import { Project } from '../../core/domain/project';
import { ListLayoutComponent } from '../../core/cardLayouts/list-layout.component';
import { LegacyEnaApplicationActionsComponent } from './legacy-ena-application-actions/legacy-ena-application-actions.component';

@Component({
  selector: 'legacy-ena-application',
  templateUrl: 'legacy-ena-application.component.html',
  styleUrl: 'legacy-ena-application.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    CoreComponentsAngularModule,
    NgxSpinnerModule,
    LegacyEnaApplicationActionsComponent,
    ListLayoutComponent
  ],
  standalone: true
})
export class LegacyEnaApplicationComponent implements OnInit {
  vm$: BehaviorSubject<LegacyEnaVm> = new BehaviorSubject<LegacyEnaVm>(null);

  @Input() project: Project;

  constructor(private presenter: LegacyEnaApplicationPresenter) {}

  ngOnInit(): void {
    this.presenter.load(this.vm$, this.project);
  }

  async retryLoad(): Promise<void> {
    await this.presenter.retryLoadApplication();
  }
}
