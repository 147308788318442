import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { I18nKeys } from '../../domain/i18n-keys';
import { UntypedFormGroup } from '@angular/forms';
import { tap, debounceTime, distinctUntilChanged, Subscription, startWith } from 'rxjs';
import { MapInfoWindow, MapMarker, GoogleMapsModule } from '@angular/google-maps';
import { QuestionHintComponent, QuestionHintPopoverComponent } from '@jump-tech-frontend/question-components';
import { NgIf, NgFor } from '@angular/common';

export interface Marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}

@Component({
  selector: 'crds-question-google-maps-marker',
  template: `
    <!-- QUESTION HINT -->
    <div class="card-item-spacer card-item-spacer--end">
      <question-hint-popover *ngIf="lat && hasAddress()" [question]="question"></question-hint-popover>
    </div>
    <question-hint *ngIf="lat && hasAddress()" [question]="question" class="mb-2"></question-hint>
    <div *ngIf="lat && hasAddress()" class="outer-wrapper">
      <div class="map-wrapper" id="wrapper" [attr.data-qa]="question.key" #wrapper>
        <google-map
          class="google-map"
          height="100%"
          width="100%"
          [center]="{lat, lng}"
          [zoom]="zoom"
          [mapTypeId]="mapStyle"
          (mapClick)="mapClicked($event)"
          [options]="mapOptions"
        >
          <map-marker
            #marker="mapMarker"
            *ngFor="let m of markers; let i = index"
            (mapClick)="clickedMarker(m.label, marker, i)"
            [position]="{ lat: m.lat, lng: m.lng }"
            [label]="m.label ?? ''"
            (mapDragend)="markerDragEnd(m, $event)"
          >
            <map-info-window *ngIf="!readOnly && i18ns?.yourLocation">
              <div>{{ i18ns.yourLocation }}</div>
            </map-info-window>
          </map-marker>
        </google-map>
      </div>
    </div>
  `,
  styles: [
    `
      .map-wrapper {
        height: 300px;
      }

      .google-map {
        margin: 0;
        padding: 0;
      }

      :host ::ng-deep google-map > div {
        margin-top: 0.75rem;
      }

      .outer-wrapper {
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
      }
    `
  ],
  imports: [
    NgIf,
    QuestionHintPopoverComponent,
    QuestionHintComponent,
    GoogleMapsModule,
    NgFor,
    QuestionHintPopoverComponent
  ]
})
export class GoogleMapsMarkerQuestionComponent implements OnDestroy {
  @Input() set form(form: UntypedFormGroup) {
    this._form = form;
    this.subscribeToFormChanges();
  }
  get form() {
    return this._form;
  }
  private _form: UntypedFormGroup;
  @Input() question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  @Input() readOnly: boolean;

  @ViewChild(MapMarker) mapMarker: MapMarker;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  // google maps zoom level
  zoom = 18;

  // initial center position for the map
  lat: number;
  lng: number;
  markers: Marker[] = [];

  mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    tilt: 0
  };

  formChangesSubscription: Subscription | undefined;

  get mapStyle(): google.maps.MapTypeId {
    return this.question?.mapsConfig?.mapStyle ?? google.maps.MapTypeId.HYBRID;
  }

  subscribeToFormChanges() {
    const changes$ = this.form?.get('address')?.valueChanges;
    this.formChangesSubscription = changes$
      ?.pipe(
        startWith({
          latitude: this.form?.get('address')?.value?.['latitude'] ?? null,
          longitude: this.form?.get('address')?.value?.['longitude'] ?? null
        }),
        debounceTime(1000),
        distinctUntilChanged(),
        tap((value: { latitude?: string; longitude?: string }) => {
          this.updateValues(value?.latitude, value?.longitude);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    if (this.formChangesSubscription) {
      this.formChangesSubscription.unsubscribe();
    }
  }

  hasAddress() {
    return this.form?.get('address')?.value?.['latitude'];
  }

  mapClicked($event: google.maps.MapMouseEvent) {
    if (this.readOnly) {
      return;
    }
    this.updateFromEvent($event);
  }

  clickedMarker(label: string | undefined, mapMarker: MapMarker, index: number) {
    console.log(`clicked the marker: ${label || index}`);
    this.openInfoWindow(mapMarker);
  }

  markerDragEnd(m: Marker, $event: google.maps.MapMouseEvent) {
    this.updateFromEvent($event);
  }

  openInfoWindow(marker: MapMarker) {
    this.infoWindow.open(marker);
  }

  private setMarkers(lat: number, lng: number) {
    this.markers.length = 0;
    this.markers.push({
      lat: lat,
      lng: lng,
      draggable: true
    });
  }

  private updateFromEvent($event: google.maps.MapMouseEvent) {
    this.updateValues($event.latLng.lat(), $event.latLng.lng());
    this.lat = $event.latLng.lat();
    this.lng = $event.latLng.lng();
  }

  private updateValues(lat: any, lng: any) {
    if (!!lat && !!lng && lat != this.lat) {
      lat = parseFloat(lat);
      lng = parseFloat(lng);
      setTimeout(() => {
        this.setMarkers(lat, lng);
        this.lat = lat;
        this.lng = lng;
        this.form?.get(this.question.key)?.patchValue(`${this.lat},${this.lng}`);
        if (this.form?.get('address')?.get('latitude')) {
          this.form?.get('address')?.get('latitude')?.patchValue(this.lat);
          this.form?.get('address')?.get('longitude')?.patchValue(this.lng);
        } else {
          const value = this.form?.get('address')?.value;
          const latLng = { latitude: this.lat, longitude: this.lng };
          this.form?.get('address')?.patchValue({ ...value, ...latLng });
        }
      });
    }
  }
}
