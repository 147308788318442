import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GlobalError } from '../../error/global-errors/global-errors.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorsService {
  private globalErrorsSubject: BehaviorSubject<GlobalError> = new BehaviorSubject(null);
  globalErrors$: Observable<GlobalError> = this.globalErrorsSubject.asObservable();

  public notifyGlobalError(error: GlobalError): void {
    this.globalErrorsSubject.next(error);
  }
}
