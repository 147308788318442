import { Component, Input, ChangeDetectorRef, OnInit, OnDestroy, NgZone } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ToasterConfig } from './toaster-config';
import { ToasterService } from './toast-service';
import { IClearWrapper, Toast } from './toast.model';
import { ToastComponent } from './toast-message.component';
import { NgClass, NgFor } from '@angular/common';

@Component({
    selector: 'toaster-container',
    templateUrl: './toaster-container.component.html',
    animations: [
        trigger('toastState', [
            state('flyRight, flyLeft, slideDown, slideUp, fade', style({ opacity: 1, transform: 'translate(0,0)' })),
            transition('void => flyRight', [
                style({
                    opacity: 0,
                    transform: 'translateX(100%)'
                }),
                animate('0.25s ease-in')
            ]),
            transition('flyRight => void', [
                animate('0.25s 10ms ease-out', style({
                    opacity: 0,
                    transform: 'translateX(100%)'
                }))
            ]),
            transition('void => flyLeft', [
                style({
                    opacity: 0,
                    transform: 'translateX(-100%)'
                }),
                animate('0.25s ease-in')
            ]),
            transition('flyLeft => void', [
                animate('0.25s 10ms ease-out', style({
                    opacity: 0,
                    transform: 'translateX(-100%)'
                }))
            ]),
            transition('void => slideDown', [
                style({
                    opacity: 0,
                    transform: 'translateY(-200%)'
                }),
                animate('0.3s ease-in')
            ]),
            transition('slideDown => void', [
                animate('0.3s 10ms ease-out', style({
                    opacity: 0,
                    transform: 'translateY(200%)'
                }))
            ]),
            transition('void => slideUp', [
                style({
                    opacity: 0,
                    transform: 'translateY(200%)'
                }),
                animate('0.3s ease-in')
            ]),
            transition('slideUp => void', [
                animate('0.3s 10ms ease-out', style({
                    opacity: 0,
                    transform: 'translateY(-200%)'
                }))
            ]),
            transition('void => fade', [
                style({
                    opacity: 0
                }),
                animate('0.3s ease-in')
            ]),
            transition('fade => void', [
                animate('0.3s 10ms ease-out', style({
                    opacity: 0
                }))
            ])
        ])
    ],
    imports: [NgClass, NgFor, ToastComponent]
})
export class ToasterContainerComponent implements OnInit, OnDestroy {
  private addToastSubscriber: any;
  private clearToastsSubscriber: any;
  private toasterService: ToasterService;

  private timeoutIds = new Map<string, number>();

  @Input() toasterConfig: ToasterConfig;

  public toasts: Toast[] = [];

  constructor(toasterService: ToasterService, private ref: ChangeDetectorRef, private ngZone: NgZone) {
    this.toasterService = toasterService;
  }

  ngOnInit() {
    this.registerSubscribers();
    if (this.isNullOrUndefined(this.toasterConfig)) {
      this.toasterConfig = new ToasterConfig();
    }
  }

  // event handlers
  click(toast: Toast, isCloseButton?: boolean) {
    if (this.toasterConfig.tapToDismiss || (toast.showCloseButton && isCloseButton)) {
      let removeToast = true;
      if (toast.clickHandler) {
        if (typeof toast.clickHandler === 'function') {
          removeToast = toast.clickHandler(toast, isCloseButton);
        } else {
          console.log('The toast click handler is not a callable function.');
          return false;
        }
      }

      if (removeToast) {
        this.removeToast(toast);
      }
    }
  }

  childClick($event: any) {
    this.click($event.value.toast, $event.value.isCloseButton);
  }

  stopTimer(toast: Toast) {
    if (this.toasterConfig.mouseoverTimerStop) {
      const toastId = this.toastIdOrDefault(toast);
      const timeoutId = this.timeoutIds.get(toastId);

      if (timeoutId) {
        window.clearTimeout(timeoutId);
        this.timeoutIds.delete(toastId);
      }
    }
  }

  restartTimer(toast: Toast) {
    const timeoutId = this.timeoutIds.get(this.toastIdOrDefault(toast));

    if (this.toasterConfig.mouseoverTimerStop) {
      if (!timeoutId) {
        this.configureTimer(toast);
      }
    } else if (toast.timeout !== 0 && !timeoutId && this.toasterConfig.timeout) {
      this.removeToast(toast);
    }
  }

  private registerSubscribers() {
    this.addToastSubscriber = this.toasterService.addToast.subscribe((toast: Toast) => {
      this.addToast(toast);
    });

    this.clearToastsSubscriber = this.toasterService.clearToasts.subscribe((clearWrapper: IClearWrapper) => {
      this.clearToasts(clearWrapper);
    });
  }

  private addToast(toast: Toast) {
    if (
      toast.toastContainerId &&
      this.toasterConfig.toastContainerId &&
      toast.toastContainerId !== this.toasterConfig.toastContainerId
    ) {
      return;
    }

    if (!toast.type) {
      toast.type = this.toasterConfig.defaultTypeClass;
    }

    if (this.toasterConfig.preventDuplicates && this.toasts.length > 0) {
      if (toast.toastId && this.toasts.some(t => t.toastId === toast.toastId)) {
        return;
      } else if (this.toasts.some(t => t.body === toast.body)) {
        return;
      }
    }

    if (this.isNullOrUndefined(toast.showCloseButton)) {
      if (typeof this.toasterConfig.showCloseButton === 'object') {
        toast.showCloseButton = this.toasterConfig.showCloseButton[toast.type];
      } else if (typeof this.toasterConfig.showCloseButton === 'boolean') {
        toast.showCloseButton = <boolean>this.toasterConfig.showCloseButton;
      }
    }

    if (toast.showCloseButton) {
      toast.closeHtml = toast.closeHtml || this.toasterConfig.closeHtml;
    }

    toast.bodyOutputType = toast.bodyOutputType || this.toasterConfig.bodyOutputType;

    this.configureTimer(toast);

    if (this.toasterConfig.newestOnTop) {
      this.toasts.unshift(toast);
      if (this.isLimitExceeded()) {
        this.toasts.pop();
      }
    } else {
      this.toasts.push(toast);
      if (this.isLimitExceeded()) {
        this.toasts.shift();
      }
    }

    if (toast.onShowCallback) {
      toast.onShowCallback(toast);
    }
  }

  private configureTimer(toast: Toast) {
    let timeout = typeof toast.timeout === 'number' ? toast.timeout : this.toasterConfig.timeout;

    if (typeof timeout === 'object') {
      timeout = timeout[toast.type];
    }
    if (timeout > 0) {
      this.ngZone.runOutsideAngular(() => {
        const timeoutId = window.setTimeout(() => {
          this.ngZone.run(() => {
            this.ref.markForCheck();
            this.removeToast(toast);
          });
        }, <number>timeout);

        this.timeoutIds.set(this.toastIdOrDefault(toast), timeoutId);
      });
    }
  }

  private isLimitExceeded() {
    return this.toasterConfig.limit && this.toasts.length > this.toasterConfig.limit;
  }

  private removeToast(toast: Toast) {
    const index = this.toasts.indexOf(toast);
    if (index < 0) {
      return;
    }

    const toastId = this.toastIdOrDefault(toast);
    const timeoutId = this.timeoutIds.get(toastId);

    this.toasts.splice(index, 1);

    if (timeoutId) {
      window.clearTimeout(timeoutId);
      this.timeoutIds.delete(toastId);
    }
    if (toast.onHideCallback) {
      toast.onHideCallback(toast);
    }
    this.toasterService._removeToastSubject.next({
      toastId: toastId,
      toastContainerId: toast.toastContainerId
    });
  }

  private removeAllToasts() {
    for (let i = this.toasts.length - 1; i >= 0; i--) {
      this.removeToast(this.toasts[i]);
    }
  }

  private clearToasts(clearWrapper: IClearWrapper) {
    const toastId = clearWrapper.toastId;
    const toastContainerId = clearWrapper.toastContainerId;

    if (this.isNullOrUndefined(toastContainerId)) {
      this.clearToastsAction(toastId);
    } else if (toastContainerId === this.toasterConfig.toastContainerId) {
      this.clearToastsAction(toastId);
    }
  }

  private clearToastsAction(toastId?: string) {
    if (toastId) {
      this.removeToast(this.toasts.filter(t => t.toastId === toastId)[0]);
    } else {
      this.removeAllToasts();
    }
  }

  private toastIdOrDefault(toast: Toast) {
    return toast.toastId || '';
  }

  private isNullOrUndefined(value: any): boolean {
    return value === null || typeof value === 'undefined';
  }

  ngOnDestroy() {
    if (this.addToastSubscriber) {
      this.addToastSubscriber.unsubscribe();
    }
    if (this.clearToastsSubscriber) {
      this.clearToastsSubscriber.unsubscribe();
    }
  }
}
