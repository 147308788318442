import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalErrorsRepository } from './global-errors.repository';
import { GlobalErrorsDm, GlobalErrorsVm, GlobalErrorType } from './global-errors.model';

@Injectable()
export class GlobalErrorsPresenter {
  constructor(private repository: GlobalErrorsRepository) {}

  load(vmSubject$: BehaviorSubject<GlobalErrorsVm>): void {
    this.repository.load((dm: GlobalErrorsDm): void => {
      if (!dm) {
        vmSubject$.next(null);
      } else {
        vmSubject$.next({
          topMessage: dm.topMessage,
          canViewErrorDetails: dm.isSuperAdmin,
          topIcon: dm.showDetails ? 'expand_less' : 'expand_more',
          errorList: dm.errors.map(err => ({
            message: err.detail,
            type: err.type === GlobalErrorType.server ? dm.serverLabel : dm.clientLabel,
            typeColor: err.type === GlobalErrorType.server ? 'default' : 'info'
          })),
          qaErrorMessage: 'globalErrorMessage',
          qaErrorDetail: 'globalErrorDetail',
          qaClearErrorsButton: 'globalErrorButton',
          qaErrorToggleDetailButton: 'globalErrorToggleDetail',
          qaErrorList: 'globalErrorList',
          showDetails: dm.showDetails && dm.isSuperAdmin
        });
      }
    });
  }

  clearErrors(): void {
    this.repository.clearErrors();
  }

  toggleDetail(): void {
    this.repository.toggleDetail();
  }
}
