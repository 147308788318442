import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { Card, ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { I18nKeys } from '../../domain/i18n-keys';
import { QuestionsComponent } from '../../core/questions.component';
import { NgIf } from '@angular/common';
import { ImageAssetComponent } from '../../core/image-asset.component';

@Component({
    selector: 'crds-address-my-location-form',
    templateUrl: './address-my-location-form.component.html',
    imports: [ImageAssetComponent, NgIf, ReactiveFormsModule, QuestionsComponent]
})
export class AddressMyLocationFormComponent implements OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() card: Card<null>;
  @Input() i18ns: I18nKeys;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;

  constructor(private imageAssetFactory: ImageAssetFactory) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.images = this.imageAssetFactory.create(this.card.image, this.form);
  }
}
