import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, EventEmitter, input, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { AuditLog, AuditLogProgress, JumptechDate } from '@jump-tech-frontend/domain';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { interval, Observable, Subscription } from 'rxjs';
import { LoggerService } from '../../error/logger.service';
import { HttpGateway } from '../../core/http-gateway.service';
import { environment } from '../../../environments/environment';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

export enum ProgressIndicatorStatus {
  todo = 'progress-todo',
  current = 'progress-current',
  progressing = 'progress-progressing',
  next = 'progress-next',
  completed = 'progress-completed',
  archived = 'progress-archived',
  skipped = 'progress-skipped'
}

export interface ProgressIndicatorState {
  id?: string;
  label: string;
  status: ProgressIndicatorStatus;
  showProgress: boolean;
  count?: number;
  statusData: any;
  showSkipped: boolean;
  selected?: boolean;
  hidden?: boolean;
}

const icons = {
  [ProgressIndicatorStatus.todo]: 'radio_button_unchecked',
  [ProgressIndicatorStatus.current]: 'check_circle',
  [ProgressIndicatorStatus.next]: 'radio_button_unchecked',
  [ProgressIndicatorStatus.completed]: 'check_circle',
  [ProgressIndicatorStatus.archived]: 'cancel',
  [ProgressIndicatorStatus.skipped]: 'remove_circle'
};

@Component({
  selector: 'progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.scss'],
  imports: [NgIf, NgFor, RoundProgressModule, TranslocoModule, CoreComponentsAngularModule, NgTemplateOutlet]
})
export class ProgressIndicatorComponent implements OnDestroy, OnChanges {
  private _auditLogs: AuditLog[] = [];
  private pollInterval = 5000; // 5 seconds
  private pollObservable: Observable<any>;

  private pollSubscription: Subscription;
  private polling = false;
  private currentStatusTypes = [ProgressIndicatorStatus.current, ProgressIndicatorStatus.progressing];

  public progress = 0;
  public progressSteps: string = null;
  public progressDate: string = null;
  public cardLabel: string = null;
  public hovering = false;

  public progressingStatus = ProgressIndicatorStatus.progressing;
  public skippedStatus = ProgressIndicatorStatus.skipped;

  states = input<ProgressIndicatorState[]>([]);

  selectedStates: string[] = [];
  allSelected = true;

  @Input() currentStatusPosition: number;
  @Input() projectId?: string;

  @Input() isArchived = false;
  @Input() selectable = false;
  @Input() showIconLoader = false;

  @Input() set auditLogs(auditLogs) {
    this._auditLogs = (auditLogs || []).filter(auditLog => auditLog.eventName === 'RelayProgress');
    const { progress, progressDate, cardLabel } = (
      this._auditLogs.length
        ? this._auditLogs.sort((a, b) => a.created_on.localeCompare(b.created_on))[this._auditLogs.length - 1].data
        : {
            progress: '0',
            progressDate: null,
            cardLabel: this.translocoService.translate('progress.sentAwaiting')
          }
    ) as AuditLogProgress;

    const progressParts = progress.split('|');
    const progressPercent = progressParts[0];
    const progressStep = progressParts.length > 1 ? progressParts[1] : null;

    this.progress = parseInt(progressPercent);
    this.progressSteps = progressStep;
    this.progressDate = progressDate
      ? JumptechDate.from(progressDate).toDateTimeFormat()
      : this.translocoService.translate('common.unknown');
    this.cardLabel = cardLabel;
  }

  @Output() selected: EventEmitter<any> = new EventEmitter<any>();
  @Output() statesSelected: EventEmitter<any> = new EventEmitter<any>();

  get auditLogs() {
    return this._auditLogs;
  }

  constructor(
    private translocoService: TranslocoService,
    private gateway: HttpGateway,
    private loggerService: LoggerService
  ) {
    this.pollObservable = interval(this.pollInterval);
  }

  ngOnChanges(): void {
    this.setSelectedStates();
  }

  ngOnDestroy() {
    this.polling = false;
    if (this.pollSubscription) {
      this.pollSubscription.unsubscribe();
    }
  }

  isProgressing(status: ProgressIndicatorStatus) {
    const isProgressing = status === ProgressIndicatorStatus.progressing;
    if (isProgressing) {
      this.startProgressPolling();
    }
    return isProgressing;
  }

  getIconForStatus(status: ProgressIndicatorStatus | string) {
    return icons[status];
  }

  shouldShowStatusData(state, pos) {
    return state && pos <= this.currentStatusPosition;
  }

  public isCurrentStatusType(status: ProgressIndicatorStatus): boolean {
    return this.currentStatusTypes.indexOf(status) !== -1;
  }

  async getAuditLogProgress() {
    const search = [
      { key: 'projectId', value: this.projectId },
      { key: 'eventName', value: 'RelayProgress' }
    ];

    const searchOptions = {
      sort: 'created_on',
      dir: 'desc',
      max: '1',
      search: JSON.stringify(search)
    };
    const { results } = await this.gateway.get(`${environment.apiAuditLogsSearchUrl}`, searchOptions, {
      skip: 'true'
    });

    return results;
  }

  private startProgressPolling() {
    if (this.polling) {
      return;
    }

    this.polling = true;
    this.pollSubscription = this.pollObservable.subscribe(async () => {
      try {
        this.auditLogs = await this.getAuditLogProgress();
      } catch (error) {
        this.loggerService.error(error);
      }
    });
  }

  getColor(color) {
    return getComputedStyle(document.documentElement).getPropertyValue(color);
  }

  private addOrRemoveState(state: ProgressIndicatorState): void {
    if (!this.selectedStates.includes(state.id)) {
      this.addSelectedState(state);
    } else {
      this.removeSelectedState(state);
    }
  }

  private addSelectedState(state: ProgressIndicatorState): void {
    if (!this.selectedStates.includes(state.id)) {
      this.selectedStates.push(state.id);
    }
  }

  private removeSelectedState(state: ProgressIndicatorState): void {
    this.selectedStates = this.selectedStates.filter(x => x !== state.id);
  }

  setSelectedStates(): void {
    if (!this.selectable) {
      return;
    }
    this.allSelected = true;
    const loadedStates: ProgressIndicatorState[] = this.states();
    loadedStates.map((state: ProgressIndicatorState): void => {
      if (state.selected) {
        this.allSelected = false;
        this.addSelectedState(state);
      } else {
        this.removeSelectedState(state);
      }
    });
  }

  updateSelectedStates(state): void {
    if (!this.selectable) {
      return;
    }
    this.allSelected = false;
    this.addOrRemoveState(state);
    if (this.selectedStates.length === 0 || this.selectedStates.length === this.states().length) {
      this.allSelected = true;
    }
    this.statesSelected.emit(this.selectedStates);
  }

  clicked(state) {
    this.updateSelectedStates(state);
    if (this.selected && state.showProgress) {
      this.selected.emit(state);
    }
  }

  getDate(date: string): string {
    return JumptechDate.from(date).toDateTimeFormat();
  }
}
