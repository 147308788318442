import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { DateTimeChangeEvent, RemoveTradespersonSlotEvent, TradesPersonSlotVm } from '../../../../schedule.model';

@Component({
  selector: 'schedule-event-selected-job-details-slot-time-form',
  templateUrl: 'schedule-event-selected-job-details-slot-time-form.component.html',
  styleUrls: ['../../../schedule-event-selected-job-details/schedule-event-selected-job-details.component.scss'],
  imports: [
    CoreComponentsAngularModule,
    NgIf,
    NgSelectModule,
    NgbDatepickerModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule
  ]
})
export class ScheduleEventSelectedJobDetailsSlotTimeFormComponent {
  @Input() vm: TradesPersonSlotVm;
  @Input() index: number;
  @Input() tradespersonId: string;
  @Output() slotDateTimeChange: EventEmitter<DateTimeChangeEvent> = new EventEmitter<DateTimeChangeEvent>();
  @Output() removeTradespersonSlot: EventEmitter<RemoveTradespersonSlotEvent> =
    new EventEmitter<RemoveTradespersonSlotEvent>();

  getStartMinDate() {
    return (this.vm.form.get('fullJob') as FormGroup).get('startDate').value;
  }

  getStartMaxDate(id: string, idx: number) {
    return (this.vm.form.get(id).get('slots') as FormArray).at(idx).get('endDate').value;
  }

  getEndMaxDate() {
    return (this.vm.form.get('fullJob') as FormGroup).get('endDate').value;
  }

  getEndMinDate(id: string, idx: number) {
    return (this.vm.form.get(id).get('slots') as FormArray).at(idx).get('startDate').value;
  }

  getSlotFormControl(id, idx, control) {
    return (this.vm.form.get(id).get('slots') as FormArray).at(idx).get(control);
  }

  slotDateChange(data: { tradespersonId: string; index: number; control: string; type: 'start' | 'end' }): void {
    const controlIsValid = this.getSlotFormControl(data.tradespersonId, data.index, data.control).valid;
    if (controlIsValid) {
      this.slotDateTimeChange.emit({ tradespersonId: data.tradespersonId, index: data.index, type: data.type });
    }
  }
}
