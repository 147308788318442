import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TradesPersonVm } from '../../../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule } from '@angular/forms';
import { ScheduleEventSelectedJobDetailsOverlapsComponent } from '../schedule-event-selected-job-details-slot-overlaps/schedule-event-selected-job-details-overlaps.component';

@Component({
  selector: 'schedule-event-selected-job-details-tradesperson',
  templateUrl: 'schedule-event-selected-job-details-tradesperson.component.html',
  styleUrls: ['../../../schedule-event-selected-job-details/schedule-event-selected-job-details.component.scss'],
  imports: [
    CoreComponentsAngularModule,
    NgIf,
    NgSelectModule,
    NgbDatepickerModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    ScheduleEventSelectedJobDetailsOverlapsComponent
  ]
})
export class ScheduleEventSelectedJobDetailsTradespersonComponent {
  @Input() vm: TradesPersonVm;
  @Output() setLeadTradesperson: EventEmitter<TradesPersonVm> = new EventEmitter<TradesPersonVm>();
  @Output() removeTradesperson: EventEmitter<TradesPersonVm> = new EventEmitter<TradesPersonVm>();

  constructor() {}
}
