@if (vm$ | async; as vm) {
  <div class="legacy-ena-application">
    @if (vm.showLoader) {
      <div class="legacy-ena-application__spinner">
        <ngx-spinner
          color="var(--jds-theme-color-primary)"
          bdColor="transparent"
          size="medium"
          [fullScreen]="false"
          type="ball-clip-rotate"
          name="legacyEnaApplicationSpinner"
          [attr.data-qa]="'legacyEnaApplicationSpinner'"
        >
        </ngx-spinner>
      </div>
    } @else if (vm.showRetry) {
      <div class="legacy-ena-application__retry">
        <span class="legacy-ena-application__message">{{ vm.i18nRetryMessage }}</span>
        <jui-button size="sm" (click)="retryLoad()">{{ vm.i18nRetryLabel }}</jui-button>
      </div>
    } @else {
      <div class="legacy-ena-application__main-header">
        <div class="legacy-ena-application__main-header-details">
          <div class="legacy-ena-application__status">
            <span class="legacy-ena-application__status-label">{{ vm.i18nTitle }}</span>
            <div
              [class]="{
              'legacy-ena-application__status-badge': true,
              'legacy-ena-application__status-badge--rejected': vm.showRejectedStatus
            }">
              {{ vm.status }}
            </div>
          </div>
          @if (vm.externalDno) {
            <div>
              <span class="legacy-ena-application__ext-dno-label">{{ vm.i18nExternalDnoLabel }}</span>
              <span [attr.data-qa]="vm.qaLegacyEnaApplicationExternalDno">{{ vm.externalDno }}</span>
            </div>
          }
        </div>
        <legacy-ena-application-actions/>
      </div>
      <jui-alert-block size="sm" iconName="info" [titleText]="vm.i18nReadOnlyAlert"/>
      <app-list-layout [readOnly]="true" [data]="vm.data" [project]="vm.project" [layout]="vm.layout"/>
    }
  </div>
}
