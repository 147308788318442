import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { ImageError, ImageManipulationService } from '../image-manipulation.service';
import { BaseQuestionComponent } from '../question.component';
import { ScrollService } from '../scroll.service';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';
import { QuestionFormErrorComponent } from '@jump-tech-frontend/question-components';
import { HttpImageComponent } from '../http-image.component';
import { ImageAssetComponent } from '../image-asset.component';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { QuestionLabelContentComponent } from '@jump-tech-frontend/question-components';
import { NgIf } from '@angular/common';
import { I18nKeys } from '@jump-tech-frontend/app-config';

@Component({
    selector: `crds-question-image-upload`,
    template: `
    <div *ngIf="show && form" [formGroup]="form">
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key">
        <question-label-content [question]="question" [isInvalid]="isInvalid" [i18ns]="i18ns"></question-label-content>
      </label>

      <!-- QUESTION INPUT -->
      <div class="inline-buttons">
        <input
          [id]="question.key"
          [name]="question.key"
          class="imageCapture"
          style="display:none;"
          type="file"
          (change)="setFromImage($event)"
          accept="image/*"
        />
        <button
          (click)="clickFile(question.key)"
          type="button"
          class="btn btn-primary image-upload-button"
          #imageUploadButton
        >
          <span class="col-8">{{ i18ns.uploadImage }}</span>
          <i class="material-icons button-icon pt-1 col-4">linked_camera</i>
        </button>
      </div>

      <!-- QUESTION EXAMPLE IMAGE OR USER UPLOADED -->
      <div class="image-wrapper">
        <question-hint [question]="question"></question-hint>
        <div *ngIf="form.get(question.key).value === null">
          <crds-image-asset [images]="images" [key]="question.key"></crds-image-asset>
        </div>
        <div *ngIf="form.get(question.key).value !== null">
          <crds-http-image [id]="question.key + '_temp'" [src]="form.get(question.key).value"></crds-http-image>
        </div>
      </div>

      <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" type="line-scale"></ngx-spinner>
      <question-form-error
        *ngIf="uploadError"
        [message]="uploadError"
        class="file-upload-error"
        [i18ns]="i18ns"
      ></question-form-error>
    </div>
  `,
    styles: [
        `
      .material-icons {
        color: #ffffff;
      }
      .image-upload-button > span {
        line-height: 2rem;
        padding-right: 0.5rem;
        vertical-align: middle;
      }
      .image-upload-button > .material-icons {
        vertical-align: middle;
      }
      .inline-buttons {
        display: grid;
        grid-template-columns: 50% 50%;
      }
    `
    ],
    imports: [
        NgIf,
        ReactiveFormsModule,
        QuestionLabelContentComponent,
        QuestionHintComponent,
        ImageAssetComponent,
        HttpImageComponent,
        NgxSpinnerModule,
        QuestionFormErrorComponent
    ]
})
export class ImageUploadQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  @ViewChild('imageUploadButton') input;
  type: string;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;
  uploadError: string;
  imageError: ImageError | null;

  private untilDestroyed = untilDestroyed();

  constructor(
    private spinnerService: NgxSpinnerService,
    private imageService: ImageManipulationService,
    private imageAssetFactory: ImageAssetFactory,
    private scrollService: ScrollService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }
    /**
     * Check for an array value
     */
    if (this.value instanceof Array && this.value.length) {
      setTimeout(() => {
        this.form?.get(this.question.key)?.patchValue(this.value[0]);
      });
    }
    this.images = this.imageAssetFactory.create(this.question.image, this.form);
    this.scrollService.scrollObservable.pipe(this.untilDestroyed()).subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
  }

  get isInvalid() {
    return this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid;
  }

  get value() {
    return this.form?.get(this.question.key)?.value;
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.form?.get(this.question.key)?.value) &&
      showIfQuestionPopulated &&
      this.question.show !== false
    );
  }

  clickFile(questionKey) {
    (document.getElementById(questionKey) as HTMLElement).click();
  }

  async setFromImage(event) {
    const file: File = event.target.files[0];
    if (!file) {
      return; // User cancelled
    }
    this.imageError = this.imageService.checkFileTypeAndSize(file);
    if (this.imageError) {
      this.uploadError = `${this.imageError.fileName}: ${this.i18ns[this.imageError.type]}`;
      return;
    }

    await this.spinnerService.show();
    try {
      const resizedImage = await this.imageService.resizeImageFromFile(file);
      this.form?.get(this.question.key)?.patchValue(resizedImage);
    } catch (error) {
      // Swallowing error as likely comes from Cancelled upload
      console.log('Failed image upload', error);
      // Cannot use instanceof
      if (error.name === 'ImageUploadError') {
        this.uploadError = this.i18ns.uploadImageError;
      }
    } finally {
      await this.spinnerService.hide();
    }
  }
}
