import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  signal
} from '@angular/core';
import { BehaviorSubject, catchError, interval, map, Observable, of, takeWhile, tap } from 'rxjs';
import { AsyncPipe, DatePipe, NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
    selector: 'question-video-player-timer',
    template: `<div *ngIf="active()" class="video-overlay-timer">{{ timer$ | async | date: 'mm:ss' }}</div>`,
    exportAs: 'videoPlayerTimer',
    imports: [DatePipe, AsyncPipe, NgIf],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerTimerComponent implements OnInit {
  @Input() public timeLimit: number;
  @Input() public recordingState: Observable<string>;
  @Output() public readonly timerFinished = new EventEmitter<true>();

  private readonly destroyRef = inject(DestroyRef);
  private remainingTime: number;
  public elapsedTime = 0;
  public timer$ = new BehaviorSubject(new Date(0, 0, 0, 0, 0, 0));
  public stopTimer$ = new BehaviorSubject<boolean>(false);
  active = signal(false);

  ngOnInit() {
    this.resetTimer();
    this.recordingState.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(state => {
      if (state === 'recording') {
        if (this.active()) {
          this.resumeTimer();
        } else {
          this.startTimer();
        }
      } else if (state === 'paused') {
        this.pauseTimer();
      } else if (state === 'stopped') {
        this.stopTimer();
      }
    });
  }

  startTimer() {
    this.active.set(true);
    this.stopTimer$.next(false);
    interval(1000)
      .pipe(
        takeWhile(() => !this.stopTimer$.value),
        map(v => this.remainingTime - 1 - v),
        tap(v => {
          this.timer$.next(new Date(0, 0, 0, 0, 0, v));
          this.elapsedTime = this.timeLimit - v;
          if (v <= 0) {
            this.stopTimer();
          }
        }),
        catchError(() => {
          this.stopTimer();
          return of();
        })
      )
      .subscribe();
  }

  pauseTimer() {
    this.stopTimer$.next(true);
  }

  resumeTimer() {
    this.remainingTime = this.timeLimit - this.elapsedTime;
    this.startTimer();
  }

  stopTimer() {
    this.pauseTimer();
    this.resetTimer();
    this.active.set(false);
    this.timerFinished.next(true);
  }

  resetTimer() {
    this.timer$.next(new Date(0, 0, 0, 0, 0, this.timeLimit));
    this.elapsedTime = 0;
    this.remainingTime = this.timeLimit;
  }
}
