import { Component, Input } from '@angular/core';
import { Size, NgxSpinnerModule } from 'ngx-spinner';

@Component({
    selector: 'crds-inline-spinner',
    templateUrl: './inline-spinner.component.html',
    styleUrls: ['./inline-spinner.scss'],
    imports: [NgxSpinnerModule]
})
export class InlineSpinnerComponent {
  private _colour: string;
  private _textColour: string;
  private _bgColour: string;
  private _name: string;
  private _type: string;
  private _message: string;
  private _size: Size;
  private _center: boolean;
  private _customCssClasses: string;
  private _qaHook: string;

  readonly defaultColour = 'white';
  readonly defaultTextColour = 'white';
  readonly defaultBgColour = 'rgba(0, 0, 0, 0)';
  readonly defaultName = 'spinner';
  readonly defaultType = 'ball-pulse';
  readonly defaultMessage = '';
  readonly defaultSize = 'small';
  readonly defaultCenterJustify = false;
  readonly defaultCustomCss = '';
  readonly defaultQaHook = 'spinner';

  @Input()
  get colour() {
    return this._colour ?? this.defaultColour;
  }
  set colour(val: string) {
    this._colour = val;
  }

  @Input()
  get textColour() {
    return this._textColour ?? this.defaultTextColour;
  }
  set textColour(val: string) {
    this._textColour = val;
  }

  @Input()
  get bgColour() {
    return this._bgColour ?? this.defaultBgColour;
  }
  set bgColour(val: string) {
    this._bgColour = val;
  }

  @Input()
  get name() {
    return this._name ?? this.defaultName;
  }
  set name(val: string) {
    this._name = val;
  }

  @Input()
  get type() {
    return this._type ?? this.defaultType;
  }
  set type(val: string) {
    this._type = val;
  }

  @Input()
  get message() {
    return this._message ?? this.defaultMessage;
  }
  set message(val: string) {
    this._message = val;
  }

  @Input()
  get size() {
    return this._size ?? this.defaultSize;
  }
  set size(val: Size) {
    this._size = val;
  }

  @Input()
  get center() {
    return this._center ?? this.defaultCenterJustify;
  }
  set center(val: boolean) {
    this._center = val;
  }

  @Input()
  get customCssClasses() {
    return this._customCssClasses ?? this.defaultCustomCss;
  }
  set customCssClasses(val: string) {
    this._customCssClasses = val;
  }

  @Input()
  get qaHook() {
    return this._qaHook ?? this.defaultQaHook;
  }
  set qaHook(val: string) {
    this._qaHook = val;
  }
}
