import { Component, EventEmitter, input, Output } from '@angular/core';
import { TaskModel } from '../../task.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { EditLayoutQuestionsComponent } from '../../../../core/cardLayouts/edit-layout-questions.component';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';

@Component({
    selector: 'app-task',
    templateUrl: './task.component.html',
    styleUrls: ['./task.component.scss'],
    imports: [NgIf, NgClass, NgFor, EditLayoutQuestionsComponent, CoreComponentsAngularModule, ReadOnlyDisableDirective]
})
export class TaskComponent {
  vm = input.required<TaskModel>();

  @Output() toggleClick: EventEmitter<string> = new EventEmitter();
  @Output() actionClick: EventEmitter<string> = new EventEmitter();

  toggle(id: string) {
    if (this.toggleClick) {
      this.toggleClick.emit(id);
    }
  }

  action(id: string) {
    this.vm().enabled = false;
    if (this.actionClick) {
      this.actionClick.emit(id);
    }
  }
}
