import { filter, firstValueFrom, interval, map, Subject, switchMap, takeUntil } from 'rxjs';

export function blobToBase64(blob: Blob, type: string): Promise<string> {
  return new Promise(resolve => {
    const reader: FileReader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result?.toString().replace('application/octet-stream', type) ?? '');
    };
    reader.readAsDataURL(blob);
  });
}

export function getMediaRecorder(
  stream: MediaStream,
  handleDataAvailable: (e: BlobEvent) => void,
  handleDataStopped: () => void
) {
  try {
    const options: MediaRecorderOptions = {
      videoBitsPerSecond: 512000 // 512kbps
    };
    const mediaRecorder = new MediaRecorder(stream, options);
    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.stop = handleDataStopped;
    return mediaRecorder;
  } catch (e) {
    console.error('Exception while creating MediaRecorder:', e);
    return;
  }
}

export async function getCamera(facingMode: string) {
  const constraints = {
    video: {
      facingMode: facingMode
    },
    audio: true
  };
  // Important to avoid await here, otherwise the stream will be returned before permission is granted
  return navigator.mediaDevices
    .getUserMedia(constraints)
    .then(stream => {
      return stream;
    })
    .catch(e => {
      console.error('Error while getting camera:', e);
      return undefined;
    });
}

export function waitForElement(id: string): Promise<HTMLElement | null> {
  return new Promise(resolve => {
    const el = document.getElementById(id);
    if (el) {
      resolve(el);
      return;
    }
    new MutationObserver((mutationRecords, observer) => {
      // Query for elements matching the specified selector
      Array.from([document.getElementById(id)]).forEach(element => {
        resolve(element);
        //Once we have resolved we don't need the observer anymore.
        observer.disconnect();
      });
    }).observe(document.documentElement, {
      childList: true,
      subtree: true
    });
  });
}

/**
 * Required as default PermissionName does not contain 'camera' or 'microphone'
 */
type CorrectedPermissionName = PermissionName | 'camera' | 'microphone';

function permissionsAlert(permissions: string[]) {
  const userAgent = navigator.userAgent;
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    alert(`Please allow permissions in Settings > Safari > ${permissions.join(' & ')}`);
    return;
  }
  if (/Safari/.test(userAgent)) {
    alert(`Please allow permissions in Settings > Websites > ${permissions.join(' & ')}`);
    return;
  }
  alert(`Please close and reopen the browser and allow permissions for ${permissions.join(' & ')} in the prompt`);
  return;
}

export async function waitForNavigatorPermission(
  permissions: CorrectedPermissionName[],
  waitIntervalMillis = 500,
  maxWaitTimeMillis = 30000
): Promise<boolean> {
  const startTime = Date.now();
  const expired$ = new Subject();
  const maxWaitTimeExpired = () => Date.now() - startTime > maxWaitTimeMillis;
  try {
    return navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then(async stream => {
        stream.getTracks().forEach(t => t.stop());
        if (!navigator?.permissions?.query) {
          return Promise.resolve(true);
        }
        return await firstValueFrom(
          interval(waitIntervalMillis).pipe(
            takeUntil(expired$),
            switchMap(async () => {
              return await Promise.all(
                permissions.map(p => navigator.permissions.query({ name: p as PermissionName }))
              );
            }),
            filter(results => {
              const allResultsGranted = results.every(r => r.state === 'granted');
              const someResultsDenied = results.some(r => r.state === 'denied');
              return maxWaitTimeExpired() || allResultsGranted || someResultsDenied;
            }),
            map(results => {
              if (results.every(r => r.state === 'granted')) {
                expired$.next(true);
                return true;
              }
              permissionsAlert(permissions);
              expired$.next(true);
              return false;
            })
          )
        );
      })
      .catch(() => {
        permissionsAlert(permissions);
        return false;
      });
  } catch (e) {
    permissionsAlert(permissions);
    return false;
  }
}
