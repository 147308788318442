import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { PasswordStrengthCheckerComponent } from '../password-strength-checker/password-strength-checker.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-new-password-required-form',
    templateUrl: './new-password-required-form.component.html',
    styleUrls: ['../../login.component.scss'],
    imports: [ReactiveFormsModule, NgIf, PasswordStrengthCheckerComponent, CoreComponentsAngularModule, TranslocoModule]
})
export class NewPasswordRequiredFormComponent implements AfterViewInit {
  @Input() newPasswordRequiredForm: UntypedFormGroup;
  @Input() submitInProgress: boolean;

  @Output() newPasswordSubmitEvent: EventEmitter<null> = new EventEmitter<null>();

  @ViewChild('newPasswordInput')
  public newPasswordInput: ElementRef;

  public showPassword = false;

  constructor() {}

  ngAfterViewInit() {
    this.focusForm();
  }

  focusForm() {
    setTimeout(() => {
      this.newPasswordInput.nativeElement.focus();
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  newPasswordSubmit() {
    this.showPassword = false;
    this.newPasswordSubmitEvent.emit();
  }
}
