import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, QueryList, ViewChildren } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalComponent } from '../questions/modal/modal.component';
import { LikertComponent } from '../questions/likert/likert.component';
import { IQuestionComponent } from '../questions/question.model';
import { QUESTIONS } from '../questions/questions.token';
import { SectionComponent } from '../questions/section/section.component';
import { TextareaComponent } from '../questions/textarea/textarea.component';
import { getQuestionParams } from '../question.helper';
import { TextboxComponent } from '../questions/textbox/textbox.component';
import { Card } from '@jump-tech-frontend/domain';

@Component({
    selector: 'question-card-to-questions',
    template: `
    <ng-container *ngIf="card?.questions?.length && (params$ | async) as params">
      <ng-container *ngFor="let question of card.questions" [ngSwitch]="question.controlType">
        <question-modal *ngSwitchCase="'Modal'" [params]="params[question.key]"></question-modal>
        <question-likert *ngSwitchCase="'Likert'" [params]="params[question.key]"></question-likert>
        <question-textarea *ngSwitchCase="'TextareaQuestion'" [params]="params[question.key]"></question-textarea>
        <question-section *ngSwitchCase="'Section'" [label]="question.label" [hint]="question.hint"></question-section>
        <question-textbox *ngSwitchCase="'Textbox'" [params]="params[question.key]"></question-textbox>
      </ng-container>
    </ng-container>
  `,
    imports: [CommonModule, ModalComponent, LikertComponent, TextareaComponent, SectionComponent, TextboxComponent],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardToQuestionsComponent implements OnChanges {
  @Input() card: Card<string>;

  @ViewChildren(QUESTIONS) questions: QueryList<IQuestionComponent>;

  params$ = new BehaviorSubject<unknown>(null);

  ngOnChanges() {
    const params: { [key: string]: unknown } = {};
    for (const question of this.card.questions ?? []) {
      params[question.key] = getQuestionParams(question);
    }
    this.params$.next(params);
  }
}
