import { Component, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

import { LegacyEnaApplicationErrorsPresenter } from './legacy-ena-application-errors.presenter';
import { LegacyEnaErrorVm } from '../legacy-ena.model';

@Component({
  selector: 'legacy-ena-application-errors',
  templateUrl: './legacy-ena-application-errors.component.html',
  imports: [AsyncPipe, CoreComponentsAngularModule],
  standalone: true
})
export class LegacyEnaApplicationErrorsComponent implements OnInit {
  vm$: BehaviorSubject<LegacyEnaErrorVm> = new BehaviorSubject<LegacyEnaErrorVm>(null);

  constructor(private presenter: LegacyEnaApplicationErrorsPresenter) {}

  ngOnInit(): void {
    this.presenter.load(this.vm$);
  }

  clearErrors(): void {
    this.presenter.clearErrors();
  }
}
