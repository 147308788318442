import { AsyncPipe, NgIf } from '@angular/common';
import { Component, DestroyRef, inject, OnDestroy, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterLink } from '@angular/router';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { TranslocoModule } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { AccessService, PathwayFeature } from '../auth/services/access.service';
import { AuthenticationService } from '../auth/services/authentication.service';
import { PathwayConfigurationService } from '../auth/services/pathway-configuration.service';
import { RouteAccessService } from '../auth/services/route-access.service';
import { UserService } from '../auth/services/user.service';
import { CustomLookupService } from '../core/custom-lookup.service';
import { User } from '../core/domain/user';
import { FeatureFlagService } from '../core/feature-flag/feature-flag.service';
import { HomeService } from '../core/home.service';
import { ProjectConfigurationService } from '../core/project-configuration.service';
import { UserThemeService } from '../core/user-theme.service';
import { atomSetupPageUrl } from '../core/utils/atom';
import { ProjectsRepository } from '../projects/projects.repository';
import { UserHistoryComponent } from './user-history/user-history.component';

@Component({
  selector: 'app-landing-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  imports: [NgIf, CoreComponentsAngularModule, RouterLink, UserHistoryComponent, AsyncPipe, TranslocoModule],
  standalone: true
})
export class HomePageComponent implements OnInit, OnDestroy {
  private destroyRef: DestroyRef = inject(DestroyRef);
  user: User;

  showProjectsButton = false;
  showScheduleButton = false;
  showSearchButton = false;
  showSetupAtomButton = false;

  schedulePath: string;
  newAtomSetupEnabled = false;
  authSubscription: Subscription;
  userSubscription: Subscription;
  hasAppBooted = false;
  userHistory$;
  invalidProjectType = false;
  logo = '';

  constructor(
    private authenticationService: AuthenticationService,
    public userService: UserService,
    protected customLookupService: CustomLookupService,
    protected projectConfigurationService: ProjectConfigurationService,
    protected featureAccessService: AccessService,
    protected routeAccessService: RouteAccessService,
    private pathwayConfiguration: PathwayConfigurationService,
    private homeService: HomeService,
    private featureFlagService: FeatureFlagService,
    public themeService: UserThemeService,
    private projectsRepository: ProjectsRepository
  ) {}

  async ngOnInit() {
    this.hasAppBooted = JSON.parse(localStorage.getItem('appBooted'));

    await this.initSchedulePath();

    this.newAtomSetupEnabled = await this.featureFlagService.isFeatureEnabled('new-atom-login');
    if (this.authenticationService.isUserSignedIn) {
      this.initUserSubscription();
      this.userHistory$ = this.homeService.userHistory$;
      this.homeService.getRouteHistory();
      this.authenticationService.signOutObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
        localStorage.removeItem('appBooted');
        this.homeService.clearRouteHistory();
      });
      localStorage.setItem('appBooted', JSON.stringify(true));
    }
  }

  initUserSubscription() {
    if (!this.userSubscription) {
      this.userSubscription = this.userService.userObservable.subscribe(async (user: User) => {
        if (user) {
          this.user = user;

          this.showProjectsButton = this.isAccessAllowed('projects');
          this.showSearchButton = this.isAccessAllowed('projects-search');
          this.showScheduleButton = this.isInstaller() && this.isAccessAllowed('schedule');
          this.showSetupAtomButton = this.isInstaller() && !this.newAtomSetupEnabled;

          if (this.showProjectsButton) {
            // Signed in user with access to projects, so warn up the projects cache
            this.projectsRepository.warmUp();
          }

          await this.initSchedulePath();
          this.newAtomSetupEnabled = await this.featureFlagService.isFeatureEnabled('new-atom-login');
        }
      });
    }
  }

  private async initSchedulePath(): Promise<void> {
    const schedulerV2Enabled = await this.featureFlagService.isFeatureEnabled('rolling-month-schedule-view');
    const schedulerV3Enabled = await this.featureFlagService.isFeatureEnabled('scheduler-v3');

    this.schedulePath = schedulerV3Enabled ? '/schedule-v3' : schedulerV2Enabled ? '/schedule-v2' : '/schedule';
  }

  isInstaller() {
    return this.isFeatureAllowed(PathwayFeature.ShowSetupAtom);
  }

  isAccessAllowed(route: string, allowAdmin = false) {
    return this.routeAccessService.isRouteAccessAllowed(route, allowAdmin);
  }

  isFeatureAllowed(feature: PathwayFeature) {
    return this.featureAccessService.isFeatureAccessAllowed(feature);
  }

  clearRouteHistory() {
    this.homeService.clearRouteHistory();
  }

  openAtomConfig() {
    const locale = this.user?.accessInfo?.configuration?.locale;
    const language = locale?.split('-')?.[0];
    const url = atomSetupPageUrl(this.pathwayConfiguration.tenant, environment.name, language);
    window.open(url, url);
  }

  openHelp() {
    window.open(environment.pathwaySupportUrl, 'helpCenter');
  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }
}
