import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { Note } from '../note';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf } from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

@Component({
    selector: 'app-add-note-modal',
    templateUrl: './add-note-modal.component.html',
    styleUrls: ['./add-note-modal.component.scss'],
    imports: [ReactiveFormsModule, NgxIntlTelInputModule, NgIf, CoreComponentsAngularModule, TranslocoModule]
})
export class AddNoteModalComponent implements OnInit, AfterViewInit {
  @Input() note: Note;
  form: UntypedFormGroup;

  @ViewChild('noteTextArea') noteTextArea: ElementRef;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      note: new UntypedFormControl(this.note.note, Validators.required)
    });
  }

  /**
   * Set the focus to the note text-area when the modal is shown.
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.noteTextArea.nativeElement.focus({ preventScroll: true });
    }, 0);
  }

  save() {
    this.note.note = this.form.get('note').value;
    this.activeModal.close(this.note);
  }
}
