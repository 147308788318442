import {
  LOGIN_PATH,
  RESET_PASSWORD_PATH,
  REGISTER_PATH,
  CHANGE_PASSWORD_PATH,
  SETUP_ATOM_PATH
} from '../../app.routes';
export const ignoredReturnUrlPaths = [
  LOGIN_PATH,
  RESET_PASSWORD_PATH,
  CHANGE_PASSWORD_PATH,
  REGISTER_PATH,
  SETUP_ATOM_PATH
].map(route => `/${route}`);

export const setReturnUrl = (pathname: string) => {
  if (pathname && !ignoredReturnUrlPaths.includes(pathname)) {
    localStorage.setItem('returnUrl', pathname ?? '/');
  }
};

/**
 * @return The window location subdomain or undefined string if there was a problem getting the subdomain
 */
export function getTenantSubdomain(): string | undefined {
  try {
    return window.location.hostname.substring(0, window.location.hostname.indexOf('.'));
  } catch (err) {
    console.log('Failed to get tenant subdomain', err);
    return undefined;
  }
}
