import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { I18nKeys } from '../../domain/i18n-keys';
import { BaseQuestionComponent } from '../question.component';
import { ImageAssetComponent } from '../image-asset.component';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { QuestionLabelContentComponent } from '@jump-tech-frontend/question-components';
import { NgIf } from '@angular/common';

@Component({
    selector: 'crds-question-general',
    template: `
    <div *ngIf="show && form" [formGroup]="form">
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key">
        <question-label-content [question]="question" [isInvalid]="isInvalid" [i18ns]="i18ns"></question-label-content>
      </label>
      <div
        *ngIf="question.value"
        class="text-question"
        [innerHtml]="question.value"
        [attr.data-qa]="question.value"
      ></div>
      <!-- QUESTION HINT -->
      <question-hint [question]="question"></question-hint>

      <!-- QUESTION IMAGE -->
      <div class="image-wrapper">
        <crds-image-asset [images]="images" [key]="question.key"></crds-image-asset>
      </div>
    </div>
  `,
    imports: [NgIf, ReactiveFormsModule, QuestionLabelContentComponent, QuestionHintComponent, ImageAssetComponent]
})
export class GeneralQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<any>;
  @Input() i18ns: I18nKeys;
  type: string;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;

  constructor(private imageAssetFactory: ImageAssetFactory) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    this.images = this.imageAssetFactory.create(this.question.image, this.form);
    if (this.question.key && this.question.value && !this.form?.get(this.question.key)?.value) {
      this.form?.get(this.question.key)?.patchValue(this.question.value);
    }
  }

  get isInvalid() {
    return (
      this.form?.get(this.question.key) &&
      this.form?.get(this.question.key)?.touched &&
      this.form?.get(this.question.key)?.invalid
    );
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }
}
