import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CardLayoutItem } from '../domain/card-layout-item';
import { MapInfoWindow, MapMarker, GoogleMapsModule } from '@angular/google-maps';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: `app-charge-point-location-map`,
    template: ` <div class="map-label">{{ item.editConfig.label }}:</div>
    <div class="map-container">
      <div class="map-inner" *ngIf="showMap">
        <google-map
          class="google-map"
          height="100%"
          width="100%"
          [center]="mapCenter"
          [zoom]="mapZoom"
          [mapTypeId]="mapType"
          [options]="{ disableDefaultUI: true, zoomControl: true }"
        >
          <map-marker
            #marker="mapMarker"
            *ngFor="let m of item.markers; let i = index"
            [position]="m.position"
            [title]="m.title"
            [icon]="m.icon"
            (mapClick)="openInfoWindow(marker, m)"
          >
            <map-info-window>
              <div class="map-info-window">{{ infoContent }}</div>
            </map-info-window>
          </map-marker>
        </google-map>
      </div>
    </div>`,
    styles: [
        `
      .map-container {
        display: flex;
        justify-content: center;
      }
      .map-inner {
        min-height: 400px;
        min-width: 200px;
        flex-grow: 1;
      }
      .map-label {
        font-weight: bold;
      }
      .map-info-window {
        font-weight: var(--jds-theme-fw-bold);
        color: var(--jds-theme-color-text);
      }
    `
    ],
    imports: [NgIf, GoogleMapsModule, NgFor]
})
export class ChargePointLocationMapComponent implements OnInit {
  @Input() item: CardLayoutItem;
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;

  infoContent = null;
  mapCenter = null;
  mapZoom = 19; // todo better to fit map bounds
  mapType: google.maps.MapTypeId = google.maps.MapTypeId.SATELLITE;
  mapIconScaleSize: google.maps.Size = new google.maps.Size(35, 35);
  mapIconLabelOrigin: google.maps.Point = new google.maps.Point(19, 40);

  siteLocationId = 'siteLocation';
  siteLocationMarker = 'assets/map/Green_Map_Pin.png';
  chargePointMarker = 'assets/map/Yellow_Map_Pin.png';
  showMap = false;

  ngOnInit(): void {
    this.parseMapMarkers();
  }

  parseMapMarkers(): void {
    if (!this.item.content) {
      return;
    }

    if (typeof this.item.content === 'string') {
      this.item.markers = JSON.parse(this.item.content);
    }
    if (this.item.markers.length < 1) {
      return;
    }

    this.item.markers = this.item.markers.map(m => {
      return {
        ...m,
        icon: {
          url: m.id === this.siteLocationId ? this.siteLocationMarker : this.chargePointMarker,
          scaledSize: this.mapIconScaleSize,
          labelOrigin: this.mapIconLabelOrigin
        },
        title: m.name ?? '',
        position: { lat: m.coordinates.latitude, lng: m.coordinates.longitude }
      };
    });
    this.mapCenter = {
      lat: this.item.markers[0].coordinates.latitude,
      lng: this.item.markers[0].coordinates.longitude
    };
    this.showMap = true;
  }

  openInfoWindow(marker: MapMarker, markerInfo) {
    this.infoContent = markerInfo.title;
    this.infoWindow.open(marker);
  }
}
