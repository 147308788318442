import { ProjectState } from '../../core/domain/project-configuration';

export const PROJECT_STATUS_CHANGE_FEATURE_KEY = 'project-status-change';

export const DISALLOWED_CURRENT_PROJECT_STATUSES = ['ARCHIVED', 'ON HOLD'];

// VM

export interface ProjectStatusChangeVm {
  projectStatuses: ProjectState[];
  selectedStatus: ProjectState | null;
  visible: boolean;
  disabled: boolean;
  i18nProjectStatusChangeLabel: string;
  i18nProjectStatusChangePlaceholder: string;
}

// DM

export interface ProjectStatusChangeDm {
  projectStatuses: ProjectState[];
  selectedStatus: ProjectState | null;
  visible: boolean;
  disabled: boolean;
  i18n: ProjectStatusChangeI18n;
}

// UTILS

export interface ProjectStatusChangeI18n {
  [key: string]: string;
}

export enum ProjectStatusChangeErrorType {
  LOAD_PROJECT_STATUSES = 'LOAD_PROJECT_STATUSES',
  SUBMIT_PROJECT_STATUS_CHANGE = 'SUBMIT_PROJECT_STATUS_CHANGE'
}
