import { Component, Input, OnInit } from '@angular/core';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { BehaviorSubject } from 'rxjs';
import { ProjectScheduleActionsPresenter } from './project-schedule-actions.presenter';
import { CommonModule } from '@angular/common';
import { ScheduleJobActionVm } from '../../schedule/utils/schedule-types';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';

@Component({
  selector: 'project-schedule-actions',
  templateUrl: 'project-schedule-actions.component.html',
  styleUrls: ['project-schedule-actions.component.scss'],
  imports: [CoreComponentsAngularModule, CommonModule, ReadOnlyDisableDirective],
  providers: [ProjectScheduleActionsPresenter]
})
export class ProjectScheduleActionsComponent implements OnInit {
  @Input() activeTheme: string;
  vm$: BehaviorSubject<ScheduleJobActionVm[]> = new BehaviorSubject<ScheduleJobActionVm[]>(null);

  constructor(private presenter: ProjectScheduleActionsPresenter) {}

  ngOnInit(): void {
    this.presenter.load(this.vm$);
  }

  openScheduleModal(vm: ScheduleJobActionVm): void {
    this.presenter.openScheduleModal(vm);
  }
}
