import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { BaseQuestionComponent } from '../question.component';
import { I18nKeys } from '../../domain/i18n-keys';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { PostCodeLookupModule } from '@jump-tech-frontend/address-lookup';
import { QuestionLabelContentComponent } from '@jump-tech-frontend/question-components';
import { NgIf } from '@angular/common';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: `crds-question-v2-postcode`,
    template: `
    <div *ngIf="show && form" [formGroup]="form" class="input-group">
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key">
        <question-label-content [question]="question" [isInvalid]="isInvalid" [i18ns]="i18ns"></question-label-content>
      </label>
      <lib-post-code-lookup
        [addressForm]="asAddressFormGroup()"
        [postCodeForm]="asPostCodeFormControl()"
        [question]="question"
        [i18ns]="i18ns"
      >
      </lib-post-code-lookup>
      <!-- QUESTION HINT -->
      <question-hint [question]="question"></question-hint>
    </div>
  `,
    styles: [
        `
      lib-post-code-lookup {
        width: 100%;
      }
    `
    ],
    imports: [NgIf, ReactiveFormsModule, QuestionLabelContentComponent, PostCodeLookupModule, QuestionHintComponent]
})
export class PostcodeQuestionV2Component extends BaseQuestionComponent implements OnInit, OnDestroy {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<string>;
  @Input() i18ns: I18nKeys;
  addressFormPostCodeChanges$: Observable<string> | undefined;
  destroy$ = new Subject();

  ngOnInit(): void {
    this.addressObjectValueToFormGroup();
    this.addressFormPostCodeChanges$ = this.form
      ?.get('address')
      ?.get('postCode')
      ?.valueChanges.pipe(takeUntil(this.destroy$));
    this.addressFormPostCodeChanges$
      ?.pipe(takeUntil(this.destroy$))
      .subscribe(postCode => this.updatePostCodeFromFormAddressPostCode(postCode));
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private updatePostCodeFromFormAddressPostCode(postCode: string) {
    this.form.get('postCode')?.patchValue(postCode);
  }

  private toAddressForm(form: UntypedFormGroup) {
    const postCodeFormControl = new UntypedFormControl(form.get('address')?.value?.postCode ?? '');
    if (this.question?.required) {
      postCodeFormControl.addValidators(Validators.required);
    }
    if (this.question?.pattern) {
      postCodeFormControl.addValidators(Validators.pattern(this.question.pattern));
    }
    return new UntypedFormGroup({
      line1: new UntypedFormControl(
        form.get('address')?.value?.line1 ?? '',
        this.question?.required ? [Validators.required] : []
      ),
      line2: new UntypedFormControl(form.get('address')?.value?.line2 ?? ''),
      line3: new UntypedFormControl(form.get('address')?.value?.line3 ?? ''),
      locality: new UntypedFormControl(form.get('address')?.value?.locality ?? ''),
      town: new UntypedFormControl(
        form.get('address')?.value?.town ?? '',
        this.question?.required ? [Validators.required] : []
      ),
      county: new UntypedFormControl(form.get('address')?.value?.county ?? ''),
      country: new UntypedFormControl(form.get('address')?.value?.country ?? ''),
      postCode: postCodeFormControl,
      latitude: new UntypedFormControl(form.get('address')?.value?.latitude ?? ''),
      longitude: new UntypedFormControl(form.get('address')?.value?.longitude ?? '')
    });
  }

  private addressObjectValueToFormGroup() {
    this.form.setControl('address', this.toAddressForm(this.form));
  }

  override get isInvalid() {
    return (this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid) ?? false;
  }

  override get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }

  asAddressFormGroup() {
    return this.form.controls['address'] as UntypedFormGroup;
  }

  asPostCodeFormControl() {
    return this.form.controls['address'].get('postCode') as UntypedFormControl;
  }
}
