import { Component, Input, ViewEncapsulation } from '@angular/core';
import { InfoGroup } from '../../domain/info-group';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgFor } from '@angular/common';

@Component({
    selector: 'crds-information-groups',
    template: `
    <div class="information-group" *ngFor="let infoGroup of infoGroups; index as i">
      <div class="information-group__sign">
        <div class="information-group__icon">
          <jui-icon [name]="infoGroup.groupImage.name" size="xl"></jui-icon>
        </div>
      </div>
      <div class="information-group__text">
        {{ infoGroup.groupDescription }}
      </div>
    </div>
  `,
    styleUrls: ['../../sass/relay.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [NgFor, CoreComponentsAngularModule]
})
export class InformationGroupsComponent {
  @Input() infoGroups: InfoGroup[];

  isOdd(i: number) {
    return i % 2 === 1;
  }
}
