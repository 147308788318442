import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { AuthNotification } from '../../../utils/auth-types';
import { TranslocoModule } from '@ngneat/transloco';
import { PasswordStrengthCheckerComponent } from '../password-strength-checker/password-strength-checker.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-forgot-password-submit-form',
    templateUrl: './forgot-password-submit-form.component.html',
    styleUrls: ['../../login.component.scss'],
    imports: [NgIf, CoreComponentsAngularModule, ReactiveFormsModule, PasswordStrengthCheckerComponent, TranslocoModule]
})
export class ForgotPasswordSubmitFormComponent implements AfterViewInit {
  @Input() forgotPasswordSubmitForm: UntypedFormGroup;
  @Input() submitInProgress: boolean;
  @Input() code: string | undefined;
  @Input() displaySplash: boolean;
  @Input() emailAddress: string | undefined;

  @Output() forgotPasswordSubmitEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() resendCodeEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() notifyEvent: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('newPasswordInput')
  public newPasswordInput: ElementRef;

  public showPassword = false;
  public splashTimeMs = 5000;

  constructor() {}

  ngAfterViewInit() {
    if (!this.displaySplash) {
      this.focusForm();
    }
  }

  focusForm() {
    setTimeout(() => {
      this.newPasswordInput.nativeElement.focus();
    });
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  forgotPasswordSubmit() {
    this.showPassword = false;
    this.forgotPasswordSubmitEvent.emit();
  }

  resendCode() {
    const username = this.forgotPasswordSubmitForm.get('username').value;
    this.resendCodeEvent.emit(username);
    this.notifyEvent.emit(AuthNotification.NewResetEmailSent);
  }
}
