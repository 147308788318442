import { DestroyRef, inject, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { AccessService, PathwayFeature } from '../../../auth/services/access.service';
import { CardLayoutItem } from '../../domain/card-layout-item';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { I18nKeys } from '@jump-tech-frontend/app-config';
import { CardsLibTranslationService } from '../../cards-lib-translation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CardControlService } from '../../card-control.service';
import * as Analytics from '../../../app.analytics';
import { ApiService } from '../../api.service';
import { Project } from '../../domain/project';
import { GalleryDm } from './gallery-v2.model';

@Injectable()
export class GalleryV2LayoutRepository {
  gallerySubscription: Subscription;
  gallery$: Subject<GalleryDm>;

  galleryImages = [];
  existingImages = [];
  galleryItem: CardLayoutItem;
  hasGalleryAccess = false;
  hasChanges = false;
  isSaving = false;
  isProcessing = false;
  numberProcessing = 0;
  isEditing = false;
  forms: UntypedFormGroup[] = [];

  i18ns: I18nKeys;
  project: Project = null;
  data: any = null;
  removedImages = [];
  initialData: any = null;
  cache: string[];

  constructor(
    private featureAccessService: AccessService,
    private cardsLibI18nService: CardsLibTranslationService,
    private cardControlService: CardControlService,
    private apiService: ApiService
  ) {}

  private destroyRef: DestroyRef = inject(DestroyRef);

  load(cb, project, galleryItem): void {
    this.gallery$ = new Subject<any>();
    this.gallerySubscription?.unsubscribe();
    this.gallerySubscription = this.gallery$.subscribe(cb);
    this.init(project, galleryItem);
  }

  setEditing(): void {
    this.isEditing = true;
    this.setCache();
    this.setForm();
    this.notifyGallery();
  }

  removeImage(index: number): void {
    const filtered = this.initialData[this.galleryItem.editConfig.key]
      .map((itm, i) => {
        if (i === index) {
          this.removedImages.push({ idx: i, value: itm });
        }
        return itm;
      })
      .filter((_, i) => {
        return i !== index;
      });

    const removedFuseCutoutPhoto =
      this.galleryItem.editConfig.key === 'fuseCutoutPhoto' &&
      filtered.length < this.initialData[this.galleryItem.editConfig.key].length;
    if (removedFuseCutoutPhoto) {
      // ENA Application specific Analytics event
      Analytics.logEvent('EnaApplicationFuseCutoutPhotoRemoved', {});
    }
    this.initialData[this.galleryItem.editConfig.key] = filtered;
    this.hasChanges = true;
    this.setForm();
    this.setExistingImages();
    this.notifyGallery();
  }

  cancelEdit(): void {
    if (this.cache?.length && this.galleryItem) {
      this.initialData[this.galleryItem.editConfig.key] = [...this.cache];
    }
    this.isEditing = false;
    this.setCache();
    this.setExistingImages();
    this.hasChanges = false;
    this.notifyGallery();
  }

  private init(project, galleryItem): void {
    this.i18ns = this.cardsLibI18nService.loadTranslations();
    this.project = { ...project };
    this.initialData = project.data;
    this.data = { ...project.data };
    this.galleryItem = { ...galleryItem };
    this.initialiseGallery(this.initialData);
    this.setExistingImages();
  }

  private initialiseGallery(data): void {
    this.hasGalleryAccess = this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.GalleryAccess, true);
    this.galleryImages = [];
    this.setForm();
    this.processImages(data);
  }

  private setExistingImages(): void {
    if (this.galleryItem?.editConfig?.key) {
      this.existingImages = this.initialData[this.galleryItem.editConfig.key] ?? [];
    }
  }

  private processImages(data): void {
    const dataKeys = Object.keys(data);
    for (const key of dataKeys) {
      if (!data[key]) {
        continue;
      }
      if (this.isGalleryImage(data[key])) {
        this.galleryImages.push(key);
      }

      if (data[key] instanceof Array && data[key].length) {
        let i = 0;
        for (const projectDataItem of data[key]) {
          if (this.isGalleryImage(projectDataItem)) {
            this.galleryImages.push(`${key}_${i}`);
            i++;
          }
        }
      }
    }
    this.notifyGallery();
  }

  private notifyGallery(): void {
    this.gallery$.next({
      galleryImages: this.galleryImages,
      dataSectionImages: this.galleryItem?.editConfig?.key ? this.initialData[this.galleryItem.editConfig.key] : [],
      existingImages: this.existingImages,
      galleryItem: this.galleryItem,
      form: this.forms[this.galleryItem.key],
      i18ns: this.i18ns,
      project: this.project,
      data: this.initialData,
      isEditing: this.isEditing,
      hasChanges: this.hasChanges,
      isProcessing: this.isProcessing,
      isSaving: this.isSaving,
      numberProcessing: this.numberProcessing
    });
  }

  private isGalleryImage(data) {
    const isMedia =
      typeof data === 'string' &&
      (data.indexOf('https://s3') === 0 ||
        data.indexOf('data:image') === 0 ||
        data.indexOf(`https://relay-uploads`) === 0 ||
        (data.indexOf('https://api') === 0 && data.indexOf('store/') > -1));

    if (isMedia && data.includes('.mp4')) {
      return false;
    }
    return isMedia;
  }

  private setCache(): void {
    if (this.galleryItem?.editConfig?.key && this.initialData[this.galleryItem.editConfig.key]) {
      this.cache = [...this.initialData[this.galleryItem.editConfig.key]];
    }
  }

  private setForm(): void {
    const formControls = {};
    if (this.galleryItem.editConfig) {
      formControls[this.galleryItem.editConfig.key] = new UntypedFormControl(
        this.getFormValue(this.galleryItem.editConfig.key) || ''
      );
    }
    this.forms[this.galleryItem.key] = new UntypedFormGroup(formControls);
  }

  private getFormValue(editConfigKey: string) {
    if (editConfigKey.indexOf('.') === -1) {
      return this.initialData[editConfigKey];
    }
    const parts = editConfigKey.split('.');
    return this.initialData[parts[0]][parts[1]];
  }

  setProcessing(numProcessing: number) {
    this.numberProcessing = numProcessing;
    this.notifyGallery();
  }

  processingComplete(): void {
    this.numberProcessing = 0;
    this.setCache();
    this.setExistingImages();
    this.isEditing = false;
    this.notifyGallery();
  }

  saveSuccess(payload): void {
    Object.assign(this.initialData, payload);
    this.notifyGallery();
  }

  save(form): void {
    const formValue = this.cardControlService.dataFromForm(form);
    if (Object.keys(formValue).length) {
      this.isSaving = true;
      this.notifyGallery();
      this.apiService
        .updateProject(this.project, { data: formValue })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((): void => {
          Object.assign(this.initialData, formValue);
          this.isProcessing = false;
          this.isSaving = false;
          this.isEditing = false;
          this.hasChanges = false;
          this.initialiseGallery(this.initialData);
          this.setCache();
          this.notifyGallery();
        });
    }
  }
}
