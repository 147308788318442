import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EnaApplicationPresenter } from './ena-application.presenter';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { EnaApplicationErrorsComponent } from './ena-application-errors/ena-application-errors.component';
import { EnaApplicationErrorsPresenter } from './ena-application-errors/ena-application-errors.presenter';
import { EnaApplicationComponent } from './ena-application.component';
import { Project } from '../../core/domain/project';
import { DisplayTabLayout } from '../project-detail.component';
import { CustomAttachment } from '../../core/domain/project-configuration';
import { EnaApplicationAttachmentsPresenter } from './ena-application-attachments/ena-application-attachments.presenter';

@Component({
  selector: 'ena-application-container',
  template: `
    <ena-application-errors></ena-application-errors>
    <ena-application
      [project]="project"
      [tabLayouts]="tabLayouts"
      [readonly]="readonly"
      [tenant]="tenant"
      [customAttachments]="customAttachments"
    ></ena-application>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CoreComponentsAngularModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbCollapseModule,
    EnaApplicationErrorsComponent,
    EnaApplicationComponent
  ],
  providers: [EnaApplicationPresenter, EnaApplicationErrorsPresenter, EnaApplicationAttachmentsPresenter]
})
export class EnaApplicationContainerComponent {
  @Input() project: Project;
  @Input() readonly: boolean; // todo check how to verify the ena tab layout is readonly specifically
  @Input() tenant: string;
  @Input() customAttachments: CustomAttachment[];
  @Input() tabLayouts: DisplayTabLayout[];
}
