import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterLinkActive } from '@angular/router';
import { NgIf } from '@angular/common';

export interface MenuItem {
  label: string;
  icon: string;
  qaHook: string;
  hasAccess: boolean;
  routeLink?: string;
  externalLink?: string;
  isDisabled?: boolean;
  isBetaFeature?: boolean;
  useFeatureToggle?: string;
  target?: string;
}

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    imports: [NgIf, RouterLinkActive, NgbTooltipModule, TranslocoModule]
})
export class MenuItemComponent {
  @Input() isActive: boolean;
  @Input() hasAccess: boolean;
  @Input() routeLink: string;
  @Input() externalLink: string;
  @Input() isClosed: boolean;
  @Input() isSmallScreen: boolean;
  @Input() qaHook: string;
  @Input() label: string;
  @Input() icon: string;
  @Input() isDisabled = false;
  @Input() isBetaFeature = false;
  @Input() target: string;

  @Output() menuItemClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() externalLinkClick: EventEmitter<string> = new EventEmitter<string>();

  onMenuItemClick(evt) {
    if (this.externalLink) {
      this.externalLinkClick.emit(this.externalLink);
    } else {
      this.menuItemClick.emit(evt);
    }
  }
}
