import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

import { LegacyEnaApplicationErrorsComponent } from './legacy-ena-application-errors/legacy-ena-application-errors.component';
import { LegacyEnaApplicationErrorsPresenter } from './legacy-ena-application-errors/legacy-ena-application-errors.presenter';
import { LegacyEnaApplicationComponent } from './legacy-ena-application.component';
import { Project } from '../../core/domain/project';

@Component({
    selector: 'legacy-ena-application-container',
    template: `
    <legacy-ena-application-errors/>
    <legacy-ena-application [project]="project"/>
  `,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CoreComponentsAngularModule, LegacyEnaApplicationErrorsComponent, LegacyEnaApplicationComponent],
    providers: [LegacyEnaApplicationErrorsPresenter]
})
export class LegacyEnaApplicationContainerComponent {
  @Input() project: Project;
}
