<div class="schedule-event-selected-job-details__assigned">
  <div class="schedule-event-selected-job-details__assigned-row schedule-event-selected-job-details__assigned-row--heading">
    <div class="schedule-event-selected-job-details__assigned-data">{{ vm.tradesPeopleDisplay }}</div>
  </div>
</div>
<div class="schedule-event-selected-job-details__assigned-engineers" [formGroup]="vm.form">
  @for (tradesPersonVm of vm.tradesPeople; track tradesPersonVm.assignedTo) {
    <div
      class="schedule-event-selected-job-details__assigned-row"
      [class.schedule-event-selected-job-details__assigned-row--overlaps]="tradesPersonVm.hasOverlap"
      [formGroupName]="tradesPersonVm.assignedTo">
      <schedule-event-selected-job-details-tradesperson
        [vm]="tradesPersonVm"
        (removeTradesperson)="removeTradesperson.emit($event)"
        (setLeadTradesperson)="setLeadTradesperson.emit($event)"
      >
      </schedule-event-selected-job-details-tradesperson>
      <div class="schedule-event-selected-job-details__slots">
        @for (slot of tradesPersonVm.slots; let idx = $index; track slot.assignmentId) {
          <schedule-event-selected-job-details-overlaps [overlapDetails]="slot.overlapDetails" [overlapLabel]="slot.i18nOverlapLabel"></schedule-event-selected-job-details-overlaps>
          <schedule-event-selected-job-details-slot-time-form
            [index]="idx"
            [tradespersonId]="tradesPersonVm.assignedTo"
            [vm]="slot"
            (slotDateTimeChange)="slotDateTimeChange.emit($event)"
            (removeTradespersonSlot)="removeTradespersonSlot.emit($event)">
          </schedule-event-selected-job-details-slot-time-form>
        }
        @if (!vm.readonly) {
          <jui-button size="sm" color="secondary" display="ghost" [disabled]="vm.actionsDisabled" (click)="addSpecificSlot.emit(tradesPersonVm)">{{ vm.i18nAddSpecificTimeBtn }}</jui-button>
        }
      </div>
    </div>
  }
  <div class="schedule-event-selected-job-details__divider"></div>
  <schedule-event-selected-job-details-add-tradesperson-form
    [vm]="vm"
    (cancelAddTradesperson)="cancelAddTradesperson.emit()"
    (confirmAddTradesperson)="confirmAddTradesperson.emit()"
    (addTradesperson)="addTradesperson.emit($event)">
  </schedule-event-selected-job-details-add-tradesperson-form>
</div>
