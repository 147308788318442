import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { SectionPresenter } from './section.presenter';
import { SectionQuestionViewModel } from './section.model';
import { AsyncPipe, NgIf } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { QUESTIONS } from '../questions.token';

@Component({
    selector: 'question-section',
    imports: [NgIf, AsyncPipe],
    providers: [SectionPresenter, { provide: QUESTIONS, useClass: SectionComponent, multi: true }],
    template: `<h3>{{ (vm$ | async).label }}</h3>
    <div *ngIf="(vm$ | async).hint" class="hint">{{ (vm$ | async).hint }}</div>`,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionComponent implements OnInit {
  private readonly presenter = inject(SectionPresenter);
  public readonly vm$: BehaviorSubject<SectionQuestionViewModel | null> =
    new BehaviorSubject<SectionQuestionViewModel | null>(null);

  @Input() hint: string;
  @Input() label: string;

  ngOnInit() {
    this.presenter.load(this.vm$, this.hint, this.label);
  }
}
