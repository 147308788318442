import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';
import { JobAssignment } from '../../../core/domain/job';
import { ScheduleMoreInfoVm } from '../../schedule.model';
import { TIME_SLOTS } from '../../utils/schedule-constants';
import { ScheduleEventMoreDetailsAddEngineerFormComponent } from '../schedule-event-more-details-add-engineer-form/schedule-event-more-details-add-engineer-form.component';
import { ScheduleEventMoreDetailsAssignedEngineersComponent } from '../schedule-event-more-details-assigned-engineers/schedule-event-more-details-assigned-engineers.component';
import { ScheduleEventMoreDetailsHeaderComponent } from '../schedule-event-more-details-header/schedule-event-more-details-header.component';
import { ScheduleEventMoreDetailsJobInfoComponent } from '../schedule-event-more-details-job-info/schedule-event-more-details-job-info.component';
import { ScheduleEventMoreDetailsRescheduleReasonFormComponent } from '../schedule-event-more-details-reschedule-reason-form/schedule-event-more-details-reschedule-reason-form.component';
import { ScheduleEventMoreDetailsScheduleDateTimeFormComponent } from '../schedule-event-more-details-schedule-date-time-form/schedule-event-more-details-schedule-date-time-form.component';
import { ScheduleEventMoreDetailsPresenter } from './schedule-event-more-details.presenter';

@Component({
  selector: 'schedule-event-more-details',
  templateUrl: 'schedule-event-more-details.component.html',
  styleUrls: ['schedule-event-more-details.component.scss'],
  imports: [
    TranslocoModule,
    CommonModule,
    NgbDatepickerModule,
    NgSelectModule,
    ReactiveFormsModule,
    CoreComponentsAngularModule,
    DragDropModule,
    ScheduleEventMoreDetailsHeaderComponent,
    ScheduleEventMoreDetailsJobInfoComponent,
    ScheduleEventMoreDetailsScheduleDateTimeFormComponent,
    ScheduleEventMoreDetailsAssignedEngineersComponent,
    ScheduleEventMoreDetailsAddEngineerFormComponent,
    ScheduleEventMoreDetailsRescheduleReasonFormComponent
  ],
  providers: [ScheduleEventMoreDetailsPresenter]
})
export class ScheduleEventMoreDetailsComponent implements OnInit {
  @Input() vm: ScheduleMoreInfoVm;
  @Input() context = null;
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closedOnSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();
  constructor(private presenter: ScheduleEventMoreDetailsPresenter) {}

  timeSlots = TIME_SLOTS;

  public vm$: BehaviorSubject<ScheduleMoreInfoVm> = new BehaviorSubject<ScheduleMoreInfoVm>(null);

  ngOnInit(): void {
    this.presenter.load(this.vm$, this.closedOnSuccess, this.context);
    this.localiseTimeSlotLabels();
  }

  closeMoreDetails(): void {
    this.presenter.closeMoreDetails();
    this.closed.emit();
  }

  addEngineer(el): void {
    this.presenter.addEngineer(el);
  }

  setLeadEngineer(engineer: JobAssignment): void {
    this.presenter.setLeadEngineer(engineer);
  }

  removeEngineer(engineer: JobAssignment): void {
    this.presenter.deleteEngineer(engineer);
  }

  confirmAddEngineer(): void {
    this.presenter.confirmAddEngineer();
  }

  cancelAddEngineer(): void {
    this.presenter.cancelAddEngineer();
  }

  localiseTimeSlotLabels(): void {
    this.timeSlots = TIME_SLOTS.map(slot => {
      const date = JumptechDate.now().set({
        hour: Number(slot.label.split(':')[0]),
        minute: Number(slot.label.split(':')[1])
      });
      return {
        ...slot,
        label: date.toTimeFormat(false)
      };
    });
  }

  handleDateTimeChange(): void {
    this.presenter.handleDateTimeChange();
  }

  handleScheduleNow(): void {
    this.presenter.scheduleJob();
  }

  handleGoToProject(): void {
    this.presenter.goToProject();
  }
}
