@if (vm$ | async; as vm) {
<div class="global-errors">
  <div class="global-errors__top">
    <div class="global-errors__top-message" [attr.data-qa]="vm.qaErrorMessage">
      <span class="global-errors__top-message-text">{{ vm.topMessage }}</span>
      @if (vm.canViewErrorDetails) {
        <span class="global-errors__top-message-icon">
        <jui-icon
          [attr.data-qa]="vm.qaErrorToggleDetailButton"
          [name]="vm.topIcon"
          color="current"
          size="sm"
          (click)="toggleDetail()"
        ></jui-icon>
      </span>
      }

    </div>
    <span (click)="clearErrors()" [attr.data-qa]="vm.qaClearErrorsButton">
      <jui-icon name="clear" color="current" size="sm"></jui-icon>
    </span>
  </div>

  @if (vm.showDetails) {
    <div class="global-errors__list" [attr.data-qa]="vm.qaErrorList">
      @for (errorVm of vm.errorList; track $index) {
        <div class="global-errors__message">
          <jui-chip class="global-errors__message-type" size="sm" [color]="errorVm.typeColor">{{ errorVm.type }}</jui-chip>
          {{ errorVm.message }}
        </div>
      }
    </div>
  }
</div>
}
