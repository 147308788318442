@if (vm()) {
<table class="list-view" [attr.data-qa]="'listView'">
  @for (row of vm(); track row) {
    <tr class="list-view__row">
    @for (item of row; track item) {
      <td class="list-view__row--cell">{{ item }}</td>
    }
    </tr>
  }
</table>
}
