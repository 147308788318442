import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, Subscription } from 'rxjs';

import { Project } from '../../core/domain/project';
import { HttpGateway } from '../../core/http-gateway.service';
import { ProjectCardLayout } from '../../core/domain/project-card-layout';
import {
  DISALLOWED_CURRENT_PROJECT_STATUSES,
  ProjectStatusChangeDm,
  ProjectStatusChangeErrorType,
  ProjectStatusChangeI18n
} from './project-status-change.model';
import { ProjectState } from '../../core/domain/project-configuration';
import { ConfirmModalComponent } from '../../shared/modals/confirm-modal.component';
import { AlertModalComponent } from '../../shared/modals/alert-modal.component';
import { UserService } from '../../auth/services/user.service';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ProjectStatusChangeRepository {
  projectStatusChangeSubscription: Subscription;
  projectStatusChange$: Subject<ProjectStatusChangeDm>;

  i18n: ProjectStatusChangeI18n = {};

  defaultDm: ProjectStatusChangeDm = {
    visible: false,
    projectStatuses: [],
    selectedStatus: null,
    i18n: this.i18n,
    disabled: false
  };

  project: Project;
  projectStatus: ProjectState | null;
  projectStatuses: ProjectState[];
  layout: ProjectCardLayout;

  cache: ProjectStatusChangeDm = { ...this.defaultDm };

  // TESTING
  useMocks = false;

  constructor(
    private gateway: HttpGateway,
    private i18nService: TranslocoService,
    private userService: UserService,
    private modalService: NgbModal
  ) {
    this.init();
  }

  init(): void {
    this.projectStatusChange$ = new Subject<ProjectStatusChangeDm>();
    this.cache = { ...this.defaultDm };

    this.initI18ns();
  }

  load(project: Project, projectStates: ProjectState[], cb: (...args: unknown[]) => void): void {
    this.projectStatusChangeSubscription?.unsubscribe();
    this.projectStatusChangeSubscription = this.projectStatusChange$.subscribe(cb);

    this.project = project;
    this.projectStatus = projectStates.find(state => state.status === project.status?.status) ?? null;
    this.projectStatuses = projectStates;

    this.loadDetails().then();
  }

  private async canChangeProjectStatus(status: string): Promise<boolean> {
    const isCurrentStatusValid = !DISALLOWED_CURRENT_PROJECT_STATUSES.includes(status);

    if (!isCurrentStatusValid) {
      return false;
    }

    const currentUser = this.userService.currentUser;

    return (currentUser.isAdmin || currentUser.isSuperAdmin) ?? false;
  }

  private async loadDetails(): Promise<void> {
    const projectStatus = this.projectStatus?.status;

    if (projectStatus) {
      try {
        const canChangeProjectStatus = await this.canChangeProjectStatus(projectStatus);
        const projectStatuses = this.getProjectStatuses(projectStatus, this.projectStatuses);

        this.cache.projectStatuses = projectStatuses;
        this.cache.visible = canChangeProjectStatus && projectStatuses.length > 0;
      } catch (error) {
        this.handleError(ProjectStatusChangeErrorType.LOAD_PROJECT_STATUSES, error);
      }
    }

    this.projectStatusChange$.next(this.cache);
  }

  private getProjectStatuses(status: string, projectStates: ProjectState[]): ProjectState[] {
    const projectStatuses: ProjectState[] = [];

    // Gather backward-travelling project states
    for (const projectState of projectStates) {
      if (projectState.status === status) {
        break;
      }

      projectStatuses.push(projectState);
    }

    return projectStatuses;
  }

  public async changeProjectStatus(status: ProjectState | undefined): Promise<void> {
    this.cache.selectedStatus = status ?? null;

    this.projectStatusChange$.next(this.cache);

    if (status) {
      await this.generateConfirmModal(status);
    }
  }

  private async generateConfirmModal(status: ProjectState): Promise<void> {
    if (!this.projectStatus) {
      console.log('No project status for confirm modal generation');
      return;
    }

    const modalRef = this.modalService.open(ConfirmModalComponent);

    const title = this.i18n.changeStatusModalTitle;

    const populatedTitle = title.replace('$1', this.projectStatus.label).replace('$2', status.label);

    modalRef.componentInstance.config = {
      title: populatedTitle,
      messages: [this.i18n.changeStatusModalMessage]
    };

    modalRef.componentInstance.config.onConfirm = async () => {
      modalRef.componentInstance.config.confirmLoading = true;
      modalRef.componentInstance.config.confirmDisabled = true;
      modalRef.componentInstance.config.cancelDisabled = true;

      await this.submitProjectStatusChange(status);

      modalRef.close();
    };
  }

  private async submitProjectStatusChange(status: ProjectState): Promise<void> {
    this.cache.disabled = true;

    this.projectStatusChange$.next(this.cache);

    try {
      if (!this.useMocks) {
        await this.gateway.post(
          `${environment.apiProjectsRoot}/admin/${this.project.id}/status`,
          {
            status: status.status,
            force: true
          },
          {}
        );
      }
    } catch (error) {
      this.handleError(ProjectStatusChangeErrorType.SUBMIT_PROJECT_STATUS_CHANGE, error);
    } finally {
      this.cache.selectedStatus = null;
      this.cache.disabled = false;

      this.projectStatusChange$.next(this.cache);
    }
  }

  private handleError(type: ProjectStatusChangeErrorType, error): void {
    if (!environment.production) {
      console.log('Project Status Change Error:', error);

      if (type === ProjectStatusChangeErrorType.SUBMIT_PROJECT_STATUS_CHANGE) {
        this.generateErrorModal();
      }
    }
  }

  private generateErrorModal(): void {
    const modalRef = this.modalService.open(AlertModalComponent);

    modalRef.componentInstance.title = this.i18n.errorModalTitle;
    modalRef.componentInstance.messages = [this.i18n.errorModalMessage];
  }

  private initI18ns(): void {
    this.i18n.label = this.i18nService.translate('projectStatusChange.label');
    this.i18n.placeholder = this.i18nService.translate('projectStatusChange.placeholder');
    this.i18n.changeStatusModalTitle = this.i18nService.translate('projectStatusChange.modals.confirm.title');
    this.i18n.changeStatusModalMessage = this.i18nService.translate('projectStatusChange.modals.confirm.message');
    this.i18n.errorModalTitle = this.i18nService.translate('projectStatusChange.modals.error.title');
    this.i18n.errorModalMessage = this.i18nService.translate('projectStatusChange.modals.error.message');
  }
}
