import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadProgressViewModel } from './upload-progress.view.model';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-progress-bar-modal',
    template: `<div class="modal-wrapper" *ngIf="vm">
    <div class="modal-header">
      <div [attr.data-qa]="'uploadProgress'">{{ vm.label }}</div>
    </div>
    <div class="modal-body">
      <div
        class="progress-bar progress-bar-striped progress-bar-animated mb-1 pl-2"
        role="progressbar"
        [style.width]="vm.progressPercent"
        [style.height]="'2rem'"
        [attr.aria-valuenow]="vm.progress"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <span class="ml-auto mr-auto">{{ vm.progressPercent }}</span>
      </div>
      <div class="modal-footer text-center" *ngIf="vm.error">
        {{ vm.error }}
        <jui-button
          size="md"
          title="{{ '_commons.ok' | transloco }}"
          expand
          color="primary"
          (click)="confirm($event)"
          [attr.data-qa]="'progressOkButton'"
        >
          {{ '_commons.ok' | transloco }}
        </jui-button>
      </div>
    </div>
  </div> `,
    styles: [
        `
      .progress-bar {
        background-color: var(--jds-theme-color-primary);
        text-align: left;
      }
    `
    ],
    imports: [NgIf, CoreComponentsAngularModule, TranslocoModule]
})
export class UploadProgressModalComponent {
  @Input() vm: UploadProgressViewModel;
  // add if we need custom styling here

  constructor(public activeModal: NgbActiveModal) {}

  confirm($event: Event) {
    $event.preventDefault();
    this.activeModal.close(true);
  }
}
