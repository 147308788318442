import { Component, OnInit } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import { SelectedJobDetailsToggleVm } from '../../../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleEventSelectedJobDetailsPresenter } from '../../schedule-event-selected-job-details.presenter';

@Component({
  selector: 'schedule-event-selected-job-details-toggle',
  templateUrl: 'schedule-event-selected-job-details-toggle.component.html',
  styleUrls: ['schedule-event-selected-job-details-toggle.component.scss'],
  imports: [AsyncPipe, CoreComponentsAngularModule, NgbTooltipModule],
  providers: [ScheduleEventSelectedJobDetailsPresenter]
})
export class ScheduleEventSelectedJobDetailsToggleComponent implements OnInit {
  constructor(private presenter: ScheduleEventSelectedJobDetailsPresenter) {}
  public vm$: BehaviorSubject<SelectedJobDetailsToggleVm> = new BehaviorSubject<SelectedJobDetailsToggleVm>(null);
  ngOnInit() {
    this.presenter.loadDetailsToggle(this.vm$);
  }

  showDisplay() {
    this.presenter.showDisplay();
  }
}
