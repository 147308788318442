import { Component, OnInit } from '@angular/core';
import { DocumentPackProgressPresenter } from './document-pack-progress.presenter';
import { IDocumentProgressVm } from '../document-pack.model';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-document-pack-progress',
    templateUrl: './document-pack-progress.component.html',
    styleUrls: ['./document-pack-progress.component.scss'],
    imports: [NgIf],
    providers: [DocumentPackProgressPresenter]
})
export class DocumentPackProgressComponent implements OnInit {
  vm: IDocumentProgressVm = {} as IDocumentProgressVm;

  constructor(private presenter: DocumentPackProgressPresenter) {}

  ngOnInit() {
    this.presenter.load(generatedVm => {
      this.vm = generatedVm;
    });
  }
}
