import { Component, OnDestroy, OnInit } from '@angular/core';
import { DocumentPackMessagesPresenter } from './document-pack-messages.presenter';
import { IMessageVm } from '../document-pack.model';
import { NgIf } from '@angular/common';
import { InlineSpinnerComponent } from '@jump-tech-frontend/cards';

@Component({
    selector: 'app-document-pack-messages',
    templateUrl: './document-pack-messages.component.html',
    styleUrls: ['./document-pack-messages.component.scss'],
    imports: [NgIf, InlineSpinnerComponent],
    providers: [DocumentPackMessagesPresenter]
})
export class DocumentPackMessagesComponent implements OnInit, OnDestroy {
  vm: IMessageVm = { message: null, showLoader: null, qaMessage: null, qaSpinner: null };

  constructor(private presenter: DocumentPackMessagesPresenter) {}

  ngOnInit() {
    this.presenter.load(generatedVm => {
      this.vm = generatedVm;
    });
  }

  ngOnDestroy() {
    this.presenter.cleanup();
  }
}
