import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { AuthStateEvent } from '../../../utils/auth-types';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
    selector: 'app-forgot-password-form',
    templateUrl: './forgot-password-form.component.html',
    styleUrls: ['../../login.component.scss'],
    imports: [ReactiveFormsModule, CoreComponentsAngularModule, NgIf, TranslocoModule]
})
export class ForgotPasswordFormComponent implements AfterViewInit {
  @Input() forgotPasswordForm: UntypedFormGroup;
  @Input() submitInProgress: boolean;

  @Output() forgotPasswordEvent: EventEmitter<null> = new EventEmitter<null>();
  @Output() setViewEvent: EventEmitter<AuthStateEvent> = new EventEmitter<AuthStateEvent>();

  @ViewChild('usernameInput')
  public usernameInput: ElementRef;

  constructor() {}

  ngAfterViewInit() {
    this.focusForm();
  }

  focusForm() {
    setTimeout(() => {
      this.usernameInput.nativeElement.focus();
    });
  }

  setView() {
    this.setViewEvent.emit(AuthStateEvent.StartSignIn);
  }

  forgotPassword() {
    this.forgotPasswordEvent.emit();
  }
}
