import { Component, Input } from '@angular/core';
import { OrderViewVm } from '../../my-orders.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf } from '@angular/common';

@Component({
    selector: 'pathway-orders-order-summary',
    templateUrl: './order-summary.component.html',
    styleUrls: ['../../order-view/order-view.component.scss'],
    imports: [NgIf, CoreComponentsAngularModule]
})
export class MyOrdersSummaryComponent {
  @Input() vm: OrderViewVm;
}
