import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ScheduleError } from '../../schedule.model';
import { ScheduleErrorsPresenter } from './schedule-errors.presenter';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'pathway-schedule-errors-v3',
    templateUrl: 'schedule-errors.component.html',
    styleUrls: ['schedule-errors.component.scss'],
    imports: [CommonModule]
})
export class ScheduleErrorsComponent implements OnInit {
  vm$: BehaviorSubject<ScheduleError> = new BehaviorSubject<ScheduleError>(null);

  constructor(private presenter: ScheduleErrorsPresenter) {}

  ngOnInit() {
    this.presenter.load(this.vm$);
  }

  clearError() {
    this.presenter.clearErrors();
  }
}
