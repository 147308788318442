import { HttpErrorResponse } from '@angular/common/http';

export interface GlobalErrorsVm {
  topMessage: string;
  topIcon: string;
  canViewErrorDetails: boolean;
  showDetails: boolean;
  errorList: GlobalErrorItemVm[];
  qaErrorMessage: string;
  qaErrorDetail: string;
  qaErrorToggleDetailButton: string;
  qaClearErrorsButton: string;
  qaErrorList: string;
}

export interface GlobalErrorItemVm {
  message: string;
  type: string;
  typeColor: string;
}

export interface GlobalErrorsDm {
  errors: GlobalError[];
  isSuperAdmin: boolean;
  serverLabel: string;
  clientLabel: string;
  topMessage: string;
  showDetails: boolean;
}

export interface GlobalErrorI18n {
  [key: string]: string;
}

export interface GlobalError {
  error: HttpErrorResponse;
  type: GlobalErrorType;
  message?: string;
  detail?: string;
}

export enum GlobalErrorType {
  client = 'client',
  server = 'server'
}
