import { Injectable } from '@angular/core';
import { Subject, Subscription, filter, map } from 'rxjs';

export enum ProjectDetailEventType {
  LEGACY_ENA_RESUBMIT = 'LEGACY_ENA_RESUBMIT'
}

type EmitEvent = {
  name: ProjectDetailEventType;
  value?: unknown;
};

@Injectable({ providedIn: 'root' })
export class ProjectDetailEventsService {
  private subject$ = new Subject<EmitEvent>();

  emit(event: EmitEvent) {
    this.subject$.next(event);
  }

  on(event: ProjectDetailEventType, action: (...args: unknown[]) => void): Subscription {
    return this.subject$
      .pipe(
        filter(e => e.name === event),
        map(e => e.value)
      )
      .subscribe(action);
  }
}
