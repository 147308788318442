import { Component } from '@angular/core';
import { NgFor } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-tabs-loader',
  template: `
    <div id="loader-tabs" #tabSetContainer>
      <ul
        ngbNav
        #loaderTabSet="ngbNav"
        [activeId]="'loaderTab'"
        class="nav-tabs"
      >
        <li [ngbNavItem]="'loaderTab'">
          <a ngbNavLink [attr.data-qa]="'loaderTab'">
            <jui-icon class="tab-icon" isButton="false" name="list_alt" size="xs" color="current"/>
            <b [attr.data-qa]="'activeLoaderTab'">
              <jui-skeleton-loader [inline]="true" width="38px" height="12px" color="primary"></jui-skeleton-loader>
            </b>
          </a>
          <ng-template ngbNavContent>
            <div class="loader-tab">
              <jui-card size="sm" *ngFor="let loader of loaders; let i = index;" class="loader-card">
               <div slot="header" class="list-layout__header">
                 <div class="list-layout__header--main">
                  <div class="loader-title">
                    <jui-skeleton-loader [width]="loader.titleWidth" glass="false" height="24px" color="high"></jui-skeleton-loader>
                  </div>
                   <div class="list-layout__header-actions">
                    <jui-skeleton-loader
                      height="24px"
                      width="36px"
                      color="low"
                    ></jui-skeleton-loader>
                   </div>
                </div>
               </div>
                @if (i < 2) {
                  <div class="loader-card-content">
                    <div class="loader-card-content-left">
                      <jui-skeleton-loader color="low" glass="true" [count]="loader.numLoaders" height="25px" random="true"></jui-skeleton-loader>
                    </div>
                    <div class="loader-card-content-right">
                      <jui-skeleton-loader color="low" glass="true" [count]="loader.numLoaders" height="25px" random="true"></jui-skeleton-loader>
                    </div>
                  </div>
                }
              </jui-card>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="'dummyTab'" [disabled]="true">
          <a ngbNavLink>
            <jui-icon class="tab-icon" isButton="false" name="photo_library" size="xs" color="current"/>
            <jui-skeleton-loader [inline]="true" width="38px" height="12px"></jui-skeleton-loader>
          </a>
        </li>

        <li [ngbNavItem]="'dummyTab2'" [disabled]="true">
          <a ngbNavLink>
            <jui-icon class="tab-icon" isButton="false" name="event_note" size="xs" color="current"/>
            <jui-skeleton-loader [inline]="true" width="38px" height="12px"></jui-skeleton-loader>
          </a>
        </li>
      </ul>
      <div class="tab-content--shadow" [ngbNavOutlet]="loaderTabSet"></div>
    </div>
  `,
  styleUrls: ['../core/cardLayouts/list-layout.component.scss'],
  imports: [NgbNavModule, CoreComponentsAngularModule, NgFor]
})
export class TabsLoaderComponent {
  loaders = [
    { titleWidth: '30%', numLoaders: 5 },
    { titleWidth: '20%', numLoaders: 4 },
    { titleWidth: '45%', numLoaders: 0 },
    { titleWidth: '10%', numLoaders: 0 },
    { titleWidth: '25%', numLoaders: 0 },
    { titleWidth: '55%', numLoaders: 0 },
    { titleWidth: '45%', numLoaders: 0 },
    { titleWidth: '40%', numLoaders: 0 },
    { titleWidth: '45%', numLoaders: 0 },
    { titleWidth: '55%', numLoaders: 0 }
  ];
}
