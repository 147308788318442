import { enableProdMode, ErrorHandler, importProvidersFrom, inject, provideAppInitializer } from '@angular/core';

import { environment } from './environments/environment';
import { bootstrapApplication, Title, BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { routing } from './app/app.routing';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import {
  withInterceptorsFromDi,
  provideHttpClient,
  HttpClient,
  HttpHandler,
  HttpClientModule
} from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';
import { interceptorProviders } from './app/core/interceptors';
import { AppName, EnvironmentToken, GlobalErrorHandler } from '@jump-tech-frontend/app-config';
import { DatadogLoggerService } from './app/error/datadog-logger.service';
import { FeatureFlagService } from './app/core/feature-flag/feature-flag.service';
import { TranslocoRootModule } from './app/transloco/transloco-root.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CanActivateRoute } from './app/auth/guards/can-activate-route';
import { CanDeactivateRoute } from './app/auth/guards/can-deactivate-route';
import { FeatureGuard } from './app/core/feature-flag/feature.guard';
import { CanActivateLeadsRoute } from './app/auth/guards/can-activate-leads-route';
import { defineCustomElements } from '@jump-tech-frontend/core-components/loader';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgbDateParserFormatter, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from './app/core/custom-ngb-date.parser';
import { CustomDatepickerI18n } from './app/core/custom-ngb-datepicker-i18n.service';
import { readOnlyServiceToken } from '@jump-tech-frontend/angular-common';
import { AggregatorReadOnlyService } from './app/project-detail/aggregator-read-only.service';
import { TraceHttpClient, traceHttpClientCreator } from './app/core/services/trace-http-client';

defineCustomElements();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const featureFactory = (featureFlagsService: FeatureFlagService, datadogLoggerService: DatadogLoggerService) => () => {
  return featureFlagsService.loadConfig();
};

if (environment.production) {
  enableProdMode();
}

declare global {
  interface Window {
    pendo?: any;
    __env?: {
      pendoId: string | null;
      environmentName: string;
    };
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    CanActivateRoute,
    CanDeactivateRoute,
    CanActivateLeadsRoute,
    FeatureGuard,
    importProvidersFrom(
      FullCalendarModule,
      TranslocoRootModule,
      BrowserModule,
      LayoutModule,
      BsDropdownModule.forRoot(),
      routing
    ),
    provideAppInitializer(() => {
      const initializerFn = featureFactory(inject(FeatureFlagService), inject(DatadogLoggerService));
      return initializerFn();
    }),
    Title,
    {
      provide: readOnlyServiceToken,
      useClass: AggregatorReadOnlyService
    },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    {
      provide: ErrorHandler,
      useClass: environment.analytics ? GlobalErrorHandler : ErrorHandler
    },
    { provide: AppName, useValue: 'PATHWAY' },
    {
      provide: 'stage',
      useValue: environment.name
    },
    { provide: EnvironmentToken, useValue: environment },
    ...interceptorProviders,
    provideHttpClient(withInterceptorsFromDi()),
    { provide: TraceHttpClient, useFactory: traceHttpClientCreator, deps: [HttpHandler] },
    { provide: HttpClient, useClass: TraceHttpClient, deps: [HttpHandler] },
    provideAnimations()
  ]
}).catch(err => console.log(err));
