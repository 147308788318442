import { ChangeDetectionStrategy, Component, EventEmitter, inject, input, OnInit, Output, Signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormUpdate, IQuestionComponent } from '../question.model';
import { VideoCapturePresenter } from './video-capture.presenter';
import { NgIf } from '@angular/common';
import { VideoCaptureQuestionParams, VideoCaptureQuestionViewModel } from './video-capture.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { VideoPlayerComponent } from './video-player/video-player.component';

@Component({
  selector: 'question-video-capture',
  templateUrl: './video-capture.component.html',
  imports: [NgIf, ReactiveFormsModule, VideoPlayerComponent, CoreComponentsAngularModule],
  providers: [VideoCapturePresenter],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./video-capture.component.scss']
})
export class VideoCaptureComponent implements OnInit, IQuestionComponent {
  private readonly presenter = inject(VideoCapturePresenter);
  public vm: Signal<VideoCaptureQuestionViewModel>;
  public params = input.required<VideoCaptureQuestionParams>();
  @Output() formChange: EventEmitter<FormUpdate> = new EventEmitter<FormUpdate>();

  private publishUpdate(key: string, value: unknown) {
    this.formChange.emit({ key, value: value });
  }

  public ngOnInit() {
    this.vm = this.presenter.load(this.params());
  }

  public async updateRecordedValue(videoData: string | null) {
    const isRemove = videoData === null;
    const isBlob = videoData?.startsWith('blob:');
    if (!isBlob) {
      this.publishUpdate(this.vm().key, isRemove ? [] : [videoData]);
    }
  }
}
