import { Component, Input } from '@angular/core';
import { JobSummaryItem } from './jobs.model';
import { TranslocoModule } from '@ngneat/transloco';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-jobs',
    templateUrl: './jobs.component.html',
    styleUrls: ['./jobs.component.scss'],
    imports: [NgFor, NgIf, TranslocoModule]
})
export class JobsComponent {
  @Input() jobs: JobSummaryItem[];
}
