import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SupportBarDataVm, SupportInstanceInformation } from './support-bar.model';
import { AsyncPipe } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { SupportBarPresenter } from './support-bar.presenter';
import { Project } from '../../core/domain/project';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { StatusChangePopoverComponent } from './status-change-popover/status-change-popover.component';

@Component({
  selector: 'support-bar',
  templateUrl: 'support-bar.component.html',
  styleUrls: ['support-bar.component.scss'],
  imports: [AsyncPipe, CoreComponentsAngularModule, NgbPopoverModule, StatusChangePopoverComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupportBarComponent implements OnInit {
  @Input() supportInstanceInformation: SupportInstanceInformation;
  public supportBarVm$: BehaviorSubject<SupportBarDataVm> = new BehaviorSubject<SupportBarDataVm>(null);

  constructor(private supportBarPresenter: SupportBarPresenter) {}

  public ngOnInit(): void {
    this.supportBarPresenter.load(this.supportBarVm$);
  }

  public openProjectStructureModal(projectStructure: Project): void {
    this.supportBarPresenter.openProjectStructureModal(projectStructure);
  }

  public handleProjectStatusChange(): void {
    this.supportBarPresenter.handleProjectStatusChange();
  }

  public async updateProjectStatus(): Promise<void> {
    await this.supportBarPresenter.updateProjectStatus(this.supportBarVm$.value?.projectStructure?.id);
  }
}
