import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectedJobActionsVm } from '../../../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'schedule-event-selected-job-details-actions',
  templateUrl: 'schedule-event-selected-job-details-actions.component.html',
  styleUrls: ['../../../schedule-event-selected-job-details/schedule-event-selected-job-details.component.scss'],
  imports: [
    CoreComponentsAngularModule,
    NgIf,
    NgSelectModule,
    NgbDatepickerModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule
  ]
})
export class ScheduleEventSelectedJobDetailsActionsComponent {
  @Input() vm: SelectedJobActionsVm;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  @Output() hide: EventEmitter<void> = new EventEmitter<void>();
  @Output() goToProject: EventEmitter<void> = new EventEmitter<void>();
  @Output() scheduleNow: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() checkCollisions: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}
}
