import { TranslocoModule } from '@ngneat/transloco';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { BehaviorSubject } from 'rxjs';
import { JobVm, ScheduleJobsDisplayVm } from '../../schedule.model';
import { ScheduleJobsDisplayPresenter } from './schedule-jobs-display.presenter';
import { ScheduleJobsLoaderComponent } from '../schedule-jobs-loader/schedule-jobs-loader.component';
import { NgbPopover, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleJobsDisplayFilterPopoverComponent } from '../schedule-jobs-display-filter-popover/schedule-jobs-display-filter-popover.component';
import { JobInfo } from '../../utils/schedule-types';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'schedule-jobs-display-v3',
  templateUrl: 'schedule-jobs-display.component.html',
  styleUrls: ['schedule-jobs-display.component.scss'],
  host: { '[class.--is-open]': 'displayOpen' },
  imports: [
    TranslocoModule,
    CommonModule,
    CoreComponentsAngularModule,
    ScheduleJobsLoaderComponent,
    NgbPopoverModule,
    ScheduleJobsDisplayFilterPopoverComponent
  ]
})
export class ScheduleJobsDisplayComponent implements OnInit {
  @ViewChild('p') rsjFilterPopover: NgbPopover;
  @Output() displayToggled: EventEmitter<void> = new EventEmitter<void>();
  @Output() assignButtonClicked: EventEmitter<JobInfo> = new EventEmitter<JobInfo>();
  public vm$: BehaviorSubject<ScheduleJobsDisplayVm> = new BehaviorSubject<ScheduleJobsDisplayVm>(null);
  public displayOpen = true;

  constructor(private presenter: ScheduleJobsDisplayPresenter) {}

  ngOnInit(): void {
    this.presenter.load(this.vm$);
  }

  openProject(projectId: string): void {
    this.presenter.openProject(projectId);
  }

  filterReadyToScheduleJobs(): void {
    this.presenter.filterReadyToScheduleJobs();
  }

  closeFilters(): void {
    this.rsjFilterPopover.close();
  }

  toggleDisplay(): void {
    this.displayOpen = !this.displayOpen;
    this.presenter.toggleDisplay();
    this.displayToggled.emit();
  }

  assignTradesperson(job: JobVm): void {
    this.assignButtonClicked.emit(job.jobInformation);
  }
}
