import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, signal } from '@angular/core';
import { readOnlyServiceToken } from '@jump-tech-frontend/angular-common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { TranslocoModule } from '@ngneat/transloco';
import * as Renderer from 'mustache-formats';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { ApiService, UserLookupResultItem } from '../../core/api.service';
import { Project } from '../../core/domain/project';
import { ProjectConfiguration, SummaryConfiguration } from '../../core/domain/project-configuration';
import { User } from '../../core/domain/user';
import { checkIfExpression } from '../../core/utils/filter';
import { AssignmentLabelComponent } from '../../feature-modules/configuration/auto-assignment/components/assignment-label/assignment-label.component';
import { cloneDeep } from 'lodash';
import {
  DropDownElement,
  MultipleSelectionDropdownComponent
} from '../../shared/form-components/multiple-selection-dropdown.component';
import { AggregatorReadOnlyService } from '../aggregator-read-only.service';

@Component({
    selector: 'app-project-detail-summary',
    templateUrl: './project-detail-summary.component.html',
    styleUrls: ['./project-detail-summary.component.scss'],
    imports: [
        NgIf,
        MultipleSelectionDropdownComponent,
        CoreComponentsAngularModule,
        AssignmentLabelComponent,
        NgFor,
        TranslocoModule
    ]
})
export class ProjectDetailSummaryComponent implements OnInit, OnChanges {
  @Input() project: Project;
  @Input() configuration: SummaryConfiguration;
  @Input() projectConfiguration: ProjectConfiguration;
  @Input() isPrimaryOwner: boolean;
  @Output() ownerChanged: EventEmitter<any> = new EventEmitter<any>();
  defaultAdminUser = 'Admin User';
  dropDownElements: DropDownElement[] = [];
  summaryItems = [];
  extendedUserDetail: any = null;
  loadingExtendedUserDetails = true;
  canChangeProjectOwner = false;
  public disableDropDown = signal(false);

  constructor(
    public apiService: ApiService,
    private featureAccessService: AccessService,
    @Inject(readOnlyServiceToken) private readOnlyProjectService: AggregatorReadOnlyService
  ) {}

  async ngOnChanges() {
    this.updateSummaryItems();
    await this.setExtendedUserDetail(this.project.owner);
  }

  async ngOnInit() {
    this.disableDropDown.set(this.readOnlyProjectService.isReadOnly());
    this.loadingExtendedUserDetails = true;
    this.canChangeProjectOwner =
      !this.isPrimaryOwner && this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.SummaryOwnerAccess, true);
    const managerRole = (this.configuration && this.configuration.managerRole) || 'Account Manager';
    const ownerPathRoot = environment.name === 'docker' ? '' : 'core/';

    if (!this.isPrimaryOwner) {
      const managersDropDownElements: UserLookupResultItem[] = await this.apiService.customLookup(
        `${ownerPathRoot}users/${encodeURIComponent(managerRole)}`
      );

      if (managersDropDownElements) {
        this.dropDownElements = managersDropDownElements
          .filter(e => {
            return e.key !== this.defaultAdminUser;
          })
          .map(u => {
            const label = u.team_name ? ` (${u.team_name})` : '';
            return { id: u.value, name: `${u.key}${label}` };
          });
      }
    }
    await this.setExtendedUserDetail(this.project.owner);
  }

  async setExtendedUserDetail(owner) {
    try {
      if (
        this.isPrimaryOwner ||
        !this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.ShowExtendedOwnerDetails) ||
        !owner ||
        owner === 'null'
      ) {
        this.extendedUserDetail = {
          email: null,
          phoneNumber: null
        };
        this.loadingExtendedUserDetails = false;
        return;
      }
      const ownerUser: User | null = await this.apiService.findUser(owner.split('|')[1]).toPromise();
      if (ownerUser) {
        this.extendedUserDetail = {
          email: ownerUser.email,
          phoneNumber: ownerUser.phoneNumber
        };
      }
      this.loadingExtendedUserDetails = false;
    } catch (e) {
      this.loadingExtendedUserDetails = false;
    }
  }

  updateSummaryItems() {
    if (this.configuration) {
      const jobMatcher = '{{project.jobs.';
      const jobLogMatcher = '{{#project.jobs.';
      this.summaryItems = this.configuration.items
        // todo - This first filter is temp, it filters out job specific data from summary until we remove from all configs
        .filter(item => !item.value.includes(jobMatcher) && !item.value.includes(jobLogMatcher))
        .filter(item => {
          return item.show ? checkIfExpression(item.show, this.project) : true;
        })
        .map(item => {
          // Before rendering we want to replace the project.data values with the translated values, will duplicate
          // the project to avoid changing the original project data
          const projectCopy = cloneDeep(this.project);
          this.getValueTranslation(projectCopy);
          const result = Renderer.render(item.value, {
            project: projectCopy,
            ids: this.getExternalIds()
          });
          return {
            label: item.label,
            value: result || item.default || ''
          };
        });
    }
  }
  private getValueTranslation(projectCopy: Project): void {
    for (const key of Object.keys(projectCopy.data)) {
      this.getTranslatedValueForKey(projectCopy, key, projectCopy.data[key]);
    }
  }

  private getTranslatedValueForKey(project: Project, propertyKey: string, propertyValue): void {
    const matchingObjects: {
      originalObject: { value: string; [key: string]: unknown };
    }[] = [];

    function traverse(obj) {
      for (const key in obj) {
        const value = obj[key];

        if (typeof value === 'object' && value) {
          traverse(value);
        } else if (typeof value === 'string' && 'key' in obj && 'value' in obj) {
          if (value === propertyValue) {
            // Use includes() for string search
            matchingObjects.push({
              originalObject: obj
            });
          }
        }
      }
    }

    traverse(this.projectConfiguration);

    if (matchingObjects.length > 0 && matchingObjects[0].originalObject.value) {
      project.data[propertyKey] = matchingObjects[0].originalObject.value;
    }
  }

  private getExternalIds() {
    return this.project.externalIds
      ? Object.keys(this.project.externalIds).map(x => {
          return { key: x, value: this.project.externalIds[x].id };
        })
      : [];
  }

  async updateOwner(selectedOwnerList: string[]) {
    const owner = selectedOwnerList?.[0] ?? null;
    const ownerUpdate = {
      owner: owner,
      owner_name: owner ? owner.split('|')[0] : null
    };
    this.ownerChanged.emit(ownerUpdate);
    await firstValueFrom<string>(this.apiService.updateProject(this.project, ownerUpdate));
    await this.setExtendedUserDetail(owner);
  }
}
