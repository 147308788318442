import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerService, NgxSpinnerModule } from 'ngx-spinner';
import { ImageError, ImageManipulationService } from '../../core/image-manipulation.service';
import { Card, ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { ImageCaptureAction } from '../../domain/card-actions/image-capture.action';
import { QuestionFormErrorComponent } from '@jump-tech-frontend/question-components';
import { QuestionsComponent } from '../../core/questions.component';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { I18nKeys } from '@jump-tech-frontend/app-config';

@Component({
    selector: 'crds-image-capture-form',
    templateUrl: './image-capture-form.component.html',
    imports: [
        NgIf,
        NgSwitch,
        NgSwitchCase,
        NgxSpinnerModule,
        ReactiveFormsModule,
        QuestionsComponent,
        QuestionFormErrorComponent
    ]
})
export class ImageCaptureFormComponent implements OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() card: Card<ImageCaptureAction>;
  @Input() i18ns: I18nKeys;
  action: ImageCaptureAction;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;
  uploadError: string;
  imageError: ImageError | null;

  constructor(
    private imageService: ImageManipulationService,
    private spinnerService: NgxSpinnerService,
    private imageAssetFactory: ImageAssetFactory
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.action = this.card.action;
    this.images = this.imageAssetFactory.create(this.card.image, this.form);
  }

  clickFile() {
    (document.getElementsByClassName('imageCapture')[0] as HTMLElement).click();
  }

  async setFromImage(event) {
    const file: File = event.target.files[0];
    if (!file) {
      return; // User cancelled
    }
    this.imageError = this.imageService.checkFileTypeAndSize(file);
    if (this.imageError) {
      this.uploadError = `${this.imageError.fileName}: ${this.i18ns[this.imageError.type]}`;
      return;
    }

    const targetFormControl = this.form.get(this.action.targetField);

    await this.spinnerService.show();
    try {
      const resizedImage = await this.imageService.resizeImageFromFile(file);
      targetFormControl.patchValue(resizedImage);
    } catch (error) {
      console.log('Failed image upload', error);
      // Cannot use instanceof
      if (error.name === 'ImageUploadError') {
        this.uploadError = this.i18ns.uploadImageError;
      }
    } finally {
      await this.spinnerService.hide();
    }
  }
}
