import { Project } from '../../core/domain/project';
import { ProjectCardLayout } from '../../core/domain/project-card-layout';

// VM
export interface LegacyEnaVm {
  project: Project;
  data: Record<string, string>;
  showRetry: boolean;
  showLoader: boolean;
  showRejectedStatus: boolean;
  layout: ProjectCardLayout;
  externalDno: string;
  status: string;
  i18nExternalDnoLabel: string;
  i18nTitle: string;
  i18nRetryMessage: string;
  i18nRetryLabel: string;
  i18nReadOnlyAlert: string;
  qaLegacyEnaApplicationStatus: string;
  qaLegacyEnaApplicationExternalDno: string;
}

export interface LegacyEnaActionsVm {
  resubmitInProgress: boolean;
  approveInProgress: boolean;
  rejectInProgress: boolean;
  i18nResubmitLabel: string;
  i18nManuallyApproveLabel: string;
  i18nManuallyRejectLabel: string;
  displayManuallyApprove: boolean;
  displayManuallyReject: boolean;
  displayResubmit: boolean;
  disableManuallyApprove: boolean;
  disableManuallyReject: boolean;
  disableResubmit: boolean;
  qaResubmitButton: string;
  qaManuallyApprove: string;
  qaManuallyReject: string;
}

export interface LegacyEnaErrorVm {
  message: string;
  qaClearErrorsButton: string;
  qaErrorMessage: string;
}

// DM
export interface LegacyEnaDm {
  applicationId: string;
  applicationStatus: string;
  externalDno: string;
  i18ns: LegacyEnaI18n;
  showRetry: boolean;
  approveInProgress: boolean;
  rejectInProgress: boolean;
  resubmitInProgress: boolean;
  loading: boolean;
  project: Project;
  data: Record<string, string>;
  layout: ProjectCardLayout;
}

export interface LegacyEnaActionsDm {
  approveInProgress: boolean;
  rejectInProgress: boolean;
  resubmitInProgress: boolean;
  i18nResubmitLabel: string;
  i18nManuallyApproveLabel: string;
  i18nManuallyRejectLabel: string;
  displayManuallyApprove: boolean;
  displayManuallyReject: boolean;
  displayResubmit: boolean;
  disableManuallyApprove: boolean;
  disableManuallyReject: boolean;
  disableResubmit: boolean;
}

export interface LegacyEnaErrorDm {
  message: string;
  qaClearErrorsButton: string;
  qaErrorMessage: string;
}

// DTO
export type LegacyEnaDto = {
  status: string;
  internalApplicationId: string;
};

export enum LegacyEnaStatusType {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

// ERROR MAPPING
export enum LegacyEnaErrorType {
  GetApplication = 'legacyEna.errors.getApplication',
  PutApplicationStatus = 'legacyEna.errors.putApplicationStatus',
  ResubmitApplication = 'legacyEna.errors.resubmitApplication',
  Generic = 'legacyEna.errors.generic'
}

// TRANSLATIONS
export interface LegacyEnaI18n {
  [key: string]: string;
}
