import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { I18nKeys } from '../../domain/i18n-keys';
import { BaseQuestionComponent } from '../question.component';
import { ScrollService } from '../scroll.service';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { SignaturePadComponent } from '../signature-pad.component';
import { QuestionLabelContentComponent } from '@jump-tech-frontend/question-components';
import { NgIf } from '@angular/common';

@Component({
    selector: `crds-question-signature`,
    template: `
    <div *ngIf="show && form" [formGroup]="form">
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key">
        <question-label-content [question]="question" [isInvalid]="isInvalid" [i18ns]="i18ns"></question-label-content>
      </label>
      <crds-signature-pad [form]="form" [key]="question.key" #signatureInput></crds-signature-pad>

      <question-hint [question]="question"></question-hint>
    </div>
  `,
    imports: [NgIf, ReactiveFormsModule, QuestionLabelContentComponent, SignaturePadComponent, QuestionHintComponent]
})
export class SignatureQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<string>;
  @Input() i18ns: I18nKeys;
  @ViewChild('signatureInput') input: ElementRef;
  type: string;

  private untilDestroyed = untilDestroyed();

  constructor(private scrollService: ScrollService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    this.scrollService.scrollObservable.pipe(this.untilDestroyed()).subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
  }

  override get isInvalid() {
    return (this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid) ?? true;
  }

  override get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }
}
