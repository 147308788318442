import { ChangeDetectionStrategy, Input, Component } from '@angular/core';
import {
  ApplicationFormControlWithHooks,
  ApplicationFormGroup,
  ApplicationFormItemWithHooks,
  ApplicationFormSectionWithHooks,
  EnaFormVm
} from '../ena.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { EnaApplicationPresenter } from '../ena-application.presenter';
import { EnaFormControlListComponent } from '../ena-form-control-list/ena-form-control-list.component';

@Component({
  selector: 'ena-form-controls',
  templateUrl: 'ena-form-controls.component.html',
  styleUrls: ['./ena-form-controls.component.scss'],
  imports: [
    CoreComponentsAngularModule,
    FormsModule,
    NgSelectModule,
    NgbDatepickerModule,
    ReactiveFormsModule,
    EnaFormControlListComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnaFormControlsComponent {
  constructor(private presenter: EnaApplicationPresenter) {}

  @Input() vm: EnaFormVm;
  @Input() control: ApplicationFormControlWithHooks;
  @Input() section: ApplicationFormSectionWithHooks;
  @Input() group: ApplicationFormGroup;
  @Input() item?: ApplicationFormItemWithHooks;
  @Input() itemIdx?: number;

  getFormControl(group: ApplicationFormGroup, control: ApplicationFormControlWithHooks, idx?: number) {
    return this.presenter.getFormControl(group, control, idx);
  }
}
