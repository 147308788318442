import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { AsyncPipe, NgIf, NgStyle } from '@angular/common';
import { QUESTIONS } from '../questions.token';
import { ListViewQuestionParams } from './list-view.model';

@Component({
  selector: 'question-list-view',
  templateUrl: './list-view.component.html',
  standalone: true,
  imports: [NgIf, AsyncPipe, NgStyle],
  providers: [{ provide: QUESTIONS, useExisting: ListViewComponent, multi: true }],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent {
  listData = input.required<ListViewQuestionParams>();
  private data = computed(() => this.listData()?.data ?? []);
  private schema = computed(() => this.listData()?.schema ?? null);

  width = computed(() => {
    if (!this.data()?.length) {
      return '33%';
    }
    const headersLength = Object.keys(this.data()[0])?.length;
    return 100 / (headersLength ?? 4) + '%';
  });

  labels = computed(() => {
    if (!this.schema()) {
      return Object.keys(this.data()?.[0]).map(key => {
        return { label: key, field: key };
      });
    }
    return this.schema()?.map(schemaItem => {
      return { label: schemaItem.label, field: schemaItem.field };
    });
  });

  values = computed(() => {
    const labels = (this.labels() ?? []).map(label => label.label);
    if (!labels.length) {
      return [];
    }
    return [
      labels,
      ...this.data().map(row => {
        return this.labels()?.map(label => row[label.field]);
      })
    ];
  });

  vm = computed(() => {
    if (!this.data().length) {
      return null;
    }
    return this.values();
  });
}
