import { ChangeDetectionStrategy, Component, inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormUpdate, IQuestionComponent } from '../question.model';
import { BehaviorSubject } from 'rxjs';
import { TextboxPresenter } from './textbox.presenter';
import { AsyncPipe, NgIf } from '@angular/common';
import { QUESTIONS } from '../questions.token';
import { TextboxQuestionParams, TextboxQuestionViewModel } from './textbox.model';

@Component({
    selector: 'question-textbox',
    templateUrl: './textbox.component.html',
    imports: [NgIf, AsyncPipe, ReactiveFormsModule],
    providers: [TextboxPresenter, { provide: QUESTIONS, useExisting: TextboxComponent, multi: true }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./textbox.component.scss']
})
export class TextboxComponent implements OnInit, IQuestionComponent {
  private readonly presenter = inject(TextboxPresenter);

  public readonly vm$: BehaviorSubject<TextboxQuestionViewModel | null> =
    new BehaviorSubject<TextboxQuestionViewModel | null>(null);

  @Input() params: TextboxQuestionParams;
  @Output() formChange: EventEmitter<FormUpdate> = new EventEmitter<FormUpdate>();

  ngOnInit() {
    this.presenter.load(this.vm$, this.params, this.formChange);
  }
}
