import { Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'app-reset-password-success',
    templateUrl: './reset-password-success.component.html',
    styleUrls: ['../../login.component.scss'],
    imports: [TranslocoModule]
})
export class ResetPasswordSuccessComponent {
  constructor() {}
}
