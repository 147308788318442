<div #addTradespersonForm>
  @if (!vm.readonly) {
    <div class="schedule-event-selected-job-details__add-row" *ngIf="!vm.isAddingTradesperson">
      <jui-button
        (click)="addTradesperson.emit(addEngineerFormEl)"
        [attr.data-qa]="vm.qaAddTradespersonBtn"
        [disabled]="vm.actionsDisabled"
        size="sm"
        color="secondary"
        display="ghost"
      >
        <span slot="icon" class="material-icons">add</span>
        {{ vm.i18nAddTradesPersonBtn }}
      </jui-button>
    </div>
  }

  @if (vm.showTradespersonRequiredError) {
    <div class="schedule-event-selected-job-details__form-error">{{ vm.i18nTradespersonRequiredError }}</div>
  }

  <div class="schedule-event-selected-job-details__add-row" *ngIf="vm.isAddingTradesperson" [formGroup]="vm.addTradespersonForm">
    <div class="schedule-event-selected-job-details__add-row-item">
      <ng-select
        class="schedule-event-selected-job-details__select"
        [items]="vm.allTradespeople"
        bindLabel="name"
        [placeholder]="vm.i18nSelectTradespersonPlaceholder"
        formControlName="tradesperson"
        [attr.data-qa]="vm.qaTradespersonSelect"
        appendTo="body"
        [multiple]="false"
        [clearable]="false"
        (keyup.enter)="confirmAddTradesperson.emit()"
      >
      </ng-select>
    </div>
    <div class="schedule-event-selected-job-details__add-row-item">
      <jui-button
        size="sm"
        (click)="confirmAddTradesperson.emit()"
        [disabled]="vm.addTradespersonForm.invalid"
        [attr.data-qa]="vm.qaConfirmAddTradespersonBtn"
      >
        {{ vm.i18nConfirmBtn }}
      </jui-button>
    </div>
    <div class="schedule-event-selected-job-details__add-row-item">
      <jui-button size="sm" color="low" (click)="cancelAddTradesperson.emit()" [attr.data-qa]="vm.qaCancelAddTradespersonBtn">
        {{ vm.i18nCancelBtn }}
      </jui-button>
    </div>
  </div>
</div>
