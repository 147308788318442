import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '../../auth/services/authentication.service';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { GlobalErrorsService } from '../services/global-errors.service';
import { GlobalErrorType } from '../../error/global-errors/global-errors.model';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private globalErrorsService: GlobalErrorsService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipIntercept = request.headers.has('skip');
    if (skipIntercept) {
      request = request.clone({
        headers: request.headers.delete('skip')
      });
      return next.handle(request);
    }
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof ErrorEvent) {
          this.globalErrorsService.notifyGlobalError({ error, detail: error.message, type: GlobalErrorType.client });
        } else {
          this.globalErrorsService.notifyGlobalError({ error, detail: error.message, type: GlobalErrorType.server });

          if (error.status === 0 || error.status === 401 || error.status === 403) {
            // If no cognito session force sign out user
            this.authenticationService.getSession().then(
              (session: CognitoUserSession) => {
                if (!session?.getIdToken()?.getJwtToken()) {
                  this.authenticationService.signOut().then();
                }
              },
              () => {
                this.authenticationService.signOut().then();
              }
            );
            return throwError(() => {
              return error;
            });
          }
        }
        return throwError(() => {
          return error;
        });
      })
    );
  }
}
