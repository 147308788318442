<ng-container [formGroup]="vm.orderForm">
  <!-- <span class="my-orders__sub-title">{{ vm.packageName }}</span> -->
  <div class="my-orders__split-row">
    <div class="my-orders__split-ctrl">
      <label class="my-orders__label" [attr.data-qa]="'selectedPackageLabel'">{{ vm.labelSelectPackage }}</label>
      <ng-select
        [items]="vm.installationPackagesOptions"
        [placeholder]="vm.placeholderSelectProducts"
        formControlName="selectedPackage"
        [attr.data-qa]="'selectedPackage'"
        [closeOnSelect]="true"
        [clearable]="false"
        [bindLabel]="'name'"
        [searchFn]="searchItems"
      >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div class="my-orders__option-label" [ngOptionHighlight]="search" [attr.data-qa]="'packageOptionLabel'">
            {{ item.name }}
          </div>
          <div class="my-orders__option-desc" [ngOptionHighlight]="search" [attr.data-qa]="'packageOptionDesc'">
            {{ item.description }}
          </div>
        </ng-template>
      </ng-select>
      <span
        class="my-orders__error"
        *ngIf="
          vm.isOrderingEnabled &&
          vm.orderForm.get('selectedPackage').getError('required') &&
          vm.orderForm.get('selectedPackage').touched
        "
        >{{ vm.validationRequired }}</span
      >
    </div>
    <div class="my-orders__split-ctrl">
      <label class="my-orders__label" [attr.data-qa]="'accessoriesLabel'"
        >{{ vm.labelPackageAddons }} {{ vm.validationOptional }}</label
      >
      <ng-select
        [items]="vm.packageAddons"
        [multiple]="true"
        [placeholder]="vm.placeholderSelectAddons"
        formControlName="selectedAddons"
        [attr.data-qa]="'selectedAddons'"
        [closeOnSelect]="false"
        [clearSearchOnAdd]="true"
        [bindLabel]="'name'"
        [searchFn]="searchItems"
      >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div class="my-orders__option-label" [ngOptionHighlight]="search" [attr.data-qa]="'addonsOptionLabel'">
            {{ item.name }}
          </div>
          <div class="my-orders__option-desc" [ngOptionHighlight]="search" [attr.data-qa]="'addonsOptionDesc'">
            {{ item.description }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>
  <div class="my-orders__split-row">
    <div class="my-orders__split-ctrl">
      <label class="my-orders__label" [attr.data-qa]="'productsLabel'">{{ vm.labelProducts }}</label>
      <ng-select
        [items]="vm.productList"
        [placeholder]="vm.placeholderSelectProducts"
        formControlName="selectedProducts"
        [attr.data-qa]="'selectedProducts'"
        [closeOnSelect]="true"
        [clearable]="false"
        [bindLabel]="'name'"
        [searchFn]="searchItems"
      >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div class="my-orders__option-label" [ngOptionHighlight]="search" [attr.data-qa]="'productOptionLabel'">
            {{ item.name }}
          </div>
          <div class="my-orders__option-desc" [ngOptionHighlight]="search" [attr.data-qa]="'productOptionDesc'">
            {{ item.description }}
          </div>
        </ng-template>
      </ng-select>
      <span
        class="my-orders__error"
        *ngIf="
          vm.isOrderingEnabled &&
          vm.orderForm.get('selectedProducts').getError('required') &&
          vm.orderForm.get('selectedProducts').touched
        "
        >{{ vm.validationRequired }}</span
      >
    </div>
    <div class="my-orders__split-ctrl">
      <label class="my-orders__label" [attr.data-qa]="'accessoriesLabel'"
        >{{ vm.labelAccessories }} {{ vm.validationOptional }}</label
      >
      <ng-select
        [items]="vm.accessoriesList"
        [multiple]="true"
        [placeholder]="vm.placeholderSelectAccessories"
        formControlName="selectedAccessories"
        [attr.data-qa]="'selectedAccessories'"
        [closeOnSelect]="false"
        [clearSearchOnAdd]="true"
        [bindLabel]="'name'"
        [searchFn]="searchItems"
      >
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div class="my-orders__option-label" [ngOptionHighlight]="search" [attr.data-qa]="'accessoryOptionLabel'">
            {{ item.name }}
          </div>
          <div class="my-orders__option-desc" [ngOptionHighlight]="search" [attr.data-qa]="'accessoryOptionDesc'">
            {{ item.description }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div class="my-orders__split-row" *ngIf="vm.isOrderingEnabled">
    <div class="my-orders__split-ctrl">
      <label class="my-orders__label" [attr.data-qa]="'preferredDateLabel'">{{ vm.labelPreferredShipmentDate }}</label>
      <div class="input-group">
        <input
          class="form-control my-orders__ctrl"
          [placeholder]="vm.shipmentDatePlaceholder"
          name="dp"
          id="preferredDate"
          [markDisabled]="markDateDisabled"
          [minDate]="vm.shipmentDateEarliest"
          ngbDatepicker
          #preferredDate="ngbDatepicker"
          [attr.data-qa]="'preferredDateInput'"
          formControlName="preferredShipmentDate"
          container="body"
        />
        <div class="input-group-append my-orders__ctrl-append">
          <button
            class="my-orders__input-btn calendar"
            (click)="preferredDate.toggle()"
            type="button"
            [attr.data-qa]="'preferredDateButton'"
            #calendarButton
          >
            <span class="my-orders__input-btn-icon material-icons">event</span>
          </button>
        </div>
      </div>
      <span
        class="my-orders__error"
        *ngIf="
          vm.orderForm.get('preferredShipmentDate').errors?.required &&
          vm.orderForm.get('preferredShipmentDate').touched
        "
      >
        {{ vm.validationRequired }}
      </span>
      <span
        class="my-orders__error"
        *ngIf="
          vm.orderForm.get('preferredShipmentDate').errors?.weekdayOnly &&
          vm.orderForm.get('preferredShipmentDate').touched
        "
      >
        {{ vm.validationWeekday }}
      </span>
      <span
        class="my-orders__error"
        *ngIf="
          vm.orderForm.get('preferredShipmentDate').errors?.ngbDate?.minDate &&
          vm.orderForm.get('preferredShipmentDate').touched
        "
      >
        {{ vm.validationMinShipDate }}</span
      >
      <span
        class="my-orders__error"
        *ngIf="
          vm.orderForm.get('preferredShipmentDate').errors?.ngbDate?.invalid &&
          vm.orderForm.get('preferredShipmentDate').touched
        "
      >
        {{ vm.validationInvalidDate }}</span
      >
    </div>
  </div>
</ng-container>
