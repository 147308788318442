import { Injectable } from '@angular/core';
import { Job } from '../../core/domain/job';
import { ActiveJobActionPayload } from './utils/schedule-types';
import { ErrorType, SelectedJobAction } from './schedule.model';
import { ScheduleRepositoryV3 } from './schedule.repository.v3';

@Injectable()
export class SchedulePresenter {
  constructor(private repository: ScheduleRepositoryV3) {}

  setSelectedJob(job: Job): void {
    this.repository.setSelectedJob(job);
  }

  public dispatch(action: SelectedJobAction, payload: ActiveJobActionPayload): void {
    this.repository.handleAction(action, payload);
  }

  public showError(type: ErrorType, e): void {
    this.repository.handleErrors(type, e);
  }

  public showSelectedJob() {
    this.repository.showDetailsToggle(false);
  }

  public listenToCalendarActiveState(cb): void {
    this.repository.listenToCalendarActiveState(cb);
  }
}
