import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { NgModel, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, NgFor, SlicePipe } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

export interface DropDownElement {
  id: string;
  name?: string;
  label?: string;
  sticky?: boolean;
}

@Component({
    selector: 'app-multi-select',
    template: `
    <ng-select
      clearSearchOnAdd="false"
      bindLabel="item_text"
      bindValue="item_id"
      #multiSelectModelValue="ngModel"
      [appendTo]="appendTo"
      [labelForId]="labelForId"
      [clearable]="clearable"
      [class.expand]="itemsShowLimit > 2"
      [items]="dropdownList"
      [placeholder]="placeholder || ('common.pleaseSelect' | transloco)"
      [closeOnSelect]="singleSelection"
      [multiple]="!singleSelection"
      [clearOnBackspace]="true"
      [loading]="loading"
      [loadingText]="'common.loading' | transloco"
      [(ngModel)]="selectedListIds"
      (ngModelChange)="onItemChange($event)"
      [compareWith]="compareFn"
      [attr.data-qa]="'multiselectDropdown'"
      [disabled]="disabled"
    >
      <ng-container>
        <ng-container *ngIf="!singleSelection">
          <ng-template ng-header-tmp>
            <button (click)="onAllSelected(multiSelectModelValue)" class="btn btn-sm btn-outline-primary">
              {{ 'common.selectAll' | transloco }}
            </button>
            &nbsp;
            <button (click)="onAllDeselected(multiSelectModelValue)" class="btn btn-sm btn-outline-secondary">
              {{ 'common.unselectAll' | transloco }}
            </button>
          </ng-template>
        </ng-container>

        <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
          <div class="ng-value-label" [ngOptionHighlight]="search">{{ item.item_text }}</div>
          <div *ngIf="item.item_description" class="ng-value-description" [ngOptionHighlight]="search">
            {{ item.item_description }}
          </div>
        </ng-template>

        <ng-template *ngIf="itemsShowLimit" ng-multi-label-tmp let-items="items" let-clear="clear">
          <div class="ng-value" *ngFor="let item of items | slice: 0:itemsShowLimit">
            <span class="ng-value-label"> {{ item.item_text }} </span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
          </div>
          <div class="ng-value" *ngIf="items.length > itemsShowLimit">
            <span class="ng-value-label"
              >{{ items.length - itemsShowLimit }} / {{ items.length }} {{ 'common.results' | transloco }}</span
            >
          </div>
        </ng-template>

        <ng-template *ngIf="!singleSelection" ng-footer-tmp>
          {{ 'common.selectedCount' | transloco }}: {{ selectedListIds?.length || 0 }}
        </ng-template>
      </ng-container>
    </ng-select>
  `,
    styles: [
        `
      .ng-value-description {
        font-size: var(--jds-theme-fs-xxs);
        white-space: normal;
      }
    `
    ],
    imports: [
        NgSelectModule,
        NgOptionHighlightModule,
        ReactiveFormsModule,
        FormsModule,
        NgIf,
        NgFor,
        SlicePipe,
        TranslocoModule
    ]
})
export class MultipleSelectionDropdownComponent implements OnInit, OnChanges {
  dropdownList = [];
  selectedList = [];
  selectedListIds = [];
  dropdownSettings = {};

  _items: DropDownElement[] | null;
  _selectedItems: string[];

  get items() {
    return this._items;
  }

  @Input() set items(items: any[]) {
    this._items = items || [];
  }

  get selectedItems() {
    return this._selectedItems;
  }

  @Input() set selectedItems(selectedItems: string[]) {
    this._selectedItems = selectedItems || [];
  }

  @Input() bindId?: string;
  @Input() bindLabel?: string;
  @Input() appendTo?: string = '';
  @Input() labelForId?: string;
  @Input() alphaSort = false;
  @Input() placeholder?: string;
  @Input() singleSelection?: boolean = false;
  @Input() clearable?: boolean = true;
  @Input() allowSearchFilter?: boolean = true;
  @Input() loading?: boolean = false;
  @Input() itemsShowLimit?: number = 10;
  @Input() disabled = false;
  @Output() itemsChanged: EventEmitter<any[]> = new EventEmitter<any[]>();

  private getItemsList(selectedItems?: unknown[]) {
    const items = this.items
      .filter(item => {
        return typeof item.name !== 'undefined' || typeof item[this.bindLabel] !== 'undefined';
      })
      .map(item => {
        return {
          item_id: item[this.bindId || 'id'],
          item_text: item[this.bindLabel || 'name'],
          item_description: item['description'],
          sticky: item.sticky || false
        };
      })
      .sort((a, b) => {
        if (this.alphaSort) {
          return a.sticky ? -1 : a.item_text.localeCompare(b.item_text);
        } else {
          return a.sticky ? -1 : 1;
        }
      });

    if (!selectedItems) {
      return items;
    }

    return items.filter(item => {
      if (typeof item.item_id === 'object') {
        return !!selectedItems.find(x => {
          const a = JSON.stringify(item.item_id, Object.keys(item.item_id).sort());
          const b = JSON.stringify(x, Object.keys(x).sort());
          return a === b;
        });
      } else {
        return selectedItems.indexOf(item.item_id) > -1;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (Object.hasOwnProperty.call(changes, propName)) {
        switch (propName) {
          case 'items':
            if (this.isValueChanged(changes[propName])) {
              this.dropdownList = [...this.getItemsList()];
            }
            break;
          case 'selectedItems':
            if (this.isValueChanged(changes[propName])) {
              this.selectedListIds = changes[propName].currentValue;
            }
            break;
        }
      }
    }
  }

  isValueChanged(changes: SimpleChange) {
    return !changes.isFirstChange() && JSON.stringify(changes.previousValue) !== JSON.stringify(changes.currentValue);
  }

  ngOnInit() {
    this.dropdownList = this.getItemsList();
    this.selectedList = this.selectedItems ? this.getItemsList(this.selectedItems) : [];
    this.selectedListIds = this.selectedItems?.length ? this.selectedList.map(x => x.item_id) : [];
  }

  onItemChange(selected) {
    if (!Array.isArray(selected)) {
      selected = [selected];
    }
    this.itemsChanged.emit(selected);
  }

  onAllSelected(select: NgModel) {
    this.selectedList = this.dropdownList.map(x => x.item_id);
    select.update.emit(this.selectedList);
    this.itemsChanged.emit(this.selectedList);
  }

  onAllDeselected(select: NgModel) {
    this.selectedList = [];
    select.update.emit(this.selectedList);
    this.itemsChanged.emit(this.selectedList);
  }

  compareFn(optionItem: unknown, selectionValue: unknown) {
    let selectionValues = [];
    if (!Array.isArray(selectionValue)) {
      selectionValues = [selectionValue];
    } else {
      selectionValues = selectionValue;
    }
    if (typeof optionItem === 'string') {
      return selectionValues.find(x => x === optionItem);
    } else {
      return selectionValues.find(x => {
        const a = JSON.stringify(optionItem['item_id'], Object.keys(optionItem['item_id']).sort());
        const b = JSON.stringify(x, Object.keys(x).sort());
        return a === b;
      });
    }
  }
}
