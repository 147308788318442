import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { CustomValidators } from '../../auth/utils/custom-validators';
import { HttpGateway } from '../../core/http-gateway.service';
import { ConfirmModalComponent } from '../../shared/modals/confirm-modal.component';
import { ToasterService } from '../../toast/toast-service';
import {
  ALLOW_ORDER_STATUS_LIST,
  CancelOrderDto,
  CreateOrderDto,
  CustomerShippingAddress,
  ErrorType,
  MY_ORDERS_EMPTY_I18N,
  MyOrdersDm,
  MyOrdersI18n,
  Order,
  OrderHistoryDm,
  OrderLineItem,
  OrderPartStatus,
  OrderResource,
  OrderViewDm,
  ProductCategory,
  ProductLineItem,
  ProductResource,
  ResourceOrderStatus,
  SelectedAddressType,
  ShippingAddress,
  TEMP_ORDER_ID,
  TenantShippingAddress
} from './my-orders.model';
import { UserService } from '../../auth/services/user.service';

@Injectable({ providedIn: 'root' })
export class MyOrdersRepository {
  moSubscription: Subscription;
  addressTypeSubscription: Subscription;
  viewOrderSubscription: Subscription;
  orderHistorySubscription: Subscription;

  mo$: Subject<MyOrdersDm>;
  vo$: Subject<OrderViewDm>;
  ho$: Subject<OrderHistoryDm>;

  myOrders: MyOrdersDm = {} as MyOrdersDm;
  rawProductResources: ProductResource[] = null;
  rawOrderResources: OrderResource[] = null;
  projectId: string;
  defaultMinShipmentDays = 3;
  cancelOrderTimeout: NodeJS.Timeout;
  updateOrderTimeout: NodeJS.Timeout;

  cacheActiveOrder: OrderViewDm = null;

  i18n: MyOrdersI18n = MY_ORDERS_EMPTY_I18N;

  constructor(
    private gateway: HttpGateway,
    private i18nService: TranslocoService,
    private fb: FormBuilder,
    private toasterService: ToasterService,
    private modalService: NgbModal,
    private userService: UserService
  ) {
    this.init();
  }

  private init() {
    this.mo$ = new Subject<MyOrdersDm>();
    this.vo$ = new Subject<OrderViewDm>();
    this.ho$ = new Subject<OrderHistoryDm>();
    this.initI18ns();
    this.initDefaults();
  }

  public load({
    cb,
    products,
    orders,
    driverAddress,
    defaultAddress,
    projectId,
    minShipmentDays,
    refresh
  }: {
    cb: (dataDm: MyOrdersDm) => void;
    products: ProductResource[];
    orders: OrderResource[];
    driverAddress?: CustomerShippingAddress;
    defaultAddress?: TenantShippingAddress;
    projectId: string;
    minShipmentDays: number;
    refresh: boolean;
  }): void {
    this.rawProductResources = products;
    this.rawOrderResources = orders;
    this.moSubscription?.unsubscribe();
    this.moSubscription = this.mo$.subscribe(cb);
    this.projectId = projectId;

    this.setMinShipmentDateFromConfig(minShipmentDays);
    this.setAddressDefaults({ driverAddress, defaultAddress });
    this.loadMyOrders(refresh);
  }

  public loadViewOrder(cb): void {
    this.viewOrderSubscription?.unsubscribe();
    this.viewOrderSubscription = this.vo$.subscribe(cb);
    this.notifyViewOrder();
  }

  public loadOrderHistory(cb): void {
    this.orderHistorySubscription?.unsubscribe();
    this.orderHistorySubscription = this.ho$.subscribe(cb);
    this.notifyOrderHistory();
  }
  private getDefaultValues(items: ProductLineItem[]): { value: ProductLineItem[] | null; disabled: boolean } {
    if (!items || items.length === 0) {
      return { value: null, disabled: true };
    }
    const defaultValues = items?.filter(p => p.default);
    if (!defaultValues || defaultValues.length === 0) {
      return { value: null, disabled: false };
    }
    return { value: defaultValues, disabled: false };
  }

  private initOrderForm() {
    const installationPackagesMissing =
      !this.myOrders.installationPackagesOptions || !this.myOrders.installationPackagesOptions.length;
    const defaultInstallationPackage = this.myOrders.installationPackagesOptions?.find(p => p.default);
    this.myOrders.orderForm = this.fb.group({
      selectedPackage: new UntypedFormControl(
        defaultInstallationPackage || { value: null, disabled: installationPackagesMissing },
        !installationPackagesMissing ? [Validators.required] : null
      ),
      selectedProducts: new UntypedFormControl(this.myOrders.products?.find(p => p.default) || null, [
        Validators.required
      ]),
      selectedAddons: new UntypedFormControl(this.getDefaultValues(this.myOrders.packageAddons)),
      selectedAccessories: new UntypedFormControl(this.getDefaultValues(this.myOrders.accessories)),
      preferredShipmentDate: new UntypedFormControl('', [Validators.required, this.validateOnlyWeekday()]),
      selectedAddressType: new UntypedFormControl(SelectedAddressType.custom, [Validators.required]),

      addressForm: this.fb.group({
        name: new UntypedFormControl(this.myOrders.address.default.name, [CustomValidators.requiredNoEmptyWhitespace]),
        company: new UntypedFormControl(this.myOrders.address.default.company),
        phone: new UntypedFormControl(this.myOrders.address.default.phone, [
          CustomValidators.requiredNoEmptyWhitespace
        ]),
        email: new UntypedFormControl(this.myOrders.address.default.email, [
          CustomValidators.requiredNoEmptyWhitespace,
          Validators.email
        ]),
        line1: new UntypedFormControl(this.myOrders.address.default.line1, [
          CustomValidators.requiredNoEmptyWhitespace
        ]),
        line2: new UntypedFormControl(this.myOrders.address.default.line2),
        locality: new UntypedFormControl(this.myOrders.address.default.locality),
        town: new UntypedFormControl(this.myOrders.address.default.town),
        county: new UntypedFormControl(this.myOrders.address.default.county),
        country: new UntypedFormControl(this.myOrders.address.default.country, [
          CustomValidators.requiredNoEmptyWhitespace
        ]),
        countryCode: new UntypedFormControl(this.myOrders.address.default.countryCode, [
          CustomValidators.requiredNoEmptyWhitespace
        ]),
        postCode: new UntypedFormControl(this.myOrders.address.default.postCode)
      })
    });

    this.myOrders.orderForm.markAllAsTouched();
    this.subscribeToAddressTypeChange();
  }

  private subscribeToAddressTypeChange(): void {
    this.addressTypeSubscription?.unsubscribe();
    this.addressTypeSubscription = this.myOrders.orderForm.get('selectedAddressType').valueChanges.subscribe(val => {
      if (val === SelectedAddressType.custom) {
        this.myOrders.orderForm.get('addressForm').enable();
      } else {
        this.myOrders.orderForm.get('addressForm').disable();
      }
      this.mo$.next({ ...this.myOrders });
      this.myOrders.orderForm.markAllAsTouched();
    });
  }

  private setAddressDefaults({
    defaultAddress,
    driverAddress
  }: {
    driverAddress?: CustomerShippingAddress;
    defaultAddress?: ShippingAddress;
  }): void {
    this.myOrders.address.driver = {
      name:
        driverAddress?.firstName && driverAddress?.lastName
          ? `${driverAddress.firstName} ${driverAddress.lastName}`
          : '',
      phone: driverAddress?.phone ?? '',
      email: driverAddress?.email ?? '',
      line1: driverAddress?.line1 ?? '',
      line2: driverAddress?.line2 ?? '',
      locality: driverAddress?.locality ?? '',
      town: driverAddress?.town ?? '',
      county: driverAddress?.county ?? '',
      country: driverAddress?.country ?? '',
      postCode: driverAddress?.postCode ?? '',
      countryCode: driverAddress?.countryCode ?? ''
    };
    this.myOrders.address.default = {
      name: defaultAddress?.name ?? '',
      phone: defaultAddress?.phone ?? '',
      email: defaultAddress?.email ?? '',
      company: defaultAddress?.company ?? '',
      line1: defaultAddress?.line1 ?? '',
      line2: defaultAddress?.line2 ?? '',
      locality: defaultAddress?.locality ?? '',
      town: defaultAddress?.town ?? '',
      county: defaultAddress?.county ?? '',
      country: defaultAddress?.country ?? '',
      postCode: defaultAddress?.postCode ?? '',
      countryCode: defaultAddress?.countryCode ?? ''
    };
  }

  loadMyOrders(needsRefresh = false): void {
    this.transformResources();
    this.initOrderForm();
    if (needsRefresh) {
      this.notifyViewOrder();
      this.notifyOrderHistory();
    }
    this.mo$.next({ ...this.myOrders, needsRefresh });
  }

  private notifyViewOrder(optimisticUpdate = false): void {
    if (this.myOrders.orders.length) {
      const activeOrder = this.myOrders.orders.find(o => o.isActive) ?? this.myOrders.orders[0];
      // no active order set take the first
      activeOrder.isActive = true;

      const voDm: OrderViewDm = {
        id: activeOrder.id,
        isOptimistic: optimisticUpdate,
        isCancelling: false,
        i18n: this.i18n,
        summary: {
          ...activeOrder.summary,
          displayAddress: this.formatDisplayAddress(activeOrder.summary.shippingAddress)
        },
        fulfilments: activeOrder.fulfilments
          ? activeOrder.fulfilments.map(f => ({
              ...f,
              fulfilmentOrderStatusDisplay: this.getOrderPartStatus(f.fulfilmentOrderStatus),
              shipments: f.shipments.map(s => ({
                ...s,
                shippedDate: s.shippedDate
                  ? JumptechDate.from(s.shippedDate).toDateTimeFormat()
                  : f.fulfilmentOrderStatus === OrderPartStatus.PENDING_FULFILMENT
                  ? OrderPartStatus.PENDING_FULFILMENT
                  : null
              }))
            }))
          : []
      };

      this.cacheActiveOrder = { ...voDm };

      this.vo$.next(this.cacheActiveOrder);
    }
  }

  public viewOrder(order) {
    if (order.isActive) {
      return;
    }
    this.myOrders.activeOrderId = order.id;
    this.myOrders.orders = this.myOrders.orders.map(o => {
      return { ...o, isActive: o.id === order.id };
    });
    this.notifyViewOrder();
    this.notifyOrderHistory();
  }

  private notifyOrderHistory(optimisticUpdate = false): void {
    if (this.myOrders.orders.length) {
      const hoDm: OrderHistoryDm = {
        i18n: this.i18n,
        orders: [...this.myOrders.orders],
        isOptimistic: optimisticUpdate
      };
      this.ho$.next(hoDm);
    }
  }

  private formatDisplayAddress(address: ShippingAddress): string {
    const keysForDisplay = ['name', 'company', 'line1', 'line2', 'locality', 'town', 'county', 'country', 'postCode'];
    return keysForDisplay
      .reduce((prev, curr) => {
        if (address[curr]) {
          return [...prev, address[curr]];
        } else {
          return [...prev];
        }
      }, [])
      .join(', ');
  }

  private getResourceOrderStatus(status: ResourceOrderStatus): string {
    switch (status) {
      case ResourceOrderStatus.CREATED:
        return this.i18n.orderStatus.created;
      case ResourceOrderStatus.AWAITING_FULFILMENT:
        return this.i18n.orderStatus.awaitingFulfilment;
      case ResourceOrderStatus.CANCELLATION_REQUESTED:
        return this.i18n.orderStatus.cancellationRequested;
      case ResourceOrderStatus.CANCELLED:
        return this.i18n.orderStatus.cancelled;
      case ResourceOrderStatus.FAILED:
        return this.i18n.orderStatus.failed;
      case ResourceOrderStatus.SHIPPING:
        return this.i18n.orderStatus.shipping;
      case ResourceOrderStatus.COMPLETED:
        return this.i18n.orderStatus.completed;
      case ResourceOrderStatus.AWAITING_UPDATE:
        return this.i18n.orderStatus.awaitingUpdate;
      default:
        if (!environment.production) {
          console.log('Unexpected Resource Order Status: ', status);
        }
        return this.i18n.orderStatus.unexpectedStatus;
    }
  }

  private getOrderPartStatus(status: OrderPartStatus): string {
    switch (status) {
      case OrderPartStatus.PENDING_APPROVAL:
        return this.i18n.partStatus.pendingApproval;
      case OrderPartStatus.PENDING_FULFILMENT:
        return this.i18n.partStatus.pendingFulfilment;
      case OrderPartStatus.PARTIALLY_FULFILLED:
        return this.i18n.partStatus.partiallyFulfilled;
      case OrderPartStatus.SHIPPING:
        return this.i18n.partStatus.shipping;
      case OrderPartStatus.SHIPPED:
        return this.i18n.partStatus.shipped;
      case OrderPartStatus.CANCELLED:
        return this.i18n.partStatus.cancelled;
      case OrderPartStatus.FAILED:
        return this.i18n.partStatus.failed;
      default:
        if (!environment.production) {
          console.log('Unexpected Order Part Status: ', status);
        }
        return this.i18n.orderStatus.unexpectedStatus;
    }
  }

  private payloadToOrderResource(payload: CreateOrderDto): OrderResource {
    return {
      id: TEMP_ORDER_ID,
      type: 'order',
      summary: {
        orderStatus: ResourceOrderStatus.AWAITING_UPDATE,
        orderStatusDisplay: this.getResourceOrderStatus(ResourceOrderStatus.AWAITING_UPDATE),
        createdOn: new Date().getTime(),
        items: [...payload.items],
        requestedShippingDate: payload.requestedShippingDate,
        shippingAddress: payload.shippingAddress
      }
    };
  }

  private optimisticUpdateOrder(payload: CreateOrderDto): void {
    const tempOrderResource = this.payloadToOrderResource(payload);
    this.rawOrderResources.unshift(tempOrderResource);
    this.myOrders.activeOrderId = tempOrderResource.id;
    this.transformResources();
    this.mo$.next({ ...this.myOrders, isProcessingOrder: false, canReorder: false });
    clearTimeout(this.updateOrderTimeout);
    this.updateOrderTimeout = setTimeout(() => {
      this.notifyViewOrder(true);
      this.notifyOrderHistory(true);
    });
  }

  private optimisticCancelOrder(id: string): void {
    const status = this.getStatusCancelOrderPayload();
    this.myOrders.orders = this.myOrders.orders.map((o: Order) => {
      if (o.id === id) {
        return {
          ...o,
          summary: {
            ...o.summary,
            orderStatus: status,
            orderStatusDisplay: this.getResourceOrderStatus(status)
          }
        };
      } else {
        return o;
      }
    });

    this.mo$.next({ ...this.myOrders, isProcessingOrder: false });
    clearTimeout(this.cancelOrderTimeout);
    this.cancelOrderTimeout = setTimeout(() => {
      this.notifyViewOrder();
      this.notifyOrderHistory();
    });
  }

  private undoOptimisticOrderUpdate(): void {
    this.rawOrderResources = this.rawOrderResources.filter(res => res.id !== TEMP_ORDER_ID);
    this.transformResources();
  }

  startNewOrder(): void {
    this.myOrders = { ...this.myOrders, showOrderForm: true };
    this.mo$.next(this.myOrders);
  }

  stopNewOrder(): void {
    this.myOrders = { ...this.myOrders, showOrderForm: false };
    this.mo$.next(this.myOrders);
  }

  public async createOrder(): Promise<void> {
    try {
      this.mo$.next({ ...this.myOrders, isProcessingOrder: true, canReorder: false });
      const payload: CreateOrderDto = this.parseOrderFormToPayload();
      await this.createNewOrder(payload);
      this.optimisticUpdateOrder(payload);
      const selectedAddressType = this.myOrders.orderForm.get('selectedAddressType').value;
      await this.updateProject({ data: { selectedAddressType } });
      this.toasterService.pop('success', this.i18n.toast.createSuccessTitle, this.i18n.toast.createSuccessMessage);
    } catch (e) {
      this.handleError(ErrorType.createOrder, e);
    }
  }

  private async createNewOrder(payload) {
    return await this.gateway.post(`${environment.apiProjectUrl}/${this.projectId}/resources/order`, payload, {});
  }

  private async updateProject(payload) {
    return this.gateway.post(`${environment.apiProjectUrl}/${this.projectId}`, payload, {});
  }

  public async cancelOrder(id: string): Promise<void> {
    try {
      const modalRef = this.modalService.open(ConfirmModalComponent, { size: 'md' });
      modalRef.componentInstance.config = {
        title: this.i18n.modal.confirmCancelTitle,
        messages: [this.i18n.modal.confirmCancelMessage],
        confirm: this.i18n.modal.confirmCancelActionConfirm,
        cancel: this.i18n.modal.confirmCancelActionClose
      };
      modalRef.result
        .then(async () => {
          this.vo$.next({ ...this.cacheActiveOrder, isCancelling: true, isOptimistic: true });
          this.notifyOrderHistory();
          await this.cancelOpenOrder(id);
          this.optimisticCancelOrder(id);
          this.toasterService.pop('success', this.i18n.toast.cancelSuccessTitle, this.i18n.toast.cancelSuccessMessage);
        })
        .catch(err => {
          // confirm rejected do nothing
          if (err) {
            // if we have an error then handle it
            this.handleError(ErrorType.cancelOrder, err);
          }
        });
    } catch (e) {
      this.handleError(ErrorType.cancelOrder, e);
    }
  }
  private getStatusCancelOrderPayload() {
    const isSuperAdmin = this.userService.currentUser?.isSuperAdmin;
    const statusPayload = !isSuperAdmin ? ResourceOrderStatus.CANCELLATION_REQUESTED : ResourceOrderStatus.CANCELLED;
    return statusPayload;
  }

  private async cancelOpenOrder(id: string) {
    const payload: CancelOrderDto = { orderStatus: this.getStatusCancelOrderPayload() };
    return await this.gateway.post(`${environment.apiProjectUrl}/${this.projectId}/resources/order/${id}`, payload, {});
  }

  private getShippingAddress(): ShippingAddress {
    const shipTo = this.myOrders.orderForm.get('selectedAddressType').value;
    let address: ShippingAddress;

    if (shipTo === SelectedAddressType.driver) {
      address = { ...this.myOrders.address.driver };
    }

    if (shipTo === SelectedAddressType.custom) {
      address = { ...this.myOrders.orderForm.get('addressForm').value };
    }
    // remove empty values from address
    return Object.keys(address)
      .filter(k => address[k] !== '')
      .reduce((a, k) => ({ ...a, [k]: address[k] }), {} as ShippingAddress);
  }

  private getOrderItems(): OrderLineItem[] {
    const product = this.myOrders.orderForm.get('selectedProducts').value ?? null;
    const accessories = this.myOrders.orderForm.get('selectedAccessories').value ?? [];
    const installation = this.myOrders.orderForm.get('selectedPackage').value ?? null;
    const addons = this.myOrders.orderForm.get('selectedAddons').value ?? null;
    const orderStationProduct: OrderLineItem = {
      category: ProductCategory.Station,
      productId: product.id,
      productCode: product.code,
      productName: product.name,
      productDescription: product.description,
      qty: 1
    };

    if (product.metadata) {
      orderStationProduct.productMetadata = product.metadata;
    }

    const orderAccessories = accessories.map(a => {
      const accessoryLineItem: OrderLineItem = {
        category: ProductCategory.Fru,
        productId: a.id,
        productCode: a.code,
        productName: a.name,
        productDescription: a.description,
        qty: 1
      };

      if (a.metadata) {
        accessoryLineItem.productMetadata = a.metadata;
      }
      return accessoryLineItem;
    });

    const items: OrderLineItem[] = [orderStationProduct, ...orderAccessories];

    if (installation) {
      const installationLineItem: OrderLineItem = {
        category: ProductCategory.Installation,
        productId: installation.id,
        productCode: installation.code,
        productName: installation.name,
        productDescription: installation.description,
        qty: 1
      };
      if (installation.metadata) {
        installationLineItem.productMetadata = installation.metadata;
      }
      items.unshift(installationLineItem);
    }
    if (addons) {
      const addOnsLineItems: OrderLineItem[] = addons.map(a => {
        const addonsLineItem: OrderLineItem = {
          category: ProductCategory.Installation,
          productId: a.id,
          productCode: a.code,
          productName: a.name,
          productDescription: a.description,
          qty: 1
        };
        if (a.metadata) {
          addonsLineItem.productMetadata = a.metadata;
        }
        return addonsLineItem;
      });
      items.push(...addOnsLineItems);
    }

    return items;
  }

  private parseOrderFormToPayload(): CreateOrderDto {
    const shipNgbDate = this.myOrders.orderForm.get('preferredShipmentDate').value;
    const shipDate = JumptechDate.from({ ...shipNgbDate, hour: 12 }).toIso();
    const shippingAddress = this.getShippingAddress();

    const items = this.getOrderItems();

    return {
      requestedShippingDate: shipDate,
      shippingAddress,
      items
    };
  }

  private validateOnlyWeekday(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const ngbDate = control.value;
        return JumptechDate.from(ngbDate).isWeekend() ? { weekdayOnly: { value: control.value } } : null;
      }
    };
  }

  private transformResources(): void {
    // get default installation package for display only (disabled)

    const installation = this.rawProductResources.find(
      (res: ProductResource) => res.type === ProductCategory.Installation
    );
    const installationOptions = installation?.options;
    const extras = installation?.extras;
    let installationPackage;
    if (installationOptions) {
      // try for a default, fall back to first item
      installationPackage = installationOptions.find((line: ProductLineItem) => line.default)
        ? installationOptions.find((line: ProductLineItem) => line.default)
        : installationOptions[0];
    }

    this.myOrders.installationPackage = installationPackage;
    this.myOrders.packageName = installationPackage?.name;
    this.myOrders.packageId = installationPackage?.id;
    this.myOrders.installationPackagesOptions = installationOptions;
    this.myOrders.packageAddons = extras || [];
    // create list for products
    const productOptions: ProductLineItem[] = this.rawProductResources.find(
      (res: ProductResource) => res.type === ProductCategory.Station
    )?.options;
    // create list for accessories
    const accessories: ProductLineItem[] = this.rawProductResources.find(
      (res: ProductResource) => res.type === ProductCategory.Fru
    )?.options;

    if (!productOptions || !accessories) {
      return this.handleError(
        ErrorType.missingProducts,
        'Missing products or accessories list please contact support.'
      );
    }

    const orders: Order[] = this.rawOrderResources
      .sort((o1: OrderResource, o2: OrderResource) => o2.summary.createdOn - o1.summary.createdOn) // date desc
      .map(
        (order: OrderResource): Order => ({
          ...order,
          isActive: this.myOrders.activeOrderId === order.id,
          id: order.id ?? 'Pending',
          summary: {
            ...order.summary,
            orderStatusDisplay: this.getResourceOrderStatus(order.summary.orderStatus),
            itemNameList: order.summary.items
              .filter(x => x.category !== ProductCategory.Installation)
              .map(itm => itm.productName)
              .join(', \n'),
            createdOnDisplay: JumptechDate.from(order.summary.createdOn).toDateTimeFormat(),
            requestedShippingDate: JumptechDate.from(order.summary.requestedShippingDate).toDateFormat()
          }
        })
      );
    let canReorder = false;
    if (orders.length) {
      // check most recent order
      canReorder = ALLOW_ORDER_STATUS_LIST.includes(orders[0].summary.orderStatus);
    }

    this.myOrders.products = [...productOptions];
    this.myOrders.accessories = [...accessories];
    this.myOrders.orders = [...orders];
    this.myOrders.canReorder = canReorder;
    this.myOrders.showOrderForm = !orders.length;
  }

  private handleError(type: ErrorType | unknown, e): void {
    if (!environment.production) {
      console.error(e);
    }
    switch (type) {
      case ErrorType.createOrder:
        this.toasterService.pop('error', this.i18n.toast.createErrorTitle, this.i18n.toast.createErrorMessage);
        this.undoOptimisticOrderUpdate();
        this.mo$.next({ ...this.myOrders, isProcessingOrder: false });
        break;
      case ErrorType.cancelOrder:
        this.toasterService.pop('error', this.i18n.toast.cancelErrorTitle, this.i18n.toast.cancelErrorMessage);
        this.mo$.next({ ...this.myOrders, isProcessingOrder: false });
        this.vo$.next({ ...this.cacheActiveOrder, isCancelling: false, isOptimistic: false });
        this.notifyOrderHistory();
        break;
      case ErrorType.missingProducts:
        this.toasterService.pop(
          'error',
          this.i18n.toast.missingProductsErrorTitle,
          this.i18n.toast.missingProductsErrorMessage
        );
        break;
      default:
        // todo handle unknown errors
        console.log('TODO: handle error unknown', e);
    }
  }

  cleanUp(): void {
    this.orderHistorySubscription?.unsubscribe();
    this.viewOrderSubscription?.unsubscribe();
    this.moSubscription?.unsubscribe();
    this.addressTypeSubscription?.unsubscribe();
  }

  private initDefaults(): void {
    this.myOrders = {
      installationPackage: null,
      installationPackagesOptions: [],
      packageAddons: [],
      packageName: '',
      packageId: '',
      minShipmentDate: JumptechDate.now().plus({ days: 3 }, { businessDays: true }).toDateObject() as NgbDateStruct,
      products: [],
      accessories: [],
      orders: [],
      address: {
        driver: {
          name: '',
          phone: '',
          email: '',
          line1: '',
          line2: '',
          locality: '',
          town: '',
          county: '',
          country: '',
          postCode: '',
          countryCode: ''
        },
        tenant: {
          name: '',
          phone: '',
          email: '',
          company: '',
          line1: '',
          line2: '',
          locality: '',
          town: '',
          county: '',
          country: '',
          postCode: '',
          countryCode: ''
        }
      },
      i18n: this.i18n,
      driverName: '',
      isLoading: false,
      isProcessingOrder: false,
      activeOrderId: null,
      needsRefresh: false,
      orderForm: null,
      canReorder: false,
      showOrderForm: true
    };
  }

  private setMinShipmentDateFromConfig(minShipmentDays = this.defaultMinShipmentDays): void {
    if (isNaN(minShipmentDays)) {
      minShipmentDays = this.defaultMinShipmentDays;
    } else {
      minShipmentDays = minShipmentDays ?? this.defaultMinShipmentDays;
    }
    this.myOrders.minShipmentDate = JumptechDate.now()
      .plus({ days: minShipmentDays }, { businessDays: true })
      .toDateObject() as NgbDateStruct;
    this.i18n.validation.minShipDate = this.i18n.validation.minShipDate.replace(
      '%minShipmentDays%',
      String(minShipmentDays)
    );
  }

  private initI18ns(): void {
    // titles
    this.i18n.title.createOrder = this.i18nService.translate('hardwareOrdering.title.createOrder');
    this.i18n.title.shipping = this.i18nService.translate('hardwareOrdering.title.shipping');
    this.i18n.title.orders = this.i18nService.translate('hardwareOrdering.title.orders');
    this.i18n.title.hardware = this.i18nService.translate('hardwareOrdering.title.hardware');
    this.i18n.title.history = this.i18nService.translate('hardwareOrdering.title.history');
    this.i18n.title.contactDetails = this.i18nService.translate('hardwareOrdering.title.contactDetails');
    this.i18n.title.address = this.i18nService.translate('hardwareOrdering.title.address');
    // table
    this.i18n.table.created = this.i18nService.translate('hardwareOrdering.table.created');
    this.i18n.table.items = this.i18nService.translate('hardwareOrdering.table.items');
    this.i18n.table.shippingDate = this.i18nService.translate('hardwareOrdering.table.shippingDate');
    this.i18n.table.status = this.i18nService.translate('hardwareOrdering.table.status');
    // messages
    this.i18n.message.ordersEmpty = this.i18nService.translate('hardwareOrdering.message.ordersEmpty');
    this.i18n.message.orderNotFulfilled = this.i18nService.translate('hardwareOrdering.message.orderNotFulfilled');
    this.i18n.message.pendingConfirmation = this.i18nService.translate('hardwareOrdering.message.pendingConfirmation');
    // validation
    this.i18n.validation.optional = this.i18nService.translate('hardwareOrdering.validation.optional');
    this.i18n.validation.required = this.i18nService.translate('hardwareOrdering.validation.required');
    this.i18n.validation.weekday = this.i18nService.translate('hardwareOrdering.validation.weekday');
    this.i18n.validation.minShipDate = this.i18nService.translate('hardwareOrdering.validation.minShipDate');
    this.i18n.validation.invalidDate = this.i18nService.translate('hardwareOrdering.validation.invalidDate');
    this.i18n.validation.invalidEmail = this.i18nService.translate('hardwareOrdering.validation.invalidEmail');
    this.i18n.validation.countryCode = this.i18nService.translate('hardwareOrdering.validation.countryCode');
    // placeholders
    this.i18n.placeholder.selectProducts = this.i18nService.translate('hardwareOrdering.placeholder.selectProducts');
    this.i18n.placeholder.noInstallationPackages = this.i18nService.translate(
      'hardwareOrdering.placeholder.noInstallationPackages'
    );
    this.i18n.placeholder.selectAccessories = this.i18nService.translate(
      'hardwareOrdering.placeholder.selectAccessories'
    );
    this.i18n.placeholder.selectAddons = this.i18nService.translate('hardwareOrdering.placeholder.selectAddons');
    // labels
    this.i18n.label.package = this.i18nService.translate('hardwareOrdering.label.package');
    this.i18n.label.products = this.i18nService.translate('hardwareOrdering.label.products');
    this.i18n.label.accessories = this.i18nService.translate('hardwareOrdering.label.accessories');
    this.i18n.label.freeOfCharge = this.i18nService.translate('hardwareOrdering.label.freeOfCharge');
    this.i18n.label.preferredShipmentDate = this.i18nService.translate('hardwareOrdering.label.preferredShipmentDate');
    this.i18n.label.customAddress = this.i18nService.translate('hardwareOrdering.label.customAddress');
    this.i18n.label.driverAddress = this.i18nService.translate('hardwareOrdering.label.driverAddress');
    this.i18n.label.name = this.i18nService.translate('hardwareOrdering.label.name');
    this.i18n.label.company = this.i18nService.translate('hardwareOrdering.label.company');
    this.i18n.label.phone = this.i18nService.translate('hardwareOrdering.label.phone');
    this.i18n.label.email = this.i18nService.translate('hardwareOrdering.label.email');
    this.i18n.label.line1 = this.i18nService.translate('hardwareOrdering.label.line1');
    this.i18n.label.line2 = this.i18nService.translate('hardwareOrdering.label.line2');
    this.i18n.label.town = this.i18nService.translate('hardwareOrdering.label.town');
    this.i18n.label.locality = this.i18nService.translate('hardwareOrdering.label.locality');
    this.i18n.label.county = this.i18nService.translate('hardwareOrdering.label.county');
    this.i18n.label.country = this.i18nService.translate('hardwareOrdering.label.country');
    this.i18n.label.postcode = this.i18nService.translate('hardwareOrdering.label.postcode');
    this.i18n.label.productDetails = this.i18nService.translate('hardwareOrdering.label.productDetails');
    this.i18n.label.accessoriesDetails = this.i18nService.translate('hardwareOrdering.label.accessoriesDetails');
    this.i18n.label.shippingAddress = this.i18nService.translate('hardwareOrdering.label.shippingAddress');
    this.i18n.label.shippedDate = this.i18nService.translate('hardwareOrdering.label.shippedDate');
    this.i18n.label.productsOrdered = this.i18nService.translate('hardwareOrdering.label.productsOrdered');
    this.i18n.label.hardware = this.i18nService.translate('hardwareOrdering.label.hardware');
    this.i18n.label.stationSerialNumber = this.i18nService.translate('hardwareOrdering.label.stationSerialNumber');
    this.i18n.label.accessoriesOrdered = this.i18nService.translate('hardwareOrdering.label.accessoriesOrdered');
    this.i18n.label.orderNumber = this.i18nService.translate('hardwareOrdering.label.orderNumber');
    this.i18n.label.tracking = this.i18nService.translate('hardwareOrdering.label.tracking');
    this.i18n.label.carrier = this.i18nService.translate('hardwareOrdering.label.carrier');
    this.i18n.label.serialNumber = this.i18nService.translate('hardwareOrdering.label.serialNumber');
    this.i18n.label.orderStatus = this.i18nService.translate('hardwareOrdering.label.orderStatus');
    this.i18n.label.summary = this.i18nService.translate('hardwareOrdering.label.summary');
    this.i18n.label.fulfilment = this.i18nService.translate('hardwareOrdering.label.fulfilment');
    this.i18n.label.fulfilmentPart = this.i18nService.translate('hardwareOrdering.label.fulfilmentPart');
    this.i18n.label.items = this.i18nService.translate('hardwareOrdering.label.items');
    this.i18n.label.externalError = this.i18nService.translate('hardwareOrdering.label.externalError');
    this.i18n.label.packageAddons = this.i18nService.translate('hardwareOrdering.label.packageAddons');
    this.i18n.label.selectPackage = this.i18nService.translate('hardwareOrdering.label.selectPackage');
    // toasts
    this.i18n.toast.createSuccessTitle = this.i18nService.translate('hardwareOrdering.toast.createSuccess.title');
    this.i18n.toast.createSuccessMessage = this.i18nService.translate('hardwareOrdering.toast.createSuccess.message');
    this.i18n.toast.createErrorTitle = this.i18nService.translate('hardwareOrdering.toast.createError.title');
    this.i18n.toast.createErrorMessage = this.i18nService.translate('hardwareOrdering.toast.createError.message');
    this.i18n.toast.cancelSuccessTitle = this.i18nService.translate('hardwareOrdering.toast.cancelSuccess.title');
    this.i18n.toast.cancelSuccessMessage = this.i18nService.translate('hardwareOrdering.toast.cancelSuccess.message');
    this.i18n.toast.cancelErrorTitle = this.i18nService.translate('hardwareOrdering.toast.cancelError.title');
    this.i18n.toast.cancelErrorMessage = this.i18nService.translate('hardwareOrdering.toast.cancelError.message');
    this.i18n.toast.missingProductsErrorTitle = this.i18nService.translate(
      'hardwareOrdering.toast.missingProductsError.title'
    );
    this.i18n.toast.missingProductsErrorMessage = this.i18nService.translate(
      'hardwareOrdering.toast.missingProductsError.message'
    );
    // modals
    this.i18n.modal.confirmCancelTitle = this.i18nService.translate('hardwareOrdering.modal.confirmCancel.title');
    this.i18n.modal.confirmCancelMessage = this.i18nService.translate('hardwareOrdering.modal.confirmCancel.message');
    this.i18n.modal.confirmCancelActionConfirm = this.i18nService.translate(
      'hardwareOrdering.modal.confirmCancel.actionConfirm'
    );
    this.i18n.modal.confirmCancelActionClose = this.i18nService.translate(
      'hardwareOrdering.modal.confirmCancel.actionClose'
    );
    // alerts
    this.i18n.alert.title = this.i18nService.translate('hardwareOrdering.alert.title');
    this.i18n.alert.message1 = this.i18nService.translate('hardwareOrdering.alert.message1');
    this.i18n.alert.message2 = this.i18nService.translate('hardwareOrdering.alert.message2');
    // buttons
    this.i18n.button.orderHardware = this.i18nService.translate('hardwareOrdering.button.orderHardware');
    this.i18n.button.cancelOrder = this.i18nService.translate('hardwareOrdering.button.cancelOrder');
    this.i18n.button.startReorder = this.i18nService.translate('hardwareOrdering.button.startReorder');
    this.i18n.button.stopReorder = this.i18nService.translate('hardwareOrdering.button.stopReorder');
    // placeholders
    this.i18n.placeholder.shipmentDate = JumptechDate.asFormat('Date');
    // resource order status
    this.i18n.orderStatus.created = this.i18nService.translate('hardwareOrdering.orderStatus.created');
    this.i18n.orderStatus.awaitingFulfilment = this.i18nService.translate(
      'hardwareOrdering.orderStatus.awaitingFulfilment'
    );
    this.i18n.orderStatus.failed = this.i18nService.translate('hardwareOrdering.orderStatus.failed');
    this.i18n.orderStatus.cancellationRequested = this.i18nService.translate(
      'hardwareOrdering.orderStatus.cancellationRequested'
    );
    this.i18n.orderStatus.cancelled = this.i18nService.translate('hardwareOrdering.orderStatus.cancelled');
    this.i18n.orderStatus.shipping = this.i18nService.translate('hardwareOrdering.orderStatus.shipping');
    this.i18n.orderStatus.completed = this.i18nService.translate('hardwareOrdering.orderStatus.completed');
    this.i18n.orderStatus.awaitingUpdate = this.i18nService.translate('hardwareOrdering.orderStatus.awaitingUpdate');
    this.i18n.orderStatus.unexpectedStatus = this.i18nService.translate(
      'hardwareOrdering.orderStatus.unexpectedStatus'
    );
    // order part status
    this.i18n.partStatus.pendingApproval = this.i18nService.translate('hardwareOrdering.partStatus.pendingApproval');
    this.i18n.partStatus.pendingFulfilment = this.i18nService.translate(
      'hardwareOrdering.partStatus.pendingFulfilment'
    );
    this.i18n.partStatus.partiallyFulfilled = this.i18nService.translate(
      'hardwareOrdering.partStatus.partiallyFulfilled'
    );
    this.i18n.partStatus.failed = this.i18nService.translate('hardwareOrdering.partStatus.failed');
    this.i18n.partStatus.cancelled = this.i18nService.translate('hardwareOrdering.partStatus.cancelled');
    this.i18n.partStatus.shipping = this.i18nService.translate('hardwareOrdering.partStatus.shipping');
    this.i18n.partStatus.shipped = this.i18nService.translate('hardwareOrdering.partStatus.shipped');
  }
}
