import { ChangeDetectionStrategy, Component, inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormUpdate, IQuestionComponent } from '../question.model';
import { BehaviorSubject } from 'rxjs';
import { ModalPresenter } from './modal.presenter';
import { AsyncPipe, NgIf } from '@angular/common';
import { QUESTIONS } from '../questions.token';
import { ModalQuestionParams, ModalQuestionViewModel } from './modal.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
    selector: 'question-modal',
    templateUrl: './modal.component.html',
    imports: [NgIf, AsyncPipe, ReactiveFormsModule, CoreComponentsAngularModule],
    providers: [ModalPresenter, { provide: QUESTIONS, useExisting: ModalComponent, multi: true }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, IQuestionComponent {
  private readonly presenter = inject(ModalPresenter);

  public readonly modalPending$ = new BehaviorSubject<boolean>(true);
  public readonly vm$: BehaviorSubject<ModalQuestionViewModel | null> =
    new BehaviorSubject<ModalQuestionViewModel | null>(null);

  @Input() params: ModalQuestionParams;
  @Output() formChange: EventEmitter<FormUpdate> = new EventEmitter<FormUpdate>();

  ngOnInit(): void {
    this.presenter.load(this.vm$, this.params, this.formChange);
  }

  acceptModal() {
    this.vm$.value?.form?.get(this.params.key)?.setValue(true);
    this.modalPending$.next(false);
  }
}
