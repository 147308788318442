import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ListLayoutComponent } from '../../../../../../core/cardLayouts/list-layout.component';
import { MultipleSelectionDropdownComponent } from '../../../../../../shared/form-components/multiple-selection-dropdown.component';
import { FormErrorComponent } from '../../../../../../shared/form-error/form-error.component';
import { IStandardDataVm } from '../../../domain';
import { StandardQuotePresenter } from './standard-quote.presenter';

@Component({
    selector: 'app-standard-quote-component',
    templateUrl: './standard-quote.component.html',
    styleUrls: ['./standard-quote.component.scss'],
    imports: [
        NgIf,
        FormErrorComponent,
        MultipleSelectionDropdownComponent,
        ListLayoutComponent,
        CoreComponentsAngularModule,
        NgxSpinnerModule
    ],
    providers: [StandardQuotePresenter]
})
export class StandardQuoteComponent implements OnInit {
  vm: IStandardDataVm;

  constructor(private presenter: StandardQuotePresenter) {}

  async ngOnInit() {
    await this.presenter.load(async generatedModel => {
      this.vm = generatedModel;
    });
  }

  async changeName(name: string) {
    this.presenter.changeName(name);
  }

  async changeProjectType(projectType: string[]) {
    const newProjectType = projectType.length ? projectType[0] : null;
    await this.presenter.changeProjectType(newProjectType);
  }

  async changeData(value) {
    this.vm.formStatus = value.formStatus;
    await this.presenter.changeData(value);
  }

  async actionClick() {
    await this.presenter.actionClicked(this.vm);
  }

  closeModal() {
    this.presenter.closeModal();
  }
}
