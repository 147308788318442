import { Component, computed, input } from '@angular/core';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf } from '@angular/common';
import { I18nKeys } from '@jump-tech-frontend/app-config';
import { getCommonsTranslation } from '../utils/question-utils';

@Component({
  selector: 'question-hint',
  template: `
    <div *ngIf="question().hint && question().permanentHint">
      <jui-alert-block size="md" color="low" [iconName]="icon()">
        <span [innerHtml]="renderHint()"></span>
      </jui-alert-block>
    </div>
  `,
  imports: [NgIf, CoreComponentsAngularModule]
})
export class QuestionHintComponent {
  question = input.required<QuestionBase<string>>();
  i18ns = input<I18nKeys>();
  icon = computed(() => this.question().icon || 'info');

  renderHint = computed(() => {
    if (this.question().useI18ns && this.i18ns()) {
      const key = this.question().hint as string as keyof I18nKeys;
      const hint = getCommonsTranslation(this.i18ns(), key);
      return hint;
    } else {
      return this.question().hint;
    }
  });
}
