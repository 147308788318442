import { Injectable } from '@angular/core';
import { Project } from '../../core/domain/project';
import { LayoutComponentVm } from './layout-component.vm';
import { BehaviorSubject } from 'rxjs';
import { DisplayTabLayout } from '../project-detail.component';

@Injectable()
export class LayoutComponentPresenter {
  load(
    args: { project: Project; readonly: boolean; tabLayout: DisplayTabLayout },
    vm: BehaviorSubject<LayoutComponentVm>,
    narrowDisplay: boolean
  ): void {
    const { project, readonly, tabLayout } = args;

    this.setMinimumQuoteToPositiveNumber(tabLayout);
    const layoutColumns = [[]];
    if (!narrowDisplay) {
      layoutColumns.push([]);
      for (let i = 0; i < tabLayout.layouts.length; i++) {
        if (i % 2 === 0) {
          layoutColumns[0].push(tabLayout.layouts[i]);
        } else {
          layoutColumns[1].push(tabLayout.layouts[i]);
        }
      }
    } else {
      layoutColumns[0].push(...tabLayout.layouts);
    }

    vm.next({
      project: project,
      readOnly: readonly,
      tabLayout: tabLayout,
      layoutColumns,
      narrowDisplay,
      showFallback: !tabLayout.layouts.length && !!tabLayout.fallbackMessage
    });
  }

  unload(vm: BehaviorSubject<LayoutComponentVm>): void {
    vm.next(null);
  }

  setMinimumQuoteToPositiveNumber(tabLayout: DisplayTabLayout) {
    if (tabLayout?.tabName === 'Quote') {
      for (const item of tabLayout.layouts) {
        for (const layout of item.layouts) {
          layout.items.map(layout => {
            if (layout.editConfig?.type === 'number' || layout.editConfig?.controlType === 'MultiDropdownQuestion') {
              layout.editConfig.min = 0;
            }
          });
        }
      }
    }
  }
}
