import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Project } from '../../core/domain/project';
import { HttpGateway } from '../../core/http-gateway.service';
import { ConfirmModalComponent } from '../../shared/modals/confirm-modal.component';
import GetLegacyEnaDataTestStub from '../../TestTools/stubs/GetLegacyEnaDataTestStub';
import {
  LegacyEnaActionsDm,
  LegacyEnaDm,
  LegacyEnaDto,
  LegacyEnaErrorDm,
  LegacyEnaErrorType,
  LegacyEnaI18n,
  LegacyEnaStatusType
} from './legacy-ena.model';
import { ProjectCardLayout } from '../../core/domain/project-card-layout';
import { DNO_DEMAND_SUMMARY_PROJECT_CARD_LAYOUT } from './constants';
import { ProjectDetailEventsService, ProjectDetailEventType } from '../services/project-detail-events.service';

@Injectable({ providedIn: 'root' })
export class LegacyEnaRepository {
  legacyEnaSubscription: Subscription;
  legacyEna$: Subject<LegacyEnaDm>;

  legacyEnaErrorsSubscription: Subscription;
  legacyEnaErrors$: Subject<LegacyEnaErrorDm>;

  legacyEnaActionsSubscription: Subscription;
  legacyEnaActions$: Subject<LegacyEnaActionsDm>;

  i18n: LegacyEnaI18n = {};
  defaultDm: LegacyEnaDm = {
    showRetry: false,
    applicationStatus: '',
    data: null,
    applicationId: '',
    externalDno: '',
    i18ns: this.i18n,
    approveInProgress: false,
    rejectInProgress: false,
    resubmitInProgress: false,
    loading: false,
    project: null,
    layout: null
  };
  project: Project;
  actionsState: LegacyEnaActionsDm = null;
  layout: ProjectCardLayout;

  cache: LegacyEnaDm = { ...this.defaultDm };

  // TESTING
  useMocks = false;

  constructor(
    private gateway: HttpGateway,
    private i18nService: TranslocoService,
    private spinnerService: NgxSpinnerService,
    private modalService: NgbModal,
    private projectDetailEventsService: ProjectDetailEventsService
  ) {
    this.init();
  }

  init(): void {
    this.legacyEna$ = new Subject<LegacyEnaDm>();
    this.legacyEnaErrors$ = new Subject<LegacyEnaErrorDm>();
    this.legacyEnaActions$ = new Subject<LegacyEnaActionsDm>();

    this.initI18ns();

    this.actionsState = {
      resubmitInProgress: false,
      rejectInProgress: false,
      approveInProgress: false,
      displayManuallyApprove: false,
      displayManuallyReject: false,
      displayResubmit: false,
      disableManuallyApprove: false,
      disableManuallyReject: false,
      disableResubmit: false,
      i18nResubmitLabel: this.i18n.resubmitApplication,
      i18nManuallyApproveLabel: this.i18n.manuallyApprove,
      i18nManuallyRejectLabel: this.i18n.manuallyReject
    };
  }

  load(project: Project, cb: (...args: unknown[]) => void): void {
    this.legacyEnaSubscription?.unsubscribe();
    this.legacyEnaSubscription = this.legacyEna$.subscribe(cb);

    this.project = project;
    this.layout = this.constructProjectCardLayout();

    this.spinnerService.show('legacyEnaApplicationSpinner').then();

    this.loadApplication().then();
  }

  private async loadApplication(showLoader = true): Promise<void> {
    let apiResponse: LegacyEnaDto;

    try {
      this.clearErrors();
      if (showLoader) {
        this.legacyEna$.next({ ...this.defaultDm, loading: true });
      }
      if (this.useMocks) {
        apiResponse = GetLegacyEnaDataTestStub('GET');
      } else {
        apiResponse = (
          await this.gateway.get(
            `${environment.apiEnaApplicationUrl}/${this.project.id}/list`,
            {
              active: true
            },
            {
              skip: 'true'
            }
          )
        )[0];
      }

      this.parseAndNotify(apiResponse);
    } catch (e) {
      this.handleError(LegacyEnaErrorType.GetApplication, e);
    }
  }

  private constructData(project: Project): Record<string, string> {
    const resource = project.resources.find(({ type }) => type === 'DnoDemandSummary');

    return resource.summary;
  }

  private parseAndNotify(dto: LegacyEnaDto): void {
    this.updateActionsForStatus(dto.status);

    const data = this.constructData(this.project);

    this.cache = {
      project: this.project,
      data,
      applicationId: dto.internalApplicationId,
      layout: this.layout,
      externalDno: data.dnoId.toUpperCase(),
      applicationStatus: dto.status,
      i18ns: this.i18n,
      loading: false,
      showRetry: false,
      rejectInProgress: false,
      approveInProgress: false,
      resubmitInProgress: false
    };

    this.legacyEna$.next(this.cache);
  }

  loadActions(cb: (...args: unknown[]) => void): void {
    this.legacyEnaActionsSubscription?.unsubscribe();
    this.legacyEnaActionsSubscription = this.legacyEnaActions$.subscribe(cb);
    this.legacyEnaActions$.next(this.actionsState);
  }

  private notifyApproveInProgress(inProgress: boolean): void {
    this.actionsState = {
      ...this.actionsState,
      approveInProgress: inProgress,
      disableManuallyApprove: inProgress,
      disableManuallyReject: inProgress,
      disableResubmit: inProgress
    };

    this.legacyEnaActions$.next(this.actionsState);
  }

  private notifyRejectInProgress(inProgress: boolean): void {
    this.actionsState = {
      ...this.actionsState,
      rejectInProgress: inProgress,
      disableManuallyApprove: inProgress,
      disableManuallyReject: inProgress,
      disableResubmit: inProgress
    };

    this.legacyEnaActions$.next(this.actionsState);
  }

  private notifyResubmitInProgress(inProgress: boolean): void {
    this.actionsState = {
      ...this.actionsState,
      resubmitInProgress: inProgress,
      disableManuallyApprove: inProgress,
      disableManuallyReject: inProgress,
      disableResubmit: inProgress
    };

    this.legacyEnaActions$.next(this.actionsState);
  }

  async manuallyApproveApplication(): Promise<void> {
    this.notifyApproveInProgress(true);

    await this.updateApplicationStatus(LegacyEnaStatusType.APPROVED);

    this.notifyApproveInProgress(false);
  }

  async manuallyRejectApplication(): Promise<void> {
    this.notifyRejectInProgress(true);

    await this.updateApplicationStatus(LegacyEnaStatusType.REJECTED);

    this.notifyRejectInProgress(false);
  }

  private async updateApplicationStatus(status: string): Promise<void> {
    try {
      if (!this.useMocks) {
        await this.gateway.put(
          `${environment.apiEnaApplicationUrl}/${this.project.id}/${this.cache.applicationId}/status`,
          {
            status
          },
          {
            skip: 'true'
          }
        );
      }

      this.cache.applicationStatus = status;

      this.legacyEna$.next(this.cache);

      this.updateActionsForStatus(status);
    } catch (e) {
      this.handleError(LegacyEnaErrorType.PutApplicationStatus, e);
    }
  }

  async resubmitApplicationDialog(): Promise<void> {
    const modalRef = this.modalService.open(ConfirmModalComponent);

    modalRef.componentInstance.config = {
      title: this.i18n.resubmitApplicationModalTitle,
      messages: [this.i18n.resubmitApplicationModalMessage],
      confirm: this.i18n.yesLabel,
      cancel: this.i18n.noLabel
    };
    modalRef.result.then(() => {
      this.resubmitApplication();
    });
  }

  async resubmitApplication(): Promise<void> {
    this.notifyResubmitInProgress(true);

    await this.convertApplication();

    this.notifyResubmitInProgress(false);
  }

  private async convertApplication(): Promise<void> {
    try {
      if (!this.useMocks) {
        await this.gateway.post(
          `${environment.apiEnaApplicationUrl}/${this.project.id}/${this.cache.applicationId}/convert`,
          null,
          {
            skip: 'true'
          }
        );
      }

      this.projectDetailEventsService.emit({ name: ProjectDetailEventType.LEGACY_ENA_RESUBMIT });
    } catch (e) {
      this.handleError(LegacyEnaErrorType.ResubmitApplication, e);
    }
  }

  private initI18ns(): void {
    this.i18n.title = this.i18nService.translate('legacyEna.title');
    this.i18n.resubmitApplication = this.i18nService.translate('legacyEna.resubmitApplication');
    this.i18n.manuallyApprove = this.i18nService.translate('legacyEna.manuallyApprove');
    this.i18n.manuallyReject = this.i18nService.translate('legacyEna.manuallyReject');
    this.i18n.retryLabel = this.i18nService.translate('legacyEna.retryLabel');
    this.i18n.retryMessage = this.i18nService.translate('legacyEna.retryMessage');
    this.i18n.resubmitApplicationModalTitle = this.i18nService.translate('legacyEna.modal.title');
    this.i18n.resubmitApplicationModalMessage = this.i18nService.translate('legacyEna.modal.message');
    this.i18n.externalDno = this.i18nService.translate('legacyEna.externalDno');
    this.i18n.readOnlyAlert = this.i18nService.translate('legacyEna.readOnlyAlert');
  }

  getErrors(cb: (...args: unknown[]) => void): void {
    this.legacyEnaErrorsSubscription = this.legacyEnaErrors$.subscribe(cb);
  }

  clearErrors(): void {
    this.legacyEnaErrors$.next({} as LegacyEnaErrorDm);
  }

  private setError(e: LegacyEnaErrorType, msg?): void {
    const message: string = msg && msg.detail ? msg.detail : this.i18nService.translate(e);
    this.legacyEnaErrors$.next({
      message,
      qaErrorMessage: 'legacyEnaErrorMessage',
      qaClearErrorsButton: 'legacyEnaClearErrorsButton'
    });
  }

  private handleError(type: LegacyEnaErrorType, e): void {
    if (!environment.production) {
      console.log('Legacy ENA Error:', e);
    }

    switch (type) {
      case LegacyEnaErrorType.GetApplication:
        this.setError(LegacyEnaErrorType.GetApplication, e.error);
        this.legacyEna$.next({ ...this.defaultDm, showRetry: true, loading: false });
        break;
      case LegacyEnaErrorType.PutApplicationStatus:
        this.setError(LegacyEnaErrorType.PutApplicationStatus, e.error);
        break;
      case LegacyEnaErrorType.ResubmitApplication:
        this.setError(LegacyEnaErrorType.ResubmitApplication, e.error);
        break;
      default:
        this.setError(LegacyEnaErrorType.Generic);
        break;
    }
  }

  private updateActionsForStatus(status: string): void {
    if (status === LegacyEnaStatusType.REJECTED) {
      this.actionsState = {
        ...this.actionsState,
        displayManuallyApprove: true,
        displayManuallyReject: false,
        displayResubmit: true
      };
    } else if (status === LegacyEnaStatusType.APPROVED) {
      this.actionsState = {
        ...this.actionsState,
        displayManuallyApprove: false,
        displayManuallyReject: false,
        displayResubmit: false
      };
    } else {
      this.actionsState = {
        ...this.actionsState,
        displayManuallyApprove: true,
        displayManuallyReject: true,
        displayResubmit: true
      };
    }

    this.legacyEnaActions$.next(this.actionsState);
  }

  async retryLoadApplication(): Promise<void> {
    await this.loadApplication(true);
  }

  private constructProjectCardLayout(): ProjectCardLayout {
    return DNO_DEMAND_SUMMARY_PROJECT_CARD_LAYOUT;
  }
}
