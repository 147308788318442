import { Component, Input } from '@angular/core';
import { ScheduleMoreInfoVm } from '../../schedule.model';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';

@Component({
    selector: 'schedule-event-more-details-job-info',
    templateUrl: 'schedule-event-more-details-job-info.component.html',
    styleUrls: ['../schedule-event-more-details/schedule-event-more-details.component.scss'],
    imports: [CoreComponentsAngularModule]
})
export class ScheduleEventMoreDetailsJobInfoComponent {
  @Input() vm: ScheduleMoreInfoVm;

  constructor() {}
}
