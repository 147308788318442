import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { VideoPlayerControlsPresenter } from './video-player-controls.presenter';
import { BehaviorSubject } from 'rxjs';
import { VideoPlayerControlsViewModel, VideoPlayerState } from './video-player-controls.model';
import { TranslocoModule } from '@ngneat/transloco';
import { VideoPlayerTimerComponent } from '../video-player-timer/video-player-timer.component';
import { waitForNavigatorPermission } from '../video-utils';

@Component({
    selector: 'question-video-player-controls',
    templateUrl: './video-player-controls.component.html',
    imports: [NgIf, AsyncPipe, CoreComponentsAngularModule, TranslocoModule, VideoPlayerTimerComponent],
    providers: [VideoPlayerControlsPresenter],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./video-player-controls.component.scss']
})
export class VideoPlayerControlsComponent implements OnInit {
  private readonly presenter = inject(VideoPlayerControlsPresenter);

  @Input() public readonly canDelete: boolean;
  @Input() public readonly canRecord: boolean;
  @Input() public readonly showRecordControls: boolean;
  @Output() public readonly startRecording = new EventEmitter<true>();
  @Output() public readonly stopRecording = new EventEmitter<true>();
  @Output() public readonly pauseRecording = new EventEmitter<true>();
  @Output() public readonly resumeRecording = new EventEmitter<true>();
  @Output() public readonly removeRecording = new EventEmitter<true>();

  public readonly vm$: BehaviorSubject<VideoPlayerControlsViewModel | null> =
    new BehaviorSubject<VideoPlayerControlsViewModel | null>(null);

  public ngOnInit() {
    this.presenter.load(this.vm$, {
      showRecordControls: this.showRecordControls,
      canDelete: this.canDelete,
      canRecord: this.canRecord
    });
  }

  protected async start() {
    const granted = await waitForNavigatorPermission(['camera', 'microphone']);
    if (granted) {
      this.presenter.enableDeletion(false);
      this.presenter.enableRecording(false);
      this.startRecording.next(true);
      this.updateState('recording');
    }
  }

  public stop() {
    this.presenter.enableDeletion(true);
    this.stopRecording.next(true);
    this.updateState('stopped');
  }

  protected pause() {
    this.pauseRecording.next(true);
    this.updateState('paused');
  }

  protected resume() {
    this.resumeRecording.next(true);
    this.updateState('recording');
  }

  protected remove() {
    this.stop();
    const confirmDeletion = confirm(this.vm$.value?.translations.confirmDeletion);
    if (confirmDeletion) {
      this.presenter.enableDeletion(false);
      this.removeRecording.next(true);
      this.presenter.enableRecording(true);
    }
  }

  private updateState(state: VideoPlayerState) {
    this.presenter.updateRecordingState(state);
  }
}
