import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'app-form-error',
    templateUrl: './form-error.component.html',
    imports: [TranslocoModule]
})
export class FormErrorComponent {
  @Input() message?: string;
  @Input() field: string;
}
