import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Project } from '../../core/domain/project';
import { LegacyEnaActionsDm, LegacyEnaActionsVm, LegacyEnaDm, LegacyEnaVm } from './legacy-ena.model';
import { LegacyEnaRepository } from './legacy-ena.repository';

@Injectable({ providedIn: 'root' })
export class LegacyEnaApplicationPresenter {
  viewModel: LegacyEnaVm;
  actionsViewModel: LegacyEnaActionsVm;

  constructor(private repository: LegacyEnaRepository) {}

  load(vmSubject$: Subject<LegacyEnaVm>, project: Project): void {
    this.repository.load(project, (dm: LegacyEnaDm) => {
      this.viewModel = {
        showLoader: dm.loading,
        showRetry: dm.showRetry,
        showRejectedStatus: dm.applicationStatus === 'REJECTED',

        project: dm.project,
        data: dm.data,
        layout: dm.layout,
        externalDno: dm.externalDno,
        status: dm.applicationStatus,

        i18nExternalDnoLabel: dm.i18ns.externalDno,
        i18nTitle: dm.i18ns.title,
        i18nRetryMessage: dm.i18ns.retryMessage,
        i18nRetryLabel: dm.i18ns.retryLabel,
        i18nReadOnlyAlert: dm.i18ns.readOnlyAlert,

        qaLegacyEnaApplicationStatus: 'qaLegacyEnaApplicationStatus',
        qaLegacyEnaApplicationExternalDno: 'qaLegacyEnaApplicationExternalDno'
      };

      vmSubject$.next(this.viewModel);
    });
  }

  loadActions(vmSubject$: Subject<LegacyEnaActionsVm>): void {
    this.repository.loadActions((dm: LegacyEnaActionsDm): void => {
      this.actionsViewModel = {
        i18nManuallyApproveLabel: dm.i18nManuallyApproveLabel,
        i18nManuallyRejectLabel: dm.i18nManuallyRejectLabel,
        i18nResubmitLabel: dm.i18nResubmitLabel,

        approveInProgress: dm.approveInProgress,
        rejectInProgress: dm.rejectInProgress,
        resubmitInProgress: dm.resubmitInProgress,

        displayManuallyReject: dm.displayManuallyReject,
        displayManuallyApprove: dm.displayManuallyApprove,
        displayResubmit: dm.displayResubmit,

        disableManuallyApprove: dm.disableManuallyApprove,
        disableManuallyReject: dm.disableManuallyReject,
        disableResubmit: dm.disableResubmit,

        qaManuallyApprove: 'qaLegacyEnaApplicationManuallyApproveButton',
        qaManuallyReject: 'qaLegacyEnaApplicationManuallyRejectButton',
        qaResubmitButton: 'qaLegacyEnaApplicationResubmitButton'
      };

      vmSubject$.next(this.actionsViewModel);
    });
  }

  async manuallyApproveApplication(): Promise<void> {
    await this.repository.manuallyApproveApplication();
  }

  async manuallyRejectApplication(): Promise<void> {
    await this.repository.manuallyRejectApplication();
  }

  async resubmitApplication(): Promise<void> {
    await this.repository.resubmitApplicationDialog();
  }

  async retryLoadApplication(): Promise<void> {
    await this.repository.retryLoadApplication();
  }
}
