import { InstallationImagesModalComponent } from '../../project-detail/installation-images-modal.component';
import { InstallationImagesModalV2Component } from '../../project-detail/installation-images-modal-v2.component';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from '../api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Directive, Input } from '@angular/core';
import { Project } from '../domain/project';
import * as url from 'url';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { RenderHelper } from '@jump-tech-frontend/angular-common';

export interface Attachment {
  name: string;
  data: string;
}

@Directive()
export abstract class AbstractLayout {
  @Input() project: Project;

  galleryImages = [];
  hasGalleryAccess = false;

  constructor(
    protected sanitizer: DomSanitizer,
    protected apiService: ApiService,
    protected featureAccessService: AccessService,
    protected modalService: NgbModal,
    private renderHelper: RenderHelper = new RenderHelper()
  ) {}

  replaceTemplateVariables(template: string, projectData: Record<string, unknown>, returnAsList?: boolean): string {
    return this.renderHelper.renderTemplate(template, projectData, { returnAsList });
  }

  protected initialiseGallery(data) {
    this.hasGalleryAccess = this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.GalleryAccess, true);
    this.galleryImages = [];
    this.processImages(data);
  }

  private processImages(data) {
    const dataKeys = Object.keys(data);
    for (const key of dataKeys) {
      if (!data[key]) {
        continue;
      }
      if (this.isGalleryImage(data[key])) {
        this.galleryImages.push(key);
      }

      if (data[key] instanceof Array && data[key].length) {
        let i = 0;
        for (const projectDataItem of data[key]) {
          if (this.isGalleryImage(projectDataItem)) {
            this.galleryImages.push(`${key}_${i}`);
            i++;
          }
        }
      }
    }
  }

  private isGalleryImage(data) {
    const isMedia =
      typeof data === 'string' &&
      (data.indexOf('https://s3') === 0 ||
        data.indexOf('data:image') === 0 ||
        data.indexOf(`https://relay-uploads`) === 0 ||
        (data.indexOf('https://api') === 0 && data.indexOf('store/') > -1));

    if (isMedia && data.includes('.mp4')) {
      return false;
    }
    return isMedia;
  }

  openImagesModal(data, currentImage: string, isGalleryV2 = false) {
    this.initialiseGallery(data);
    const modalRef = isGalleryV2
      ? this.modalService.open(InstallationImagesModalV2Component, {
          size: 'xl',
          modalDialogClass: 'gallery-v2-modal-dialog'
        })
      : this.modalService.open(InstallationImagesModalComponent, { size: 'lg' });
    modalRef.componentInstance.galleryImages = this.galleryImages;
    modalRef.componentInstance.currentImage = currentImage;
    modalRef.componentInstance.projectData = data;
  }

  isUrl(dataItem) {
    return typeof dataItem === 'string' && dataItem.indexOf('http') === 0;
  }

  getImageSrc(dataItem: string | Attachment, size = 's'): string | null {
    if (this.isAttachment(dataItem)) {
      return null; // No thumbnails for attachments
    }
    if (this.isUrl(dataItem)) {
      const urlParts = url.parse(dataItem as string);
      let urlRoot = `${dataItem}${urlParts.query ? '&' : '?'}`;
      if (size !== 'o') {
        urlRoot += `size=${size}`;
      }
      return urlRoot;
    }
    return dataItem as string;
  }

  isAttachment(dataItem) {
    return (
      dataItem &&
      typeof dataItem === 'object' &&
      Object.prototype.hasOwnProperty.call(dataItem, 'data') &&
      Object.prototype.hasOwnProperty.call(dataItem, 'name')
    );
  }
}
