import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { ApiService } from '../api.service';
import { CardControlService } from '../card-control.service';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../domain/user';
import { CountryISO, NgxIntlTelInputModule, SearchCountryField } from 'ngx-intl-tel-input';
import {
  DelegationShare,
  DelegationShareSubTenant,
  ProjectAssignmentTeam
} from '../../admin/user-management/domain/types';
import { v4 as uuidv4 } from 'uuid';
import { Project } from '../domain/project';
import * as Analytics from '../../app.analytics';
import { ProjectConfigurationService } from '../project-configuration.service';
import { AccessService, PathwayFeature } from '../../auth/services/access.service';
import { UserService } from '../../auth/services/user.service';
import { DelegationService } from '../delegate/delegation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { EditLayoutQuestionsComponent } from '../cardLayouts/edit-layout-questions.component';
import { MultipleSelectionDropdownComponent } from '../../shared/form-components/multiple-selection-dropdown.component';
import { FormErrorComponent } from '../../shared/form-error/form-error.component';
import { NgxLoadingModule } from 'ngx-loading';
import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault, UpperCasePipe } from '@angular/common';
import { catchError, throwError } from 'rxjs';
import { PhoneNumberComponent } from '../../../../../../libs/question-components/src/lib/questions/phone-number/phone-number.component';
import { FormUpdate } from '@jump-tech-frontend/question-components';

type PartialProject = Pick<Project, 'id' | 'attachments'>;

@Component({
    selector: 'app-new-project-modal',
    templateUrl: 'new-project-modal.component.html',
    styleUrls: ['new-project-modal.component.scss'],
    imports: [
        NgIf,
        NgxLoadingModule,
        ReactiveFormsModule,
        FormErrorComponent,
        NgxIntlTelInputModule,
        NgFor,
        MultipleSelectionDropdownComponent,
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault,
        EditLayoutQuestionsComponent,
        CoreComponentsAngularModule,
        NgxSpinnerModule,
        UpperCasePipe,
        TranslocoModule,
        PhoneNumberComponent
    ]
})
export class NewProjectModalComponent implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);
  partialProject: PartialProject;
  form: UntypedFormGroup;
  user: User;
  projectConfigurationTypes: { projectType: string }[];
  delegates: DelegationShare[] = [];
  assignments: ProjectAssignmentTeam[] = [];
  assignmentLabel: string;
  canDelegate = false;
  canAssign = false;
  loading = false;
  newProjectLayout: any;
  formInitialised = false;
  createInProgress = false;

  // Sub Tenants
  subTenants: DelegationShareSubTenant[] = null;
  selectDelegates: any[] = [];
  selectSubTenants: DelegationShareSubTenant[] = [];
  fullSubTenants: {
    [key: string]: DelegationShareSubTenant[];
  } = {};

  tenantConfig: any;
  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField;

  get showSubTenants() {
    return this.selectSubTenants && this.selectSubTenants.length;
  }

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private projectConfigurationService: ProjectConfigurationService,
    private cardControlService: CardControlService,
    private spinnerService: NgxSpinnerService,
    private featureAccessService: AccessService,
    private userService: UserService,
    private delegationService: DelegationService
  ) {}

  /**
   * Get the selection of Project Types available and initialise the form.
   * Subscribe to Project Type changes to update the form layout.
   */
  async ngOnInit() {
    this.partialProject = {
      id: uuidv4(),
      attachments: []
    };
    this.subscribeToUser();
    this.tenantConfig = await this.featureAccessService.getTenantConfig();
    await this.setProjectTypes();
    this.updateFormForNullProjectType();
  }

  private subscribeToUser() {
    this.userService.userObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user: User) => {
      if (user !== null) {
        this.user = user;
      }
    });
  }

  private initialiseForm(projectType: string) {
    this.form = new UntypedFormGroup({
      projectType: new UntypedFormControl(projectType, Validators.required),
      team: new UntypedFormControl(null),
      delegate: new UntypedFormControl(null, Validators.required),
      subTenants: new UntypedFormControl(null, Validators.required)
    });
    this.form
      .get('projectType')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async projectType => {
        if (projectType === null || projectType === 'null') {
          this.updateFormForNullProjectType();
        } else {
          await this.updateFormForProjectType(projectType);
        }
        await this.setDelegates(projectType);
        await this.setTeams(projectType);
      });
  }

  private updateFormForNullProjectType() {
    this.formInitialised = false;
    this.newProjectLayout = [];
    this.initialiseForm(null);
    this.formInitialised = true;
  }

  private async updateFormForProjectType(projectType: string) {
    this.formInitialised = false;
    this.canDelegate = await this.featureAccessService.isDataSharingAccessAllowedForProject(
      this.user.tenant,
      projectType
    );

    this.canAssign = await this.featureAccessService.isTeamAssignmentAllowedForProject(this.user.tenant, projectType);
    this.newProjectLayout = await this.projectConfigurationService.getNewProjectLayoutByProjectType(projectType);
    this.initialiseForm(projectType);

    const customFormGroup = await this.cardControlService.toFormGroup(this.newProjectLayout?.items);
    if (this.featureAccessService.isFeatureAccessAllowed(PathwayFeature.UserIsCustomer) && this.user) {
      const userDetailsForm = new UntypedFormBuilder().group({
        firstName: new UntypedFormControl({ value: this.firstName, disabled: true }),
        lastName: new UntypedFormControl({ value: this.lastName, disabled: true })
      });

      this.form = new UntypedFormBuilder().group({
        ...this.form.controls,
        ...customFormGroup.controls,
        ...userDetailsForm.controls
      });
    } else {
      this.form = new UntypedFormBuilder().group({
        ...this.form.controls,
        ...customFormGroup.controls
      });
    }

    this.formInitialised = true;
  }

  private get firstName() {
    return this.user.label.split(' ')[0];
  }

  private get lastName() {
    return this.user.label.split(' ')[1];
  }

  private get email() {
    return this.user.email || null;
  }

  private async setProjectTypes() {
    this.projectConfigurationTypes = await this.projectConfigurationService.getProjectTypes(
      PathwayFeature.ProjectCreation
    );
  }

  private async setDelegates(projectType: string) {
    if (this.canDelegate) {
      this.loading = true;
      this.delegates = await this.delegationService.getDelegatesForProjectType(projectType, true);
      if (!this.delegates.length) {
        this.disableDelegate();
      } else {
        this.form.get('delegate').enable();
      }
      this.loading = false;
    } else {
      this.disableDelegate();
    }
    this.onDelegateChange();
    return true;
  }

  private async setTeams(projectType: string) {
    if (this.canAssign) {
      this.loading = true;
      this.assignments = await this.featureAccessService.getTeamsForProjectType(projectType, true);
      this.assignmentLabel = await this.featureAccessService.getAssignmentLabel();
      if (!this.assignments.length) {
        this.disableTeam();
      } else {
        this.form.get('team').enable();
      }
      this.loading = false;
    } else {
      this.disableTeam();
    }
    return true;
  }

  private disableDelegate() {
    if (!this.form || !this.form.get('delegate')) {
      return;
    }
    const formControl = this.form.get('delegate');
    formControl.patchValue('');
    formControl.disable();
  }

  private disableTeam() {
    if (!this.form || !this.form.get('team')) {
      return;
    }
    const formControl = this.form.get('team');
    formControl.patchValue('');
    formControl.disable();
  }

  createProject() {
    this.createInProgress = true;
    this.spinnerService.show();
    this.apiService
      .addProject({
        ...this.partialProject,
        type: this.form.value.projectType,
        owner: this.form.value.owner || null,
        owner_name: this.form.value.owner ? this.form.value.owner.split('|')[0] : null,
        assigned_to_team: this.form.value.team ? this.form.value.team : null,
        assigned_to_team_name: this.form.value.team
          ? this.assignments.find(x => x.id === this.form.value.team).name
          : null,
        delegate: this.form.value.delegate || null,
        subTenants: this.form.value.subTenants || null,
        data: this.form.getRawValue()
      })
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(err => {
          this.spinnerService.hide();
          this.createInProgress = false;
          console.log(err);
          return throwError(() => 'Failed to create project');
        })
      )
      .subscribe(id => {
        this.activeModal.close({ id });
        this.createInProgress = false;
        this.spinnerService.hide();
        Analytics.logEvent('ProjectCreated', { projectType: this.form.value.projectType });
      });
  }

  isInvalid(field) {
    if (field === 'subTenants') {
      return this.showSubTenants && this.form.get(field).invalid;
    }

    return this.form.get(field).dirty && this.form.get(field).invalid;
  }

  private onDelegateChange() {
    const selectedDelegate = this.form.get('delegate').value;
    if (selectedDelegate === 'null') {
      this.form.controls.delegate.patchValue(null);
      this.form.controls.subTenants.patchValue(null);
    }
    this.selectDelegates = Object.assign(
      [],
      this.delegates.map(delegate => {
        return { id: delegate.tenant, name: delegate.tenant };
      })
    );
    for (const delegate of this.delegates) {
      this.fullSubTenants[delegate.tenant] = delegate.subTenants;
    }

    this.selectSubTenants = [];

    setTimeout(() => {
      this.selectSubTenants = selectedDelegate ? this.fullSubTenants[selectedDelegate] : [];
      if (this.selectSubTenants && this.selectSubTenants.length) {
        this.form.controls.subTenants.enable();
      } else {
        this.form.controls.subTenants.disable();
      }
    });

    if (!this.form || !this.form.get('projectOwner')) {
      return;
    }
    const formControl = this.form.get('delegate');
    if (formControl && formControl.value === null) {
      this.form.get('projectOwner').enable();
    } else {
      this.form.get('projectOwner').patchValue('');
      this.form.get('projectOwner').disable();
    }
  }

  setSubTenants(subTenants: any[]) {
    const delegate = this.form.get('delegate').value;
    this.form.controls.subTenants.patchValue(
      delegate && subTenants.length
        ? this.fullSubTenants[delegate].filter(subTenant => subTenants.includes(subTenant.id))
        : null
    );
    this.form.controls.subTenants.markAsDirty();
  }

  close(): void {
    this.activeModal.dismiss();
  }

  phoneNumberFieldChanged(event: FormUpdate) {
    if (typeof event.value === 'string') {
      event.value = event.value.trim();
    }
    this.form.get(event.key)?.setValue(event.value);
  }
}
