import { Injectable } from '@angular/core';
import { Project } from '../../core/domain/project';
import { LayoutComponentVm } from './layout-component.vm';
import { BehaviorSubject } from 'rxjs';
import { DisplayTabLayout } from '../project-detail.component';
import { LayoutComponentColumnsService } from './layout-component-columns.service';

@Injectable()
export class LayoutComponentPresenter {
  constructor(private layoutComponentColumnsService: LayoutComponentColumnsService) {}

  load(
    args: { project: Project; readonly: boolean; tabLayout: DisplayTabLayout },
    vm: BehaviorSubject<LayoutComponentVm>
  ): void {
    const { project, readonly, tabLayout } = args;

    this.setMinimumQuoteToPositiveNumber(tabLayout);
    vm.next({
      layoutColCount$: this.layoutComponentColumnsService.subscribe(tabLayout.layouts.length, 'project-detail-tabs'),
      project: project,
      readOnly: readonly,
      tabLayout: tabLayout,
      showFallback: !tabLayout.layouts.length && !!tabLayout.fallbackMessage
    });
  }

  unload(vm: BehaviorSubject<LayoutComponentVm>): void {
    vm.next(null);
  }

  setMinimumQuoteToPositiveNumber(tabLayout: DisplayTabLayout) {
    if (tabLayout?.tabName === 'Quote') {
      for (const item of tabLayout.layouts) {
        for (const layout of item.layouts) {
          layout.items.map(layout => {
            if (layout.editConfig?.type === 'number' || layout.editConfig?.controlType === 'MultiDropdownQuestion') {
              layout.editConfig.min = 0;
            }
          });
        }
      }
    }
  }
}
