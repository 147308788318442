<div class="my-orders" *ngIf="vm$ | async as vm">
  <!-- FORM -->
  <div
    class="card my-orders__card my-orders__card--form"
    *ngIf="vm.showOrderForm"
    [attr.data-qa]="vm.qaCreateOrderSection"
  >
    <div class="card-header my-orders__card-header">
      <span class="my-orders__card-title">{{ vm.titleCreateOrder }}</span>
      <jui-button
        *ngIf="vm.showStopReorderButton"
        size="xs"
        color="low"
        (click)="stopNewOrder()"
        [attr.data-qa]="vm.qaStopReorderButton"
      >
        {{ vm.buttonStopReorder }}
      </jui-button>
    </div>
    <div class="card-body">
      <div
        class="my-orders__error my-orders__error--top-level"
        *ngIf="vm.orderForm.get('addressForm.countryCode').getError('required')"
      >
        {{ vm.validationCountryCode }}
      </div>
      <div class="my-orders__order-form" [formGroup]="vm.orderForm" [attr.data-qa]="vm.qaOrderForm">
        <!-- PRODUCTS FORM -->
        <div class="my-orders__order-form-details" [attr.data-qa]="vm.qaOrderFormHardwareSection">
          <pathway-my-orders-products-selection [vm]="vm"></pathway-my-orders-products-selection>
        </div>

        <!-- ADDRESS FORM -->
        <div
          class="my-orders__order-form-shipping"
          *ngIf="vm.isOrderingEnabled"
          [attr.data-qa]="vm.qaOrderFormAddressSection"
        >
          <div class="my-orders__title">{{ vm.titleShipping }}</div>
          <pathway-my-orders-address-type-selection [vm]="vm"></pathway-my-orders-address-type-selection>
          <pathway-my-orders-custom-address [vm]="vm"></pathway-my-orders-custom-address>
          <pathway-my-orders-driver-address [vm]="vm"></pathway-my-orders-driver-address>
        </div>
      </div>
      <div class="my-orders__order-form-confirm" *ngIf="vm.isOrderingEnabled">
        <div class="my-orders__actions">
          <div class="my-orders__action">
            <jui-button
              [attr.data-qa]="vm.qaCreateOrderButton"
              size="sm"
              (click)="orderHardware()"
              [disabled]="vm.orderForm.invalid || vm.isProcessingOrder"
              [loading]="vm.isProcessingOrder"
              >{{ vm.buttonOrderHardware }}</jui-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ORDERS -->
  <div class="card my-orders__card" *ngIf="vm.showOrders" [attr.data-qa]="vm.qaOrdersSection">
    <div class="card-header my-orders__card-header">
      <span class="my-orders__card-title">{{ vm.titleOrders }}</span>
      <jui-button
        *ngIf="vm.showStartReorderButton"
        size="xs"
        (click)="startNewOrder()"
        [attr.data-qa]="vm.qaStartReorderButton"
      >
        {{ vm.buttonStartReorder }}
      </jui-button>
    </div>
    <div class="card-body my-orders__card-body">
      <div class="my-orders__open-order">
        <pathway-my-orders-order-view [refresh]="vm.refresh"></pathway-my-orders-order-view>
        <pathway-my-orders-order-history [refresh]="vm.refresh"></pathway-my-orders-order-history>
      </div>
    </div>
  </div>
</div>
