import { EventEmitter, Injectable } from '@angular/core';
import { EnaApplicationAttachmentsRepository } from './ena-application-attachments.repository';
import { Subject } from 'rxjs';
import { EnaAttachment, EnaAttachmentsDm, EnaAttachmentsVm } from '../ena.model';
import { FormGroup } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class EnaApplicationAttachmentsPresenter {
  constructor(private repository: EnaApplicationAttachmentsRepository) {}

  load(
    vmSubject$: Subject<EnaAttachmentsVm>,
    groupForm: FormGroup,
    attachmentCheckedEventEmitter: EventEmitter<void>
  ): void {
    this.repository.load(groupForm, attachmentCheckedEventEmitter, (dm: EnaAttachmentsDm) => {
      const vm = {
        attachments: dm.attachments.map(attachment => {
          return {
            ...attachment,
            qaEnaAttachmentCheckbox: `qaEnaAttachmentCheckbox_${attachment.fileDisplayName}`,
            qaEnaDownloadAttachmentBtn: `qaEnaDownloadAttachmentBtn_${attachment.fileDisplayName}`
          };
        }),
        selectionDisabled: dm.selectionDisabled,
        form: dm.form,
        uploadProgress: {
          ...dm.uploadProgress,
          label: `${dm.uploadProgress.fileName} (${dm.uploadProgress.roundedProgress}%)`,
          barHeight: `3px`,
          barWidth: `${dm.uploadProgress.progress}%`,
          ariaValue: `${dm.uploadProgress.roundedProgress}%`,
          isComplete: dm.uploadProgress.progress > 95
        },
        uploadInProgress: dm.uploadInProgress,
        acceptedFileTypes: dm.acceptedFileTypes,
        canUploadFiles: dm.canUploadFile,
        i18nActionsLabel: dm.i18ns.actions,
        i18nDeleteBtnLabel: dm.i18ns.delete,
        i18nDownloadBtnLabel: dm.i18ns.download,
        i18nFileNameLabel: dm.i18ns.fileName,
        i18nProjectSpecificAttachmentsLabel: dm.i18ns.projectSpecificAttachments,
        i18nUnknownLabel: dm.i18ns.unknown,
        i18nUploadDateLabel: dm.i18ns.uploadDate,
        i18nUploadedByLabel: dm.i18ns.uploadedBy,
        i18nUploadLabel: dm.i18ns.upload,
        qaUploadAttachmentBtn: 'qaEnaUploadAttachmentBtn'
      };
      vmSubject$.next(vm);
    });
  }

  async downloadFile(attachment: EnaAttachment) {
    await this.repository.downloadFile(attachment);
  }

  handleFileSelection(checked: boolean, fileName: string): void {
    this.repository.handleFileSelection(checked, fileName);
  }

  upload(): void {
    (document.querySelector(`[id="generalUpload"]`) as HTMLElement).click();
  }

  handleFileUploadEvent(fileInput: Event) {
    this.repository.validateAndUploadFile(fileInput).then();
  }
}
