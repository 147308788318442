import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { I18nKeys } from '../../domain/i18n-keys';
import { BaseQuestionComponent } from '../question.component';
import { ScrollService } from '../scroll.service';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';
import { ImageAssetComponent } from '../image-asset.component';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { QuestionLabelContentComponent } from '@jump-tech-frontend/question-components';
import { NgIf } from '@angular/common';

@Component({
    selector: `crds-question-textarea`,
    template: `
    <div *ngIf="show && form" [formGroup]="form">
      <!-- QUESTION LABEL -->
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key">
        <question-label-content [question]="question" [isInvalid]="isInvalid" [i18ns]="i18ns"></question-label-content>
      </label>

      <!-- QUESTION INPUT -->
      <textarea
        [formControlName]="question.key"
        [id]="question.key"
        rows="5"
        class="form-control mb-2"
        [attr.data-qa]="question.key + 'Input'"
        #textArea
      >
      </textarea>

      <!-- QUESTION HINT -->
      <question-hint [question]="question"></question-hint>

      <!-- QUESTION IMAGE -->
      <div class="image-wrapper">
        <crds-image-asset [images]="images" [key]="question.key"></crds-image-asset>
      </div>
    </div>
  `,
    imports: [NgIf, ReactiveFormsModule, QuestionLabelContentComponent, QuestionHintComponent, ImageAssetComponent]
})
export class TextareaQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<string>;
  @Input() i18ns: I18nKeys;
  @ViewChild('textArea') input;
  type: string;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;

  private untilDestroyed = untilDestroyed();

  constructor(private imageAssetFactory: ImageAssetFactory, private scrollService: ScrollService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    if (this.question.disabled === true && !this.show) {
      setTimeout(() => this.form?.get(this.question.key)?.disable());
    }
    this.images = this.imageAssetFactory.create(this.question.image, this.form);
    this.scrollService.scrollObservable.pipe(this.untilDestroyed()).subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
  }

  get isInvalid() {
    return this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid;
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }
}
