import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthenticationService } from '../auth/services/authentication.service';
import { PathwayConfigurationService } from '../auth/services/pathway-configuration.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  ignorePaths = ['/', '/home', '/login', '/error', '/changePassword', '/resetPassword', '/configuration'];
  ignoreTitles = ['Details', 'Loading..'];
  hostConfiguration = null;

  private key: string;
  private historySubject: BehaviorSubject<any> = new BehaviorSubject(null);

  public userHistory$: Observable<any> = this.historySubject.asObservable();

  constructor(
    private configService: PathwayConfigurationService,
    private authenticationService: AuthenticationService
  ) {}

  public set savedRoutesKey(key) {
    this.key = key;
  }

  public get savedRoutesKey() {
    return this.key;
  }

  public clearRouteHistory() {
    localStorage.removeItem(this.savedRoutesKey);
    this.historySubject.next(null);
  }

  public getRouteHistory() {
    this.savedRoutesKey = `savedRoutes_${environment.name}_${this.configService.hostConfiguration.tenant}`;
    if (
      this.savedRoutesKey &&
      JSON.parse(localStorage.getItem(this.savedRoutesKey)) &&
      JSON.parse(localStorage.getItem(this.savedRoutesKey)).length
    ) {
      this.notifyHistorySubs();
    }
  }

  public saveRouteHistory(rawRoute, title) {
    if (this.authenticationService.isUserSignedIn) {
      this.savedRoutesKey = `savedRoutes_${environment.name}_${this.configService.hostConfiguration.tenant}`;
      const route = rawRoute.split(';')[0];
      let savedRoutes = JSON.parse(localStorage.getItem(this.savedRoutesKey)) || [];
      if (this.ignorePaths.includes(route) || this.ignoreTitles.includes(title) || route.indexOf('/_callback') !== -1) {
        return false;
      }
      // save a visited route and number of hits
      if (savedRoutes.findIndex(r => r.route === route) === -1) {
        savedRoutes.unshift({ route, title, visits: 1 });
        savedRoutes = savedRoutes.slice(0, 10);
        savedRoutes.sort((a, b) => {
          return b.visits - a.visits;
        });
        localStorage.setItem(this.savedRoutesKey, JSON.stringify(savedRoutes));
      } else {
        const updatedRoutes = savedRoutes.map(x => {
          if (x.route === route) {
            return { ...x, visits: ++x.visits };
          } else {
            return x;
          }
        });
        updatedRoutes.sort((a, b) => {
          return b.visits - a.visits;
        });
        localStorage.setItem(this.savedRoutesKey, JSON.stringify(updatedRoutes));
      }
      this.notifyHistorySubs();
    }
  }

  public notifyHistorySubs() {
    this.historySubject.next(JSON.parse(localStorage.getItem(this.savedRoutesKey)));
  }
}
