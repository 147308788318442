import { ChangeDetectionStrategy, Component, computed, effect, input } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ComponentInputs } from '@jump-tech-frontend/domain';
import { TranslocoModule } from '@ngneat/transloco';

export type SsoUrlInputs = ComponentInputs<SsoUrlComponent>;

@Component({
    selector: 'pathway-sso-url',
    templateUrl: 'sso-url.component.html',
    styleUrls: ['sso-url.component.scss'],
    imports: [TranslocoModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SsoUrlComponent {
  public authDomain = input.required<string>();
  public clientId = input.required<string>();
  public idProvider = input.required<string>();
  public redirectUri = input.required<string>();
  public responseType? = input<'code' | 'token'>('code');
  public scopes? = input<string[]>(['openid', 'aws.cognito.signin.user.admin']);

  log = effect(() => {
    if (!environment.production) {
      console.log('SSO Url', this.url());
    }
  });

  url = computed(() =>
    [
      `https://${this.authDomain()}`,
      '/oauth2/authorize',
      `?response_type=${this.responseType()}`,
      `&identity_provider=${this.idProvider()}`,
      `&client_id=${this.clientId()}`,
      `&redirect_uri=${this.redirectUri()}`,
      `&scope=${this.scopes().join('+')}`
    ].join('')
  );

  imgUrl = computed(() => `assets/${this.idProvider().toLowerCase()}-logo.png`);
}
