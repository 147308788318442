import { Injectable } from '@angular/core';
import { GalleryV2LayoutRepository } from './gallery-v2-layout.repository';
import { GalleryDm, GalleryVm } from './gallery-v2.model';

@Injectable()
export class GalleryV2LayoutPresenter {
  constructor(private repository: GalleryV2LayoutRepository) {}

  load(vmSubject$, project, galleryItem): void {
    this.repository.load(
      (dm: GalleryDm): void => {
        const vm: GalleryVm = {
          i18ns: dm.i18ns,
          data: dm.data,
          project: dm.project,
          isEditing: dm.isEditing,
          isSaving: dm.isSaving,
          form: dm.form,
          allItems: dm.galleryImages,
          item: dm.galleryItem,
          isVideoType: dm.galleryItem.editConfig.controlType === 'VideoUploadQuestion',
          sectionImages: dm.dataSectionImages,
          isPristine: !dm.isEditing && (!dm.dataSectionImages || !dm.dataSectionImages?.length),
          isSaveDisabled: dm.isSaving || dm.isProcessing || !dm.hasChanges,
          showSaveButton: dm.hasChanges,
          isWorking: dm.isSaving || dm.isProcessing,
          imageCount: dm.dataSectionImages?.length ?? 0,
          showMainDisplay: !!dm.dataSectionImages?.length || dm.isEditing,
          existingImages: dm.existingImages,
          showUploadedImages: Array.isArray(dm.dataSectionImages),
          activelyProcessing: dm.numberProcessing
        };
        vmSubject$.next(vm);
      },
      project,
      galleryItem
    );
  }

  setEditing(): void {
    this.repository.setEditing();
  }

  cancelEdit(): void {
    this.repository.cancelEdit();
  }

  removeImage(index): void {
    this.repository.removeImage(index);
  }

  setProcessing(numberProcessing): void {
    this.repository.setProcessing(numberProcessing);
  }

  handleProcessingComplete(): void {
    this.repository.processingComplete();
  }

  handleSaveSuccess(payload): void {
    this.repository.saveSuccess(payload);
  }

  save(form): void {
    this.repository.save(form);
  }
}
