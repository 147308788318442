import { DestroyRef, inject, Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { GlobalError, GlobalErrorI18n, GlobalErrorsDm } from './global-errors.model';
import { GlobalErrorsService } from '../../core/services/global-errors.service';
import { TranslocoService } from '@ngneat/transloco';
import { UserService } from '../../auth/services/user.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { User } from '../../core/domain/user';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorsRepository {
  public data$: Subject<GlobalErrorsDm>;
  private dataSubscription: Subscription;
  private dataDm: GlobalErrorsDm | null = {} as GlobalErrorsDm;
  private globalErrorSub: Subscription;
  private destroyRef: DestroyRef = inject(DestroyRef);
  i18n: GlobalErrorI18n = {};
  private user: User;

  constructor(
    private globalErrorsService: GlobalErrorsService,
    private i18nService: TranslocoService,
    private userService: UserService
  ) {
    this.init();
  }

  init(): void {
    this.data$ = new Subject<GlobalErrorsDm>();
    this.initI18ns();
    this.userService.userObservable.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((user: User) => {
      if (user !== null) {
        this.user = user;
      }
    });
  }

  load(cb): void {
    this.dataSubscription?.unsubscribe();
    this.dataSubscription = this.data$.subscribe(cb);
    this.listenForGlobalErrors();
  }

  private listenForGlobalErrors(): void {
    this.globalErrorSub?.unsubscribe();
    this.globalErrorSub = this.globalErrorsService.globalErrors$.subscribe((globalError: GlobalError) => {
      if (!this.dataDm) {
        this.initDm();
      }
      if (globalError) {
        const error = this.parseError(globalError);
        this.dataDm.errors.push(error);
      } else {
        this.dataDm = null;
      }
      this.data$.next(this.dataDm);
    });
  }

  private initDm(): void {
    this.dataDm = {
      errors: [],
      isSuperAdmin: this.user?.isSuperAdmin ?? false,
      serverLabel: this.i18n.server,
      clientLabel: this.i18n.client,
      topMessage: this.i18n.topMessage,
      showDetails: false
    };
  }

  clearErrors(): void {
    this.globalErrorsService.notifyGlobalError(null);
  }

  toggleDetail(): void {
    this.dataDm.showDetails = !this.dataDm.showDetails;
    this.data$.next(this.dataDm);
  }

  private parseError(globalError: GlobalError): GlobalError {
    // leaving the parse in for now in case we want to change the shape
    return {
      detail: globalError.detail,
      type: globalError.type,
      error: globalError.error
    };
  }

  private initI18ns(): void {
    this.i18n.server = this.i18nService.translate('globalError.server');
    this.i18n.client = this.i18nService.translate('globalError.client');
    this.i18n.topMessage = this.i18nService.translate('globalError.topMessage');
  }
}
