import { Component, Input } from '@angular/core';
import { MyOrdersVm } from '../../my-orders.model';
import { NgIf } from '@angular/common';

@Component({
    selector: 'pathway-my-orders-driver-address',
    templateUrl: './driver-address.component.html',
    styleUrls: ['../../my-orders.component.scss'],
    imports: [NgIf]
})
export class MyOrdersDriverAddressComponent {
  @Input() vm: MyOrdersVm;
}
