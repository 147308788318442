import { AuditLog } from '@jump-tech-frontend/domain';
import { AuditLogAggregations } from './project-audit-logs.repository';

export const LOCALE_CONTEXT = 'auditLogs';

export interface ProjectAuditLogsError {
  message: string;
  qaClearErrorsButton: string;
  qaErrorMessage: string;
}

export enum ProjectAuditLogsErrorType {
  GetAuditLogs = 'auditLogs.errors.getAuditLogs'
}

export interface ProjectAuditLogsVm {
  allAuditLogs: AuditLog[];
  filteredAuditLogs: AuditLog[];
  auditLogAggregations?: AuditLogAggregations;
  displayProjectId?: boolean;
  showLoader: boolean;
}
