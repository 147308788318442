import { ApiService } from '../../core/api.service';
import { Subject, Subscription } from 'rxjs';
import { AuditLog } from '@jump-tech-frontend/domain';
import { Injectable } from '@angular/core';
import { ProjectAuditLogsError, ProjectAuditLogsErrorType } from './project-audit-logs.model';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from '../../../environments/environment';
import { HttpGateway } from '../../core/http-gateway.service';

export type AuditLogAggregations = { [key: string]: { [key: string]: string }[] };

@Injectable({ providedIn: 'root' })
export class ProjectAuditLogsRepository {
  errors$: Subject<ProjectAuditLogsError>;
  projectAuditLogsErrorsSubscription: Subscription;
  cachedLogs: AuditLog[];

  constructor(private apiService: ApiService, private i18nService: TranslocoService, private gateway: HttpGateway) {
    this.init();
  }

  init(): void {
    this.errors$ = new Subject<ProjectAuditLogsError>();
  }

  async list(projectId: string): Promise<AuditLog[]> {
    const search = [{ key: 'projectId', value: projectId }];
    const searchOptions = {
      sort: 'created_on',
      dir: 'desc',
      max: '200',
      search: JSON.stringify(search)
    };

    try {
      const { results } = await this.gateway.get(`${environment.apiAuditLogsSearchUrl}`, searchOptions, {
        skip: 'true'
      });
      this.cachedLogs = results;
      return results;
    } catch (e) {
      this.handleError(ProjectAuditLogsErrorType.GetAuditLogs, e);
      return this.cachedLogs ?? [];
    }
  }

  async listForTenant(aggregators: {
    [key: string]: string;
  }): Promise<{ results: AuditLog[]; aggregations: AuditLogAggregations }> {
    const searchOptions = {
      sort: 'created_on',
      dir: 'desc',
      max: '200'
    };

    for (const key of Object.keys(aggregators)) {
      searchOptions[key] = aggregators[key];
    }

    return this.apiService.searchAllAuditLogs(searchOptions);
  }

  getErrors(cb): void {
    this.projectAuditLogsErrorsSubscription = this.errors$.subscribe(cb);
  }

  clearErrors(): void {
    this.errors$.next({} as ProjectAuditLogsError);
  }

  private setError(e: ProjectAuditLogsErrorType): void {
    const message: string = this.i18nService.translate(e);
    this.errors$.next({
      message,
      qaErrorMessage: 'projectAuditLogsErrorMessage',
      qaClearErrorsButton: 'projectAuditLogsClearErrorsButton'
    });
  }

  private handleError(type: ProjectAuditLogsErrorType, e) {
    if (!environment.production) {
      console.log('Project Audit Logs Error:', e);
    }

    switch (type) {
      case ProjectAuditLogsErrorType.GetAuditLogs:
        this.setError(ProjectAuditLogsErrorType.GetAuditLogs);
        break;
    }
  }
}
