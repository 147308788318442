import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { BehaviorSubject } from 'rxjs';
import { EnaError } from '../ena.model';
import { EnaApplicationErrorsPresenter } from './ena-application-errors.presenter';

@Component({
    selector: 'ena-application-errors',
    imports: [CommonModule, CoreComponentsAngularModule],
    templateUrl: './ena-application-errors.component.html',
    styleUrl: './ena-application-errors.component.scss'
})
export class EnaApplicationErrorsComponent implements OnInit {
  vm$: BehaviorSubject<EnaError> = new BehaviorSubject<EnaError>(null);

  constructor(private presenter: EnaApplicationErrorsPresenter) {}

  ngOnInit(): void {
    this.presenter.load(this.vm$);
  }

  clearErrors(): void {
    this.presenter.clearErrors();
  }
}
