import { NgIf } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CoreComponentsAngularModule } from '../../../../../core-components-angular/src/lib/core-components-angular.module';
import { QuestionsComponent } from '../../core/questions.component';
import { Card } from '../../domain/card';
import { CardActionBase } from '../../domain/card-actions/card-action.base';
import { I18nKeys } from '../../domain/i18n-keys';

@Component({
    selector: `crds-summary-form`,
    template: `
    <div class="card-wrapper">
      <div class="card__title-header">
        <div class="card__title">{{ card.label }}</div>
      </div>

      <div class="card__svg card__svg--lg a-fade-in">
        @if (card.cardIcon) {
        <jui-svg-icon [name]="card.cardIcon"></jui-svg-icon>
        } @else{
        <svg width="100%" viewBox="0 0 900 600" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_73_148)">
            <path
              d="M671.157 171.197C653.212 112.625 498.109 37.029 375.572 131.005C318.582 174.714 336.271 206.082 303.458 268.007C283.899 304.916 249.253 340.108 251.568 382.482C253.558 419.06 272.697 452.141 298.588 468.728C356.816 506.031 449.072 512.364 497.302 402.254C545.532 292.144 717.422 322.262 671.157 171.197Z"
              fill="url(#paint0_linear_73_148)"
            />
            <path
              d="M740.703 350.515C727.353 333.353 703.949 334.263 667.619 343.413C640.296 350.3 615.325 356.209 589.884 375.53C572.077 389.046 558.88 405.681 549.772 421.277C539.901 438.172 525.543 457.923 536.444 473.732C551.423 495.448 620.495 505.184 679.767 463.168C744.877 417.002 753.76 367.287 740.703 350.515Z"
              fill="url(#paint1_linear_73_148)"
            />
            <path
              d="M305.248 191.019C285.094 244.758 264.922 301.541 228.022 336.733C189.965 373.045 177.719 360.231 168.236 337.185C158.753 314.138 157.352 253.887 209.575 205.4C261.812 156.899 324.103 140.725 305.248 191.019Z"
              fill="url(#paint2_linear_73_148)"
            />
            <path
              d="M343.026 241.614C343.026 270.039 353.135 296.105 369.957 316.459C384.511 334.055 392.592 356.081 392.592 378.872V411.746C392.592 432.647 409.753 449.746 430.73 449.746H491.763C512.74 449.746 529.901 432.647 529.901 411.746V378.813C529.901 355.971 538.102 333.966 552.646 316.309C569.537 295.807 579.626 269.531 579.456 240.897C579.067 175.936 526.435 123.823 461.246 123.823C454.004 123.823 446.912 124.48 440.02 125.724C438.491 126.003 436.983 126.301 435.475 126.64C382.573 138.344 343.026 185.371 343.026 241.614Z"
              fill="var(--jds-theme-color-primary)"
            />
            <path
              d="M483.572 219.841C481.754 239.404 463.172 258.034 463.172 258.034C463.172 258.034 444.804 235.959 446.622 216.396C448.453 196.829 458.19 181.741 468.397 182.698C478.6 183.641 485.39 200.278 483.572 219.841ZM445.04 257.778C457.854 269.303 461.23 292.137 461.23 292.137C461.23 292.137 436.081 293.517 423.262 281.98C410.444 270.444 404.931 255.687 410.953 249.004C416.964 242.325 432.225 246.253 445.04 257.778ZM464.762 334.469C465.463 337.079 465.807 338.92 465.896 339.387C465.904 339.441 465.918 339.481 465.916 339.505L465.919 339.515C465.919 339.515 465.909 339.519 465.906 339.509L465.832 339.524C465.585 339.533 464.849 339.571 463.734 339.598C457.768 339.712 441.022 339.255 431.47 330.984C419.706 320.796 414.483 307.548 419.796 301.422C425.106 295.287 438.951 298.571 450.716 308.76C455.982 313.326 459.584 319.957 461.942 325.91C463.052 328.704 463.994 331.562 464.762 334.469ZM498.459 284.548C484.221 294.264 461.234 292.148 461.234 292.148C461.234 292.148 465.865 267.376 480.102 257.66C494.34 247.945 509.985 246.087 515.058 253.506C520.135 260.936 512.697 274.832 498.459 284.548Z"
              fill="white"
            />
            <path
              d="M496.78 329.146C484.911 339.21 464.073 339.465 464.073 339.465C464.073 339.465 465.904 316.785 477.772 306.721C489.64 296.658 503.522 293.512 508.773 299.708C514.024 305.904 508.658 319.079 496.78 329.146Z"
              fill="white"
            />
            <path
              d="M465.883 249.833C465.812 250.116 460.504 277.387 468.78 344.91C469.415 350.08 470.098 355.244 470.829 360.401L470.502 360.447L468.099 360.781L468.095 360.768L468.082 360.773L467.989 360.792C467.677 360.804 466.748 360.852 465.341 360.885C464.524 355.134 463.77 349.375 463.079 343.608C454.979 276.494 460.252 249.881 460.484 248.694L465.883 249.833Z"
              fill="white"
            />
            <path
              d="M483.764 452.942L483.676 453.386L482.538 458.995L482.241 460.473V460.486L481.936 461.972L480.806 467.577L480.508 469.054L480.501 469.067L480.203 470.558L480.034 471.377C479.458 474.223 474.408 476.394 468.363 476.394H454.75C453.308 476.394 451.934 476.271 450.654 476.045H450.647C449.568 475.858 448.508 475.573 447.479 475.195L447.405 475.169C446.686 474.904 445.996 474.565 445.347 474.158C444.128 473.382 443.323 472.454 443.093 471.429L442.612 469.255L442.307 467.886V467.882L442.301 467.851V467.847L442.003 466.509L440.845 461.31L440.541 459.945L440.534 459.915L440.236 458.568L438.984 452.937H483.764V452.942Z"
              fill="#11341B"
            />
            <path
              d="M304.643 313.266C308.764 313.266 312.106 316.607 312.106 320.729C312.106 324.851 308.764 328.192 304.643 328.192C300.521 328.192 297.18 324.851 297.18 320.729C297.18 316.607 300.521 313.266 304.643 313.266Z"
              fill="var(--jds-theme-color-primary)"
            />
            <path
              d="M657.648 149.622C648.059 155.158 635.797 151.873 630.261 142.283C624.724 132.694 628.01 120.432 637.599 114.896C647.188 109.359 659.45 112.645 664.986 122.234C670.523 131.824 667.237 144.085 657.648 149.622Z"
              fill="var(--jds-theme-color-primary)"
            />
            <path
              d="M663.626 229.261C659.313 231.751 653.799 230.273 651.309 225.961C648.819 221.648 650.296 216.133 654.609 213.643C658.922 211.153 664.436 212.631 666.926 216.944C669.416 221.256 667.939 226.771 663.626 229.261Z"
              fill="var(--jds-theme-color-primary)"
            />
            <path
              d="M265.604 206.567C261.188 206.567 257.608 210.147 257.608 214.563C257.608 218.979 261.188 222.559 265.604 222.559C270.02 222.559 273.6 218.979 273.6 214.563C273.6 210.147 270.02 206.567 265.604 206.567Z"
              fill="#E1E4E5"
            />
            <path
              d="M739.959 414.356C745.504 411.154 747.404 404.064 744.202 398.519C741.001 392.975 733.911 391.075 728.366 394.276C722.821 397.477 720.921 404.568 724.123 410.112C727.324 415.657 734.414 417.557 739.959 414.356Z"
              fill="#E1E4E5"
            />
            <path
              d="M175.634 409.223C183.878 409.223 190.561 415.906 190.561 424.15C190.561 432.394 183.878 439.077 175.634 439.077C167.39 439.077 160.707 432.394 160.707 424.15C160.707 415.906 167.39 409.223 175.634 409.223Z"
              fill="#E1E4E5"
            />
            <path
              d="M505.128 94.8236C500.199 97.6695 493.895 95.9806 491.05 91.0513C488.204 86.122 489.893 79.819 494.822 76.9731C499.751 74.1271 506.054 75.816 508.9 80.7453C511.746 85.6746 510.057 91.9777 505.128 94.8236Z"
              fill="#E1E4E5"
            />
            <path
              d="M325.967 147.519C330.089 147.519 333.43 144.178 333.43 140.056C333.43 135.934 330.089 132.593 325.967 132.593C321.845 132.593 318.504 135.934 318.504 140.056C318.504 144.178 321.845 147.519 325.967 147.519Z"
              fill="var(--jds-theme-color-primary)"
            />
            <path
              d="M649.797 441.896C638.941 435.628 625.059 439.348 618.791 450.204C612.523 461.06 616.243 474.942 627.099 481.21C637.955 487.478 651.837 483.758 658.105 472.902C664.373 462.046 660.653 448.164 649.797 441.896Z"
              fill="var(--jds-theme-color-primary)"
            />
            <path
              d="M218.939 300.42C214.056 297.601 207.812 299.274 204.993 304.157C202.174 309.039 203.847 315.283 208.73 318.102C213.613 320.921 219.857 319.248 222.676 314.366C225.495 309.483 223.822 303.239 218.939 300.42Z"
              fill="var(--jds-theme-color-primary)"
            />
            <path
              d="M288.117 428.381C283.111 428.381 279.054 424.801 279.054 420.385C279.054 415.969 283.111 412.389 288.117 412.389C293.122 412.389 297.18 415.969 297.18 420.385C297.18 424.801 293.122 428.381 288.117 428.381Z"
              fill="#E1E4E5"
            />
            <path
              d="M693.26 295.076C696.127 296.731 697.109 300.397 695.454 303.264C693.799 306.131 690.133 307.113 687.266 305.458C684.399 303.803 683.417 300.137 685.072 297.27C686.727 294.403 690.393 293.421 693.26 295.076Z"
              fill="#E1E4E5"
            />
            <path
              d="M195.768 225.792C205.189 225.792 212.827 218.393 212.827 209.266C212.827 200.139 205.189 192.74 195.768 192.74C186.347 192.74 178.709 200.139 178.709 209.266C178.709 218.393 186.347 225.792 195.768 225.792Z"
              fill="#E1E4E5"
            />
            <path
              d="M627.375 349.688C620.626 345.792 611.997 348.104 608.1 354.853C604.204 361.602 606.516 370.231 613.265 374.128C620.013 378.024 628.643 375.712 632.539 368.963C636.436 362.214 634.123 353.585 627.375 349.688Z"
              fill="#E1E4E5"
            />
            <path
              d="M573.439 418.687C572.377 479.088 523.319 527.703 462.968 527.703C402.627 527.703 353.569 479.088 352.507 418.687H573.439Z"
              fill="white"
              stroke="#E1E4E5"
              stroke-width="4"
            />
            <path
              d="M350.527 533.034C351.484 527.448 356.383 523.174 362.309 523.174H563.658C569.565 523.174 574.464 527.44 575.42 533.034H350.527Z"
              fill="#E1E4E5"
              stroke="#E1E4E5"
              stroke-width="4"
            />
            <path d="M577.589 416.687H346.225V436.944H577.589V416.687Z" fill="#E1E4E5" />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_73_148"
              x1="410.715"
              y1="-123.821"
              x2="548.204"
              y2="900.738"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="#EEEEEE" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_73_148"
              x1="651.497"
              y1="239.697"
              x2="621.604"
              y2="674.767"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="#EEEEEE" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_73_148"
              x1="229.578"
              y1="52.607"
              x2="254.871"
              y2="570.091"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="#EEEEEE" />
            </linearGradient>
            <clipPath id="clip0_73_148">
              <rect width="900" height="600" fill="white" />
            </clipPath>
          </defs>
        </svg>
        }
      </div>

      <div class="card__description card__description--centered a-fade-in">
        <p class="card-text" [innerHTML]="description" [attr.data-qa]="'description'"></p>
      </div>
    </div>
    <div *ngIf="description2" class="card-wrapper">
      <div class="card">
        <div class="card-body a-fade-in--delayed">
          <p class="card-text" [innerHTML]="description2">&nbsp;</p>
        </div>
      </div>
    </div>
    <div class="form-wrapper" *ngIf="hasQuestionsToShow && card">
      <form [formGroup]="questionFormGroup">
        <crds-questions [form]="questionFormGroup" [card]="card" [i18ns]="i18ns"></crds-questions>
      </form>
    </div>
  `,
    imports: [NgIf, ReactiveFormsModule, QuestionsComponent, CoreComponentsAngularModule]
})
export class SummaryFormComponent implements OnChanges {
  @Input() card: Card<CardActionBase>;
  @Input() form: UntypedFormGroup;
  @Input() formKey: string;
  @Input() i18ns: I18nKeys;

  questionFormGroup: UntypedFormGroup;
  hasQuestionsToShow = false;

  action: any;
  description: SafeHtml | null;
  description2: SafeHtml | null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.questionFormGroup = this.form.get(this.formKey) as UntypedFormGroup;
    this.hasQuestionsToShow = !!(this.card.questions?.length && !!this.card.questions.find(q => q.show));

    this.action = this.card.action;
    this.description = this.sanitizer.bypassSecurityTrustHtml(this.card.description);
    this.description2 = this.card.description2 ? this.sanitizer.bypassSecurityTrustHtml(this.card.description2) : null;
  }
}
