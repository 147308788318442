import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { BehaviorSubject } from 'rxjs';
import { FormErrorComponent } from '../../../../../../apps/pathway/src/app/shared/form-error/form-error.component';
import { ControlContext } from '../../address-lookup-v2.vm';
import { AddressProvider } from '../../domain/address-provider';
import { IAddressV2 } from '../../domain/address-v2';
import { SearchProviderFactory } from '../../providers/search-provider.factory';
import { AddressSearchPresenter } from './address-search.presenter';
import { AddressSearchResultType, AddressSearchViewModel } from './address-search.vm';

@Component({
    selector: 'jump-tech-frontend-address-search',
    templateUrl: './address-search.component.html',
    styleUrls: ['./address-search.component.scss'],
    providers: [SearchProviderFactory, AddressSearchPresenter],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [AsyncPipe, NgForOf, ReactiveFormsModule, CoreComponentsAngularModule, NgIf, FormErrorComponent]
})
export class AddressSearchComponent implements OnInit {
  @Input() countryCodes: string[];
  @Input() provider: AddressProvider;
  @Input() label: string;
  @Input() selectedAddress?: IAddressV2 | null;
  @Input() context: ControlContext;
  @Input() disabled?: boolean;
  @Input() required: boolean;
  @Input() errorMessage: string;
  @Output() selectedResult: EventEmitter<IAddressV2> = new EventEmitter<IAddressV2>();

  private readonly presenter = inject(AddressSearchPresenter);
  public vm$ = new BehaviorSubject<AddressSearchViewModel | null>(null);

  ngOnInit(): void {
    this.presenter.load(
      this.vm$,
      this.countryCodes,
      this.selectedResult,
      this.getLabel(),
      this.provider,
      this.disabled,
      this.required,
      this.selectedAddress,
      this.errorMessage
    );
  }
  getLabel(): string {
    return `${this.label}${this.required ? ' *' : ''}`;
  }

  async search(searchTermEvent: string, container?: string): Promise<void> {
    await this.presenter.search(searchTermEvent, container);
  }

  async searchOrRetrieve(id: string, type: AddressSearchResultType = 'Address'): Promise<void> {
    const controlValue = this.vm$.value?.searchTermControl?.value ?? '';
    await this.presenter.searchOrRetrieve(id, controlValue, type);
  }

  async retrieve(id: string): Promise<void> {
    const controlValue = this.vm$.value?.searchTermControl?.value ?? '';
    await this.presenter.retrieve(id, controlValue);
  }
}
