import { Component } from '@angular/core';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-document-pack-loader',
    templateUrl: './document-pack-loader.html',
    styleUrls: ['../../document-pack.component.scss'],
    imports: [NgFor, CoreComponentsAngularModule]
})
export class DocumentPackLoaderComponent {
  loaders = [{ width: '150px' }, { width: '95px' }, { width: '110px' }];
}
