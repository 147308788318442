import { inject } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

export function spinnerInjectable(key: string) {
  const spinnerService = inject(NgxSpinnerService);
  return (show: boolean) => {
    if (show) {
      spinnerService.show(key).then();
    } else {
      spinnerService.hide(key).then();
    }
  };
}
