import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Card } from '../../domain/card';
import { I18nKeys } from '../../domain/i18n-keys';
import { CardActionBase } from '../../domain/card-actions/card-action.base';
import { QuestionsComponent } from '../../core/questions.component';
import { NgIf } from '@angular/common';

@Component({
    selector: `crds-quote-form`,
    template: `
    <div class="card-wrapper">
      <div class="card__title-header card__title-header--centered">
        <div class="card__title">{{ card.label }}</div>
      </div>
      <div class="card__description card__description--centered card__description--minimal">
        <div class="card-text card-text--lg" [innerHTML]="quoteSentBy"></div>
      </div>

      <div class="card__svg card__svg--md a-fade-in">
        <svg width="100%" viewBox="0 0 900 600" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_21_64)">
            <path
              d="M631.754 206.427C620.778 226.016 596.738 228.708 558.226 224.985C529.262 222.18 502.849 220.01 473.882 204.217C453.608 193.171 437.559 178.226 425.846 163.711C413.153 147.986 395.436 130.04 404.121 112.211C416.057 87.719 485.08 67.091 552.104 100.824C625.73 137.89 642.492 187.281 631.754 206.427Z"
              fill="url(#paint0_linear_21_64)"
            />
            <path
              d="M782.448 316.502C754.73 330.251 717.943 312.572 717.943 312.572C717.943 312.572 726.119 272.609 753.851 258.879C781.568 245.129 818.341 262.789 818.341 262.789C818.341 262.789 810.165 302.752 782.448 316.502Z"
              fill="url(#paint1_linear_21_64)"
            />
            <path
              d="M175.251 211.281C191.298 220.899 214.32 212.008 214.32 212.008C214.32 212.008 211.31 187.528 195.253 177.921C179.206 168.303 156.193 177.183 156.193 177.183C156.193 177.183 159.204 201.664 175.251 211.281Z"
              fill="url(#paint2_linear_21_64)"
            />
            <path
              d="M702.154 371.239C693.796 371.239 687.02 378.015 687.02 386.373C687.02 394.731 693.796 401.507 702.154 401.507C710.512 401.507 717.288 394.731 717.288 386.373C717.288 378.015 710.512 371.239 702.154 371.239Z"
              fill="var(--jds-theme-color-primary)"
            />
            <path
              d="M159.806 275.445C152.925 275.445 147.347 281.023 147.347 287.904C147.347 294.785 152.925 300.363 159.806 300.363C166.687 300.363 172.265 294.785 172.265 287.904C172.265 281.023 166.687 275.445 159.806 275.445Z"
              fill="var(--jds-theme-color-primary)"
            />
            <path
              d="M196.951 458.224C189.115 458.224 182.763 451.872 182.763 444.036C182.763 436.2 189.115 429.848 196.951 429.848C204.787 429.848 211.139 436.2 211.139 444.036C211.139 451.872 204.787 458.224 196.951 458.224Z"
              fill="var(--jds-theme-color-primary)"
            />
            <path
              d="M667.101 275.003C663.444 275.003 660.48 272.039 660.48 268.382C660.48 264.725 663.444 261.761 667.101 261.761C670.758 261.761 673.722 264.725 673.722 268.382C673.722 272.039 670.758 275.003 667.101 275.003Z"
              fill="var(--jds-theme-color-primary)"
            />
            <path
              d="M223.124 145.187C218.422 145.187 214.611 141.376 214.611 136.674C214.611 131.972 218.422 128.161 223.124 128.161C227.826 128.161 231.637 131.972 231.637 136.674C231.637 141.376 227.826 145.187 223.124 145.187Z"
              fill="#EBEBEB"
            />
            <path
              d="M80.297 463.627C73.5061 463.627 68.001 458.122 68.001 451.331C68.001 444.54 73.5061 439.035 80.297 439.035C87.0879 439.035 92.593 444.54 92.593 451.331C92.593 458.122 87.0879 463.627 80.297 463.627Z"
              fill="#EBEBEB"
            />
            <path
              d="M562.039 539.313C556.87 539.313 552.68 535.123 552.68 529.954C552.68 524.785 556.87 520.595 562.039 520.595C567.208 520.595 571.398 524.785 571.398 529.954C571.398 535.123 567.208 539.313 562.039 539.313Z"
              fill="#EBEBEB"
            />
            <path
              d="M684.264 182.603C678.517 182.603 673.859 177.944 673.859 172.198C673.859 166.451 678.517 161.793 684.264 161.793C690.01 161.793 694.669 166.451 694.669 172.198C694.669 177.944 690.01 182.603 684.264 182.603Z"
              fill="#EBEBEB"
            />
            <path
              d="M117.497 254.537C118.833 259.521 115.875 264.645 110.891 265.98C105.907 267.316 100.784 264.358 99.4482 259.374C98.1127 254.389 101.071 249.266 106.055 247.931C111.039 246.595 116.162 249.553 117.497 254.537Z"
              fill="#EBEBEB"
            />
            <path
              d="M293.61 158.345C286.745 158.345 281.18 152.78 281.18 145.915C281.18 139.05 286.745 133.485 293.61 133.485C300.475 133.485 306.04 139.05 306.04 145.915C306.04 152.78 300.475 158.345 293.61 158.345Z"
              fill="#EBEBEB"
            />
            <path
              d="M765.702 453.237C760.422 453.237 756.141 449.812 756.141 445.588C756.141 441.364 760.422 437.939 765.702 437.939C770.982 437.939 775.263 441.364 775.263 445.588C775.263 449.812 770.982 453.237 765.702 453.237Z"
              fill="#EBEBEB"
            />
            <path
              d="M299.45 520.51C302.23 530.883 296.074 541.545 285.701 544.325C275.327 547.104 264.665 540.948 261.886 530.575C259.106 520.202 265.262 509.539 275.635 506.76C286.008 503.98 296.671 510.136 299.45 520.51Z"
              fill="#EBEBEB"
            />
            <path
              d="M674.036 434.125H674.286C675.767 455.111 691.375 455.434 691.375 455.434C691.375 455.434 674.164 455.77 674.164 480.019C674.164 455.77 656.954 455.434 656.954 455.434C656.954 455.434 672.555 455.111 674.036 434.125ZM136.796 491.691H136.927C137.702 503.085 145.861 503.26 145.861 503.26C145.861 503.26 136.863 503.443 136.863 516.608C136.863 503.443 127.866 503.26 127.866 503.26C127.866 503.26 136.022 503.085 136.796 491.691Z"
              fill="#EBEBEB"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M494.796 274.428C499.366 264.046 502.117 252.605 502.117 240.405C502.117 195.907 468.173 159.835 426.3 159.835C384.427 159.835 350.482 195.907 350.482 240.405C350.482 254.746 354.294 268.028 360.49 279.676"
              fill="var(--jds-theme-color-secondary)"
            />
            <path
              d="M235.627 359.69C231.917 349.065 233.416 337.812 240.792 330.426C251.83 319.372 271.57 321.191 284.885 334.487L298.995 348.578"
              stroke="var(--jds-theme-color-primary)"
              stroke-width="22"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M572.359 232.413V278.214C586.559 289.835 597.833 304.325 604.517 320.914H621.803C632.721 320.914 641.581 328.948 641.581 338.847V393.813C641.581 403.712 632.721 411.746 621.803 411.746H595.875C585.491 428.029 570.5 441.587 552.581 451.038V483.479C552.581 493.378 543.721 501.413 532.803 501.413H505.376C494.458 501.413 493.762 493.378 493.762 483.479L493.248 465.546H377.566V481.112C377.566 491.011 381.394 499.045 370.476 499.045H344.914C333.997 499.045 325.137 491.011 325.137 481.112V437.982C300.909 418.274 285.581 389.778 285.581 357.946C285.581 298.515 338.704 250.346 404.248 250.346H493.762C502.247 250.346 510.474 247.817 517.238 243.191C527.107 236.448 539.29 232.413 552.581 232.413H572.359Z"
              fill="var(--jds-theme-color-primary)"
              stroke="var(--jds-theme-color-primary)"
              stroke-width="22"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M565.72 309.158C572.76 316.227 572.76 327.625 565.72 334.694C558.675 341.769 547.33 341.769 540.284 334.694C533.239 327.625 533.239 316.227 540.284 309.158C541.879 307.536 543.838 306.238 546.03 305.349C548.222 304.46 550.599 304 553.002 304C555.406 304 557.782 304.46 559.974 305.349C562.166 306.238 564.125 307.536 565.72 309.158Z"
              fill="white"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_21_64"
              x1="557.81"
              y1="333.384"
              x2="459.976"
              y2="-106.242"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="#EEEEEE" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_21_64"
              x1="676.01"
              y1="359.389"
              x2="904.623"
              y2="178.556"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="#EEEEEE" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_21_64"
              x1="237.349"
              y1="242.24"
              x2="108.228"
              y2="122.27"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="1" stop-color="#EEEEEE" />
            </linearGradient>
            <clipPath id="clip0_21_64">
              <rect width="900" height="600" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>

      <div class="card__description card__description--centered card__description--minimal a-fade-in">
        <p class="card-text" [innerHTML]="description" [attr.data-qa]="'description'"></p>
      </div>
    </div>
    <div class="form-wrapper" *ngIf="hasQuestionsToShow && card">
      <form [formGroup]="questionFormGroup">
        <crds-questions [form]="questionFormGroup" [card]="card" [i18ns]="i18ns"></crds-questions>
      </form>
    </div>
    <div *ngIf="description2" class="card-wrapper">
      <div class="card">
        <div class="card-body a-fade-in">
          <p class="card-text" [innerHTML]="description2">&nbsp;</p>
        </div>
      </div>
    </div>

    <div class="card__quote a-fade-in">
      <div class="card__quote-title" *ngIf="quoteTotalTitle">{{ quoteTotalTitle }}</div>
      <div class="card__quote-value">{{ quoteTotalValue }}</div>
      <div class="card__quote-description" *ngIf="quoteTotalDescription">{{ quoteTotalDescription }}</div>
    </div>
  `,
    imports: [NgIf, ReactiveFormsModule, QuestionsComponent]
})
export class QuoteFormComponent implements OnChanges {
  @Input() card: Card<CardActionBase>;
  @Input() form: UntypedFormGroup;
  @Input() formKey: string;
  @Input() i18ns: I18nKeys;
  questionFormGroup: UntypedFormGroup;
  hasQuestionsToShow = false;

  action: any;
  description: SafeHtml | null;
  description2: SafeHtml | null;
  quoteSentBy: string | null;
  quoteTotalTitle: string | null;
  quoteTotalValue: string | null;
  quoteTotalDescription: string | null;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.card || !Object.prototype.hasOwnProperty.call(changes, 'card')) {
      return;
    }

    this.questionFormGroup = this.form.get(this.formKey) as UntypedFormGroup;

    this.hasQuestionsToShow = !!(this.card.questions?.length && !!this.card.questions.find(q => q.show));

    this.action = this.card.action;
    this.description = this.sanitizer.bypassSecurityTrustHtml(this.card.description);
    this.description2 = this.card.description2 ? this.sanitizer.bypassSecurityTrustHtml(this.card.description2) : null;
    this.quoteSentBy = this.card.quoteSentBy ?? null;
    this.quoteTotalTitle = this.card.quoteTotalTitle ?? null;
    this.quoteTotalValue = this.card.quoteTotalValue ?? null;
    this.quoteTotalDescription = this.card.quoteTotalDescription ?? null;
  }
}
