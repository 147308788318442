import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DIRECTIVES } from '../generated/directives';
import { TextValueAccessor } from '../generated/directives/text-value-accessor';
import { BooleanValueAccessor } from '../generated/directives/boolean-value-accessor';
import { NumericValueAccessor } from '../generated/directives/number-value-accessor';
import { SelectValueAccessor } from '../generated/directives/select-value-accessor';
import { FileValueAccessor } from './file-value-accessor';

@NgModule({
  imports: [
    CommonModule,
    DIRECTIVES,
    TextValueAccessor,
    BooleanValueAccessor,
    NumericValueAccessor,
    SelectValueAccessor,
    FileValueAccessor
  ],
  declarations: [],
  exports: [
    ...DIRECTIVES,
    TextValueAccessor,
    BooleanValueAccessor,
    NumericValueAccessor,
    SelectValueAccessor,
    FileValueAccessor
  ]
})
export class CoreComponentsAngularModule {}
