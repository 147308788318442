import { Project } from '../../core/domain/project';
import { LegacyEnaDto } from '../../project-detail/legacy-ena-application/legacy-ena.model';

export default function GetLegacyEnaDataTestStub(type = 'GET'): LegacyEnaDto {
  switch (type) {
    case 'GET':
    case 'GET_SUBMITTED':
      return STUB_LEGACY_ENA_DATA;
    case 'GET_APPROVED':
      return STUB_LEGACY_ENA_APPROVED_DATA;
    case 'GET_REJECTED':
      return STUB_LEGACY_ENA_REJECTED_DATA;
  }
}

export const MOCK_PROJECT_ID = 'projectIdMock';

export const MOCK_INTERNAL_APPLICATION_ID = 'internalApplicationIdMock';

export const MOCK_DNO_DEMAND_SUMMARY_RESOURCE_DATA = {
  considerLoadMonitoring: 'No',
  cutOutRating: '3',
  dno: 'Test DNO',
  dnoApplicationEmail: 'noreply@jumptech.co.uk',
  dnoApplicationRequiredInAdvance: 'No',
  dnoId: 'testdno',
  dnoNotificationEmail: 'test@jumptech.co.uk',
  earthingArrangement: 'TT (Single Phase)',
  finalMaximumDemand: '33',
  inadequaciesOfSupply: 'No',
  inadequaciesOfSupplyDetails: 'Some details',
  isThreePhase: false,
  loadLimitingDeviceRequired: 'No',
  loopedService: 'No',
  maximumDemand: '31A',
  mpan: '1234567890123',
  newInstallationDetails: '32A',
  phases: 'Single Phase',
  proceed: 'Test Proceed',
  suggestLoadManageChargeStation: 'No'
};

const MOCK_DNO_DEMAND_SUMMARY_RESOURCE = {
  type: 'DnoDemandSummary',
  summary: MOCK_DNO_DEMAND_SUMMARY_RESOURCE_DATA
};

const MOCK_RESOURCES = [MOCK_DNO_DEMAND_SUMMARY_RESOURCE];

export const MOCK_PROJECT = {
  id: MOCK_PROJECT_ID,
  resources: MOCK_RESOURCES
} as unknown as Project;

const STUB_LEGACY_ENA_DATA = {
  status: 'SUBMITTED',
  internalApplicationId: MOCK_INTERNAL_APPLICATION_ID
};

const STUB_LEGACY_ENA_APPROVED_DATA = {
  status: 'APPROVED',
  internalApplicationId: MOCK_INTERNAL_APPLICATION_ID
};

const STUB_LEGACY_ENA_REJECTED_DATA = {
  status: 'REJECTED',
  internalApplicationId: MOCK_INTERNAL_APPLICATION_ID
};
