import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule, inject, provideAppInitializer } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { pickLanguage, TranslocoHttpLoader } from '@jump-tech-frontend/app-config';
import { JumptechDateSettings } from '@jump-tech-frontend/domain';
import {
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  TRANSLOCO_MISSING_HANDLER,
  TranslocoConfig,
  translocoConfig,
  TranslocoLoader,
  TranslocoMissingHandler,
  TranslocoModule,
  TranslocoService
} from '@ngneat/transloco';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { PathwayConfigurationService } from '../auth/services/pathway-configuration.service';

const DEFAULT_FALLBACK_LANG = 'en-GB';

@Injectable({ providedIn: 'root' })
export class PathwayTranslationsLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  /**
   * Fetches the translation file from the existing API endpoint.
   * @param lang
   */
  async getTranslation(lang: string) {
    const data = await new TranslocoHttpLoader(this.http, environment.translationPath).getTranslation(lang, 'pathway');
    if (data) {
      // save off the active i18ns to session storage, so we can then consume in core components
      sessionStorage.setItem(`activeI18ns`, JSON.stringify(data));
    }
    return data;
  }
}

/**
 * Preloads the active (browser) language.
 * @param transloco
 * @param pathwayConfiguration
 */
export const preloadLanguage = (transloco: TranslocoService, pathwayConfiguration: PathwayConfigurationService) => {
  return function () {
    return pathwayConfiguration
      .getConfig()
      .then(config => {
        let allowedLanguageList = [];
        if (config) {
          allowedLanguageList = [config?.i18n?.locale, ...(config?.i18n?.allowedLocales || []), DEFAULT_FALLBACK_LANG];
        }
        transloco.setAvailableLangs(allowedLanguageList);

        for (const desiredLang of navigator.languages) {
          const matchedLanguage = pickLanguage(allowedLanguageList, desiredLang, { loose: true });
          if (matchedLanguage) {
            transloco.setActiveLang(matchedLanguage);
            if (!environment.production) {
              console.log('\x1b[32m%s\x1b[0m', `Active LANG: [${matchedLanguage}]`);
            }
            break;
          } else {
            transloco.setActiveLang(DEFAULT_FALLBACK_LANG);
          }
          if (!environment.production) {
            console.log('\x1b[35m%s\x1b[0m', `Desired LANG: [${desiredLang}]`);
          }
        }
        const activeLanguage = transloco.getActiveLang();
        JumptechDateSettings.defaultLocale = activeLanguage;
        return firstValueFrom(transloco.load(activeLanguage));
      })
      .catch(() => {
        transloco.setAvailableLangs([DEFAULT_FALLBACK_LANG]);
        return firstValueFrom(transloco.load(DEFAULT_FALLBACK_LANG));
      });
  };
};

export class TranslocoMissingKeyHandler implements TranslocoMissingHandler {
  handle(key: string, config: TranslocoConfig) {
    datadogLogs.logger.error(`i18n: Missing translation for key: ${key} in language file: ${config['activLang']}`, {
      key,
      lang: config['activeLang']
    });
    return key;
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideAppInitializer(() => {
      const initializerFn = preloadLanguage(inject(TranslocoService), inject(PathwayConfigurationService));
      return initializerFn();
    }),
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        fallbackLang: [DEFAULT_FALLBACK_LANG],
        reRenderOnLangChange: true,
        failedRetries: 0,
        prodMode: environment.production,
        missingHandler: {
          useFallbackTranslation: true
        }
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: PathwayTranslationsLoader },
    { provide: TRANSLOCO_MISSING_HANDLER, useClass: TranslocoMissingKeyHandler }
  ]
})
export class TranslocoRootModule {}
