import { Directive, ElementRef, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  /* tslint:disable-next-line:directive-selector */
  selector: 'jui-file-input',
  host: {
    '(juiChange)': 'handleChangeEvent($event.detail)'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FileValueAccessor,
      multi: true
    }
  ]
})
export class FileValueAccessor implements ControlValueAccessor {
  private onChange: (value: File[] | null) => void = () => {
    /**/
  };
  private onTouched: () => void = () => {
    /**/
  };
  protected lastValue: null | FileList;

  constructor(protected el: ElementRef) {}

  writeValue(value: File[]) {
    setTimeout(() => {
      const fileInputEl = this.el.nativeElement.shadowRoot.querySelector('input#fileInput') as HTMLInputElement;
      if (value) {
        const dataTransfer = new DataTransfer();
        value.forEach(file => {
          dataTransfer.items.add(file);
        });

        const dataTransferFiles = dataTransfer.files == null ? null : (dataTransfer.files as FileList);
        if (fileInputEl) {
          fileInputEl.files = dataTransferFiles;
          this.lastValue = dataTransferFiles;
        }

        this.el.nativeElement.files = dataTransferFiles;
      } else {
        if (fileInputEl) {
          this.lastValue = null;
          fileInputEl.files = null;
        }

        this.el.nativeElement.files = null;
      }
    });
  }

  handleChangeEvent(value: File[]) {
    this.onChange(value);
  }

  @HostListener('focusout')
  _handleBlurEvent() {
    this.onTouched();
  }

  registerOnChange(fn: (value: File[] | null) => void) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.el.nativeElement.disabled = isDisabled;
  }
}
