import { ListDatasource, ProjectDatasource } from './datasource';
import { ImageAsset } from './image.asset';
import { IfDefinition } from './expression';

export interface CardOption {
  key: string;
  value: any;
  enables?: string[];
  disables?: string[];
  filter?: { key: string; value: string };
  scrollTo?: boolean;
  type?: string;
  description?: string;
  usei18n?: boolean;
}

export interface QuestionSubscription {
  key: string;
  trigger: string;
}

export interface ShowIf {
  $or?: any[];
  $and?: any[];
  $ne?: string;
  $eq?: string;
  $gt?: string;
  $lt?: string;
  $gte?: string;
  $lte?: string;
  $in?: string;
  $nin?: string;
  $mr?: string;
  $nmr?: string;
}

export interface DeprecatedShowIf {
  key?: string;
  value?: string | ShowIf;
}

export class QuestionBase<T> {
  value?: T;
  key: string;
  label: string;
  useI18ns?: boolean;
  type?: string;
  content?: string;
  placeholder?: string;
  required?: boolean;
  pattern?: string;
  errorMessage?: string;
  order?: number;
  controlType: string;
  targetLabel?: string;
  targetArg?: string;
  image?: ImageAsset | ImageAsset[];
  hint?: string;
  permanentHint?: boolean;
  icon?: string;
  options?: CardOption[];
  toggles?: string[];
  alert?: object;
  html?: string;
  show?: boolean;
  vertical?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  showIf?: IfDefinition | DeprecatedShowIf | ShowIf;
  showIfPopulated?: boolean;
  defaultValue?: any;
  min?: string;
  /** Used in multi-dropdown-with-value questions */
  defaultType?: string;
  regexs?: { type: string; patterns: string[] }[];
  targetOcr?: string;
  targetOcrField?: string;
  subscriptions?: QuestionSubscription[];
  sort?: 'asc' | 'desc';
  dropdownItemLabel?: string;
  dropdownValueLabel?: string;
  mapsConfig?: any;
  datasource?: ListDatasource;
  projectDatasource?: ProjectDatasource;
  /** if true, then force the input in a textbox to uppercase */
  forceUpper?: boolean;
  /** Button label for document viewer */
  buttonLabel?: string;
  /** Button icon for document viewer */
  buttonIcon?: string;
  countryCodes?: string[];
  /** Optional style property to control what the question looks like */
  style?: string;
  /** if specified, then store a list of key and value pairs for selected options in this field */
  fullValuesKey?: string;

  /** Add loading message to display in the inline spinner if the question is fetching data - defaults to '' */
  loadingMessage?: string;
  /** Add loading data qa hook to the inline spinner - defaults to 'spinner' */
  loadingQaHook?: string;
  /** Set loading colour for inline spinner animation - defaults to '#6c757d' */
  loadingColour?: string;
  /** Set loading background colour for inline spinner - defaults to 'rgba(0, 0, 0, 0)' (transparent) */
  loadingBgColour?: string;
  /** Set loading colour for inline spinner loading text - defaults to '#6c757d' */
  loadingTextColour?: string;
  /** Set loading animation type for inline spinner - defaults to 'ball-pulse' */
  loadingType?: string;
  /** Set loading animation/message to center justify - defaults to false */
  loadingCenter?: boolean;

  /** File Upload Question - accept string. */
  accept?: string;

  /** Likert Only */
  bottomHint?: string;
  topHint?: string;

  /** Modal */
  actionLabel?: string;

  constructor(
    options: {
      value?: T;
      key?: string;
      label?: string;
      type?: string;
      content?: string;
      placeholder?: string;
      required?: boolean;
      pattern?: string;
      errorMessage?: string;
      order?: number;
      controlType?: string;
      targetLabel?: string;
      targetArg?: string;
      image?: ImageAsset;
      hint?: string;
      icon?: string;
      options?: CardOption[];
      toggles?: string[];
      alert?: object;
      html?: string;
      show?: boolean;
      disabled?: boolean;
      showIf?: { key: string; value: string };
      showIfPopulated?: boolean;
      defaultValue?: any;
      regexs?: { type: string; patterns: string[] }[];
      targetOcr?: string;
      targetOcrField?: string;
      subscriptions?: QuestionSubscription[];
      sort?: 'asc' | 'desc';
      dropdownItemLabel?: string;
      dropdownValueLabel?: string;
      mapsConfig?: any;
      datasource?: ListDatasource;
      countryCodes?: string[];
      loadingMessage?: string;
      loadingQaHook?: string;
      loadingColour?: string;
      loadingBgColour?: string;
      loadingTextColour?: string;
      loadingType?: string;
      loadingCenter?: boolean;
      accept?: string;
    } = {}
  ) {
    if (options.value) {
      this.value = options.value;
    }
    this.key = options.key || '';
    this.label = options.label || '';
    this.type = options.type;
    this.content = options.content;
    this.placeholder = options.placeholder || '';
    this.required = !!options.required;
    this.pattern = options.pattern;
    this.errorMessage = options.errorMessage;
    this.order = options.order === undefined ? 0 : options.order;
    this.controlType = options.controlType || '';
    this.targetLabel = options.targetLabel || '';
    this.targetArg = options.targetArg;
    this.image = options.image;
    this.hint = options.hint || '';
    this.icon = options.icon || 'info';
    this.options = options.options || [];
    this.toggles = options.toggles || [];
    this.alert = options.alert;
    this.html = options.html;
    this.show = options.show === undefined ? true : options.show;
    this.disabled = options.disabled === undefined ? false : options.disabled;
    this.showIf = options.showIf;
    this.showIfPopulated = options.showIfPopulated === undefined ? false : options.showIfPopulated;
    this.defaultValue = options.defaultValue;
    this.regexs = options.regexs;
    this.targetArg = options.targetOcr;
    this.targetOcrField = options.targetOcrField;
    this.subscriptions = options.subscriptions || [];
    this.sort = options.sort || 'desc';
    this.dropdownItemLabel = options.dropdownItemLabel;
    this.dropdownValueLabel = options.dropdownValueLabel;
    this.mapsConfig = options.mapsConfig;
    this.datasource = options.datasource;
    this.countryCodes = options.countryCodes;
    this.loadingMessage = options.loadingMessage;
    this.loadingQaHook = options.loadingQaHook;
    this.loadingColour = options.loadingColour;
    this.loadingBgColour = options.loadingBgColour;
    this.loadingTextColour = options.loadingBgColour;
    this.loadingType = options.loadingType;
    this.loadingCenter = options.loadingCenter;
    this.accept = options.accept;
  }
}
