import { Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { QuestionBase } from '@jump-tech-frontend/domain';
import { ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { BaseQuestionComponent } from '../question.component';
import { ScrollService } from '../scroll.service';
import { untilDestroyed } from '@jump-tech-frontend/angular-common';
import { ImageAssetComponent } from '../image-asset.component';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { QuestionLabelContentComponent } from '@jump-tech-frontend/question-components';
import { NgIf, NgStyle } from '@angular/common';
import { I18nKeys } from '@jump-tech-frontend/app-config';

@Component({
  selector: `crds-question-textbox`,
  template: `
    <div *ngIf="show && form" [formGroup]="form">
      <!-- QUESTION LABEL -->
      <label *ngIf="question.label" class="form-label" [attr.for]="question.key" [attr.data-qa]="question.key">
        <question-label-content [question]="question" [isInvalid]="isInvalid" [i18ns]="i18ns"></question-label-content>
      </label>

      <!-- QUESTION INPUT -->
      <input
        [formControlName]="question.key"
        [id]="question.key"
        [type]="question['type'] || 'text'"
        [readOnly]="question.readOnly"
        (change)="onChange()"
        [ngStyle]="{ 'text-transform': this.question.forceUpper && 'uppercase' }"
        class="form-control mb-2"
        [attr.data-qa]="question.key + 'Input'"
        #textInput
        [attr.min]="getMinAttribute(question)"
      />

      <!-- QUESTION HINT -->
      <question-hint [question]="question"></question-hint>

      <!-- QUESTION IMAGE -->
      <div class="image-wrapper">
        <crds-image-asset [images]="images" [key]="question.key"></crds-image-asset>
      </div>
    </div>
  `,
  styles: [
    `
      [readonly] {
        color: -internal-light-dark(rgb(84, 84, 84), rgb(170, 170, 170));
        cursor: default;
        background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
        border-color: rgba(118, 118, 118, 0.3);
        pointer-events: none;
      }
    `
  ],
  imports: [
    NgIf,
    ReactiveFormsModule,
    QuestionLabelContentComponent,
    NgStyle,
    QuestionHintComponent,
    ImageAssetComponent
  ]
})
export class TextboxQuestionComponent extends BaseQuestionComponent implements OnChanges {
  @Input() override form: UntypedFormGroup;
  @Input() override question: QuestionBase<string>;
  @Input() i18ns: I18nKeys;
  @ViewChild('textInput') input: ElementRef;
  type: string;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;

  private untilDestroyed = untilDestroyed();

  constructor(private imageAssetFactory: ImageAssetFactory, private scrollService: ScrollService) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.question || !Object.prototype.hasOwnProperty.call(changes, 'question')) {
      return;
    }

    if (this.question.disabled === true && !this.show) {
      setTimeout(() => this.form?.get(this.question.key)?.disable());
    }
    this.images = this.imageAssetFactory.create(this.question.image, this.form);
    this.scrollService.scrollObservable.pipe(this.untilDestroyed()).subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
  }

  /** Though we can force to uppercase using CSS, that doesn't change the actual value
   *  so when we leave the field, we also need to upperCase the value too
   */
  onChange() {
    if (this.question.forceUpper) {
      const field = this.form.get(this.question.key);
      if (field?.value) {
        field.setValue(field.value.toUpperCase());
      }
    }
  }

  override get isInvalid() {
    if (this.checkForNegativeNumber()) {
      this.form.setErrors({ negativeNumber: true });
    }
    return (this.form?.get(this.question.key)?.touched && this.form?.get(this.question.key)?.invalid) ?? false;
  }

  override get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }
  getMinAttribute(question: QuestionBase<string>) {
    if (question.type === 'number' && question.min !== undefined) {
      return question.min;
    }
    return null;
  }
  checkForNegativeNumber() {
    if (this.question.type === 'number' && this.question.min !== undefined) {
      return this.form.get(this.question.key)?.value < 0 ? true : false;
    }
    return false;
  }
}
