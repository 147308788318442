<link *ngIf="!isFreeLeadsApp && cssUrl" rel="stylesheet" [href]="cssUrl" type="text/css" />
<link *ngIf="fontUrl" rel="stylesheet" [href]="fontUrl" />

<link *ngIf="isFreeLeadsApp" rel="stylesheet" href="/assets/leads.css" type="text/css" />
<div class="free-leads-container" *ngIf="isFreeLeadsApp; else mainApp">
  <router-outlet name="free-leads-management" (activate)="onActivate($event)"></router-outlet>
</div>

<ng-template #mainApp>
  <support-bar></support-bar>
  <global-errors></global-errors>
  <div *ngIf="isAppUpdateRequired" class="app-update-bar">
    <div class="app-update-bar__message">{{ 'appUpdate.message' | transloco }}</div>
    <div>
      <jui-button
        size="xs"
        color="info"
        (click)="invalidateAppCache()"
        [disabled]="appUpdateInProgress"
        [loading]="appUpdateInProgress"
        >{{ 'Buttons.update.text' | transloco }}</jui-button
      >
    </div>
  </div>
  <div class="app-wrapper" *ngIf="cssUrl" [class.sidebar--shrink]="menuClosed">
    <div class="grid-logo">
      <a
        *ngIf="logoUrl"
        routerLink="/home"
        [class.navbar-brand--demo]="useDemoLogo"
        class="navbar-brand tenant-brand"
        [attr.data-qa]="'navbarBrandLogo'"
      >
        <img [src]="useDemoLogo ? (themeService.brandLogo$ | async) : logoUrl" alt="" />
        <span *ngIf="isPreview" class="nonProdEnvironment" title="{{ buildEnvironmentDisplayName }}">(preview)</span>
        <span
          *ngIf="!isProduction && !isPreview"
          class="nonProdEnvironment"
          title="{{ buildEnvironmentDisplayName }}"
          >{{ buildEnvironmentDisplayName }}</span
        >
      </a>
      <br />
      <div *ngIf="loggedIn && isSmallScreen" class="app-top-bar__user">
        <app-user-panel></app-user-panel>
      </div>
    </div>
    <div class="grid-headerbar">
      <div class="app-top-bar">
        <div class="app-top-bar__search">
          <app-search maxResults="10" [target]="SearchTarget.inline"></app-search>
        </div>
        <div *ngIf="loggedIn && !isSmallScreen" class="app-top-bar__user">
          <app-user-panel></app-user-panel>
        </div>
      </div>
    </div>

    <div class="grid-sidebar">
      <app-navbar
        [isPreview]="isPreview"
        [isLocal]="isLocal"
        [isSmallScreen]="isSmallScreen"
        [branchName]="buildEnvironmentDisplayName"
        [branchShortName]="buildEnvironmentShortDisplayName"
        (navToggle)="toggleNavigation($event)"
        (onBranchReset)="resetBranch()"
      >
        (onChangeEnvironment)="changeEnvironment($event)">
      </app-navbar>
    </div>

    <div class="grid-maincontent">
      <div class="container-fluid">
        <router-outlet (activate)="onActivate($event)"></router-outlet>
        <toaster-container></toaster-container>
      </div>
    </div>
  </div>
</ng-template>
