import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { datadogLogs } from '@datadog/browser-logs';

@Injectable()
export class TraceHttpClient extends HttpClient {
  constructor(handler: HttpHandler) {
    super(handler);
  }

  request(...args: [any]): Observable<any> {
    const stack = Error().stack;
    return super.request(...args).pipe(
      catchError(err => {
        const datadogError = new Error('HTTP Client error');
        if (stack) {
          console.error('HTTP Client error stack\n', stack);
          datadogError.stack = stack;
        }
        datadogLogs.logger.error('HTTP Error', {}, datadogError);
        return throwError(() => {
          return err;
        });
      })
    );
  }
}

export function traceHttpClientCreator(httpHandler: HttpHandler): TraceHttpClient {
  return new TraceHttpClient(httpHandler);
}
