@if (vm$ | async; as vm) {
<div class="ena-attachments">
<table class="ena-attachments__table" [formGroup]="vm.form">
  <thead>
    <tr>
      <th class="ena-attachments__header"></th>
      <th class="ena-attachments__header">{{ vm.i18nFileNameLabel }}</th>
      <th class="ena-attachments__header">{{ vm.i18nUploadedByLabel }}</th>
      <th class="ena-attachments__header">{{ vm.i18nUploadDateLabel }}</th>
      <th class="ena-attachments__header"></th>
    </tr>
  </thead>
  <tbody>
    @for (attachment of vm.attachments; track $index) {
    <tr class="ena-attachments__row">
      <td class="ena-attachments__entry ena-attachments__entry--small">
        <div>
          <jui-checkbox [disable]="vm.selectionDisabled" [formControlName]="attachment.safeFormControlName" [attr.data-qa]="attachment.qaEnaAttachmentCheckbox" (juiChecked)="chooseFile($event.detail, attachment.safeFormControlName)"></jui-checkbox>
        </div>
      </td>
      <td class="ena-attachments__entry">{{ attachment.fileDisplayName }}</td>
      <td class="ena-attachments__entry">{{ attachment.uploadedByDisplayName }}</td>
      <td class="ena-attachments__entry">{{ attachment.uploadDateDisplay }}</td>
      <td class="ena-attachments__entry ena-attachments__entry--small">
        <jui-button
          color="secondary"
          size="xs"
          *ngIf="attachment.showDownload"
          (click)="downloadFile(attachment)"
          [title]="vm.i18nDownloadBtnLabel"
          [attr.data-qa]="attachment.qaEnaDownloadAttachmentBtn"
          >{{ vm.i18nDownloadBtnLabel }}</jui-button
        >
      </td>
    </tr>
    }
  @if (vm.uploadInProgress) {
    <tr>
      <td colspan="5">
        <div class="ena-attachments-progress">
          <div class="ena-attachments-progress__label">{{ vm.uploadProgress.label }}</div>
          <div
            class="ena-attachments-progress__bar"
            [class.ena-attachments-progress__bar--complete]="vm.uploadProgress.isComplete"
            [style.width]="vm.uploadProgress.barWidth"
            [style.min-height]="vm.uploadProgress.barHeight"
            [attr.aria-valuenow]="vm.uploadProgress.ariaValue"
            role="progressbar"
            aria-valuemin="0"
            aria-valuemax="100"
          >
          </div>
        </div>
      </td>
    </tr>
  }
  </tbody>
</table>
@if (vm.canUploadFiles) {
  <div class="ena-attachments__upload">
    <jui-button readOnlyDisable size="sm" (click)="uploadFile()" [attr.data-qa]="vm.qaUploadAttachmentBtn">{{ vm.i18nUploadLabel }}</jui-button>
    <input
      class="upload-file-input"
      id="generalUpload"
      style="display: none"
      type="file"
      (change)="onFileUploadEvent($event)"
      [accept]="vm.acceptedFileTypes"
    />
  </div>
}
</div>
}
