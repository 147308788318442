import { NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { Calendar, CalendarOptions, EventInput, ToolbarInput, ViewMountArg } from '@fullcalendar/core';
import { EventImpl } from '@fullcalendar/core/internal';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { JumptechDate } from '@jump-tech-frontend/domain';
import {
  NgbDatepickerModule,
  NgbDateStruct,
  NgbModal,
  NgbModalRef,
  NgbPopover,
  NgbPopoverModule
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, filter, Observable, Subscription, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import * as Analytics from '../../app.analytics';
import { UserService } from '../../auth/services/user.service';
import { ApiService } from '../../core/api.service';
import { Job, JobAssignment } from '../../core/domain/job';
import { User } from '../../core/domain/user';
import { FeatureFlagService } from '../../core/feature-flag/feature-flag.service';
import { HttpGateway } from '../../core/http-gateway.service';
import { JobTypeService } from '../../core/job-type.service';
import { LocalStorageGateway } from '../../core/local-storage-gateway.service';
import { IScheduleModalResult } from '../../feature-modules/planning-map/planning-map.model';
import { DropDownElement } from '../../shared/form-components/multiple-selection-dropdown.component';
import { ScheduleEventModalComponent } from '../components/schedule-event-modal/schedule-event-modal.component';
import { ScheduleKeyComponent } from '../components/schedule-key/schedule-key.component';
import { ErrorType } from '../schedule.model';
import {
  JOB_STATUSES_V2,
  JOB_STYLES,
  JT_EVENT,
  JT_EVENT_BG,
  JT_EVENT_PREASSIGNMENT,
  JT_EVENT_SELECTED,
  SCHEDULE_VIEWS,
  SELECTABLE_CAL_TYPES,
  TEMP_EVENT_ID
} from '../utils/schedule-constants';
import { ScheduleErrorsComponent } from './components/schedule-errors/schedule-errors.component';
import { ScheduleErrorsPresenter } from './components/schedule-errors/schedule-errors.presenter';
import { ScheduleEventSelectedJobDetailsToggleComponent } from './components/schedule-event-selected-job-details/components/schedule-event-selected-job-details-toggle/schedule-event-selected-job-details-toggle.component';
import { ScheduleEventSelectedJobDetailsComponent } from './components/schedule-event-selected-job-details/schedule-event-selected-job-details.component';
import { ScheduleEventTooltipComponent } from './components/schedule-event-tooltip/schedule-event-tooltip.component';
import { ScheduleFiltersPopoverComponent } from './components/schedule-filters-popover/schedule-filters-popover.component';
import { ScheduleJobsDisplayComponent } from './components/schedule-jobs-display/schedule-jobs-display.component';
import { ScheduleJobsDisplayPresenter } from './components/schedule-jobs-display/schedule-jobs-display.presenter';
import { SelectedJobAction } from './schedule.model';
import { SchedulePresenter } from './schedule.presenter';
import { ScheduleService } from './services/schedule.service';
import { JT_EVENT_PARTIAL } from './utils/schedule-constants';
import {
  ActiveCalendarElements,
  ActiveJobState,
  CalendarEventV3,
  EventInfo,
  JobEvent,
  JobInfo,
  Resource,
  ResourceInfo,
  ScheduleFilterInformation,
  ScheduleTypeListItem,
  SelectedEventType,
  SelectedScheduleFilters
} from './utils/schedule-types';
import {
  generateEventPreAssignment,
  generateScheduleEvent,
  getStyleForStatus,
  mapLegacyStatusesToNewStatuses,
  standardCalendarConfiguration,
  trimEventIdSuffixes
} from './utils/schedule.helper';

const selectedFiltersKey = `selectedScheduleFilters-${environment.name}`;

@Component({
  selector: 'schedule-v3',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss'],
  standalone: true,
  imports: [
    FullCalendarModule,
    NgxSpinnerModule,
    TranslocoModule,
    NgbDatepickerModule,
    NgbPopoverModule,
    ScheduleKeyComponent,
    ScheduleFiltersPopoverComponent,
    ScheduleEventModalComponent,
    ScheduleJobsDisplayComponent,
    ScheduleErrorsComponent,
    CoreComponentsAngularModule,
    NgSelectModule,
    FormsModule,
    NgIf,
    ScheduleErrorsComponent,
    ScheduleEventSelectedJobDetailsComponent,
    ScheduleEventSelectedJobDetailsToggleComponent
  ],
  providers: [ScheduleErrorsPresenter, SchedulePresenter]
})
export class ScheduleV3Component implements OnInit, OnDestroy {
  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild('eventTooltip') eventTooltip: ScheduleEventTooltipComponent;
  @ViewChild('p') filterPopover: NgbPopover;
  @ViewChild('viewSelect') viewSelectDropdown: NgSelectComponent;
  @Input() selectedJob: Job;
  @Input() actionId: string;
  @Input() context: string;
  @Output() scheduleSuccess: EventEmitter<IScheduleModalResult> = new EventEmitter<IScheduleModalResult>();

  calendarApi: Calendar;
  scheduleEventTypeList: ScheduleTypeListItem[];
  calendarEvents: EventInput[] = [];
  transientEvents: EventInput[] = [];
  activeScheduleEvent: EventInput = null;
  activeScheduleEventEl: ActiveCalendarElements[] = [];
  jobStatuses: DropDownElement[];
  jobStatusesV2: DropDownElement[];
  selectedEngineers: Resource[] = [];
  allEngineers: Resource[] = [];
  engineersForDropdown: DropDownElement[];
  scheduleViews: DropDownElement[] = [];
  defaultScheduleView: DropDownElement;
  selectedEventId: string;
  selectedEventType: SelectedEventType;
  selectedEventElements: Element[];
  eventStateBeforeEdit: EventInfo;
  // timers
  intervalEventRescheduleRetry: NodeJS.Timeout;
  timerRescheduleJob: NodeJS.Timeout;
  timerTooltipI18n: NodeJS.Timeout;
  timerRefreshSelectedJobDetails: NodeJS.Timeout;
  timerSelectedLinkedEvents: NodeJS.Timeout;
  timerLoadWeekView: NodeJS.Timeout;
  timerSelectedJobDetailsClosedSelectedEvent: NodeJS.Timeout;
  timerSelectedJobDetailsClosed: NodeJS.Timeout;
  timerSelectedJobDetailsSuccessClosed: NodeJS.Timeout;
  timerPopover: NodeJS.Timeout;

  CALENDAR_EVENT_REFRESH_DELAY = 4500;
  CALENDAR_EVENT_RESCHEDULE_SELECT_DELAY = 1000;
  CALENDAR_EVENT_RESCHEDULE_SELECT_RETRIES = 5;
  CALENDAR_EVENT_SELECT_LINKED_DELAY = 1500;
  selectedJobEventRendered = false;
  activeState: ActiveJobState = null;
  selectedJobEventChanged = false;

  currentView = '';
  viewTitle = '';
  activeFilters = 0;
  loadReadyToScheduleJobs = false;
  provisionalSchedulingEnabled = false;
  calendarOptionsReady = false;
  tenantTimezone: string;
  tenant: string;
  isSelectable = false;
  selectLinkedEventsInProgress = false;
  calendarConfig: CalendarOptions = {
    ...JSON.parse(JSON.stringify(standardCalendarConfiguration)),
    plugins: [
      dayGridPlugin,
      timeGridPlugin,
      interactionPlugin,
      resourceTimeGridPlugin,
      resourceTimelinePlugin,
      luxonPlugin
    ],
    droppable: true,
    schedulerLicenseKey: '0173554956-fcs-1657206500',
    initialView: 'resourceTimelineRollingMonth',
    resources: this.allEngineers,
    resourceAreaHeaderDidMount: this.handleResourceHeader.bind(this),
    resourceLabelDidMount: this.handleResourceRender.bind(this),
    viewDidMount: this.handleViewMount.bind(this),
    events: this.calendarEvents,
    height: 'auto',
    selectable: this.isSelectable,
    eventDidMount: this.handleEventRender.bind(this),
    eventResize: this.handleEventResize.bind(this),
    eventDrop: this.handleEventDrag.bind(this),
    eventDragStart: this.handleEventDragStart.bind(this),
    eventDragStop: this.handleEventResizeDragStop.bind(this),
    eventReceive: this.handleEventReceive.bind(this),
    eventResizeStart: this.handleEventResizeStart.bind(this),
    eventResizeStop: this.handleEventResizeDragStop.bind(this),
    datesSet: this.handleDateChange.bind(this),
    dateClick: this.handleDateClick.bind(this),
    eventClick: this.handleEventClick.bind(this),
    moreLinkClick: this.handleMoreLinkClick.bind(this)
  };

  selectedJobStatuses: string[] = [];
  selectedAbsenceTypes: string[] = [];
  selectedResources: string[] = [];

  scheduleEventModalSubs: Subscription[] = [];
  pollingEvents: Subscription;
  userSub: Subscription;
  featuresSub: Subscription;
  filtersLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  filtersLoaded$ = this.filtersLoaded.asObservable();
  user$: Observable<User>;
  toolTipTranslations: { [key: string]: string };

  constructor(
    public jobTypeService: JobTypeService,
    private apiService: ApiService,
    private userService: UserService,
    private scheduleService: ScheduleService,
    private spinnerService: NgxSpinnerService,
    private cdRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private translateService: TranslocoService,
    private featureFlagService: FeatureFlagService,
    private localStorageGateway: LocalStorageGateway,
    private httpGateway: HttpGateway,
    private presenter: SchedulePresenter,
    private sjdPresenter: ScheduleJobsDisplayPresenter,
    protected router: Router
  ) {}

  /**
   * t(list.scheduleEventType.appointment, list.scheduleEventType.holiday, list.scheduleEventType.other, list.scheduleEventType.sickness)
   */
  async ngOnInit() {
    this.presenter.listenToCalendarActiveState(this.handleActiveStateChanges);

    this.provisionalSchedulingEnabled = await this.featureFlagService.isFeatureEnabled('provisional-scheduling');
    this.setPreviouslySelectedFilters();

    this.loadReadyToScheduleJobs = this.context !== 'project';
    (this.calendarConfig.headerToolbar as ToolbarInput).right =
      'resourceTimelineRollingMonth,dayGridMonth,resourceTimelineWeek,resourceTimelineDay';
    this.calendarConfig.slotDuration = { days: 1 };
    this.calendarConfig.slotLabelInterval = { days: 1 };
    this.calendarOptionsReady = true;
    this.cdRef.detectChanges();

    if (this.isRescheduleJob()) {
      this.calendarConfig.initialView = 'resourceTimeLineWeek';
    }

    this.jobStatuses = Object.entries(JOB_STYLES).map(e => ({
      id: e[0],
      name: this.translateService.translate(e[1].key)
    }));
    this.jobStatusesV2 = this.provisionalSchedulingEnabled
      ? Object.entries(JOB_STATUSES_V2).map(status => ({
          id: status[0],
          name: this.translateService.translate(status[1].key)
        }))
      : Object.entries(JOB_STATUSES_V2)
          .filter(s => s[0] !== 'PROVISIONALLY_SCHEDULED')
          .map(status => ({
            id: status[0],
            name: this.translateService.translate(status[1].key)
          }));
    this.userSub = this.userService.userObservable.pipe(filter(user => user !== null)).subscribe(async user => {
      this.tenantTimezone = user.accessInfo?.configuration?.timezone;
      await this.fetchFilters(user.tenant);
    });
    this.featuresSub = this.featureFlagService.featureFlag$.pipe().subscribe(async () => {
      this.provisionalSchedulingEnabled = await this.featureFlagService.isFeatureEnabled('provisional-scheduling');
      this.loadReadyToScheduleJobs = this.context !== 'project';
    });

    await this.getSelectedJobInformation(this.selectedJob);
    this.getTranslationsForTooltip();
    this.calendarConfig.views.resourceTimelineWeek.buttonText = this.translateService.translate('common.week');
    this.calendarConfig.views.resourceTimelineRollingMonth.buttonText =
      this.translateService.translate('common.rollingMonth');
    this.calendarApi = this.calendarComponent.getApi();
    this.generateFilterData();
    this.updateActiveFilterAmount();
    this.setupViewDropdownElements();
    this.presenter.setSelectedJob(this.selectedJob);

    if (this.isRescheduleJob()) {
      await this.setupRescheduleJob();
    } else {
      this.calendarApi.gotoDate(JumptechDate.now().startOf('week').toIso());
    }
    this.viewTitle = this.calendarApi.view.title;
    this.overrideTodayButton();
  }

  isRescheduleJob(): boolean {
    return this.selectedJob?.jobAssignments?.length > 0 || !!this.selectedJob?.startDate;
  }

  private async setupRescheduleJob(): Promise<void> {
    this.defaultScheduleView = this.scheduleViews.find(view => view.id === 'week');
    this.loadWeekView(this.selectedJob.startDate);
    let selectedEvent;
    this.selectedJob.linkId = this.selectedJob.id.split('--')[0];

    if (this.selectedJob.jobAssignments.length > 1) {
      // build event id if we have multiple assignments
      let eventId = `${this.selectedJob.linkId ?? this.selectedJob.id}--${
        this.selectedJob.jobAssignments[0].assignedTo
      }`;
      if (this.selectedJob.jobAssignments[0].id) {
        eventId = `${eventId}--${this.selectedJob.jobAssignments[0].id}`;
      }
      this.selectedJob.id = eventId;
    }

    if (this.selectedJobEventRendered) {
      selectedEvent = this.calendarApi.getEventById(this.selectedJob.id);
      await this.openEventForRescheduling(selectedEvent);
    } else {
      let retryAmount = 0;
      this.intervalEventRescheduleRetry = setInterval(async () => {
        if (this.selectedJobEventRendered) {
          selectedEvent = this.calendarApi.getEventById(this.selectedJob.id);
          await this.openEventForRescheduling(selectedEvent);
          clearInterval(this.intervalEventRescheduleRetry);
        } else {
          retryAmount++;
          if (retryAmount === this.CALENDAR_EVENT_RESCHEDULE_SELECT_RETRIES) {
            this.presenter.showError(
              ErrorType.selectRescheduleJob,
              new Error('Unable to select calendar event for reschedule')
            );
            clearInterval(this.intervalEventRescheduleRetry);
          }
        }
      }, this.CALENDAR_EVENT_RESCHEDULE_SELECT_DELAY);
    }
  }

  ngOnDestroy(): void {
    this.presenter.dispatch(SelectedJobAction.destroy, {});

    this.stopPolling();
    // timers
    clearInterval(this.intervalEventRescheduleRetry);
    clearTimeout(this.timerRescheduleJob);
    clearTimeout(this.timerTooltipI18n);
    clearTimeout(this.timerRefreshSelectedJobDetails);
    clearTimeout(this.timerLoadWeekView);
    clearTimeout(this.timerSelectedJobDetailsClosedSelectedEvent);
    clearTimeout(this.timerSelectedJobDetailsSuccessClosed);
    clearTimeout(this.timerSelectedJobDetailsClosed);
    clearTimeout(this.timerPopover);
    clearTimeout(this.timerSelectedLinkedEvents);
    // subscriptions
    this.scheduleEventModalSubs.map(x => x?.unsubscribe);
    this.userSub?.unsubscribe();
    this.featuresSub?.unsubscribe();
  }

  async openEventForRescheduling(event: EventImpl): Promise<void> {
    const evtInfo = { event } as unknown as EventInfo;
    await this.handleEventClick(evtInfo);
    // update jobInfo
    const jobInfo = { ...this.activeState.jobInformation };
    jobInfo.context = this.context;
    this.presenter.dispatch(SelectedJobAction.updateJobInfoAndEvents, { jobInformation: jobInfo });
  }

  async getSelectedJobInformation(selectedJob?: Job): Promise<void> {
    if (!selectedJob) {
      return;
    }

    try {
      const res = (
        await this.httpGateway.get(`${environment.apiJobsUrl}`, {
          jobId: selectedJob.id
        })
      ).results as Job[];
      this.selectedJob.defaultDuration = res[0].defaultDuration;
    } catch (e) {
      this.selectedJob.defaultDuration = 2;
    }
  }

  getTranslationsForTooltip() {
    this.timerTooltipI18n = setTimeout(() => {
      this.toolTipTranslations = {
        name: this.translateService.translate('common.name'),
        phone: this.translateService.translate('common.phone'),
        riskAssessor: this.translateService.translate('common.riskAssessor'),
        siteSupervisor: this.translateService.translate('common.siteSupervisor'),
        chargePoint: this.translateService.translate('common.chargePoint'),
        start: this.translateService.translate('common.start'),
        end: this.translateService.translate('common.end'),
        allDay: this.translateService.translate('common.allDay')
      };
    });
  }

  async fetchFilters(tenant: string): Promise<void> {
    const configCalls: [Promise<ScheduleTypeListItem[]>, Promise<void>, Promise<void>] = [
      this.scheduleService.getScheduleEventTypes(tenant),
      this.jobTypeService.fetchJobTypes(),
      this.fetchEngineers()
    ];
    const vals = await Promise.all(configCalls);
    this.scheduleEventTypeList = vals[0];
    this.filtersLoaded.next(true);
  }

  setJobTypes(selected: string[]) {
    this.jobTypeService.setSelectedJobTypes(selected);
    this.updateSelectedFilters('selectedJobTypes', selected);
    this.filterEvents();
    this.updateActiveFilterAmount();
    Analytics.logEvent('CalendarFilter', { jobTypes: selected.join(',') });
  }

  setAbsenceTypes(selected: string[]) {
    this.selectedAbsenceTypes = selected;
    this.updateSelectedFilters('selectedScheduleEventTypes', selected);
    this.filterEvents();
    this.updateActiveFilterAmount();
    Analytics.logEvent('CalendarFilter', { absenceTypes: selected.join(',') });
  }

  setJobStatuses(selected: string[]) {
    const selectedLegacyStatuses = [];
    selected.forEach(status => {
      selectedLegacyStatuses.push(...JOB_STATUSES_V2[status].legacyStatuses);
    });
    this.selectedJobStatuses = selectedLegacyStatuses;
    this.updateSelectedFilters('selectedJobStatuses', selectedLegacyStatuses);
    this.filterEvents();
    this.updateActiveFilterAmount();
    Analytics.logEvent('CalendarFilter', { jobStatus: selected.join(',') });
  }

  setEngineers(selected: string[]) {
    this.selectedResources = selected;
    this.updateSelectedFilters('selectedTradespeople', selected);
    this.selectedEngineers = this.allEngineers.filter(r => {
      return !selected.length ? true : selected.includes(r.id);
    });
    this.calendarConfig.resources = this.selectedEngineers;
    this.filterEvents();
    this.updateActiveFilterAmount();
    Analytics.logEvent('CalendarFilter', { leadEngineers: selected.join(',') });
  }

  addToSchedule() {
    this.openEventModal('add');
  }

  unSubModalEvents() {
    if (this.scheduleEventModalSubs.length) {
      this.scheduleEventModalSubs.forEach(sub => sub.unsubscribe());
      this.scheduleEventModalSubs = [];
    }
  }

  handleEventRender(evt: EventInfo) {
    if (evt.event.display === 'background') {
      this.scheduleService.handleBackgroundEventRender(evt);
      return;
    }

    // Handle rendering of resized or dragged events separately
    if (evt.isResizing || evt.isDragging) {
      return;
    }
    if (!evt.event.id) {
      // we must have received an external event, so we need to set the end and temp ID
      this.setExternalEventProps(evt);
    }
    if (evt.event.id === TEMP_EVENT_ID) {
      this.addTempEventResourcesToTransientState(evt);
    }

    // Handle re-render of changed events whose changes we want to disregard
    if (this.eventStateBeforeEdit && this.eventStateBeforeEdit.event.id === evt.event.id) {
      evt.event.setExtendedProp('jobInformation', this.eventStateBeforeEdit.event.extendedProps.jobInformation);
    }
    this.scheduleService.handleEventRender(evt);
    if (evt.event.id === this.selectedJob?.id || evt.event.extendedProps?.linkId === this.selectedJob?.id) {
      if (evt.event.extendedProps.linkId) {
        this.selectedJob.linkId = evt.event.extendedProps?.linkId;
        this.selectedJob.id = evt.event.id;
      }
      this.selectedJobEventRendered = true;
    }

    if (evt.event.classNames.includes(JT_EVENT_PREASSIGNMENT) && !evt.event.extendedProps.jobAssigned) {
      this.handlePreAssignmentEventRender(evt);
    }

    if (this.selectedEventId) {
      this.handlePreviouslySelectedEvent(evt);
    }
  }

  handlePreviouslySelectedEvent(evt: EventInfo): void {
    if (
      this.selectedEventId &&
      this.selectedEventId === evt.event.extendedProps.linkId &&
      this.selectedEventType === SelectedEventType.link
    ) {
      evt.el.classList.add(JT_EVENT_SELECTED);
      if (!this.selectLinkedEventsInProgress) {
        clearTimeout(this.timerSelectedLinkedEvents);
        this.timerSelectedLinkedEvents = setTimeout(() => {
          this.setSelectedEvent(evt);
          this.updateActiveScheduleEventEl(this.selectedEventId, true);
          this.selectLinkedEventsInProgress = false;
        }, this.CALENDAR_EVENT_SELECT_LINKED_DELAY);
      }
      this.selectLinkedEventsInProgress = true;
    } else {
      if (this.selectedEventId && this.selectedEventId === evt.event.id) {
        evt.el.classList.add(JT_EVENT_SELECTED);
        this.selectedEventId = evt.event.id;
        this.toggleFcButtonState(false);
      }
    }
  }

  setExternalEventProps(evt: EventInfo): void {
    const defaultDuration = evt.event.extendedProps.jobInformation.defaultDuration;
    const endDate = JumptechDate.from(evt.event.start).plus({ hours: defaultDuration });
    const startDate = JumptechDate.from(evt.event.start);
    evt.event.setEnd(endDate.toIso());
    evt.event.setStart(startDate.toIso());
    this.addTempEventResourcesToTransientState(evt);
  }

  addTempEventResourcesToTransientState(evt: EventInfo): void {
    const resourcesIdsForEvent = evt.event.getResources().map(r => r.id);

    // todo better to be a hash map
    resourcesIdsForEvent.forEach(resourceId => {
      const tempEventId = `${evt.event.extendedProps.jobInformation.id}--${resourceId}--${TEMP_EVENT_ID}`;
      evt.event.setProp('id', tempEventId);
      evt.event.setExtendedProp('linkId', evt.event.extendedProps.jobInformation.id);
      const currentActiveIds = this.activeScheduleEventEl.map(x => x.resourceId);
      if (!currentActiveIds.includes(resourceId)) {
        this.activeScheduleEventEl.push({ resourceId, el: evt.el, eventId: tempEventId });
      }
    });
  }

  refreshSelectedJobDetailsEvent(event: EventInput): void {
    // refresh more details pane with updated Event
    this.timerRefreshSelectedJobDetails = setTimeout((): void => {
      const activeEvents = this.updateActiveScheduleEventEl(event.extendedProps.linkId, true);
      this.stopPolling();
      this.presenter.dispatch(SelectedJobAction.load, {
        jobInformation: event.extendedProps.jobInformation,
        activeEvents
      });
      this.sjdPresenter.preventDraggableItems();
    });
  }

  /**
   * Called when an event is resized (duration changed)
   * @param evt
   */
  // has two deltas for start and end
  handleEventResize(evt: EventInfo): void {
    this.updateEventDataFromDragResize(evt);
  }

  assignTradespersonToJob(job: JobInfo): void {
    const firstDateInCalendar = this.calendarApi.getDate().toISOString();
    const event = generateEventPreAssignment(
      firstDateInCalendar,
      { ...job },
      this.selectedEngineers[0].id,
      this.currentView !== 'dayGridMonth'
    );
    this.calendarConfig.events = [...this.calendarEvents, ...this.transientEvents, event];
  }

  handlePreAssignmentEventRender(evt: EventInfo): void {
    if (this.selectedEventId) {
      this.unselectEvent();
    }

    // Only open Selected Job Details on first click of Assign button
    if (!this.activeState) {
      const eventInput: EventInput = {
        ...evt.event.toPlainObject()
      };

      // Handle case where Month view doesn't have any event resources
      const eventResources = evt.event.getResources();
      if (eventResources.length) {
        eventInput.assignedToDisplayName = eventResources[0].title;
        eventInput.assignedTo = eventResources[0].id;
        eventInput.resourceIds = [eventResources[0].id];
      }

      this.persistTransientEventToCalendar(eventInput);
      const defaultDuration = evt.event.extendedProps?.jobInformation?.defaultDuration || 2;
      const start = JumptechDate.from(
        new Date(new Date(evt.event.extendedProps.jobInformation.startDateTimestamp).setHours(9, 0, 0, 0))
      ).toIso();
      const end = JumptechDate.from(start).plus({ hours: defaultDuration }).toIso();
      const jobInfo: JobInfo = {
        ...eventInput.extendedProps.jobInformation,
        actionId: this.actionId ?? null,
        startDateTimestamp: start,
        endDateTimestamp: end,
        jobAssignments: [],
        setDefaultStartTime: true,
        fullJobStart: start,
        fullJobEnd: end,
        linkId: eventInput.extendedProps.jobInformation.id
      };

      this.stopPolling();

      const activeEvents = this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
      this.presenter.dispatch(SelectedJobAction.load, { jobInformation: jobInfo, activeEvents });
      this.sjdPresenter.preventDraggableItems();
    }
  }

  createBackgroundEvent(evt: any, draggingOrResizing: boolean): void {
    const existingBgEvents = this.calendarEvents.filter(evt => evt.display === 'background');
    let fullJobStart: string;
    let fullJobEnd: string;
    let idParts: string[];

    if (evt.event) {
      idParts = evt.event.id.split('--');
      fullJobStart = evt.event.extendedProps.fullJobStart;
      fullJobEnd = evt.event.extendedProps.fullJobEnd;
    } else {
      idParts = evt.id.split('--');
      fullJobStart = evt.extendedProps.fullJobStart;
      fullJobEnd = evt.extendedProps.fullJobEnd;
    }

    const resId = evt.newResource ? evt.newResource.id : idParts[1];
    const evtId = idParts[0];
    // remove old BG event from previous resource
    if (evt.oldResource) {
      const oldBgIndex = this.calendarEvents.findIndex(x => {
        if (x.resourceIds && x.resourceIds.length) {
          x.resourceIds[0] === evt.oldResource.id && x.display === 'background';
        }
      });
      if (oldBgIndex !== -1) {
        this.calendarEvents.splice(oldBgIndex, 1);
        this.calendarConfig.events = [...this.calendarEvents];
      }
    }

    const alreadyRenderedBg = existingBgEvents.find(existing => {
      return existing.resourceIds.indexOf(resId) !== -1 && existing.extendedProps.id === evtId;
    });

    if (!alreadyRenderedBg) {
      const bgEvent: EventInput = {
        display: 'background',
        resourceIds: [resId],
        start: JumptechDate.from(fullJobStart).toIso(),
        end: JumptechDate.from(fullJobEnd).toIso(),
        backgroundColor: getStyleForStatus('PROVISIONALLY_SCHEDULED').backgroundColor,
        classNames: [JT_EVENT_BG],
        extendedProps: {
          id: evtId,
          dragged: draggingOrResizing
        }
      };
      this.calendarEvents.push(bgEvent);
      this.calendarConfig.events = [...this.calendarEvents];
    } else {
      for (const evt of this.calendarEvents) {
        if (evt.resourceIds && evt.resourceIds.indexOf(resId) !== -1 && evt.display === 'background') {
          evt.start = fullJobStart;
          evt.end = fullJobEnd;
        }
      }
      this.calendarConfig.events = [...this.calendarEvents];
    }
  }

  moveLinkedJobs(evt: EventInfo, newResource = false): void {
    // set the updated times in the case where we move a single temp event
    let updatedFullStart = evt.event.start.toISOString();
    let updatedFullEnd = evt.event.end.toISOString();

    this.activeState.activeEvents.forEach(eventEl => {
      const linkedEventId = eventEl.el.getAttribute('data-eventid');
      if (linkedEventId !== evt.event.id) {
        const linkedEvent = this.calendarApi.getEventById(linkedEventId);
        // todo check delta is not all zeros
        if (linkedEvent && linkedEvent.extendedProps.isPartial) {
          linkedEvent.moveDates(evt.delta);

          const update = { eventId: linkedEventId, start: linkedEvent.start, end: linkedEvent.end };
          const thisJA = this.activeState.jobInformation.jobAssignments.find(
            ja => ja.id === linkedEventId.split('--')[2]
          );
          thisJA.start = linkedEvent.start.toISOString();
          thisJA.startDate = linkedEvent.start.toISOString();
          thisJA.end = linkedEvent.end.toISOString();
          thisJA.endDate = linkedEvent.end.toISOString();

          const eventToUpdate = (this.calendarConfig.events as EventInput[]).find(x => x.id === linkedEventId);
          eventToUpdate.start = update.start;
          eventToUpdate.end = update.end;
          const updatedStart = update.start.toISOString();
          const updatedEnd = update.end.toISOString();

          // update constraints for partials
          const movedEvent = this.calendarApi.getEventById(evt.event.id);
          if (eventToUpdate.constraint) {
            eventToUpdate.fullJobStart = movedEvent.startStr;
            eventToUpdate.fullJobEnd = movedEvent.endStr;
            eventToUpdate.constraint = {
              ...eventToUpdate.constraint,
              start: movedEvent.startStr,
              end: movedEvent.endStr
            };
          }

          // update assignments
          const assignment = evt.event.extendedProps.jobInformation.jobAssignments.find(ja =>
            ja.id === linkedEventId.split('--')[2] || ja.id
              ? trimEventIdSuffixes(ja.id) === trimEventIdSuffixes(linkedEventId.split('--')[2])
              : ''
          );

          if (assignment?.start || assignment?.startDate) {
            assignment.start = updatedStart;
            assignment.startDate = updatedStart;
          }
          if (assignment?.end || assignment?.endDate) {
            assignment.end = updatedEnd;
            assignment.endDate = updatedEnd;
          }

          linkedEvent.setExtendedProp('fullJobStart', movedEvent.startStr);
          linkedEvent.setExtendedProp('fullJobEnd', movedEvent.endStr);

          updatedFullStart = movedEvent.startStr;
          updatedFullEnd = movedEvent.endStr;

          this.findAndRerenderChangedCalendarEvents(linkedEventId, updatedStart, updatedEnd, linkedEvent);

          if (linkedEvent.extendedProps.isPartial) {
            this.createBackgroundEvent({ event: linkedEvent }, false);
          }
        }
      }
    });

    setTimeout(() => {
      const activeEvents = this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
      const eventToUpdate = evt.event.toPlainObject();
      eventToUpdate.extendedProps.fullJobStart = updatedFullStart;
      eventToUpdate.extendedProps.fullJobEnd = updatedFullEnd;
      eventToUpdate.extendedProps.jobInformation.fullJobStart = updatedFullStart;
      eventToUpdate.extendedProps.jobInformation.fullJobEnd = updatedFullEnd;

      const updatedJobInfo = {
        ...this.activeState.jobInformation,
        fullJobStart: updatedFullStart,
        fullJobEnd: updatedFullEnd
      };
      const action = newResource ? SelectedJobAction.newResource : SelectedJobAction.fullJobDragged;
      this.presenter.dispatch(action, { jobInformation: updatedJobInfo, activeEvents });
      this.stopPolling();
    });
  }

  openSelectedJobFromResize(evt: EventInfo, action: SelectedJobAction): void {
    const jobInfo = this.activeState.jobInformation;
    const activeEvents = this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
    this.presenter.dispatch(action, { jobInformation: jobInfo, activeEvents });
    this.sjdPresenter.preventDraggableItems();
    this.setSelectedEvent(evt);
    this.stopPolling();
  }

  updateCalendarEventDateTime(evt: EventInfo): void {
    // find the calendar event and updates to the new date times

    if (evt.newResource) {
      // create background event for this resource
      // update this event id string
      const thisEvent = this.calendarEvents.find(x => x.id === evt.event.id);

      const oldEvent = this.calendarApi.getEventById(thisEvent.id);
      const newEvent = oldEvent.toPlainObject();
      newEvent.editable = true;
      newEvent.durationEditable = true;
      newEvent.resourceEditable = true;
      newEvent.startEditable = true;

      const idParts = thisEvent.id.split('--');
      idParts[1] = evt.newResource.id;
      thisEvent.id = idParts.join('--');
      evt.event.setProp('id', thisEvent.id);
      thisEvent.resourceIds = [evt.newResource.id];
      thisEvent.start = evt.event.start;
      thisEvent.end = evt.event.end;

      newEvent.id = idParts.join('--');
      oldEvent.remove();
      this.calendarApi.addEvent(newEvent);
      // this.scheduleService.handleEventRender(evt);

      this.calendarConfig.events = [...this.calendarEvents];
      if (newEvent.extendedProps.isPartial) {
        this.createBackgroundEvent(evt, true);
      }
      this.presenter.dispatch(SelectedJobAction.updateJobInfoAndEvents, {
        jobInformation: evt.event.extendedProps.jobInformation,
        activeEvents: this.activeState.activeEvents
      });
    }

    if (evt.event.extendedProps.linkId && evt.delta) {
      // move linked jobs by the same delta amount
      if (!evt.event.extendedProps.isPartial) {
        this.moveLinkedJobs(evt, !!evt.newResource);
      }
    } else {
      this.openSelectedJobFromResize(evt, SelectedJobAction.fullJobResized);
    }

    this.calendarEvents.forEach(event => {
      if (event.id === evt.event.id) {
        event.start = evt.event.start;
        event.end = evt.event.end;
        event.resourceIds = evt.event.getResources().map(x => x.id);
      }
    });
    this.calendarConfig.events = [...this.calendarEvents];
  }

  private removeBackgroundEvent(): void {
    // Remove any existing background events
    this.calendarConfig.events = [
      ...(this.calendarConfig.events as EventInput[]).filter(x => x?.display !== 'background')
    ];
    this.calendarEvents = this.calendarEvents.filter(x => x?.display !== 'background');
  }

  private handleFullJobDragResize(evt: EventInfo): void {
    const eventToUpdate = evt.event.toPlainObject();
    const eventResources = evt.event.getResources();
    const assignedResources = eventResources.length ? eventResources : undefined;
    let updatedAssignmentId = null;
    let updatedAssignmentDisplayName = null;
    let jobAssignmentToUpdate = null;
    let jobInfo;
    // todo can we always just use the active state - check drag without details active?
    if (this.activeState) {
      jobInfo = { ...this.activeState.jobInformation };
    } else {
      jobInfo = { ...evt.event.extendedProps.jobInformation };
    }

    if (!this.activeScheduleEvent) {
      const plainObjectEvent = evt.event.toPlainObject();
      this.activeScheduleEvent = { ...plainObjectEvent, editable: true, resourceEditable: true };
    }

    if (!this.activeState) {
      const activeEvents = this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
      this.presenter.dispatch(SelectedJobAction.load, { jobInformation: jobInfo, activeEvents });
    }

    // if we  are a temp event add full job start/end
    if (evt.event.extendedProps.dragged) {
      jobInfo.fullJobStart = evt.event.start.toISOString();
      jobInfo.fullJobEnd = evt.event.end.toISOString();
      const activeEvents = this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
      this.presenter.dispatch(SelectedJobAction.fullJobDragged, { jobInformation: jobInfo, activeEvents });
    }

    if (assignedResources && evt.newResource) {
      evt.event.setExtendedProp('assignedToDisplayName', evt.newResource.title);
      evt.event.setExtendedProp('assignedTo', evt.newResource.id);

      // update jobInformation for resource we are editing
      jobAssignmentToUpdate = jobInfo.jobAssignments.find(x => x.assignedTo === evt.oldResource.id);

      if (jobAssignmentToUpdate) {
        updatedAssignmentId = evt.newResource.id;
        updatedAssignmentDisplayName = evt.newResource.title;
      }
    }

    // update full job start and end times
    evt.event.setExtendedProp('fullJobStart', evt.event.start.toISOString());
    evt.event.setExtendedProp('fullJobEnd', evt.event.end.toISOString());

    // update calendar events array
    const eventsToPatch = this.calendarEvents.filter(
      x => x.linkId === evt.event.extendedProps.linkId || x.extendedProps?.linkId === evt.event.extendedProps.linkId
    );
    if (eventsToPatch.length) {
      eventsToPatch.forEach(patch => {
        const update = this.calendarEvents.find(x => x.id === patch.id);
        const calEvent = this.calendarApi.getEventById(patch.id);
        calEvent.setExtendedProp('fullJobStart', evt.event.start.toISOString());
        calEvent.setExtendedProp('fullJobEnd', evt.event.end.toISOString());
        update.fullJobStart = evt.event.start.toISOString();
        update.fullJobEnd = evt.event.end.toISOString();
        if (!update?.isPartial) {
          update.start = evt.event.start.toISOString();
          update.end = evt.event.end.toISOString();
        }
        if (update?.isPartial) {
          update.constraint = {
            ...update.constraint,
            start: evt.event.start.toISOString(),
            end: evt.event.end.toISOString()
          };
        }
      });

      this.calendarConfig.events = [...this.calendarEvents, ...this.transientEvents];
    }

    jobInfo.fullJobStart = JumptechDate.from(eventToUpdate.start).toIso();
    jobInfo.fullJobEnd = JumptechDate.from(eventToUpdate.end).toIso();

    if (jobAssignmentToUpdate) {
      jobAssignmentToUpdate.assignedTo = updatedAssignmentId;
      jobAssignmentToUpdate.assignedToDisplayName = updatedAssignmentDisplayName;
    }

    const activeEvents = this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
    this.presenter.dispatch(SelectedJobAction.fullJobDragged, { jobInformation: jobInfo, activeEvents });

    const nonPartials = this.activeState.activeEvents.filter(ae => !ae.el.classList.contains(JT_EVENT_PARTIAL));
    nonPartials.forEach(active => {
      const calEvent = this.calendarApi.getEventById(active.eventId);
      const evtToUpdate = {
        event: calEvent,
        el: active.el,
        view: { type: this.currentView }
      } as unknown as EventInfo;

      evtToUpdate.event.setStart(eventToUpdate.start);
      evtToUpdate.event.setEnd(eventToUpdate.end);

      setTimeout(() => this.scheduleService.handleEventRender(evtToUpdate));
    });

    evt.event.setExtendedProp('jobInformation', jobInfo);
    evt.event.setExtendedProp('fullJobStart', JumptechDate.from(eventToUpdate.start).toIso());
    evt.event.setExtendedProp('fullJobEnd', JumptechDate.from(eventToUpdate.end).toIso());
    this.updateCalendarEventDateTime(evt);
  }

  private handlePartialDragResize(evt: EventInfo): void {
    const eventHasJobInformation = !!evt.event.extendedProps.jobInformation;
    let jobInfo = { ...evt.event.extendedProps.jobInformation };
    // update jobInformation
    jobInfo.fullJobStart = evt.event.extendedProps.fullJobStart;
    jobInfo.fullJobEnd = evt.event.extendedProps.fullJobEnd;

    if (!this.activeState) {
      const activeEvents = this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
      this.presenter.dispatch(SelectedJobAction.load, { jobInformation: jobInfo, activeEvents });
    }

    if (!eventHasJobInformation) {
      // we should try the active event info
      jobInfo = { ...this.activeState.jobInformation };
    }

    let isNewEvent = false;
    let assignmentId;
    let jobAssignmentToUpdate;

    if (evt.event.id && trimEventIdSuffixes(evt.event.id).split('--').length === 2) {
      isNewEvent = true;
    }

    if (isNewEvent) {
      assignmentId = evt.event.id.split('--')[2];
      jobAssignmentToUpdate = jobInfo.jobAssignments.find(x => x.id === assignmentId);
    } else {
      assignmentId = trimEventIdSuffixes(evt.event.id.split('--')[2]);
      jobAssignmentToUpdate = jobInfo.jobAssignments.find(x => trimEventIdSuffixes(x.id) === assignmentId);
    }

    // update jobInformation for slot we are editing
    if (jobAssignmentToUpdate) {
      jobAssignmentToUpdate.start = evt.event.start.toISOString();
      jobAssignmentToUpdate.startDate = evt.event.start.toISOString();
      jobAssignmentToUpdate.end = evt.event.end.toISOString();
      jobAssignmentToUpdate.endDate = evt.event.end.toISOString();
    }

    // update calendar
    const cachedEvent = this.calendarEvents.find(event => event.id === evt.event.id);
    const calEvent = this.calendarApi.getEventById(evt.event.id);
    cachedEvent.start = evt.event.start.toISOString();
    cachedEvent.end = evt.event.end.toISOString();
    calEvent.setEnd(evt.event.end.toISOString());
    calEvent.setStart(evt.event.start.toISOString());

    this.calendarConfig.events = [...this.calendarEvents];

    evt.event.setExtendedProp('jobInformation', jobInfo);

    // get all linked eventIds
    const linkedEvents = this.calendarEvents.filter(ce => ce.id?.split('--')[0] === evt.event.id.split('--')[0]);
    linkedEvents.forEach(event => {
      if (event.extendedProps) {
        event.extendedProps.jobInformation = jobInfo;
      } else {
        event.jobInformation.jobAssignments = jobInfo.jobAssignments;
      }
      this.calendarApi.getEventById(event.id).setExtendedProp('jobInformation', jobInfo);
    });

    // update event for rendering (case where event props may be missing)
    if (!evt.event.extendedProps.eventType) {
      evt.event.setExtendedProp('eventType', 'Job');
    }

    this.activeScheduleEvent = evt.event.toPlainObject();
    this.scheduleService.handleEventRender(evt);

    // open selected job details
    this.openSelectedJobFromResize(evt, SelectedJobAction.slotResizeDrag);
  }

  updateEventDataFromDragResize(evt: EventInfo): void {
    if (!this.eventStateBeforeEdit) {
      this.eventStateBeforeEdit = { ...evt, event: JSON.parse(JSON.stringify(evt.oldEvent)) };
    }
    this.setSelectedEvent(evt, false);
    if (evt.event.extendedProps.isPartial) {
      this.handlePartialDragResize(evt);
    } else {
      this.handleFullJobDragResize(evt);
    }

    this.selectedJobEventChanged = true;
    this.setEditableOnEvents(false);
  }

  /**
   * Called when an event in the calendar is dragged
   * @param evt
   */
  handleEventDrag(evt: EventInfo): void {
    this.updateEventDataFromDragResize(evt);
  }

  setEditableOnEvents(editable: boolean): void {
    let otherEvents = this.calendarEvents.filter(evt => evt.display !== 'background');

    if (!editable) {
      otherEvents = otherEvents.filter(evt => evt.linkId !== this.activeState.jobInformation.id.split('--')[0]);
      otherEvents.forEach(event => {
        event.editable = editable;
        event.durationEditable = editable;
        event.resourceEditable = editable;
        event.startEditable = editable;
      });
    } else {
      // Only make the scheduleable/rescheduleable events editable
      otherEvents.forEach(event => {
        if (event.jobInformation && JOB_STATUSES_V2['SCHEDULED'].legacyStatuses.includes(event.jobInformation.status)) {
          event.editable = editable;
          event.durationEditable = editable;
          event.resourceEditable = editable;
          event.startEditable = editable;
        }
      });
    }

    this.calendarConfig.events = [...this.calendarEvents];
  }

  setFormDisabled(evt: EventInfo): void {
    if (this.activeState) {
      const activeEvents = this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
      this.presenter.dispatch(SelectedJobAction.resizeDragStart, { activeEvents });
    }
  }

  setFormEnabled(evt: EventInfo) {
    if (this.activeState) {
      const activeEvents = this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
      this.presenter.dispatch(SelectedJobAction.resizeDragStop, { activeEvents });
    }
  }

  handleEventResizeStart(evt: EventInfo): void {
    this.setFormDisabled(evt);
    if (evt.event.extendedProps.isPartial) {
      this.createBackgroundEvent(evt, true);
    }
  }

  handleEventDragStart(evt: EventInfo): void {
    this.setFormDisabled(evt);
    if (evt.event.extendedProps.isPartial) {
      this.createBackgroundEvent(evt, true);
    }
  }

  handleEventResizeDragStop(evt: EventInfo) {
    setTimeout(() => {
      this.setFormEnabled(evt);
    }, 0);
  }

  private getLinkedEventElements(eventId: string, isGroup = false): HTMLElement[] {
    // todo - see if we can remove isGroup here as we should always have a linkId
    return isGroup
      ? Array.from(document.querySelectorAll(`[data-linkid='${eventId}']`))
      : Array.from(document.querySelectorAll(`[data-eventid='${eventId}']`));
  }

  updateActiveScheduleEventEl(eventOrLinkId: string, isGroup = false): ActiveCalendarElements[] {
    const elements: HTMLElement[] = this.getLinkedEventElements(eventOrLinkId, isGroup);
    const activeCalEls: ActiveCalendarElements[] = [];

    // Ignore update of resource IDs in month view
    if (this.currentView === 'dayGridMonth') {
      elements.forEach(element => {
        const eventId = element.getAttribute('data-eventid');
        const isPartial = element.classList.contains(JT_EVENT_PARTIAL);
        activeCalEls.push({ resourceId: '', el: element, eventId, isPartial });
      });
      return activeCalEls;
    }
    elements.forEach(element => {
      const elementResourceId = element.closest('td.fc-timeline-lane').getAttribute('data-resource-id');
      const eventId = element.getAttribute('data-eventid');
      const isPartial = element.classList.contains(JT_EVENT_PARTIAL);
      activeCalEls.push({ resourceId: elementResourceId, el: element, eventId, isPartial });
    });

    return activeCalEls;
  }

  persistTransientEventToCalendar(event: EventInput): void {
    event = { ...event, editable: true, resourceEditable: true };
    this.activeScheduleEvent = { ...event };
    // if (event.extendedProps.dragged) {
    this.transientEvents = [...this.transientEvents, event];
    this.calendarEvents = [...this.calendarEvents, ...this.transientEvents];
    this.calendarConfig.events = [...this.calendarEvents];
    // }
  }

  private findAndRerenderChangedCalendarEvents(eventId, start, end, evt): void {
    const calEntry = this.calendarApi.getEventById(eventId);
    let elem;
    if (this.activeState.activeEvents.length) {
      elem = this.activeState.activeEvents.find(ae => ae.eventId === eventId)?.el;
    }
    if (!elem) {
      elem = document.querySelector(`[data-eventid="${eventId}"]`);
    }

    const parsedEvent = JSON.parse(JSON.stringify(evt));
    parsedEvent.start = start;
    parsedEvent.end = end;
    parsedEvent.extendedProps.eventType = 'Job';
    parsedEvent.extendedProps.isPartial = calEntry?.extendedProps?.isPartial ?? false;
    parsedEvent.id = eventId;

    parsedEvent.extendedProps.jobInformation.id = eventId;

    if (calEntry && calEntry?.extendedProps) {
      parsedEvent.extendedProps.jobInformation.assignedTo =
        calEntry.extendedProps.job?.assignedTo ?? calEntry.extendedProps.assignedTo;
      parsedEvent.extendedProps.assignedTo =
        calEntry.extendedProps.job?.assignedTo ?? calEntry.extendedProps.assignedToDisplayName;
      parsedEvent.extendedProps.assignedToDisplayName = calEntry.extendedProps.job?.assignedToDisplayName;
      parsedEvent.extendedProps.jobInformation.assignedToDisplayName =
        calEntry.extendedProps.job?.assignedToDisplayName;
    }

    // reselect temp events
    if (evt.extendedProps.jobAssigned === false) {
      elem = document.querySelector(`[data-eventid="${eventId}"]`);
    }

    if (calEntry && !!parsedEvent.extendedProps?.jobInformation?.assignedTo) {
      const eventInfo = {
        event: parsedEvent,
        el: elem,
        view: { type: this.currentView }
      } as unknown as EventInfo;
      this.scheduleService.handleEventRender(eventInfo);
      if (this.activeState.lastChange === 'start') {
        // The order of operations matters depending on whether you're just updating the start or the end
        calEntry.setEnd(parsedEvent.end);
        calEntry.setStart(parsedEvent.start);
      } else {
        calEntry.setStart(parsedEvent.start);
        calEntry.setEnd(parsedEvent.end);
      }

      for (const evt of this.calendarEvents) {
        if (evt.id === eventId) {
          evt.start = parsedEvent.start;
          evt.end = parsedEvent.end;
        }
      }

      this.setSelectedEvent(eventInfo, false, false);
    }
  }

  handleFullJobChange(): void {
    if (!this.activeState.activeEvents.length) {
      const linkId = this.activeState.activeEvents[0].eventId.split('--')[0];
      // todo update state
      this.updateActiveScheduleEventEl(linkId, true);
    }
    // we are a full job change so update all linked events that don't have slots
    const fullJobEvents: ActiveCalendarElements[] = this.activeState.activeEvents.filter(
      activeEl => !activeEl.isPartial
    );

    if (!this.activeState.jobInformation.fullJobStart && !this.activeState.jobInformation.fullJobEnd) {
      return;
    }

    const fullStart = this.activeState.jobInformation.fullJobStart;
    const fullEnd = this.activeState.jobInformation.fullJobEnd;

    fullJobEvents.forEach(fe => {
      let evtId = fe.eventId;
      let calEntry = this.calendarEvents.find(ce => ce.id === evtId);
      if (!calEntry) {
        // search for transient events
        calEntry = this.transientEvents.find(ce => ce.id === evtId);
      }

      if (calEntry) {
        calEntry.start = fullStart;
        calEntry.end = fullEnd;
      }

      const idParts = fe.eventId.split('--');
      const eventIdResource = idParts[1];
      if (eventIdResource !== fe.resourceId && fe.resourceId) {
        // todo should be a state update for events
        // we need to update the activeEl event id to the updated resource
        const activeEl = this.activeState.activeEvents.find(x => x.eventId === fe.eventId);
        idParts[1] = fe.resourceId;
        evtId = idParts.join('--');
        activeEl.eventId = evtId;
      }

      const thisEvent = this.calendarApi.getEventById(this.activeState.activeEvents[0].eventId);
      let parsedEvent;
      if (!thisEvent) {
        parsedEvent = { extendedProps: {} };
      } else {
        parsedEvent = JSON.parse(JSON.stringify(thisEvent));
      }
      parsedEvent.start = fullStart as unknown as Date;
      parsedEvent.end = fullEnd as unknown as Date;

      if (calEntry && calEntry.extendedProps) {
        parsedEvent.extendedProps = { ...calEntry.extendedProps };
      }

      if (calEntry && !calEntry.extendedProps) {
        calEntry.extendedProps = {
          jobInformation: { ...this.activeState.jobInformation }
        };
      }

      if (!parsedEvent.extendedProps.jobInformation) {
        parsedEvent.extendedProps = {
          jobInformation: { ...this.activeState.jobInformation }
        };
      }

      this.findAndRerenderChangedCalendarEvents(evtId, parsedEvent.start, parsedEvent.end, parsedEvent);
    });

    // update constraints for partials
    this.updateConstraintsForPartialJobs(fullStart, fullEnd);
  }

  private updateConstraintsForPartialJobs(fullStart: string, fullEnd: string): void {
    const partialJobEvents = this.activeState.activeEvents.filter(activeEl => activeEl.isPartial);
    if (partialJobEvents.length) {
      const assignmentConstraints = {
        start: fullStart,
        end: fullEnd
      };
      partialJobEvents.forEach(partialEvent => {
        const calEntry = this.calendarEvents.find(ce => ce.id === partialEvent.eventId);
        calEntry.constraint = assignmentConstraints;
        calEntry.extendedProps = {};
        calEntry.extendedProps.fullJobStart = fullStart;
        calEntry.extendedProps.fullJobEnd = fullEnd;
        calEntry.fullJobStart = fullStart;
        calEntry.fullJobEnd = fullEnd;
      });

      // update bg events
      const bgEvents = this.calendarEvents.filter(evt => evt.display === 'background');

      const updated = bgEvents.map(bgEvt => ({
        ...bgEvt,
        start: fullStart,
        end: fullEnd
      }));
      this.calendarEvents = [...this.calendarEvents.filter(evt => evt.display !== 'background'), ...updated];
      this.calendarConfig.events = [...this.calendarEvents];
    }
  }

  handleRemoveTradespersonChange(): void {
    if (this.activeState.jobInformation) {
      this.activeScheduleEvent = {
        ...this.activeScheduleEvent,
        extendedProps: {
          ...this.activeScheduleEvent.extendedProps,
          jobInformation: { ...this.activeState.jobInformation }
        }
      };
    }

    const tradesperson = this.activeState.payload.tradesperson;
    this.calendarEvents = this.calendarEvents.filter(evt => {
      if (evt.display === 'background') {
        return evt.resourceIds[0] !== tradesperson.assignedTo;
      }
      return !evt.id.includes(`${this.activeScheduleEvent.extendedProps.linkId}--${tradesperson.assignedTo}`);
    });

    this.calendarEvents = this.calendarEvents.map(evt => {
      const linkId = evt?.linkId ?? evt.extendedProps?.linkId;
      if (linkId === this.activeScheduleEvent.extendedProps.linkId) {
        const jobInfo = evt?.jobInformation ?? evt.extendedProps?.jobInformation;
        jobInfo.jobAssignments = [...this.activeState.jobInformation.jobAssignments];
        return evt;
      }
      return evt;
    });
    this.calendarConfig.events = [...this.calendarEvents];

    setTimeout(() => {
      const activeEvents = this.updateActiveScheduleEventEl(this.activeScheduleEvent.extendedProps.linkId, true);
      this.presenter.dispatch(SelectedJobAction.updateJobInfoAndEvents, {
        jobInformation: this.activeState.jobInformation,
        activeEvents
      });
    });
  }

  handleRemoveTradespersonSlotChange(): void {
    const linkId = this.activeScheduleEvent?.extendedProps?.linkId ?? this.activeState.jobInformation.id.split('--')[2];

    this.calendarEvents = this.calendarEvents.filter(evt => {
      return (
        evt.id !==
        `${linkId}--${this.activeState.payload.tradesperson.assignedTo}--${this.activeState.payload.slot.assignmentId}`
      );
    });
    this.calendarConfig.events = [...this.calendarEvents];

    const activeEvents = this.updateActiveScheduleEventEl(linkId, true);
    this.presenter.dispatch(SelectedJobAction.updateJobInfoAndEvents, { activeEvents });

    const tradespersonHasSlots = this.activeState.payload.tradesperson.slots.length;
    //
    if (!tradespersonHasSlots) {
      // If the tradesperson has no slots now then create a full job event
      // set the cached eventId so we can use it again

      const eventId = `${linkId}--${this.activeState.payload.tradesperson.assignedTo}--${this.activeState.payload.slot.assignmentId}`;
      const trimmedId = trimEventIdSuffixes(eventId);
      this.calendarApi.getEventById(eventId)?.remove();

      const elToUpdate = this.activeState.activeEvents.find(el => el.eventId === eventId);

      if (elToUpdate) {
        elToUpdate.eventId = trimmedId;
      }

      const revertFromSlot = true;
      this.handleAddTradespersonChange(revertFromSlot);
    }
  }

  private handleAddTradespersonChange(revertFromSlot = false): void {
    let linkId = this.activeScheduleEvent?.extendedProps?.linkId ?? this.activeState.jobInformation.id.split('--')[0];
    if (!this.activeScheduleEvent) {
      this.activeScheduleEvent = {
        extendedProps: {
          linkId,
          jobInformation: {
            ...this.activeState.jobInformation
          }
        }
      };
    }
    const calendarEvent: EventInput = { ...this.activeScheduleEvent };
    const activeEventUnassigned = this.activeScheduleEvent.extendedProps?.assignedToDisplayName === 'Unassigned';
    linkId = activeEventUnassigned
      ? this.activeScheduleEvent.extendedProps.jobInformation.id.split('--')[0]
      : this.activeScheduleEvent.extendedProps.linkId;

    const assignment = this.activeState.payload.jobAssignment;

    const eventId = revertFromSlot
      ? `${linkId}--${assignment.assignedTo}--${assignment.id}`
      : `${linkId}--${assignment.assignedTo}--${TEMP_EVENT_ID}`;

    const newJobAssignment: JobAssignment = {
      assignedTo: assignment.assignedTo,
      assignedToDisplayName: assignment.assignedToDisplayName,
      assignmentType: 'SUPPORT',
      id: assignment.assignmentId ?? eventId.split('--')[2]
    };
    const eventToAdd = {
      ...calendarEvent,
      extendedProps: {
        ...calendarEvent.extendedProps,
        assignedToDisplayName: assignment.assignedToDisplayName,
        assignedTo: assignment.assignedTo,
        linkId: linkId,
        fullJobStart: this.activeState.jobInformation.fullJobStart,
        fullJobEnd: this.activeState.jobInformation.fullJobEnd,
        jobInformation: { ...calendarEvent.extendedProps.jobInformation, id: eventId }
      },
      assignedToDisplayName: assignment.assignedToDisplayName,
      assignedTo: assignment.assignedTo,
      id: eventId,
      start: this.activeState.jobInformation.fullJobStart,
      end: this.activeState.jobInformation.fullJobEnd,
      classNames: [JT_EVENT],
      job: {
        assignedToDisplayName: assignment.assignedToDisplayName,
        assignedTo: assignment.assignedTo,
        id: `${this.activeScheduleEvent.extendedProps.linkId}--${assignment.assignedTo}--${assignment.assignmentId}`,
        fullJobStart: this.activeScheduleEvent.extendedProps.fullJobStart,
        fullJobEnd: this.activeScheduleEvent.extendedProps.fullJobEnd,
        start: this.activeState.jobInformation.fullJobStart,
        startDate: this.activeState.jobInformation.fullJobStart,
        end: this.activeState.jobInformation.fullJobEnd,
        endDate: this.activeState.jobInformation.fullJobEnd
      },
      resourceIds: [assignment.assignedTo],
      isPartial: false,
      linkId: linkId,
      constraint: null,
      editable: true,
      durationEditable: true,
      resourceEditable: true,
      startEditable: true
    };

    if (activeEventUnassigned) {
      // clear out any pre assignment
      eventToAdd.extendedProps.jobInformation.jobAssignments = [];
      const oldEventId = this.calendarEvents.find(evt => evt.id.includes('unassigned')).id;
      const oldUnassignedEvent = this.calendarApi.getEventById(oldEventId);
      oldUnassignedEvent.remove();
      this.calendarEvents = this.calendarEvents.filter(x => !x.classNames.includes(JT_EVENT_PREASSIGNMENT));
    }

    if (!revertFromSlot) {
      eventToAdd.extendedProps.jobInformation.jobAssignments.push(newJobAssignment);
    }

    // update active event;
    this.activeScheduleEvent = {
      ...this.activeScheduleEvent,
      id: eventId,
      extendedProps: {
        ...this.activeScheduleEvent.extendedProps,
        assignedToDisplayName: assignment.assignedToDisplayName,
        linkId,
        jobInformation: { ...eventToAdd.extendedProps.jobInformation }
      }
    };

    this.calendarEvents = [
      ...this.calendarEvents.filter(evt => !evt.classNames.includes(JT_EVENT_PREASSIGNMENT)),
      eventToAdd
    ];
    this.calendarConfig.events = [...this.calendarEvents];

    setTimeout(() => {
      const activeEvents = this.updateActiveScheduleEventEl(linkId, true);
      this.presenter.dispatch(SelectedJobAction.updateJobInfoAndEvents, {
        jobInformation: this.activeState.jobInformation,
        activeEvents
      });
    });
  }

  handleAddSpecificSlotChange(): void {
    // If the slot has a corresponding full job event remove it to prevent duplicate events rendering
    const tradesperson = this.activeState.payload.tradesperson;
    const linkId = this.activeScheduleEvent?.extendedProps?.linkId ?? this.activeState.jobInformation.id.split('--')[0];
    const oldEventId = [
      linkId,
      this.activeState.payload.tradesperson.assignedTo,
      trimEventIdSuffixes(tradesperson.assignmentId)
    ].join('--');
    this.calendarApi.getEventById(oldEventId)?.remove();
    this.calendarEvents = this.calendarEvents.filter(evt => evt.id !== oldEventId);
    this.calendarConfig.events = [...this.calendarEvents];

    const fullJobEvents: ActiveCalendarElements[] = this.activeState.activeEvents.filter(
      activeEl => !activeEl.isPartial
    );

    const newEventId = [
      linkId,
      this.activeState.payload.tradesperson.assignedTo,
      this.activeState.payload.tradesperson.assignmentId
    ].join('--');

    const trimmedId = trimEventIdSuffixes(newEventId);
    const assignedDisplayName = tradesperson.assignedToDisplayName;

    const matchingFullJobEvent = fullJobEvents.find(event => {
      return trimEventIdSuffixes(event.eventId) === trimmedId || event.resourceId === tradesperson.assignedTo;
    });
    if (matchingFullJobEvent) {
      this.calendarEvents = this.calendarEvents.filter(evt => evt.id !== matchingFullJobEvent.eventId);
      this.calendarConfig.events = [...this.calendarEvents];
    }
    const calendarEvent: EventInput = { ...this.activeScheduleEvent };
    const assignmentConstraints = {
      start: this.activeState.jobInformation.fullJobStart,
      end: this.activeState.jobInformation.fullJobEnd,
      resourceIds: [tradesperson.assignedTo]
    };

    const eventToAdd = {
      ...calendarEvent,
      extendedProps: {
        ...calendarEvent.extendedProps,
        assignedToDisplayName: assignedDisplayName,
        assignedTo: tradesperson.assignedTo,
        fullJobStart: this.activeState.jobInformation.fullJobStart,
        fullJobEnd: this.activeState.jobInformation.fullJobEnd,
        isPartial: true,
        jobInformation: { ...this.activeState.jobInformation }
      },
      assignedToDisplayName: assignedDisplayName,
      assignedTo: tradesperson.assignedTo,
      id: newEventId,
      start: this.activeState.jobInformation.fullJobStart,
      end: this.activeState.jobInformation.fullJobEnd,
      classNames: [JT_EVENT, JT_EVENT_PARTIAL],
      job: {
        id: this.activeState.jobInformation.id,
        assignedToDisplayName: assignedDisplayName,
        assignedTo: tradesperson.assignedTo,
        fullJobStart: this.activeState.jobInformation.fullJobStart,
        fullJobEnd: this.activeState.jobInformation.fullJobEnd,
        start: this.activeState.jobInformation.fullJobStart,
        startDate: this.activeState.jobInformation.fullJobStart,
        end: this.activeState.jobInformation.fullJobEnd,
        endDate: this.activeState.jobInformation.fullJobEnd
      },
      resourceIds: [tradesperson.assignedTo],
      isPartial: true,
      linkId: linkId,
      constraint: assignmentConstraints,
      editable: true,
      durationEditable: true,
      resourceEditable: true,
      startEditable: true
    };

    this.calendarEvents = [...this.calendarEvents, eventToAdd];
    this.calendarConfig.events = [...this.calendarEvents];

    // Give time for the calendar events to re-render and then update the active events
    setTimeout(() => {
      const activeEvents = this.updateActiveScheduleEventEl(linkId, true);

      this.presenter.dispatch(SelectedJobAction.updateJobInfoAndEvents, {
        activeEvents
      });
    });
  }

  handleSlotChange(): void {
    const eventId = [
      this.activeState.jobInformation.id.split('--')[0],
      this.activeState.payload.tradesperson.assignedTo,
      this.activeState.payload.slot.assignmentId
    ].join('--');

    // find event in calendar and update the times
    const start = this.activeState.payload.slot.startDate;
    const end = this.activeState.payload.slot.endDate;
    const currentCalEvent = this.calendarApi.getEventById(eventId);
    if (currentCalEvent?.start?.toISOString() !== start || currentCalEvent?.end?.toISOString() !== end) {
      const calEntry = this.calendarEvents.find(ce => ce.id === eventId);
      const parsedEvent = JSON.parse(JSON.stringify(calEntry));
      parsedEvent.start = start as unknown as Date;
      parsedEvent.end = end as unknown as Date;
      if (calEntry) {
        parsedEvent.extendedProps = { ...calEntry };
        parsedEvent.extendedProps.fullJobStart = calEntry.fullJobStart;
        parsedEvent.extendedProps.fullJobEnd = calEntry.fullJobEnd;
      }
      parsedEvent.extendedProps.jobInformation = { ...this.activeState.jobInformation };
      this.findAndRerenderChangedCalendarEvents(eventId, start, end, parsedEvent);
    }
  }

  handleDateClick(dateClickInfo: DateClickArg): void {
    if (this.context !== 'project' || this.activeScheduleEvent || this.isRescheduleJob()) {
      return;
    }

    this.transientEvents = [];
    let transientDuration: number;
    // todo the below won't run because of the first condition..
    // if we already have a transient event then lets keep the duration
    if (this.activeScheduleEvent) {
      const start = JumptechDate.from(this.activeScheduleEvent.start as string);
      const end = JumptechDate.from(this.activeScheduleEvent.end as string);
      const duration = end.diff(start, { units: 'minutes' });
      const transientDurationMinutes = duration.minutes;
      transientDuration = transientDurationMinutes / 60;
    }

    // build the event
    const viewWithoutTime = this.isViewWithoutTime();
    const defaultDuration = transientDuration ? transientDuration : this.selectedJob.defaultDuration;
    const { startDate, endDate } = this.calculateEventDateTimes(dateClickInfo.date, viewWithoutTime, defaultDuration);
    let resources = [];
    let eventId;
    const linkId = this.selectedJob.id.split('--')[0];

    // if we have a resource defined grab it
    if (dateClickInfo.resource) {
      if (this.activeScheduleEvent) {
        const leadIndex = this.activeScheduleEvent.extendedProps.jobInformation.jobAssignments.findIndex(
          x => x.assignmentType === 'LEAD'
        );
        const leadAssignment = this.activeScheduleEvent.extendedProps.jobInformation.jobAssignments[leadIndex];

        leadAssignment.assignedTo = dateClickInfo.resource.id;
        leadAssignment.assignedToDisplayName = dateClickInfo.resource.title;
        resources = [...this.activeScheduleEvent.extendedProps.jobInformation.jobAssignments];
        // if lead is also a support engineer then remove it
        resources = resources.filter(r => r.assignedTo !== leadAssignment.assignedTo || r.assignmentType === 'LEAD');
      } else {
        resources = [
          {
            assignedToDisplayName: dateClickInfo.resource.title,
            assignedTo: dateClickInfo.resource.id,
            assignmentType: 'LEAD',
            id: TEMP_EVENT_ID
          }
        ];
      }
      eventId = `${linkId}--${dateClickInfo.resource.id}--${TEMP_EVENT_ID}`;
    } else {
      eventId = `${linkId}--unassigned--${TEMP_EVENT_ID}`;
    }

    // build event input
    const eventToAdd: EventInput = {
      allDay: false,
      id: eventId,
      start: '',
      end: '',
      backgroundColor: 'var(--jds-theme-schedule-v2-job-status-color-provisionally-scheduled-bg)',
      classNames: [JT_EVENT],
      title: this.selectedJob.type,
      extendedProps: {
        eventType: 'Job',
        assignedToDisplayName: dateClickInfo.resource ? dateClickInfo.resource.title : '', // todo i18n
        dragged: true,
        linkId: linkId,
        jobInformation: {
          ...this.selectedJob,
          isInitialSchedule: true,
          contactInfo: {
            email: this.selectedJob.email,
            telephoneNumber: this.selectedJob.phoneNumber
          },
          status: 'PROVISIONALLY_SCHEDULED',
          startDateTimestamp: startDate,
          endDateTimestamp: endDate,
          fullJobStart: startDate,
          fullJobEnd: endDate
        }
      },
      assignedToDisplayName: dateClickInfo.resource ? dateClickInfo.resource.title : '',
      assignedTo: dateClickInfo.resource ? dateClickInfo.resource.id : '',
      resourceIds: dateClickInfo.resource ? [dateClickInfo.resource.id] : []
    };

    eventToAdd.extendedProps.jobInformation.jobAssignments = resources;
    eventToAdd.start = startDate;
    eventToAdd.end = endDate;

    this.persistTransientEventToCalendar(eventToAdd);
    this.refreshSelectedJobDetailsEvent(eventToAdd);
  }

  /**
   * Called when an external event is dragged onto the calendar
   * @param evt
   */
  handleEventReceive(evt: EventInfo): void {
    if (evt.draggedEl.classList.contains('jt-event')) {
      // Guarding against full-calendar's weirdness where dragging an existing event triggers handleEventReceive
      return;
    }
    this.stopPolling();
    const assignedResource = evt.event.getResources().length ? evt.event.getResources()[0] : undefined;
    const defaultDuration = evt.event.extendedProps?.jobInformation?.defaultDuration || 2;
    const viewWithoutTime = this.isViewWithoutTime();
    const { startDate, endDate } = this.calculateEventDateTimes(evt.event.start, viewWithoutTime, defaultDuration);
    // setDates only works correctly on views that do not display times
    if (viewWithoutTime) {
      evt.event.setDates(startDate, endDate, { allDay: false });
    } else {
      evt.event.setAllDay(false);
      evt.event.setEnd(endDate);
      evt.event.setStart(startDate);
    }
    // todo needs a new event id structure
    const resourceId = assignedResource ? assignedResource.id : 'unassigned';
    const tempEventId = `${evt.event.extendedProps.jobInformation.id}--${resourceId}--${TEMP_EVENT_ID}`;

    evt.event.setProp('id', tempEventId);

    if (this.currentView === 'dayGridMonth') {
      // set unassigned
      evt.event.setProp('classNames', [JT_EVENT, JT_EVENT_PREASSIGNMENT]);
    }

    const eventInput: EventInput = {
      ...evt.event.toPlainObject()
    };

    if (this.currentView === 'dayGridMonth') {
      eventInput.assignedToDisplayName = 'Unassigned';
      eventInput.assignedTo = null;
      const info = { ...evt.event.extendedProps.jobInformation };
      info.assignedToDisplayName = 'Unassigned';
      info.assignedTo = 'unassigned';
      evt.event.setExtendedProp('jobInformation', info);
      evt.event.setExtendedProp('assignedToDisplayName', 'Unassigned');
      evt.event.setExtendedProp('assignedTo', 'unassigned');
      evt.event.setExtendedProp('linkId', evt.event.extendedProps.jobInformation.id);

      // grab the unassigned element
      const unassignedEl = document.querySelector(`.${JT_EVENT_PREASSIGNMENT}`) as HTMLElement;
      evt.el = unassignedEl;
    }

    if (assignedResource) {
      eventInput.assignedToDisplayName = assignedResource.title;
      eventInput.assignedTo = assignedResource.id;
      eventInput.resourceIds = [assignedResource.id];
      if (evt.event.extendedProps.dragged) {
        eventInput.extendedProps.assignedToDisplayName = assignedResource.title;
      } else {
        eventInput.setExtendedProp('assignedToDisplayName', assignedResource.title);
      }
    }

    if (evt.event.extendedProps.dragged) {
      eventInput.extendedProps.jobInformation.id = tempEventId;
      eventInput.extendedProps.jobInformation.jobAssignments = evt.event.extendedProps.jobInformation.jobAssignments;
    } else {
      const patchedJobInfo = { ...evt.event.extendedProps.jobInformation, id: tempEventId };
      eventInput.setExtendedProp('jobInformation', patchedJobInfo);
    }

    const jobInfo: JobInfo = {
      ...eventInput.extendedProps.jobInformation,
      actionId: this.actionId ?? null,
      fullJobStart: startDate,
      fullJobEnd: endDate,
      jobAssignments: eventInput.assignedTo
        ? [
            {
              assignedTo: eventInput.assignedTo,
              assignedToDisplayName: eventInput.assignedToDisplayName,
              assignmentType: 'LEAD',
              id: TEMP_EVENT_ID
            }
          ]
        : [],
      startDateTimestamp: startDate,
      endDateTimestamp: endDate,
      setDefaultStartTime: viewWithoutTime
    };

    evt.event.setStart(startDate);
    evt.event.setEnd(endDate);

    evt.event.setExtendedProp('start', startDate);
    evt.event.setExtendedProp('end', endDate);

    evt.event.extendedProps.jobInformation = { ...jobInfo };
    if (this.currentView !== 'dayGridMonth') {
      eventInput.extendedProps.jobInformation = { ...jobInfo };
    }

    if (evt.el && assignedResource) {
      this.activeScheduleEventEl.push({ resourceId: assignedResource.id, el: evt.el, eventId: tempEventId });
    }

    if (this.activeScheduleEventEl.length) {
      evt.el = this.activeScheduleEventEl.find(ae => ae.eventId === evt.event.id)?.el;
    }
    this.persistTransientEventToCalendar(eventInput);
    this.scheduleService.handleEventRender(evt);
    const activeEvents = this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
    this.presenter.dispatch(SelectedJobAction.load, { jobInformation: jobInfo, activeEvents });
    this.sjdPresenter.preventDraggableItems();
  }

  private calculateEventDateTimes(start: Date, viewWithoutTime: boolean, defaultDuration: number) {
    let startDate = JumptechDate.from(start).toIso();

    if (viewWithoutTime) {
      startDate = JumptechDate.from(start).plus({ hours: 9 }).toIso();
    }
    const endDate = JumptechDate.from(startDate).plus({ hours: defaultDuration }).toIso();
    return { startDate, endDate };
  }

  private isViewWithoutTime() {
    return ['resourceTimelineRollingMonth', 'dayGridMonth'].includes(this.currentView);
  }

  async onSelectedJobDetailsClosed(): Promise<void> {
    // first unselect the event;
    this.unselectEvent();
    if (this.transientEvents.length) {
      this.transientEvents = [];
      this.activeScheduleEvent = null;
      this.activeScheduleEventEl = [];
      this.eventStateBeforeEdit = null;
      // remove transient and preassignment events from cache
      this.calendarEvents = this.calendarEvents.filter(
        evt => !evt.id.includes(TEMP_EVENT_ID) && !evt.classNames.includes(JT_EVENT_PREASSIGNMENT)
      );
      this.calendarConfig.events = [...this.calendarEvents];
      this.filterEvents();
    } else if (this.eventStateBeforeEdit && this.eventStateBeforeEdit.event.id !== TEMP_EVENT_ID) {
      // remove downgrade to partial tag from id
      const partialIdEvents = this.calendarEvents.filter(evt => evt.id.includes('-partial'));
      for (const evt of partialIdEvents) {
        this.calendarApi.getEventById(evt.id).remove();
        const evtToAdd = { ...evt, id: evt.id.replace(/-partial-\d+/, '') };
        this.calendarApi.addEvent(evtToAdd);
      }
      // Remove events with --temp on the end of their IDs
      this.calendarEvents = this.calendarEvents.filter(evt => evt.id.indexOf('temp') === -1);
      this.calendarConfig.events = [...this.calendarEvents];

      // Manually reset and re-render any events that could have been affected by the edit
      const isMultiJob = !!this.eventStateBeforeEdit.event.extendedProps.linkId;
      const evtIdPrefix = isMultiJob
        ? this.eventStateBeforeEdit.event.extendedProps.linkId
        : this.eventStateBeforeEdit.event.id;

      const elements: HTMLElement[] = this.getLinkedEventElements(evtIdPrefix, isMultiJob);

      this.eventStateBeforeEdit.event.extendedProps.jobAssignments.forEach(ja => {
        // manually rerender any events previously selected
        let evId = evtIdPrefix;
        if (isMultiJob) {
          evId = this.buildPartialEventId(evtIdPrefix, ja);
        }

        const evToReset = this.calendarApi.getEventById(evId);

        // Check if event corresponding to job assignment is still in the calendar and reset it if so
        if (evToReset) {
          evToReset.setStart(
            ja.startDate ??
              (isMultiJob
                ? this.eventStateBeforeEdit.event.extendedProps.fullJobStart
                : this.eventStateBeforeEdit.event.extendedProps.startDate)
          );
          evToReset.setEnd(
            ja.endDate ??
              (isMultiJob
                ? this.eventStateBeforeEdit.event.extendedProps.fullJobEnd
                : this.eventStateBeforeEdit.event.extendedProps.endDate)
          );

          const el = elements.find(elm => elm.getAttribute('data-eventid') === evId);
          this.scheduleService.handleEventRender({
            event: evToReset,
            el,
            view: { type: this.currentView }
          } as unknown as EventInfo);
        }
      });

      // Deal with the scenario when closing Selected Job Details with a selected event still on the calendar
      this.timerSelectedJobDetailsClosedSelectedEvent = setTimeout(() => {
        // clear cache
        this.activeScheduleEvent = null;
        this.activeScheduleEventEl = [];
        if (this.eventStateBeforeEdit.event.extendedProps.dragged) {
          this.eventStateBeforeEdit = null;
        } else {
          this.unselectEvent();
        }
        this.transientEvents = [];
      }, 0);

      this.selectedEventId = null;
      this.toggleFcButtonState(true);

      // restart polling
      await this.handleDateChange(undefined, this.calendarApi, null);
    }
  }

  notifySuccessToMap(data): void {
    const res: IScheduleModalResult = {
      data: { scheduleInfo: data },
      action: [],
      project: {
        id: data.projectId
      }
    };
    this.scheduleSuccess.emit(res);
  }

  async onSelectedJobDetailsSuccessClosed(data): Promise<void> {
    this.notifySuccessToMap(data);
    if (!this.activeScheduleEvent) {
      return;
    }
    await this.spinnerService.show('calendarSpinner');
    const isTempEventUpdate = this.transientEvents.length;
    this.transientEvents = [];
    this.eventStateBeforeEdit = null;
    this.sjdPresenter.initDraggableItems();
    this.unselectEvent();

    this.timerSelectedJobDetailsSuccessClosed = setTimeout(
      async () => {
        await this.handleDateChange(undefined, this.calendarApi, data);
      },
      isTempEventUpdate ? this.CALENDAR_EVENT_REFRESH_DELAY : 0
    );
  }

  // handles active state changes
  handleActiveStateChanges = (activeState: ActiveJobState | null): void => {
    console.log('\x1b[31m%s\x1b[0m', 'handleActiveStateChanges COMP', activeState);
    this.activeState = activeState;
    if (!activeState) {
      return;
    }
    switch (this.activeState.action) {
      case SelectedJobAction.fullJobFormChange: {
        this.handleFullJobChange();
        this.selectedJobEventChanged = true;
        this.setEditableOnEvents(false);
        break;
      }
      case SelectedJobAction.addTradespersonChange: {
        this.handleAddTradespersonChange();
        this.selectedJobEventChanged = true;
        this.setEditableOnEvents(false);
        break;
      }
      case SelectedJobAction.removeTradespersonChange: {
        this.handleRemoveTradespersonChange();
        this.selectedJobEventChanged = true;
        this.setEditableOnEvents(false);
        break;
      }
      case SelectedJobAction.addSlotChange: {
        this.handleAddSpecificSlotChange();
        this.selectedJobEventChanged = true;
        this.setEditableOnEvents(false);
        break;
      }
      case SelectedJobAction.removeTradespersonSlotChange: {
        this.handleRemoveTradespersonSlotChange();
        this.selectedJobEventChanged = true;
        this.setEditableOnEvents(false);
        break;
      }
      case SelectedJobAction.slotFormChange: {
        this.handleSlotChange();
        this.selectedJobEventChanged = true;
        this.setEditableOnEvents(false);
        break;
      }
      case SelectedJobAction.allTimesUpdated: {
        const fullStart = this.activeState.jobInformation.fullJobStart;
        const fullEnd = this.activeState.jobInformation.fullJobEnd;
        this.updateConstraintsForPartialJobs(fullStart, fullEnd);
        break;
      }
      case SelectedJobAction.destroy: {
        this.onSelectedJobDetailsClosed().then();
        break;
      }
      default: {
        console.log('unknown change doing nothing', this.activeState);
      }
    }
  };

  async handleEventClick(evt: EventInfo) {
    const { event } = evt;
    if (!event) {
      return;
    }
    if (event.extendedProps?.dragged || this.transientEvents.length) {
      return;
    }

    // Prevent clicks to other events if we are editing an event already
    if (this.selectedJobEventChanged) {
      return;
    }

    if (this.context === 'project' && evt.event.id !== this.selectedJob?.id) {
      return;
    }

    if (event.extendedProps.eventType === 'Job') {
      if (
        (evt.event.extendedProps.linkId && this.selectedEventId === evt.event.extendedProps.linkId) ||
        (!evt.event.extendedProps.linkId && this.selectedEventId === evt.event.id)
      ) {
        this.presenter.showSelectedJob();
        return;
      }
      this.setSelectedEvent(evt);
      const plainObjectEvent = evt.event.toPlainObject();
      this.activeScheduleEvent = { ...plainObjectEvent, editable: true, resourceEditable: true };

      if (evt.event.extendedProps.linkId) {
        // todo can we remove?
        this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
      } else {
        this.updateActiveScheduleEventEl(evt.event.id);
      }

      if (!this.eventStateBeforeEdit || this.eventStateBeforeEdit.event.id !== evt.event.id) {
        this.eventStateBeforeEdit = JSON.parse(JSON.stringify(evt));
      }
      const jobInfo = this.parseJobInfo(event);
      const activeEvents = this.updateActiveScheduleEventEl(evt.event.extendedProps.linkId, true);
      this.presenter.dispatch(SelectedJobAction.load, { jobInformation: jobInfo, activeEvents });

      evt.event.setExtendedProp('jobInformation', jobInfo);

      this.sjdPresenter.preventDraggableItems();
      this.stopPolling();
      this.setEditableOnEvents(false);
    } else {
      // Prevent users from opening the other events modal when there is a job selected
      if (!this.selectedEventId) {
        this.openEventModal('edit', evt, event.extendedProps);
      }
    }
  }

  private parseJobInfo(event: JobEvent): JobInfo {
    return {
      id: event.id,
      address: event.extendedProps.address,
      type: event.extendedProps.type,
      customerFirstName: event.extendedProps.firstName,
      customerLastName: event.extendedProps.lastName,
      jobAssignments: event.extendedProps.jobInformation?.jobAssignments ?? event.extendedProps.jobAssignments,
      assignedTo: event.extendedProps.assignedTo,
      assignedToDisplayName: event.extendedProps.assignedToDisplayName,
      contactInfo: {
        email: event.extendedProps.email,
        telephoneNumber: event.extendedProps.phoneNumber
      },
      startDateTimestamp: event.extendedProps.jobInformation?.startDateTimestamp ?? event.extendedProps.startDate,
      endDateTimestamp: event.extendedProps.jobInformation?.endDateTimestamp ?? event.extendedProps.endDate,
      projectId: event.extendedProps.projectId,
      isInitialSchedule: event.extendedProps.isInitialSchedule,
      fullJobStart: event.extendedProps.fullJobStart ?? null,
      fullJobEnd: event.extendedProps.fullJobEnd ?? null,
      status: event.extendedProps.jobInformation?.status
    };
  }

  handleMoreLinkClick(): void {
    clearTimeout(this.timerPopover);
    this.timerPopover = setTimeout((): void => {
      const popoverEL: HTMLElement = document.querySelector('.fc-popover');
      if (popoverEL) {
        const calendarEl = popoverEL.parentElement;
        const calendarHeight = calendarEl.clientHeight;
        const calendarWidth = calendarEl.clientWidth;
        const popoverHeight = popoverEL.clientHeight;
        const popoverWidth = popoverEL.clientWidth;
        const top = popoverEL.offsetTop;
        const left = popoverEL.offsetLeft;
        const nudgePadding = 10;

        const fitsInCalendarY = popoverHeight + top < calendarHeight;
        const fitsInCalendarX = popoverWidth + left < calendarWidth;

        const spaceReqY = top + popoverHeight;
        const spaceReqX = left + popoverWidth;
        const nudgeY = spaceReqY - calendarHeight + nudgePadding;
        const nudgeX = spaceReqX - calendarWidth + nudgePadding;

        if (!fitsInCalendarY && !fitsInCalendarX) {
          popoverEL.style.transform = `translate(-${nudgeX}px, -${nudgeY}px)`;
        } else {
          if (!fitsInCalendarY) {
            popoverEL.style.transform = `translateY(-${nudgeY}px)`;
          }
          if (!fitsInCalendarX) {
            popoverEL.style.transform = `translateX(-${nudgeX}px)`;
          }
        }
      }
    }, 5);
  }

  setSelectedEvent(event: EventInfo, unselect = true, renderConstraints = true): void {
    if (this.selectedEventId && unselect) {
      this.unselectEvent();
    }

    if (event.event.extendedProps.linkId) {
      this.selectedEventId = event.event.extendedProps.linkId;
      this.selectedEventType = SelectedEventType.link;
    } else {
      this.selectedEventId = event.event.id;
      this.selectedEventType = SelectedEventType.normal;
    }
    this.toggleFcButtonState(false);

    // set selected style for events
    const isGroup = !!event.event.extendedProps.linkId;
    const eventId = isGroup ? event.event.extendedProps.linkId : event.event.id;
    const matchingEventElements: HTMLElement[] = this.getLinkedEventElements(eventId, isGroup);

    this.selectedEventElements = matchingEventElements;
    for (const element of matchingEventElements) {
      element.classList.add(JT_EVENT_SELECTED);
    }

    this.calendarEvents.forEach(evt => {
      if (evt.linkId && evt.linkId === event.event.extendedProps.linkId) {
        if (!evt.classNames.includes(JT_EVENT_SELECTED)) {
          (evt.classNames as string[]).push(JT_EVENT_SELECTED);
        }
      }
    });
    this.calendarConfig.events = [...this.calendarEvents];

    if (renderConstraints) {
      // render any bg events
      this.renderSelectedEventConstraints();
    }
  }

  private renderSelectedEventConstraints(): void {
    if (this.selectedEventType === SelectedEventType.link && this.selectedEventElements.length) {
      this.removeBackgroundEvent();
      this.selectedEventElements.forEach(el => {
        const linkedEvent = this.calendarApi.getEventById(el.getAttribute('data-eventid'));
        if (linkedEvent && linkedEvent.extendedProps.isPartial) {
          this.createBackgroundEvent(linkedEvent, true);
        }
      });
    }
  }

  toggleFcButtonState(enabled: boolean) {
    const fcNextButton = document.querySelector('.fc-next-button') as HTMLButtonElement;
    const fcPrevButton = document.querySelector('.fc-prev-button') as HTMLButtonElement;
    const fcTodayButton = document.querySelector('.fc-today-button') as HTMLButtonElement;

    // These may already not exist when going through the component destroy flow
    if (!fcNextButton || !fcPrevButton || !fcTodayButton) {
      return;
    }

    fcNextButton.disabled = !enabled;
    fcPrevButton.disabled = !enabled;

    // Ensure that the 'Today' button does not get enabled unintentionally
    const todayTime = new Date().getTime();
    const currentDateInView =
      todayTime >= this.calendarApi.view.activeStart.getTime() && this.calendarApi.view.activeEnd.getTime() > todayTime;

    if (!currentDateInView) {
      fcTodayButton.disabled = !enabled;
    }
  }

  unselectEvent() {
    this.selectedEventElements?.forEach(el => {
      el.classList.remove(JT_EVENT_SELECTED);
      const eventId = el.getAttribute('data-eventid');
      const calendarEvent = this.calendarEvents.find(evt => evt.id === eventId);
      if (calendarEvent) {
        calendarEvent.classNames = (calendarEvent.classNames as string[]).filter(cn => cn !== JT_EVENT_SELECTED);
      }
    });
    this.calendarConfig.events = [...this.calendarEvents];
    this.selectedEventId = '';
    this.toggleFcButtonState(true);
    this.selectedEventElements = [];
    this.activeScheduleEventEl = [];
    this.selectedJobEventChanged = false;
    this.setEditableOnEvents(true);
    this.removeBackgroundEvent();
  }

  patchExistingEventIfNotUpdated(existing, updated) {
    // patches event with data from recent successful POST update so we don't have to wait for the API
    let updatedEvt = null;
    // check same event

    const existingProjectId = existing.job?.projectId ?? existing.projectId;
    const existingStartDate = existing.job?.startDate ?? existing.startDate;
    const existingEndDate = existing.job?.endDate ?? existing.endDate;
    const existingJobType = existing.job?.type ?? existing.type;
    const existingJobAssignments = existing.job?.jobAssignments ?? existing.jobAssignments;

    if (existingProjectId === updated.projectId && existingJobType === updated.jobType) {
      let eventHasUpdated = true;
      if (existingStartDate !== updated.start || existingEndDate !== updated.end) {
        eventHasUpdated = false;
      }
      if (existingJobAssignments.length !== updated.jobAssignments.length) {
        eventHasUpdated = false;
      }

      if (existingJobAssignments.length === updated.jobAssignments.length) {
        existingJobAssignments.forEach((eja: JobAssignment, idx): void => {
          const updateJa = updated.jobAssignments[idx];
          if (
            eja.assignedTo !== updateJa.assignedTo ||
            eja.startDate !== updateJa.startDate ||
            eja.endDate !== updateJa.endDate ||
            eja.assignmentType !== updateJa.assignmentType
          ) {
            eventHasUpdated = false;
          }
        });
      }

      if (!eventHasUpdated) {
        updatedEvt = {
          id: existing.id,
          startIso: updated.start,
          endIso: updated.end,
          title: existing.job.title,
          eventType: 'Job',
          allDayEvent: false,
          job: {
            ...existing.job,
            endDate: updated.end,
            startDate: updated.start,
            jobAssignments: updated.jobAssignments
          }
        };
      }
    }
    return updatedEvt;
  }

  async handleDateChange(evt: EventInfo, existingCalendarApi?: Calendar, updatedEvent?) {
    this.viewTitle = existingCalendarApi ? existingCalendarApi.view.title : evt.view.title;
    this.isSelectable = SELECTABLE_CAL_TYPES.includes(
      existingCalendarApi ? existingCalendarApi.view.type : evt.view.type
    );
    const start = existingCalendarApi
      ? existingCalendarApi.view.activeStart.toISOString()
      : evt.view.activeStart.toISOString();
    const end = existingCalendarApi
      ? existingCalendarApi.view.activeEnd.toISOString()
      : evt.view.activeEnd.toISOString();
    Analytics.logEvent('CalendarView', { start, end });
    try {
      // we don't want to trigger the spinner if we are just changing event details
      if (!this.activeScheduleEvent) {
        await this.spinnerService.show('calendarSpinner');
      }
      this.stopPolling();

      this.pollingEvents = this.filtersLoaded$
        .pipe(
          filter(x => x === true),
          switchMap(() => {
            return this.apiService.observeCalendarEvents(start, end);
          })
        )
        .subscribe((evts: CalendarEventV3[]) => {
          this.calendarEvents = evts
            .filter(event => {
              return this.scheduleService.isValidEventType(event.eventType);
            })
            .filter(event => {
              if (event.eventType === 'Job') {
                return this.allEngineers.find(e => e.id === event.job?.assignedTo);
              } else {
                return this.allEngineers.find(e => e.id === event.userIds[0]);
              }
            })
            .map((event: any) => {
              if (updatedEvent) {
                const patchedEvent = this.patchExistingEventIfNotUpdated(event, updatedEvent);
                return patchedEvent ?? event;
              }
              return event;
            })
            .map(event => {
              return this.buildPartialEvents(event);
            })
            .flat()
            .map(event => {
              return { ...event, title: this.scheduleService.getEventTitleFromProps(event) } as CalendarEventV3;
            })
            .map(event => {
              const evType = this.scheduleService.getEventType(event.eventType);
              return generateScheduleEvent(event, evType?.textColour, this.context, this.selectedJob?.linkId);
            });
          this.calendarConfig.events = [...this.calendarEvents, ...this.transientEvents];
          this.filterEvents();
          this.resizeCalendar();
          this.updateSelectedEventElements();
          this.spinnerService.hide('calendarSpinner').catch(console.log);
        });
    } catch (e) {
      console.error('Unable to access calendar events list', e);
      await this.spinnerService.hide('calendarSpinner');
    }
  }

  async saveEvent(modalRef: NgbModalRef, event: EventInfo, formData: any) {
    modalRef.componentInstance.savingEvent = true;
    const eventTo = this.eventTransform(formData);
    const { textColour } = this.scheduleService.getEventType(formData.type);
    const localEvent = generateScheduleEvent(eventTo, textColour, this.context, this.selectedJob?.id);
    try {
      if (!formData.id) {
        await this.insertEvent(eventTo, localEvent);
      } else {
        await this.updateEvent(eventTo, event, localEvent, formData.id);
      }
      this.scheduleService.showSuccessToast(formData);
      modalRef.componentInstance.savingEvent = false;
      modalRef.close();
    } catch (e) {
      console.log(`Save error:  ${e}`);
      this.scheduleService.showErrorToast();
      modalRef.componentInstance.savingEvent = false;
      modalRef.close();
    }
  }

  async deleteEvent(modalRef: NgbModalRef, formData: any) {
    modalRef.componentInstance.deletingEvent = true;
    try {
      await this.apiService.deleteCalendarEvent(formData.id);
      this.calendarEvents = this.calendarEvents.filter(x => x.id !== formData.id);
      this.calendarConfig.events = [...this.calendarEvents];
      this.filterEvents();
      modalRef.componentInstance.deletingEvent = false;
      this.scheduleService.showDeleteSuccessToast(formData);
      modalRef.close();
    } catch (e) {
      console.log(`Delete error: ${e}`);
      this.scheduleService.showDeleteErrorToast();
      modalRef.componentInstance.deletingEvent = false;
      modalRef.close();
    }
  }

  async insertEvent(eventTo: CalendarEventV3, localEvent: EventInput) {
    this.cleanLocalDates(eventTo);
    const res = await this.apiService.createCalendarEvent(eventTo);
    if (res) {
      localEvent.id = res;
      localEvent.title = this.scheduleService.getEventTitleFromProps(localEvent);
      this.calendarEvents = [...this.calendarEvents, localEvent, ...this.transientEvents];
      this.filterEvents();
    }
  }

  async updateEvent(eventTo: CalendarEventV3, event: EventInfo, localEvent: EventInput, eventId: string) {
    this.cleanLocalDates(eventTo);
    const res = await this.apiService.updateCalendarEvent(eventTo);
    if (res) {
      const itemIndex = this.calendarEvents.findIndex(x => x.id === eventId);
      localEvent.title = this.scheduleService.getEventTitleFromProps(localEvent);
      this.calendarEvents[itemIndex] = localEvent;
      event.event.setProp('title', localEvent.title);
      event.event.setStart(localEvent.start);
      this.scheduleService.handleEventRender(event);
      this.filterEvents();
    }
  }

  overrideTodayButton() {
    (document.querySelector('.fc-today-button') as HTMLElement).onclick = () => {
      if (this.currentView === 'resourceTimelineRollingMonth') {
        this.calendarApi.gotoDate(JumptechDate.now().startOf('week').toIso());
      }
    };
  }

  cleanLocalDates(eventTo: CalendarEventV3) {
    delete eventTo.localStartDate;
    delete eventTo.localEndDate;
  }

  getIsoDateTime(date: NgbDateStruct, hour: string, minutes: string) {
    return JumptechDate.from({
      ...date,
      hour: parseInt(hour),
      minute: parseInt(minutes)
    }).toIso();
  }

  eventTransform(d: any): CalendarEventV3 {
    const startTime = d.startTime.name.split(':');
    const endTime = d.endTime.name.split(':');
    const start = this.getIsoDateTime(d.startDate, startTime[0], startTime[1]);
    const end = this.getIsoDateTime(d.endDate, endTime[0], endTime[1]);
    const localStartDate = this.getIsoDateTime(d.startDate, '0', '0');
    const localEndDate = this.getIsoDateTime(d.endDate, '0', '0');

    const calendarEvent: CalendarEventV3 = {
      tz: d.tz,
      startIso: start,
      endIso: end,
      localStartDate: localStartDate,
      localEndDate: localEndDate,
      eventType: d.type,
      userIds: [d.engineer],
      allDayEvent: d.allDayEvent,
      title: d.title,
      description: d.description ?? ''
    };
    if (d.id) {
      calendarEvent.id = d.id;
    }
    return calendarEvent;
  }

  handleViewMount(evt: ViewMountArg) {
    this.viewTitle = evt.view.title;
    this.currentView = evt.view.type;
  }

  handleViewChange(evt: DropDownElement) {
    Analytics.logEvent('CalendarViewChange', { view: evt.id });
    switch (evt.id) {
      case 'month': {
        this.loadMonthView();
        break;
      }
      case 'week': {
        this.loadWeekView();
        break;
      }
      case 'day': {
        this.loadDayView();
        break;
      }
      case 'rollingMonth': {
        this.loadRollingView();
        break;
      }
      default: {
        return;
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  resizeCalendar(): void {
    if (this.calendarApi) {
      const containerHeight = document.getElementsByClassName('main-calendar-container')[0]?.clientHeight;
      this.calendarApi.setOption('height', containerHeight);
      setTimeout(() => {
        // fixes issue with month view not resizing when toggling jobs list
        this.calendarApi.updateSize();
      });
    }
  }

  private buildPartialEventId(idPrefix: string, jobAssignment: JobAssignment): string {
    return `${idPrefix}--${jobAssignment.assignedTo}--${jobAssignment.id}`;
  }

  private buildPartialEvents(evt: CalendarEventV3): CalendarEventV3 | CalendarEventV3[] {
    //  this creates calendar items for each entry in job assignments and links
    //  them by a linkId (eventId). New eventIds are created to enable full calendar to work: <eventId>--<resourceId>--<assignmentId>
    //  if any assignments are partial slots then we add time constraints to restrict to total job length
    const hasAssignments = evt.job?.jobAssignments.length;
    if (hasAssignments) {
      const calendarEvents: CalendarEventV3[] = [];
      evt.job.jobAssignments.forEach((ja: JobAssignment): void => {
        let calendarEvent: CalendarEventV3 = { ...evt };

        const assignmentConstraints = {
          start: evt.startIso,
          end: evt.endIso,
          resourceIds: [ja.assignedTo]
        };
        // if we have specific slot data
        const isPartial = !!(ja.startDate && ja.endDate);

        calendarEvent = {
          ...calendarEvent,
          job: {
            ...evt.job,
            id: this.buildPartialEventId(evt.id, ja),
            fullJobStart: evt.startIso,
            fullJobEnd: evt.endIso,
            start: ja.startDate ?? evt.startIso,
            startDate: ja.startDate ?? evt.startIso,
            end: ja.endDate ?? evt.endIso,
            endDate: ja.endDate ?? evt.endIso
          },
          isPartial,
          linkId: evt.id,
          constraint: isPartial ? assignmentConstraints : null
        };
        calendarEvents.push(calendarEvent);
      });
      return calendarEvents;
    }
    return evt;
  }

  async fetchEngineers(): Promise<void> {
    const { dropdown, all } = await this.scheduleService.fetchEngineers();
    this.allEngineers = all;
    this.setEngineers(this.selectedResources);
    this.engineersForDropdown = dropdown;
  }

  handleResourceRender(evt: ResourceInfo): void {
    const { resource, el } = evt;
    const elements: HTMLCollection = el.getElementsByClassName('fc-datagrid-cell-main');
    const cellContents = elements[0];
    cellContents.outerHTML = `
      <div class="resource-cell">
        ${resource._resource.title}
      </div>`;
  }

  handleResourceHeader(evt: ResourceInfo) {
    const { el } = evt;
    const elements: HTMLCollection = el.getElementsByClassName('fc-datagrid-cell-main');
    const cellContents = elements[0];
    cellContents.outerHTML = `
      <div class="resource-header-cell">
        ${this.translateService.translate('common.tradespeople')}
      </div>`;
  }

  private filterEvents() {
    this.calendarConfig.events = [
      ...(this.calendarEvents as EventInput[]),
      ...(this.transientEvents as EventInput[])
    ].filter(e => {
      const eventJobAssignmentIds = e.eventType === 'Job' ? e.jobAssignments.map(ja => ja.assignedTo) : [];
      return (
        // filter jobs
        e.eventType === 'Job'
          ? this.jobTypeService.isSelected(e.title) &&
              (!this.selectedResources?.length ||
                this.selectedResources.some(sr => eventJobAssignmentIds.includes(sr))) &&
              (!this.selectedJobStatuses?.length || this.selectedJobStatuses.includes(e.status))
          : // filter absences
            (!this.selectedAbsenceTypes?.length || this.selectedAbsenceTypes.includes(e.eventType)) &&
              (!this.selectedResources?.length || this.selectedResources.includes(e.userIds[0]))
      );
    });
  }

  updateSelectedEventElements(): void {
    if (this.selectedEventId) {
      if (this.selectedEventType === SelectedEventType.link) {
        this.selectedEventElements = Array.from(document.querySelectorAll(`[data-linkid="${this.selectedEventId}"]`));
      } else {
        this.selectedEventElements = Array.from(document.querySelectorAll(`[data-eventid="${this.selectedEventId}"]`));
      }
    }
  }

  generateFilterData(): ScheduleFilterInformation {
    return {
      jobTypes: this.jobTypeService.jobTypes,
      jobStatuses: this.jobStatusesV2,
      scheduleEventTypes: this.scheduleEventTypeList,
      tradesmen: this.engineersForDropdown,
      selectedJobTypes: this.jobTypeService.getSelectedJobTypes(),
      selectedScheduleEventTypes: this.selectedAbsenceTypes,
      selectedTradespeople: this.selectedResources,
      selectedJobStatuses: mapLegacyStatusesToNewStatuses(this.selectedJobStatuses)
    };
  }

  setPreviouslySelectedFilters() {
    const savedScheduleFilters = this.localStorageGateway.getItem(selectedFiltersKey);
    if (savedScheduleFilters) {
      const parsedFilters = JSON.parse(savedScheduleFilters) as SelectedScheduleFilters;
      this.jobTypeService.setSelectedJobTypes(parsedFilters.selectedJobTypes);
      this.selectedAbsenceTypes = parsedFilters.selectedScheduleEventTypes;
      this.setEngineers(parsedFilters.selectedTradespeople);
      this.selectedJobStatuses = parsedFilters.selectedJobStatuses;
    }
  }

  updateSelectedFilters(filterKey: string, filterValues: string[]) {
    const existingScheduleFilters = this.localStorageGateway.getItem(selectedFiltersKey);
    if (existingScheduleFilters) {
      const parsedFilters = JSON.parse(existingScheduleFilters) as SelectedScheduleFilters;
      parsedFilters[filterKey] = filterValues;
      this.localStorageGateway.setItem(selectedFiltersKey, JSON.stringify(parsedFilters));
    } else {
      const filtersToSave: SelectedScheduleFilters = {
        selectedJobStatuses: [],
        selectedTradespeople: [],
        selectedScheduleEventTypes: [],
        selectedJobTypes: []
      };
      filtersToSave[filterKey] = filterValues;
      this.localStorageGateway.setItem(selectedFiltersKey, JSON.stringify(filtersToSave));
    }
  }

  closeFilterPopover() {
    this.filterPopover.close();
  }

  updateActiveFilterAmount() {
    this.activeFilters =
      this.jobTypeService.getSelectedJobTypes().length +
      this.selectedAbsenceTypes.length +
      this.selectedResources.length +
      mapLegacyStatusesToNewStatuses(this.selectedJobStatuses).length;
  }

  setupViewDropdownElements() {
    const scheduleViews: DropDownElement[] = JSON.parse(JSON.stringify(SCHEDULE_VIEWS));
    for (const view of scheduleViews) {
      view.label = this.translateService.translate(`common.${view.id}`);
    }
    this.scheduleViews = scheduleViews;
    this.defaultScheduleView = scheduleViews[0];
  }

  resetFilter() {
    this.jobTypeService.setSelectedJobTypes([]);
    this.setJobTypes([]);
    this.selectedAbsenceTypes = [];
    this.setAbsenceTypes([]);
    this.selectedResources = [];
    this.setEngineers([]);
    this.selectedJobStatuses = [];
    this.setJobStatuses([]);
    this.updateActiveFilterAmount();
  }

  openEventModal(mode: 'edit' | 'add' | string, eventData?: EventInfo, extendedProps?: CalendarEventV3 & Job) {
    const modalRef = this.modalService.open(ScheduleEventModalComponent, { backdrop: 'static' });
    modalRef.componentInstance.mode = mode;
    modalRef.componentInstance.engineerList = this.engineersForDropdown;
    modalRef.componentInstance.eventTypes = this.scheduleEventTypeList;
    modalRef.componentInstance.tz = this.tenantTimezone;
    if (extendedProps) {
      modalRef.componentInstance.currentEventTitle = this.scheduleService.getEventTitleFromProps(extendedProps);
    }
    if (eventData?.event) {
      modalRef.componentInstance.eventData = eventData.event;
    }

    this.scheduleEventModalSubs.push(
      modalRef.componentInstance.saveEvent.subscribe($event => {
        this.saveEvent(modalRef, eventData, $event).then();
      }),
      modalRef.componentInstance.deleteEvent.subscribe($event => {
        modalRef.componentInstance.isDeleting = true;
        this.deleteEvent(modalRef, $event).then();
      })
    );

    modalRef.result.then(
      () => this.unSubModalEvents(),
      () => this.unSubModalEvents()
    );
  }

  private loadMonthView(): void {
    this.calendarApi.changeView('dayGridMonth');
    this.calendarApi.setOption('dayHeaderFormat', { weekday: 'short' });
    this.calendarApi.gotoDate(JumptechDate.now().startOf('month').toIso());
    // Ensure that if an event is selected before changing views to Month view
    // we carry over the context so that editing may continue
    if (this.eventStateBeforeEdit) {
      const ev = this.calendarApi.getEventById(this.eventStateBeforeEdit.event.id);
      const evtInfo = { event: ev } as unknown as EventInfo;
      const plainObjectEvent = evtInfo.event.toPlainObject();
      this.activeScheduleEvent = { ...plainObjectEvent, editable: true, resourceEditable: true };
      this.eventStateBeforeEdit = JSON.parse(JSON.stringify(evtInfo));
      this.updateActiveScheduleEventEl(evtInfo.event.id);
    }
  }

  private loadWeekView(selectedDate: string = null): void {
    if (selectedDate) {
      this.calendarApi.gotoDate(selectedDate);
      this.calendarApi.changeView('resourceTimeline');
      this.timerLoadWeekView = setTimeout((): void => {
        this.calendarApi.scrollToTime(JumptechDate.from(selectedDate).toTimeFormat());
      });
    } else {
      this.calendarApi.changeView('dayGridMonth');
      this.calendarApi.changeView('resourceTimeline');
      this.calendarApi.gotoDate(JumptechDate.now().toIso());
      // Set the calendar to the start of the current day
      this.timerLoadWeekView = setTimeout((): void => {
        this.calendarApi.scrollToTime(JumptechDate.from(new Date(new Date().setHours(0, 0, 0, 0))).toTimeFormat());
      });
    }
    this.calendarApi.setOption('slotLabelInterval', { minutes: 60 });
    this.calendarApi.setOption('slotDuration', { minutes: 30 });
    this.calendarApi.setOption('duration', { days: 7 });
    this.calendarApi.setOption('eventMinWidth', 30);
    this.calendarApi.setOption('slotMinWidth', undefined);
    this.calendarApi.setOption('resourceAreaWidth', 200);
    this.calendarApi.setOption('slotLabelFormat', [
      { weekday: 'short', day: 'numeric' },
      { hour: 'numeric', minute: 'numeric' }
    ]);
    this.calendarApi.setOption('expandRows', true);
  }

  private loadDayView(): void {
    this.calendarApi.gotoDate(JumptechDate.now().toIso());
    this.calendarApi.changeView('dayGridMonth');
    this.calendarApi.changeView('resourceTimelineDay');
    this.calendarApi.setOption('resourceAreaWidth', 200);
    this.calendarApi.setOption('slotMinWidth', 30);
    this.calendarApi.setOption('slotLabelInterval', '01:00');
    this.calendarApi.setOption('slotLabelFormat', [{ hour: 'numeric', minute: 'numeric' }]);
  }

  private loadRollingView(): void {
    this.calendarApi.gotoDate(JumptechDate.now().startOf('week').toIso());
    this.calendarApi.setOption('slotDuration', { days: 1 });
    this.calendarApi.setOption('slotLabelInterval', { days: 1 });
    this.calendarApi.changeView('dayGridMonth');
    this.calendarApi.changeView('resourceTimelineRollingMonth');
    this.calendarApi.setOption('duration', { days: 30 });
    this.calendarApi.setOption('slotLabelFormat', [{ weekday: 'short', day: 'numeric' }]);
    this.calendarApi.setOption('eventMinWidth', 50);
    this.calendarApi.setOption('slotMinWidth', 50);
    this.calendarApi.setOption('resourceAreaWidth', 200);
    // Add class so that fc style overrides work
    this.calendarApi.el
      .querySelector('div.fc-resourceTimeline-view')
      ?.classList.add('fc-resourceTimelineRollingMonth-view');
  }

  private stopPolling() {
    if (this.pollingEvents) {
      this.pollingEvents.unsubscribe();
    }
  }
}
