export const DNO_DEMAND_SUMMARY_PROJECT_CARD_LAYOUT = {
  items: [
    {
      content: '{proceed}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'proceed',
        label: 'Proceed with install'
      },
      order: '',
      key: 'Proceed with install'
    },
    {
      content: '{dnoApplicationRequiredInAdvance}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'dnoApplicationRequiredInAdvance',
        label: 'DNO Approval Required'
      },
      order: '',
      key: 'DNO Approval Required'
    },
    {
      content: '{considerLoadMonitoring}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'considerLoadMonitoring',
        label: 'Consider Monitoring'
      },
      order: '',
      key: 'Consider Monitoring'
    },
    {
      content: '{suggestLoadManageChargeStation}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'suggestLoadManageChargeStation',
        label: 'Suggest Load-managed Solution'
      },
      order: '',
      key: 'Suggest Load-managed Solution'
    },
    {
      content: '{maximumDemand}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'maximumDemand',
        label: 'Maximum Demand (MD) of premises before charging point installation'
      },
      order: '',
      key: 'Maximum Demand (MD) of premises before charging point installation'
    },
    {
      content: '{finalMaximumDemand}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'finalMaximumDemand',
        label: 'Maximum Demand (MD) of premises including proposed charging point with any load limiting devices'
      },
      order: '',
      key: 'Maximum Demand (MD) of premises including proposed charging point with any load limiting devices'
    },
    {
      content: '{newInstallationDetails}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'newInstallationDetails',
        label: 'Maximum current demand that the proposed EV/HP can draw'
      },
      order: '',
      key: 'Maximum current demand that the proposed EV/HP can draw'
    },
    {
      content: '{cutOutRating}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'cutOutRating',
        label: 'Premises cut-out rating'
      },
      order: '',
      key: 'Premises cut-out rating'
    },
    {
      content: '{phases}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'phases',
        label: 'Phases'
      },
      order: '',
      key: 'Phases'
    },
    {
      content: '{earthingArrangement}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'earthingArrangement',
        label: 'Charging Point Earthing Arrangement'
      },
      order: '',
      key: 'Charging Point Earthing Arrangement'
    },
    {
      content: '{loadLimitingDeviceRequired}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'loadLimitingDeviceRequired',
        label: 'Do these premises include an import or load-limiting device'
      },
      order: '',
      key: 'Do these premises include an import or load-limiting device'
    },
    {
      content: '{loopedService}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'loopedService',
        label: 'Is the service looped'
      },
      order: '',
      key: 'Is the service looped'
    },
    {
      content: '{inadequaciesOfSupply}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'inadequaciesOfSupply',
        label: 'Have you identified any issues with adequacy of the existing supply equipment'
      },
      order: '',
      key: 'Have you identified any issues with adequacy of the existing supply equipment'
    },
    {
      content: '<strong>{inadequaciesOfSupplyDetails}</strong>',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'inadequaciesOfSupplyDetails',
        label: 'Please detail inadequacies of supply',
        showIf: {
          inadequaciesOfSupply: {
            $eq: 'Yes'
          }
        }
      },
      order: '',
      key: 'Please detail inadequacies of supply'
    },
    {
      content: '{dnoApplicationEmail}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'dnoApplicationEmail',
        label: 'DNO Application email'
      },
      order: '',
      key: 'DNO Application email'
    },
    {
      content: '{dnoNotificationEmail}',
      editConfig: {
        options: [],
        controlType: 'GeneralText',
        key: 'dnoNotificationEmail',
        label: 'DNO Notification Email'
      },
      order: '',
      key: 'DNO Notification email'
    }
  ],
  order: 0,
  key: 'dnoDemandSummary',
  label: 'DNO Demand Summary'
};
