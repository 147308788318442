import type { IfCheck, IfDefinition } from '@jump-tech-frontend/domain';
import { get } from 'lodash';
import * as XRegExp from 'xregexp';

// Only use this method - it covers both new and old formats
export const checkIfExpression = (expression: IfDefinition, target: any, actualValue?: any): boolean => {
  if (!expression) {
    return true;
  }

  const key = (expression as IfDefinition)['key'];
  const value = (expression as IfDefinition)['value'];

  if (expression && key && typeof key === 'string') {
    actualValue = getPropertyValue(target, key);
  }

  if (expression && value && typeof value === 'string') {
    return actualValue === value;
  }

  let expressionOrCheck: IfDefinition = expression;
  if (typeof value === 'object') {
    expressionOrCheck = value as IfCheck;
  }

  return evaluateExpression(expressionOrCheck, target, actualValue);
};

const evaluateExpression = (expression: IfDefinition | IfCheck, target: any, actualValue?: any): boolean => {
  if (typeof expression !== 'object') {
    return false;
  }

  const keys = Object.keys(expression);
  const propertyOrOperator = keys[0];
  const targetValue = getTargetValue(target, expression[propertyOrOperator]);
  if (propertyOrOperator) {
    switch (propertyOrOperator) {
      case '$or': {
        return (
          Array.isArray(targetValue) && targetValue.some(thisExpression => evaluateExpression(thisExpression, target))
        );
      }
      case '$and': {
        return (
          Array.isArray(targetValue) && targetValue.every(thisExpression => evaluateExpression(thisExpression, target))
        );
      }
      case '$eq': {
        return actualValue === targetValue || (!!actualValue && targetValue === '*');
      }
      case '$ne': {
        return actualValue !== targetValue;
      }
      case '$gt': {
        return Array.isArray(actualValue) ? actualValue.length > targetValue : actualValue > targetValue;
      }
      case '$gte': {
        return Array.isArray(actualValue) ? actualValue.length >= targetValue : actualValue >= targetValue;
      }
      case '$lt': {
        return Array.isArray(actualValue) ? actualValue.length < targetValue : actualValue < targetValue;
      }
      case '$lte': {
        return Array.isArray(actualValue) ? actualValue.length <= targetValue : actualValue <= targetValue;
      }
      case '$in': {
        const targetList = targetValue
          .toString()
          .split(',')
          .map((e: string) => e.trim());
        return targetList.includes(actualValue);
      }
      case '$nin': {
        const targetList = targetValue
          .toString()
          .split(',')
          .map((e: string) => e.trim());
        return !targetList.includes(actualValue);
      }
      case '$mr':
        return XRegExp(targetValue, 'gi').test(actualValue);
      case '$nmr':
        return !XRegExp(targetValue, 'gi').test(actualValue);
      case '$inc': {
        if (Array.isArray(actualValue) || typeof actualValue === 'string') {
          return actualValue.includes(targetValue);
        }
        return false;
      }
      case '$aom': {
        return (
          Array.isArray(actualValue) &&
          actualValue.some(av => {
            return evaluateExpression(targetValue, av);
          })
        );
      }
      case '$aonm': {
        return (
          !Array.isArray(actualValue) ||
          !actualValue.some(av => {
            return evaluateExpression(targetValue, av);
          })
        );
      }
      default: {
        const actualValue = getPropertyValue(target, propertyOrOperator);
        return evaluateExpression(targetValue, target, actualValue);
      }
    }
  }
  return false;
};

function isEmpty(value: any) {
  return value === undefined || value === null || value?.length === 0;
}

export const getTargetValue = (target: any, targetValue: any) => {
  if (typeof targetValue === 'object' && targetValue?.property) {
    return getPropertyValue(target, targetValue?.property);
  }

  return targetValue;
};

export const getPropertyValue = (value: any, propertyKey: string) => {
  try {
    const resolved = get(value, propertyKey);
    if (!isEmpty(resolved)) {
      return resolved;
    }
  } catch (e) {
    console.log('Path does not exist', propertyKey);
  }

  if (value.data && value.data[propertyKey] && !isEmpty(value.data[propertyKey])) {
    return value.data[propertyKey];
  }
  return '';
};
