import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDragPlaceholder,
  CdkDropList,
  moveItemInArray
} from '@angular/cdk/drag-drop';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {
  Component,
  DestroyRef,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  signal,
  ViewChild
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbDropdownModule,
  NgbModal,
  NgbNav,
  NgbNavChangeEvent,
  NgbNavModule,
  NgbTooltipModule
} from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { diff } from 'deep-object-diff';
import { get, merge as lodashMerge } from 'lodash';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';

import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  filter,
  firstValueFrom,
  of,
  retryWhen,
  Subscription,
  switchMap,
  timer
} from 'rxjs';
import { ProjectFilter, ProjectStatus } from '../admin/user-management/domain/types';
import * as Analytics from '../app.analytics';

import { PROJECTS_PATH } from '../app.routes';
import { AccessService } from '../auth/services/access.service';
import { PathwayConfigurationService } from '../auth/services/pathway-configuration.service';
import { UserService } from '../auth/services/user.service';

import { ApiService } from '../core/api.service';

import { AuditLog, AuditLogStatus, JumptechDate, JumptechDateSettings } from '@jump-tech-frontend/domain';
import { Module, Project } from '../core/domain/project';
import { LayoutDisplayCriteria, ProjectCardLayout } from '../core/domain/project-card-layout';
import { ProjectConfiguration, ProjectState } from '../core/domain/project-configuration';
import { ProjectDlType } from '../core/domain/project-dl.type';
import { HomeService } from '../core/home.service';
import { LayoutUpdateService } from '../core/layout-update.service';
import { LocalStorageGateway } from '../core/local-storage-gateway.service';
import { ProjectConfigurationService } from '../core/project-configuration.service';
import { ProjectOwnerService } from '../core/project-owner.service';
import { ProjectUpdateService } from '../core/project-update.service';
import { checkIfExpression } from '../core/utils/filter';
import { LoggerService } from '../error/logger.service';

import {
  ProgressIndicatorComponent,
  ProgressIndicatorState,
  ProgressIndicatorStatus
} from '../shared/progress-indicator/progress-indicator.component';
import { ToasterService } from '../toast/toast-service';
import { AUTO_ADD_DOC_PACK_FEATURE_KEY, IDocumentPackManagerConfig } from './document-pack/document-pack.model';
import { JobSummaryItem } from './jobs/jobs.model';

import { Note } from './notes/note';
import { ProjectAttachment, ProjectAttachmentsComponent } from './project-attachments/project-attachments.component';
import { RelayComponent } from './relay/relay.component';
import { FeatureFlagService } from '../core/feature-flag/feature-flag.service';
import {
  CustomerShippingAddress,
  OrderFulfilment,
  OrderPartStatus,
  OrderResource,
  ProductResource,
  TenantShippingAddress
} from './my-orders/my-orders.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NumberToArrayPipe } from '../shared/pipes/number-to-array.pipe';
import { ProjectAuditLogsComponent } from './audit-logs/project-audit-logs.component';
import { NotesComponent } from './notes/notes.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { LayoutComponent } from './layouts/layout.component';
import { TabsLoaderComponent } from './tabs-loader.component';
import { LinkedProjectsComponent } from './linked-projects/linked-projects.component';
import { LinkedProjectsMessagesComponent } from './linked-projects/linked-projects-messages/linked-projects-messages.component';
import { LinkedProjectsErrorsComponent } from './linked-projects/linked-projects-errors/linked-projects-errors.component';
import { LinkedProjectsPresenter } from './linked-projects/linked-projects.presenter';
import { DocumentPackComponent } from './document-pack/document-pack.component';
import { DocumentPackMessagesComponent } from './document-pack/document-pack-messages/document-pack-messages.component';
import { DocumentPackErrorsComponent } from './document-pack/document-pack-errors/document-pack-errors.component';
import { TasksComponent } from './tasks/tasks.component';
import { JobsLoaderComponent } from './jobs/jobs-loader.component';
import { JobsComponent } from './jobs/jobs.component';
import { ProjectDetailSummaryComponent } from './summary/project-detail-summary.component';
import { ProjectLastAuditLogComponent } from './audit-logs/project-last-audit-log.component';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { CommonModule } from '@angular/common';
import { CardControlService } from '../core/card-control.service';
import { TasksRepository } from './tasks/tasks.repository';
import { RemoveUnderscorePipe } from '@jump-tech-frontend/angular-common';
import { createRenderer } from '@jump-tech/lib-mustache-renderer';
import { GalleryV2Component } from './gallery/gallery-v2.component';
import { GalleryComponent } from './gallery/gallery.component';
import { EnaApplicationContainerComponent } from './ena-application/ena-application-container.component';
import { EnaAttachmentsService } from './ena-application/services/ena-attachments.service';
import { LegacyEnaApplicationContainerComponent } from './legacy-ena-application/legacy-ena-application-container.component';
import { DNO_MODULE_LD_FEATURE_KEY } from './ena-application/ena.model';
import { SupportBarRepository } from '../support-login/support-bar/support-bar.repository';
import { HttpGateway } from '../core/http-gateway.service';
import { environment } from '../../environments/environment';
import { ProjectDetailEventsService, ProjectDetailEventType } from './services/project-detail-events.service';

export interface ExpandedCardLayout extends ProjectCardLayout {
  data: {
    [key: string]: any;
  };
}

export interface ExpandedCardLayoutCollection {
  editFilter?: boolean | ProjectFilter[];
  layouts: ExpandedCardLayout[];
}

export interface TabLayout extends LayoutDisplayCriteria {
  tabName: string;
  tabIcon: string;
  fallbackMessage?: string;
  layouts: ProjectCardLayout[];
  editFilter?: boolean | ProjectFilter[];
  type?: string;
}

export interface DisplayTabLayout extends LayoutDisplayCriteria {
  tabName: string;
  tabIcon: string;
  fallbackMessage?: string;
  layouts: ExpandedCardLayoutCollection[];
  editFilter?: boolean | ProjectFilter[];
  type?: string;
}

export enum TaskStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED'
}

export interface Task {
  id: string;
  task: string;
  filter?: any[];
  status: TaskStatus;
  questions?: any[];
  tenant?: string;
  description?: string;
  taskOptions?: string[];
  due_by?: Date;
  assigned_to?: string;
  updated_on?: Date;
  updated_by?: string;
  created_on?: Date;
  created_by?: string;
  removable?: boolean;
}

interface StatusChange {
  status: string;
  date: Date;
  actionedBy: string;
  stamp: string;
  show: boolean;
}

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  styleUrls: ['./project-detail.component.scss'],
  providers: [CardControlService, LayoutUpdateService, LinkedProjectsPresenter, TasksRepository],
  standalone: true,
  imports: [
    CommonModule,
    NgxSpinnerModule,
    CoreComponentsAngularModule,
    NgbDropdownModule,
    ProgressIndicatorComponent,
    ProjectLastAuditLogComponent,
    CdkDropList,
    CdkDrag,
    CdkDragPlaceholder,
    CdkDragHandle,
    NgbTooltipModule,
    ProjectDetailSummaryComponent,
    JobsComponent,
    JobsLoaderComponent,
    TasksComponent,
    DocumentPackErrorsComponent,
    DocumentPackMessagesComponent,
    DocumentPackComponent,
    LinkedProjectsErrorsComponent,
    LinkedProjectsMessagesComponent,
    LinkedProjectsComponent,
    TabsLoaderComponent,
    NgbNavModule,
    ProjectAttachmentsComponent,
    LayoutComponent,
    MyOrdersComponent,
    NotesComponent,
    ProjectAuditLogsComponent,
    TranslocoModule,
    NumberToArrayPipe,
    RemoveUnderscorePipe,
    GalleryV2Component,
    GalleryComponent,
    EnaApplicationContainerComponent,
    LegacyEnaApplicationContainerComponent
  ]
})
export class ProjectDetailComponent implements OnInit, OnDestroy {
  private readonly destroyRef = inject(DestroyRef);
  project: Project = null;
  tenant: string;
  auditLogs: AuditLog[] | null = [];
  jobSummaryItems: JobSummaryItem[] = [];

  statusChanges: StatusChange[] = [];
  currentStatusPosition = 0;
  statusChangeTypes = ['PROJECT_STATUS_CHANGE', 'JOB_STATUS_CHANGE'];
  changeEventName = 'status change';

  delayWsUpdate$ = new BehaviorSubject(false);
  downloadTypes = ProjectDlType;
  additionalDownloads = [];
  isPrimaryOwner = false;
  archiveReason = null;
  spinnerName = 'ProjectDetail'; // todo - when we remove the spinner well want to let regression know when page is ready
  appName = 'Pathway';
  commercialMarket = 'Commercial';

  moduleTabLayoutsCache: Map<Module, TabLayout> = new Map<Module, TabLayout>();
  tabLayouts: DisplayTabLayout[] = [];
  rawTabLayouts: DisplayTabLayout[] = [];
  enaTabLayouts: DisplayTabLayout[] = [];
  projectStates: ProjectState[] = [];
  projectState: ProjectState = null;
  projectProgress = signal<ProgressIndicatorState[]>([]);
  projectConfiguration: ProjectConfiguration = null;
  dockTasks = false;
  isCommercialMultiJob = false;
  userLayoutProjectDetailsLeft = 'USER_PREF--LAYOUT-PD-LEFT';
  userPrefLayoutLeft = ['summary', 'jobs', 'tasks', 'document-pack', 'linked-projects'];

  defaultStatesNumber = 12;
  quietReloadLinkedProjects = false;

  progressCircleSize = '40px';
  progressLabelSize = '65px';

  showAuditLogsLoader = true;
  showTabsLoader = true;
  showSummaryLoader = true;
  showJobsLoader = true;
  moduleTabsLoading = 0;

  showTasks = false;
  isViewingGallery = false;
  isGalleryV2 = false;
  isDnoModuleEnabled = false;
  isLegacyDnoApplication = false;

  hasDocumentPackDefinition = false;
  documentPackConfig: IDocumentPackManagerConfig;
  AUTO_ADD_DOC_PACK_FEATURE_KEY = AUTO_ADD_DOC_PACK_FEATURE_KEY;
  GALLERY_V2_LD_FEATURE_KEY = 'gallery-v2';
  DNO_MODULE_LD_FEATURE_KEY = DNO_MODULE_LD_FEATURE_KEY;

  autoAddDocumentPack: boolean;

  refreshOrders = false;
  canOrderProducts = false;
  showMyOrdersTab = false;
  productResources: ProductResource[] = null;
  orderResources: OrderResource[] = null;
  customerAddress: CustomerShippingAddress = null;
  tenantAddress: TenantShippingAddress = null;
  isShippingOrder = false;
  displayDnoApplicationTab = false;

  /**
   * The current value of the subscribeToUpdates timeout.
   * Should be stored in order to cancel it during edits.
   */
  updateSubscription: Subscription;
  enaAttachmentsSub: Subscription;

  resources = {};
  infoMessage: string = null;
  offline = false;

  @ViewChild('layoutTabSet') layoutTabSet: NgbNav;
  @ViewChild('tabSetContainer') tabSetContainer: ElementRef<HTMLElement>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    private modalService: NgbModal,
    private layoutUpdateService: LayoutUpdateService,
    private projectUpdateService: ProjectUpdateService,
    private spinnerService: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private pathwayConfigurationService: PathwayConfigurationService,
    private projectConfigurationService: ProjectConfigurationService,
    private loggerService: LoggerService,
    private projectOwnerService: ProjectOwnerService,
    private toasterService: ToasterService,
    private featureAccessService: AccessService,
    private titleService: Title,
    private homeService: HomeService,
    private userService: UserService,
    private localStorageGateway: LocalStorageGateway,
    private translocoService: TranslocoService,
    private featureFlagService: FeatureFlagService,
    private enaAttachmentsService: EnaAttachmentsService,
    private projectDetailEventsService: ProjectDetailEventsService,
    private supportBarRepository: SupportBarRepository,
    private gateway: HttpGateway
  ) {
    combineLatest([this.route.params, this.route.queryParams], (routeParams, routeQueryParams) => ({
      routeParams,
      routeQueryParams
    }))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(async join => {
        this.ngOnDestroy();
        this.initialise(join);
      });
  }

  private static getDataSource(layoutSpec) {
    return layoutSpec.dataSource === 'data' || layoutSpec.dataSource === 'imageData' ? 'data' : layoutSpec.dataSource;
  }

  async ngOnInit(): Promise<void> {
    const savedUserPrefLayoutLeft = JSON.parse(this.localStorageGateway.getItem(this.userLayoutProjectDetailsLeft));
    if (savedUserPrefLayoutLeft && this.userPrefLayoutLeft.length === savedUserPrefLayoutLeft.length) {
      this.userPrefLayoutLeft = [...savedUserPrefLayoutLeft];
    }
    this.isGalleryV2 = await this.featureFlagService.isFeatureEnabled(this.GALLERY_V2_LD_FEATURE_KEY);
    this.isDnoModuleEnabled = await this.featureFlagService.isFeatureEnabled(this.DNO_MODULE_LD_FEATURE_KEY);

    this.projectDetailEventsService.on(ProjectDetailEventType.LEGACY_ENA_RESUBMIT, () =>
      this.setIsLegacyDnoApplication(false)
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler() {
    this.ngOnDestroy();
  }

  @HostListener('window:online', ['$event']) onOnline() {
    this.subscribeToUpdates().then(() => console.log('Reconnected to WSS'));
    this.offline = false;
  }

  @HostListener('window:offline', ['$event']) onOffline() {
    this.offline = true;
  }

  reload() {
    if (this.project) {
      this.initialise({
        routeParams: {
          projectId: this.project.id
        },
        routeQueryParams: {
          o: false
        }
      });
    }
  }

  initialise(join: any) {
    this.tabLayouts = [];
    this.rawTabLayouts = [];
    this.hasDocumentPackDefinition = false;
    this.canOrderProducts = false;
    this.showMyOrdersTab = false;
    const projectId = join.routeParams['projectId'];
    this.quietReloadLinkedProjects = join.routeParams['linkProjectsQuietReload'];
    this.isPrimaryOwner = /true/i.test(join.routeQueryParams['o']);
    this.projectOwnerService.setForProjectId(projectId, this.isPrimaryOwner);
    this.fetchProject(projectId).then(() => {
      this.tenant = this.pathwayConfigurationService.tenant;
    });
    this.breakpointObserver
      .observe(['(min-width: 768px)', '(min-width: 992px)', '(min-width: 1200px)'])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((state: BreakpointState) => {
        // Dock the tasks if below md width
        this.progressCircleSize = state.breakpoints['(min-width: 992px)'] ? '40px' : '28px';
        this.progressLabelSize = state.breakpoints['(min-width: 992px)'] ? '65px' : '35px';
        this.dockTasks = !state.breakpoints['(min-width: 768px)'];
      });
  }

  private setIsLegacyDnoApplication(value: boolean): void {
    this.isLegacyDnoApplication = value;
  }

  private isSipModuleEnabled(): boolean {
    const jobTypes = this.projectConfiguration.jobTypes;

    return jobTypes.some(({ modules }) => modules.isSip);
  }

  setDetailTitle(project) {
    this.titleService.setTitle(
      `${project.data.firstName} ${project.data.lastName} - ${project.type} | ${this.appName}`
    );
    this.homeService.saveRouteHistory(
      this.router.url,
      `${project.data.firstName} ${project.data.lastName} - ${project.type}`
    );
  }

  ngOnDestroy() {
    this.unsubscribeWs();
  }

  private unsubscribeWs() {
    if (this.project) {
      this.projectUpdateService.close();
    }
    if (this.updateSubscription) {
      this.updateSubscription.unsubscribe();
    }
  }

  private showLoaders() {
    this.showAuditLogsLoader = true;
    this.showTabsLoader = true;
    this.showSummaryLoader = true;
    this.showJobsLoader = true;
  }

  private hideLoaders(loaderName: string) {
    if (loaderName === 'auditLogs') {
      this.showAuditLogsLoader = false;
    }
    if (loaderName === 'tabs') {
      this.showTabsLoader = false;
    }
    if (loaderName === 'summary') {
      this.showSummaryLoader = false;
    }
    if (loaderName === 'jobs') {
      this.showJobsLoader = false;
    }
  }

  private async showSpinner() {
    await this.spinnerService.show(this.spinnerName);
  }

  private async hideSpinner() {
    await this.spinnerService.hide(this.spinnerName);
  }

  private async fetchProject(projectId: string) {
    if (!this.quietReloadLinkedProjects) {
      this.showTasks = false;
      this.showLoaders();
    }

    const project: Project = await firstValueFrom(this.apiService.getProject(projectId));
    if (!project) {
      this.loggerService.log('Project not found');
      await this.router.navigate([PROJECTS_PATH], { replaceUrl: true });
    } else {
      this.project = { ...project };
      this.showTasks = true;
      this.setDetailTitle(project);
      this.setJobSummary();
      await this.setProjectConfiguration();
      this.setProjectResources();
      this.setAdditionalDownloads();
      await this.subscribeToUpdates();
      setTimeout(() => {
        this.layoutUpdateService.cancel();
        this.projectUpdateService.nextProjectUpdates(this.project);
        this.hideLoaders('jobs');
      });
    }
  }

  public hasArchiveReason() {
    return (
      this.isArchived && this.project.data && (this.project.data.archiveReason || this.project.data.reasonForArchive)
    );
  }

  public getArchiveStatusLabel() {
    const statusConfig: ProjectState = this.projectConfiguration.states.find(s => s.status === ProjectStatus.ARCHIVED);
    return statusConfig?.label?.toLowerCase() ?? ProjectStatus.ARCHIVED.toLowerCase();
  }

  public getArchiveReason() {
    if (this.project?.data?.archiveReason) {
      return this.project?.data?.archiveReason;
    }

    const projectArchiveReasonKey = this.project?.data?.reasonForArchive;
    const projectStatus = this.project.status.status;
    const statusConfig: ProjectState = this.projectConfiguration.states.find(s => s.status === projectStatus);

    for (const action of statusConfig.actions) {
      const item = action.confirmation?.layout?.items?.find(item => (item.content = '{reasonForArchive}'));

      if (item) {
        const reasonForArchiveOption = item.editConfig?.options?.find(option => option.key === projectArchiveReasonKey);
        if (reasonForArchiveOption) {
          // Return localised version of reasonForArchive
          return reasonForArchiveOption.value;
        }
      }
    }

    // Return non-localised version of reasonForArchive
    return projectArchiveReasonKey;
  }

  listenForEnaAttachments(): void {
    if (this.displayDnoApplicationTab) {
      this.enaAttachmentsSub?.unsubscribe();
      this.enaAttachmentsSub = this.enaAttachmentsService.modifiedAttachments$.subscribe(attachments => {
        if (attachments && attachments.length > 0) {
          this.updateAttachments(attachments);
        }
      });
    }
  }

  private async setProjectConfiguration(docPackShowLoading = true, docPackRefreshing = false) {
    this.projectConfiguration = await this.projectConfigurationService.getProjectConfiguration(this.project.type, true);
    this.setState();
    this.displayDnoApplicationTab = this.projectConfiguration.dno?.isDno && this.isDnoModuleEnabled;
    const isLegacyDnoApplication = this.project.modules?.dno?.isLegacy ?? false;
    this.setIsLegacyDnoApplication(isLegacyDnoApplication);
    this.autoAddDocumentPack = await this.autoCreateDocumentPack();

    this.listenForEnaAttachments();
    await this.initDocumentPacks(docPackShowLoading, docPackRefreshing);
    this.setCommercialMultiJob();
    this.setProjectConfigurationCardLayouts();
    this.hideLoaders('tabs');
    this.hideLoaders('summary');
    await this.setModuleCardLayouts();
    await this.initOrders();
    await this.hideSpinner();
  }

  public async initDocumentPacks(showLoading = true, isRefreshing = false) {
    if (this.projectConfiguration?.documentPackDefinition || this.project.documentPackId) {
      this.hasDocumentPackDefinition = true;
      this.documentPackConfig = {
        documentPackDefinition: this.projectConfiguration.documentPackDefinition,
        projectId: this.project.id,
        projectStates: this.projectConfiguration.states,
        currentProjectState: this.getStateByStatus(this.projectStatus).status,
        user: this.userService.currentUser,
        tenant: this.project.tenant,
        showLoading,
        isRefreshing
      };
      if (this.project.documentPackId) {
        this.documentPackConfig.autoAddInProgress = false;
        this.documentPackConfig.documentPackId = this.project.documentPackId;
        const documentPack = await this.apiService.getDocumentPack(this.project.documentPackId, this.project.id);
        this.documentPackConfig.documentPackDefinition = documentPack.documentPackDefinitionReference;

        // if project has documentPackId in flight - check the doc definition for DNO document types
        this.checkProjectDocumentsForDno();
      } else if (this.autoAddDocumentPack) {
        const secondsSinceProjectCreation = (new Date().getTime() - new Date(this.project.created_on).getTime()) / 1000;

        this.documentPackConfig.autoAddInProgress = secondsSinceProjectCreation < 12;
      }
    } else {
      this.hasDocumentPackDefinition = false;
    }
  }

  private checkProjectDocumentsForDno(): void {
    if (
      this.documentPackConfig.documentPackDefinition.id &&
      this.projectConfiguration.dno?.isDno &&
      this.isDnoModuleEnabled
    ) {
      this.apiService
        .getDocumentPackDefinition(
          this.documentPackConfig.documentPackDefinition.id,
          this.documentPackConfig.documentPackDefinition.version
        )
        .then(res => {
          let docPackContainsDno = false;
          for (const doc of res.documents) {
            if (doc.modelDefinition?.modelGenerator?.includes('DNO_')) {
              docPackContainsDno = true;
              break;
            }
          }
          this.displayDnoApplicationTab = !docPackContainsDno;
          this.updateDnoCardLayout();
        });
    }
  }

  private async initOrders(refreshResources = false): Promise<void> {
    this.isShippingOrder = false;
    if (refreshResources) {
      this.refreshOrders = refreshResources;
    }

    const metaStatusReached = this.isHardwareOrderingMetaStatusReached();

    const productResources = this.project.resources.find(resource => resource.type === 'Products');
    if (productResources) {
      this.showMyOrdersTab = true;
      this.productResources = productResources.summary?.packages;
      this.orderResources = this.project.resources.filter(resource => resource.type === 'order');
      this.checkForOrderShippingStatus();
      // driver details
      this.customerAddress = this.project.data['address'];
      if (this.customerAddress) {
        this.customerAddress.firstName = this.project.data.firstName;
        this.customerAddress.lastName = this.project.data.lastName;
        this.customerAddress.email = this.project.data.email;
        this.customerAddress.phone = this.project.data.phoneNumber;
      }
      // default address
      const tenantConfig = await this.featureAccessService.getTenantConfig();
      this.tenantAddress = tenantConfig.address;
      this.tenantAddress.name = tenantConfig.installerContact;
      this.tenantAddress.company = tenantConfig.installer;
      this.tenantAddress.email = tenantConfig.installerEmail;
      this.tenantAddress.phone = tenantConfig.installerPhone;

      this.canOrderProducts = this.productResources && this.customerAddress && this.tenantAddress && metaStatusReached;
    }
  }

  private checkForOrderShippingStatus(): void {
    if (this.orderResources.length > 0) {
      // find most recent order and check for a Shipping status
      const openOrder: OrderResource = this.orderResources.sort(
        (o1: OrderResource, o2: OrderResource) => o2.summary.createdOn - o1.summary.createdOn
      )[0];

      openOrder.fulfilments?.forEach((f: OrderFulfilment): void => {
        if (f.fulfilmentOrderStatus === OrderPartStatus.SHIPPING) {
          this.isShippingOrder = true;
        }
      });
    }
  }

  private isHardwareOrderingMetaStatusReached(): boolean {
    // default show tab always
    let metaStatusReached = true;

    // if we have set a meta status, then only show tab when we have reached the meta status
    if (this.projectConfiguration.hardwareOrderingMetaStatus) {
      const currentStatusIndex = this.projectStates.findIndex(x => x.status === this.projectState.status);
      const orderDisplayStatusIndex = this.projectStates.findIndex(
        x => x.metaStatus === this.projectConfiguration.hardwareOrderingMetaStatus
      );
      metaStatusReached = currentStatusIndex >= orderDisplayStatusIndex;
    }
    return metaStatusReached;
  }

  public setCommercialMultiJob() {
    const isCommercial = this.projectConfiguration?.projectMarket === this.commercialMarket;
    const isMultiJobProject = this.projectConfiguration?.jobTypes?.length > 1;
    this.isCommercialMultiJob = isCommercial && isMultiJobProject;
  }

  public getStateByStatus(status: string): ProjectState | null {
    return this.projectConfiguration?.states.find(state => state.status === status) || null;
  }

  get projectStatus() {
    return this.project.status.status;
  }

  private transformTabLayout(tabLayout: TabLayout): DisplayTabLayout | undefined {
    if (!this.showLayout(tabLayout)) {
      return undefined;
    }

    const displayTabLayout: DisplayTabLayout = { ...tabLayout, layouts: [] };
    const projectCardLayouts = tabLayout.layouts;

    if (projectCardLayouts?.length) {
      const filteredProjectCardLayouts = projectCardLayouts
        .filter(layout => this.showLayout(layout))
        .map(layout => this.augmentLayout(layout));

      displayTabLayout.layouts = filteredProjectCardLayouts.filter(({ layouts }) => layouts.length);
    }

    return displayTabLayout;
  }

  private transformTabLayouts(tabLayouts: TabLayout[]): DisplayTabLayout[] {
    const displayTabLayouts = tabLayouts.map(tabLayout => this.transformTabLayout(tabLayout));

    return displayTabLayouts.filter(displayTabLayout => !!displayTabLayout);
  }

  private async setSipTabLayout(): Promise<void> {
    const sipTabLayout = this.moduleTabLayoutsCache.get('sip');

    if (sipTabLayout) {
      const index = this.tabLayouts.findIndex(({ tabName }) => tabName === sipTabLayout.tabName);

      const displayTabLayout = this.transformTabLayout(sipTabLayout);

      this.tabLayouts[index] = displayTabLayout;
      this.rawTabLayouts[index] = displayTabLayout;

      return;
    }

    try {
      this.moduleTabsLoading += 1;

      const tabLayout = await this.gateway.get(`${environment.apiSipFormUrl}`, {});

      const displayTabLayout = this.transformTabLayout(tabLayout);

      this.tabLayouts.push(displayTabLayout);
      this.rawTabLayouts.push(displayTabLayout);

      this.moduleTabLayoutsCache.set('sip', tabLayout);
    } catch (error) {
      console.error('Unable to set SIP tab form', error);
    } finally {
      this.moduleTabsLoading -= 1;
    }
  }

  private async setModuleCardLayout(module: Module): Promise<void> {
    if (module === 'sip' && this.isSipModuleEnabled()) {
      await this.setSipTabLayout();
    }
  }

  private async setModuleCardLayouts(): Promise<void> {
    const layoutGenerationPromises: Promise<void>[] = [];

    layoutGenerationPromises.push(this.setModuleCardLayout('sip'));

    if (layoutGenerationPromises.length) {
      await Promise.allSettled(layoutGenerationPromises);
    }
  }

  private setProjectConfigurationCardLayouts(): void {
    const displayTabLayouts = this.transformTabLayouts(this.projectConfiguration.layouts);

    for (const displayTabLayout of displayTabLayouts) {
      const index = this.tabLayouts.findIndex(({ tabName }) => tabName === displayTabLayout.tabName);

      // Update the tab layout at its current index if it already exists in the tabLayouts array
      if (index !== -1) {
        this.tabLayouts[index] = displayTabLayout;
        this.rawTabLayouts[index] = displayTabLayout;

        continue;
      }

      this.tabLayouts.push(displayTabLayout);
      this.rawTabLayouts.push(displayTabLayout);
    }

    this.updateDnoCardLayout();
  }

  handleDocPackRemoved(): void {
    this.displayDnoApplicationTab = this.projectConfiguration.dno?.isDno && this.isDnoModuleEnabled;
    this.updateDnoCardLayout();
  }

  private updateDnoCardLayout() {
    // Temporary hardcoded removal of existing DNO tab - eventually will be removed from project configuration instead
    if (this.displayDnoApplicationTab) {
      this.tabLayouts = this.tabLayouts.filter(layout => layout.tabName !== 'DNO');
      this.enaTabLayouts = this.tabLayouts.filter(layout => layout.type === 'attachments' || layout.type === 'gallery');
    } else {
      this.tabLayouts = [...this.rawTabLayouts];
    }
  }

  private render(template: string, context: any) {
    const renderer = createRenderer({
      timezone: JumptechDateSettings.defaultTimeZone,
      locale: JumptechDateSettings.defaultLocale
    });

    return template ? renderer.render('{{={ }=}} ' + template, context) : template;
  }

  /**
   * Take in a ProjectCardLayout, calculating the grid position and style and fetch the data
   * NB a project will have several resources of the same type if blocks have been
   * repeated in Atom. To deal with this we add each resource to the layouts
   * @param layoutSpec
   */
  private augmentLayout(layoutSpec) {
    const projectSource = get(this.project, ProjectDetailComponent.getDataSource(layoutSpec)) || null;
    if (projectSource) {
      // If it's project.data or similar, just add the one layout
      return {
        layouts: [
          {
            ...layoutSpec,
            data: projectSource
          }
        ]
      };
    } else {
      const resources = this.project.resources.filter(resource => resource.type === layoutSpec.dataSource);
      return {
        // For resources, add each one to the layouts and expand out the label for each one
        layouts: resources.map((r, i) => ({
          ...layoutSpec,
          data: this.getResourceData(r),
          // If the resource has an id, then use that, otherwise use layoutSpec.updateResource which is the type
          updateResource: r.id || layoutSpec.updateResource,
          // Rendering the label allows us to add an index (starting at 1)
          label: this.render(layoutSpec.label, { index: i + 1 })
        }))
      };
    }
  }

  private getResourceData(resource) {
    const resourceData = { ...resource.summary };
    const resourcePrefix = `${resource.id}__`;
    Object.keys(this.project.data)
      .filter(key => key.startsWith(resourcePrefix))
      .forEach(key => {
        resourceData[key.substr(resourcePrefix.length)] = this.project.data[key];
      });
    return resourceData;
  }

  private showLayout(layout: LayoutDisplayCriteria): boolean {
    if (layout.requiredStatus && !this.isAtStatus(layout.requiredStatus)) {
      return false;
    }
    if (layout.showIf) {
      if (typeof layout.showIf === 'object') {
        return checkIfExpression(layout.showIf, this.project);
      } else if (typeof layout.showIf === 'string') {
        const requiredValue = layout.showIfValue || 'Yes';
        if (this.project.data[layout.showIf] !== requiredValue) {
          return false;
        }
      }
    }
    return true;
  }

  private isAtStatus(status: string) {
    const currentStatusIdx = this.projectStates.findIndex(projectState => projectState === this.projectState);
    const requiredStatusIdx = this.projectStates.findIndex(projectState => projectState.status === status);
    return currentStatusIdx === -1 || currentStatusIdx >= requiredStatusIdx;
  }

  private setState() {
    this.projectStates = this.projectConfiguration.states;
    this.projectState = this.projectStates.filter(state => state.status === this.project.status.status).pop();
  }

  private setJobSummary() {
    this.jobSummaryItems = (this.project.jobs || [])
      .filter(x => x.scheduledDate)
      .map(job => {
        return {
          jobType: job.type,
          assignedToDisplayName:
            job.jobAssignments?.find(j => j.assignmentType === 'LEAD')?.assignedToDisplayName ||
            job.assignedToDisplayName,
          supportEngineers:
            job.jobAssignments
              ?.filter(j => j.assignmentType === 'SUPPORT')
              .reduce((filtered, ja) => {
                if (!filtered.some(o => o.assignedTo === ja.assignedTo)) {
                  filtered.push(ja);
                }
                return filtered;
              }, []) || [],
          scheduledDate: JumptechDate.from(job.scheduledDate).toDateTimeFormat(),
          jobAssignments: job.jobAssignments || [],
          status: job.status,
          statusLog: job.statusLog.map(log => ({
            ...log,
            timestamp: JumptechDate.from(log.timestamp).toDateTimeFormat()
          })),
          showLogs: false
        };
      });
  }

  private setAdditionalDownloads() {
    this.additionalDownloads = this.project.resources.filter(resource => resource.location !== undefined);
  }

  private setProjectResources() {
    this.project.resources.forEach(resource => {
      if (resource.summary) {
        this.resources[resource.type] = resource.summary;
      }
    });
  }

  downloadProject(downloadType: ProjectDlType) {
    this.showSpinner();
    return this.apiService
      .getDownloadUrl(this.project.id, downloadType)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(
        (downloadLocation: string) => {
          this.hideSpinner();
          // open the download url in a hidden iframe so we don't see a flash as we download
          window.open(downloadLocation, 'downloadTarget');
          this.toasterService.pop(
            'info',
            this.translocoService.translate('common.export'),
            `${this.translocoService.translate('common.project')} ${
              downloadType == ProjectDlType.ALL ? '' : downloadType + ' '
            }${this.translocoService.translate('common.exported')}`
          );
        },
        error => {
          this.hideSpinner().then(() => {
            this.loggerService.error(error);
          });
          const message =
            (error.error && error.error.errorMessage) ||
            this.translocoService.translate('project.modals.exportError.messages.unable');
          this.toasterService.pop(
            'error',
            this.translocoService.translate('project.modals.exportError.title'),
            message
          );
        }
      );
  }

  private getProgress(): ProgressIndicatorState[] {
    // If the project is archived, the "current" status is the status before archiving
    const status =
      this.isArchived && this.project.status.previousStatus
        ? this.project.status.previousStatus
        : this.project.status.status;

    const projectStateIndex = this.projectStates.findIndex(state => state.status === status);

    return this.projectStates
      .filter(state => {
        return !state.hidden;
      })
      .map((step, stepIndex) => {
        let status: ProgressIndicatorStatus;
        if (projectStateIndex == stepIndex) {
          if (this.isArchived) {
            status = ProgressIndicatorStatus.archived;
          } else {
            status = step.showProgress ? ProgressIndicatorStatus.progressing : ProgressIndicatorStatus.current;
          }
          this.currentStatusPosition = stepIndex;
        } else if (stepIndex == projectStateIndex + 1) {
          status = this.isArchived ? ProgressIndicatorStatus.todo : ProgressIndicatorStatus.next;
        } else {
          // compare with our list of statuses and determine if we have been skipped or not
          if (stepIndex < projectStateIndex) {
            status = this.statusChanges.map(x => x.status).includes(step.status)
              ? ProgressIndicatorStatus.completed
              : ProgressIndicatorStatus.skipped;
          } else {
            status = ProgressIndicatorStatus.todo;
          }
        }
        return {
          label: step.label,
          showProgress: !!step.showProgress,
          status: status,
          statusData: this.statusChanges.find(x => x.status === step.status) || null,
          showSkipped: false
        };
      });
  }

  async subscribeToUpdates() {
    this.updateSubscription?.unsubscribe();
    this.updateSubscription = (
      await this.projectUpdateService.makeJsonWebSocketObservable(this.project.id, this.project.tenant)
    )
      .pipe(
        filter(update => update == 'true' || (typeof update === 'string' && JSON.parse(update) === 'DOCUMENT_PACK')),
        concatMap(update => {
          if (update == 'true') {
            return timer(2000).pipe(
              switchMap(() => {
                return this.apiService.getProject(this.project.id);
              }),
              retryWhen(this.delayWsUpdate$.asObservable)
            );
          } else if (typeof update === 'string' && JSON.parse(update) === 'DOCUMENT_PACK') {
            this.initDocumentPacks(false, false).then();
            return of(null);
          }
        })
      )
      .subscribe((updatedProject: Project | null) => {
        if (!updatedProject) {
          return;
        }
        this.processUpdatedProject(updatedProject).then();
      });
  }

  async processUpdatedProject(updatedProject: Project) {
    let shouldSetCardLayouts = false;
    const originalProject = this.project;
    const projectDiff: Partial<Project> = diff(originalProject, updatedProject);

    const filtered = Object.keys(projectDiff).filter(k => {
      const value = projectDiff[k];

      if (this.isViewingGallery) {
        if (k === 'attachments') {
          return false;
        }
        if (typeof value === 'object') {
          const hasPhotosUpdate = Object.keys(value).find(
            x => x.toLowerCase().indexOf('photo') !== -1 || x.toLowerCase().indexOf('image') !== -1
          );
          if (hasPhotosUpdate) {
            return false;
          }
        }
      }

      if (typeof value === 'undefined') {
        return false;
      }
      if (k === 'updated_on') {
        return false;
      }
      if (k === 'attachments') {
        return true;
      }

      function checkSubDiffs(value, parentKeys) {
        const subDiffs = Object.keys(value).filter(j => {
          const subValue = value[j];
          if (subValue && typeof subValue === 'object') {
            return checkSubDiffs(subValue, [...parentKeys, j]);
          }

          if (subValue?.toString().startsWith('https')) {
            const firstKey = parentKeys[0];
            let data = originalProject[firstKey];
            for (let i = 1; i < parentKeys.length; i++) {
              data = data[parentKeys[i]];
            }
            const imageUrl = data?.[j];
            return imageUrl?.replace(/\?.*/, '') !== subValue?.replace(/\?.*/, '');
          }
          return true;
        });

        return subDiffs.length;
      }

      if (value && typeof value === 'object') {
        return checkSubDiffs(value, [k]);
      }
      return true;
    });

    // check image data arrays for removals, and update project directly because lodashMerge will add them back
    if (!this.isViewingGallery && this.displayDnoApplicationTab) {
      if (projectDiff && projectDiff.data) {
        Object.keys(projectDiff.data).filter(key => {
          if (Array.isArray(originalProject.data[key])) {
            const removals = [];
            Object.entries(projectDiff.data[key]).forEach(([k, v]) => {
              if (!v) {
                // array item was removed
                removals.push(parseInt(k));
              }
            });
            if (removals.length) {
              // filter out removed items from project
              originalProject.data[key] = originalProject.data[key].filter((x, i) => !removals.includes(i));
            }
          }
        });
      }
    }

    const isDifferent = filtered.length > 0;
    if (!isDifferent) {
      return;
    }
    this.project = { ...lodashMerge(this.project, updatedProject) };

    if (updatedProject.jobs) {
      updatedProject.jobs.forEach(j => {
        if (!j.jobAssignments?.length) {
          this.project.jobs.find(pj => pj.type === j.type).jobAssignments = [];
        }
      });
    }

    // Source properties that resolve to undefined are skipped if a destination value exists,
    // that's why we need to manually set documentPackId to null if it doesn't exist in the
    // updated project object. https://lodash.com/docs/#merge
    if (!updatedProject.documentPackId) {
      this.project.documentPackId = null;
    }

    if (updatedProject?.owner && typeof updatedProject?.owner !== 'string') {
      this.project.owner = null;
    }
    if (filtered.find(x => x === 'documentPackId')) {
      await this.initDocumentPacks(false, false);
    }
    if (filtered.find(x => x === 'resources')) {
      // There might be fewer resources than before, so as a special case, let's replace the resources
      this.project.resources = updatedProject.resources;
      this.initOrders(true).then();
      shouldSetCardLayouts = true;
    }
    if (filtered.find(x => x === 'status')) {
      this.setState();
      shouldSetCardLayouts = true;
      await this.initDocumentPacks(false, true);
      this.initOrders().then();
      this.supportBarRepository.loadData(this.project.id).then();
    } else if (filtered.some(x => ['resources', 'data'].includes(x))) {
      shouldSetCardLayouts = true;
      if (filtered.find(x => x === 'data')) {
        await this.initDocumentPacks(false, true);
      }
    }
    if (filtered.find(x => x === 'attachments')) {
      this.project.attachments = updatedProject.attachments;
    }
    this.setState();
    this.setProjectResources();
    if (shouldSetCardLayouts) {
      this.setProjectConfigurationCardLayouts();
    }

    if (filtered.includes('modules')) {
      this.project.modules = updatedProject.modules;

      const isLegacyDnoApplication = this.project.modules?.dno?.isLegacy ?? false;
      this.setIsLegacyDnoApplication(isLegacyDnoApplication);

      const modules = Object.keys(projectDiff.modules) as Module[];

      for (const module of modules) {
        await this.setModuleCardLayout(module);
      }
    }

    this.setJobSummary();
    this.projectUpdateService.nextAuditLogUpdates();
    this.setAdditionalDownloads();
  }

  /**
   * If a tab is changed, make sure that none of the child layouts being edited are
   * still in edit mode.
   * @param $event
   */
  onTabChange($event: NgbNavChangeEvent) {
    this.tabLayouts.forEach(tabLayout => {
      tabLayout.layouts.forEach(layout => {
        layout.layouts.forEach(l => (l.edit = false));
      });
    });
  }

  onDelayWebHook() {
    this.delayWsUpdate$.next(true);
  }

  onInfoMessage(message: string) {
    this.infoMessage = message;
  }

  onSwitchTab(id: string) {
    if (id && this.layoutTabSet.items.find(x => x.id === id)) {
      this.layoutTabSet.select(id);
      this.tabSetContainer.nativeElement.scrollIntoView(true);
    }
  }

  onActioned(action) {
    if (action.transitionType === 'ScheduleJob') {
      this.setProjectConfiguration(false, true).then();
    }
  }

  onSave(data: unknown) {
    if (data) {
      const update = Object.assign(this.project.data, data);
      this.project = { ...this.project, ...{ data: update } };
    }
  }

  saveDocumentToAttachments(documentAttachment: ProjectAttachment) {
    const idx = this.project.attachments.findIndex(attachment => attachment.key === documentAttachment.key);
    if (idx > -1) {
      this.project.attachments[idx] = documentAttachment;
    } else {
      this.project.attachments.push(documentAttachment);
    }
    this.updateAttachments(this.project.attachments);
  }

  updateAttachments($event: ProjectAttachment[]) {
    if (this.isArchived) {
      return;
    }

    ($event || []).map(x => delete x.url);

    const update = { attachments: $event };

    this.project = { ...this.project, ...update };
    this.apiService.updateProject(this.project, update).toPromise().catch(this.loggerService.error);
  }

  async onAuditLogs(auditLogs: AuditLog[] | null) {
    this.auditLogs = auditLogs?.length ? auditLogs : null;
    if (auditLogs !== null) {
      this.setStatusChanges();
      this.projectProgress.set(this.getProgress());
      this.hideLoaders('auditLogs');
    }
  }

  setStatusChanges(): void {
    if (!this.auditLogs) {
      return;
    }
    const changes = this.auditLogs
      .filter(log => {
        return (
          this.statusChangeTypes.includes(log.type) || log.eventName.toLowerCase().indexOf(this.changeEventName) !== -1
        );
      })
      .map(log => {
        return {
          status: log.newValue,
          date: new Date(log.created_on),
          actionedBy: log.userName || log.resourceName,
          stamp: log.timestamp,
          show: false
        };
      })
      .sort((a, b) => {
        if (a.stamp < b.stamp) {
          return 1;
        }
        if (a.stamp > b.stamp) {
          return -1;
        }
        return 0;
      });

    // filter out all but the most recent changes for each status type
    this.statusChanges = changes.filter((log, pos) => {
      return changes.map(x => x.status).indexOf(log.status) === pos;
    });
  }

  getNotesCount() {
    return this.project.notes?.filter(note => note.type !== 'CONTACT_LOG')?.length || 0;
  }

  updateNotes($event: { notes: Note[]; projectId: string }) {
    if (this.isArchived || $event.projectId !== this.project.id) {
      return;
    }

    const update = { notes: $event.notes };
    this.project = { ...this.project, ...update };
    this.apiService.updateProject(this.project, update).toPromise().catch(this.loggerService.error);
  }

  isLayoutReadOnly(layout: TabLayout | DisplayTabLayout | ExpandedCardLayoutCollection) {
    return !this.layoutUpdateService.allowEdit(layout);
  }

  setViewingGallery(): void {
    this.isViewingGallery = !this.isViewingGallery;
    if (this.isViewingGallery) {
      this.projectUpdateService.nextProjectUpdates(this.project);
    }
  }

  updateOwner($event: any) {
    this.project.owner = $event.owner;
    this.project.owner_name = $event.owner_name;
  }

  drop(event: CdkDragDrop<string[]>) {
    const previousLayout = [...this.userPrefLayoutLeft];
    moveItemInArray(this.userPrefLayoutLeft, event.previousIndex, event.currentIndex);
    if (previousLayout.join() !== this.userPrefLayoutLeft.join()) {
      Analytics.logEvent('User preference update | Project Details layout (left)', {
        from: previousLayout.join(', '),
        to: this.userPrefLayoutLeft.join(', ')
      });
    }
    this.localStorageGateway.setItem(this.userLayoutProjectDetailsLeft, JSON.stringify(this.userPrefLayoutLeft));
  }

  /**
   * Tell whether at least one of the audit logs is a failure.
   */
  hasFailureLogs(): boolean {
    if (!this.auditLogs) {
      return;
    }
    for (const log of this.auditLogs) {
      if (log.status === AuditLogStatus.FAILURE) {
        return true;
      }
    }
    return false;
  }

  selected(_state) {
    const modalRef = this.modalService.open(RelayComponent, {
      windowClass: 'relay-modal-dialog relay-container'
    });
    modalRef.componentInstance.project = this.project;
    modalRef.componentInstance.viewingTenant = this.userService.currentUser.tenant;
  }

  async changeSuccess() {
    await this.fetchProject(this.project.id);
    this.projectUpdateService.nextAuditLogUpdates();
  }

  private async autoCreateDocumentPack(): Promise<boolean> {
    const createDocumentPackOnAddProject = this.projectConfiguration?.eventActions?.onAddProject?.asyncActions?.some(
      asyncAction => asyncAction.eventName === 'CREATE_DOCUMENT_PACK'
    );

    const featureFlagEnabled = await this.featureFlagService.isFeatureEnabled(this.AUTO_ADD_DOC_PACK_FEATURE_KEY);
    return featureFlagEnabled && createDocumentPackOnAddProject;
  }

  get isArchived(): boolean {
    return this.project?.status?.status === ProjectStatus.ARCHIVED;
  }

  /**
   * For the time being, a project is readonly only when it's archived.
   */
  get isReadOnly(): boolean {
    return this.isArchived;
  }

  updateData(event: { layout: ExpandedCardLayout; data: any }) {
    const { layout, data } = event;
    layout.data = { ...layout.data, ...data };
  }
}
