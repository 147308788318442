import { Injectable } from '@angular/core';
import { FormControl, FormGroup, UntypedFormArray } from '@angular/forms';
import { IAddressV2 } from '@jump-tech-frontend/address-lookup-v2';
import { JumptechDate } from '@jump-tech-frontend/domain';
import { Subject } from 'rxjs';
import { Project } from '../../core/domain/project';
import { ProjectAttachment } from '../project-attachments/project-attachments.component';
import { DisplayTabLayout } from '../project-detail.component';
import {
  ApplicationFormControl,
  ApplicationFormGroup,
  ApplicationFormItem,
  ApplicationFormItemWithHooks,
  ApplicationFormSectionWithHooks,
  EnaActionsDm,
  EnaActionsVm,
  EnaAttachmentInputs,
  EnaFormDm,
  EnaFormVm,
  FINALIZED_STATES,
  GroupState,
  RegisteredDeviceData
} from './ena.model';
import { EnaRepository } from './ena.repository';

@Injectable({ providedIn: 'root' })
export class EnaApplicationPresenter {
  viewModel: EnaFormVm;
  actionsViewModel: EnaActionsVm;

  constructor(private repository: EnaRepository) {}

  unload() {
    this.repository.unload();
  }

  load(vmSubject$: Subject<EnaFormVm>, project: Project, tabLayouts: DisplayTabLayout[]): void {
    this.repository.load(project, tabLayouts, (dm: EnaFormDm) => {
      this.viewModel = {
        project: dm.project,
        lctType: dm.lctType,
        groups: dm.formGroups.map(grp => ({
          title: grp.title,
          subHeading: grp.subHeading,
          key: grp.key,
          form: grp.form,
          isOpen: grp.isOpen,
          invalidGroupMessage: grp.invalidGroupMessage,
          toggleClass: grp.isOpen ? 'expand_less' : 'expand_more',
          useHug: grp.useHug,
          savedState: grp.savedState,
          savedStateCss: grp.savedStateCss,
          showSavedState: !!grp.savedState || !!grp.savedStateCss,
          qaEnaApplicationGroupName: `qaEnaApplicationGroupHeader_${grp.key}`,
          qaEnaApplicationGroupStatus: grp.showSavedState
            ? `qaEnaApplicationGroupStatus_${grp.savedState}`
            : `qaEnaApplicationGroupStatus_${this.getState(grp, false)}`,
          qaEnaApplicationAddDeviceBtn: 'qaEnaApplicationAddDeviceBtn',
          qaEnaApplicationNumberOfItems: 'qaEnaApplicationNumberOfItems',
          numberOfItems: grp.items ? `(${grp.items.length})` : null,
          items: grp.items
            ? grp.items.map((itm: ApplicationFormItem, i) => ({
                name: itm.name,
                isNew: itm.isNew,
                key: itm.key,
                manualEntry: itm.manualEntry,
                removeDisabled: this.inputsDisabled(dm) || grp.items.length === 1,
                sections: this.parseSections(itm, dm, itm.manualEntry, itm.name),
                isLastItem: grp.items.length - 1 === i,
                qaEnaItemManualEntry: 'qaEnaApplicationAddDeviceManuallyBtn',
                qaEnaItemBackToDeviceSearch: 'qaEnaApplicationBackToDeviceSearchBtn',
                qaEnaItemDuplicateDevice: 'qaEnaApplicationDuplicateDeviceBtn',
                qaEnaItemRemoveDevice: 'qaEnaApplicationRemoveDeviceBtn'
              }))
            : null,
          sections: grp.sections ? this.parseSections(grp, dm) : null
        })),
        displayTextGroups: dm.displayTextGroups.map(grp => ({
          title: grp.title,
          headerString: grp.headerString,
          entries: grp.entries,
          timestamp: grp.timestamp,
          formattedTimestamp: JumptechDate.from(grp.timestamp).toTimestampFormat(),
          isOpen: grp.isOpen,
          toggleClass: grp.isOpen ? 'expand_less' : 'expand_more',
          toggleColor: !grp.entries.length ? 'low' : 'primary',
          emptyEntriesString: grp.emptyEntriesString,
          displayEmptyText: !grp.entries.length && !!grp.emptyEntriesString,
          qaEnaDisplayTextGroupName: `qaEnaDisplayTextGroupName_${grp.title}`
        })),
        i18nTitle: dm.i18ns.title,
        i18nSubmitLabel:
          dm.applicationStatus !== 'REJECTED' ? dm.i18ns.submitApplication : dm.i18ns.resubmitApplication,
        i18nSaveLabel: dm.i18ns.saveApplication,
        i18nUndoLabel: dm.i18ns.undoChanges,
        i18nRetryLabel: dm.i18ns.retryLabel,
        i18nRetryMessage: dm.i18ns.retryMessage,
        i18nSelectDateMessage: dm.i18ns.selectDate,
        i18nAddDeviceLabel: dm.i18ns.addDevice,
        i18nRemoveDeviceLabel: dm.i18ns.removeDevice,
        i18nDuplicateDeviceLabel: dm.i18ns.duplicateDevice,
        i18nManualEntryDeviceLabel: dm.i18ns.manualEntryDevice,
        i18nSearchDeviceLabel: dm.i18ns.searchForDevice,
        i18nExternalDnoLabel: dm.i18ns.externalDno,
        i18nRefNoLabel: dm.i18ns.refNo,
        i18nManuallyApproveLabel: dm.i18ns.manuallyApprove,
        i18nManuallyApprovedLabel: dm.i18ns.manuallyApprovedLabel,

        i18nDeviceIdLabel: dm.i18ns.deviceId,
        i18nDeviceTypeLabel: dm.i18ns.deviceType,
        i18nManufacturerLabel: dm.i18ns.manufacturer,
        i18nModelLabel: dm.i18ns.model,
        i18nPhaseLabel: dm.i18ns.phase,

        showRetry: dm.showRetry,
        showLoader: dm.loading,
        status: dm.applicationStatus,
        showRejectedStatus: dm.applicationStatus === 'REJECTED',
        showAddDevice: !this.inputsDisabled(dm),
        externalStatus: this.getExternalStatus(dm),
        externalDno: dm.externalDno ?? null,
        externalApplicationId: dm.externalApplicationId ?? null,
        inputsDisabled: this.inputsDisabled(dm),
        qaEnaApplicationStatus: 'qaEnaApplicationStatus',
        qaEnaApplicationExternalStatus: 'qaEnaApplicationExternalStatus',
        qaEnaApplicationExternalDno: 'qaEnaApplicationExternalDno',
        qaEnaApplicationRefNo: 'qaEnaApplicationRefNo'
      };
      vmSubject$.next(this.viewModel);
    });
  }

  inputsDisabled(dm: EnaFormDm): boolean {
    return (
      dm.saveInProgress ||
      dm.submitInProgress ||
      dm.approveInProgress ||
      FINALIZED_STATES.includes(dm.applicationStatus)
    );
  }

  loadActions(vmSubject$: Subject<EnaActionsVm>): void {
    this.repository.loadActions((dm: EnaActionsDm): void => {
      this.actionsViewModel = {
        saveLabel: dm.i18nSaveLabel,
        undoLabel: dm.i18nUndoLabel,
        cancelApplicationLabel: dm.i18nCancelApplicationLabel,
        manuallyApproveLabel: dm.i18nManuallyApproveLabel,
        manuallyRejectLabel: dm.i18nManuallyRejectLabel,
        submitLabel: dm.applicationStatus === 'REJECTED' ? dm.i18nResubmitLabel : dm.i18nSubmitLabel,

        disableSave: dm.disableSave,
        disableUndo: false,
        disableCancelApplication: dm.disableCancelApplication,
        disableManuallyReject: dm.disableManuallyReject,
        disableManuallyApprove: dm.disableManuallyApprove,

        displaySave: dm.displaySave,
        displayUndo: dm.displayUndo,
        displaySubmit: dm.displaySubmit,
        displayCancelApplication: dm.displayCancelApplication,
        displayManuallyReject: dm.displayManuallyReject,
        displayManuallyApprove: dm.displayManuallyApprove,

        saveInProgress: dm.saveInProgress,
        submitInProgress: dm.submitInProgress,
        cancelApplicationInProgress: dm.cancelApplicationInProgress,
        rejectInProgress: dm.rejectInProgress,
        approveInProgress: dm.approveInProgress,

        // hooks
        qaSaveButton: this.createActionQaTag('qaEnaApplicationSaveButton', dm.saveInProgress),
        qaUndoButton: 'qaEnaApplicationUndoButton',
        qaSubmitButton: this.createSubmitButtonQaTag(dm),
        qaCancelApplicationButton: this.createActionQaTag(
          'qaEnaApplicationCancelApplicationButton',
          dm.cancelApplicationInProgress
        ),
        qaManuallyApproveButton: this.createActionQaTag('qaEnaApplicationManuallyApproveButton', dm.approveInProgress),
        qaManuallyRejectButton: this.createActionQaTag('qaEnaApplicationManuallyRejectButton', dm.rejectInProgress)
      };

      vmSubject$.next(this.actionsViewModel);
    });
  }

  loadAttachments(attachments: ProjectAttachment[], projectId: string, tenant: string, readonly: boolean) {
    const attachmentInputs = this.createAttachmentInputsObject(attachments, projectId, tenant, readonly);
    this.repository.loadAttachments(attachmentInputs);
  }

  async hydrateProject(project: Project): Promise<void> {
    await this.repository.hydrateProject(project);
  }

  getFormControl(grp: ApplicationFormGroup, control: ApplicationFormControl, i?: number): FormControl {
    if (i !== null && i !== undefined) {
      return this.getFormAt(grp, grp.key, i).get(control.name) as FormControl;
    } else {
      return grp.form.get(control.name) as FormControl;
    }
  }

  getErrors(grp: ApplicationFormGroup, control: ApplicationFormControl, i?: number) {
    if (i !== null && i !== undefined) {
      return this.getFormAt(grp, grp.key, i).get(control.name).errors;
    } else {
      return grp.form.get(control.name).errors;
    }
  }

  getDisabled(grp: ApplicationFormGroup, control: ApplicationFormControl, i?: number): boolean {
    if (i !== null && i !== undefined) {
      return this.getFormAt(grp, grp.key, i).get(control.name).disabled;
    } else {
      return grp.form.get(control.name).disabled;
    }
  }

  getState(grp: ApplicationFormGroup, showCustomMessage = true): string {
    const incompleteMessage = this.repository.getIncompleteMessageForGroup(grp);
    return grp.form.invalid ? (showCustomMessage ? incompleteMessage : GroupState.incomplete) : GroupState.complete;
  }

  getStateCss(grp: ApplicationFormGroup) {
    return grp.form.invalid
      ? 'ena-application__group-state ena-application__group-state--invalid'
      : 'ena-application__group-state ena-application__group-state--valid';
  }

  isRequiredLabel(control: ApplicationFormControl): string {
    const nonTextControlTypes = ['select', 'date', 'address', 'boolean'];
    return nonTextControlTypes.includes(control.type) && control.validators.find(x => x === 'required');
  }

  submitDisabled(): boolean {
    const invalidForms = this.viewModel.groups.filter(grp => !grp.form.valid);
    return !!invalidForms.length || this.actionsViewModel.saveInProgress || this.actionsViewModel.submitInProgress;
  }

  getValidationMessageForError(grp: ApplicationFormGroup, control: ApplicationFormControl, i?: number): string {
    const errors = this.getErrors(grp, control, i);
    if (!errors) {
      return;
    }
    const errorKeys = Object.keys(errors);
    if (Object.prototype.hasOwnProperty.call(errors, 'ngbDate')) {
      errorKeys.splice(errorKeys.indexOf('ngbDate'), 1);
      for (const ngbDateKey of Object.keys(errors.ngbDate)) {
        errorKeys.push(`ngbDate_${ngbDateKey}`);
      }
    }
    const errorMessages = [];
    for (const key of errorKeys) {
      errorMessages.push(control.validationMessages[key]);
    }
    return errorMessages[0];
  }

  handleAttachmentChecked() {
    this.repository.handleAttachmentChecked();
  }

  toggleGroup(group: ApplicationFormGroup) {
    this.repository.toggleGroup(group);
  }

  toggleDisplayTextGroup(groupTitle: string) {
    this.repository.toggleDisplayTextGroup(groupTitle);
  }

  updateAddress(event: { address: IAddressV2; location: string } | null, key: string, formGroup: FormGroup) {
    formGroup.get(key)?.setValue(event.address);
  }

  saveApplication(): void {
    this.repository.saveApplication().then();
  }

  submitApplication(): void {
    this.repository.beginSubmitApplication();
  }

  manuallyApproveApplication(): void {
    this.repository.manuallyApproveApplicationDialog();
  }

  async manuallyRejectApplication(): Promise<void> {
    await this.repository.manuallyRejectApplication();
  }

  async cancelApplication(): Promise<void> {
    await this.repository.cancelApplication();
  }

  undoChanges(): void {
    this.repository.undoChanges();
  }

  createAttachmentInputsObject(
    attachments: ProjectAttachment[],
    projectId: string,
    tenant: string,
    readonly: boolean
  ): EnaAttachmentInputs {
    return {
      attachments,
      projectId,
      tenant,
      readonly
    };
  }

  private createSubmitButtonQaTag(dm: EnaActionsDm): string {
    const defaultTag = `${
      dm.applicationStatus === 'REJECTED' ? 'qaEnaApplicationResubmitButton' : 'qaEnaApplicationSubmitButton'
    }`;

    return this.createActionQaTag(defaultTag, dm.submitInProgress);
  }

  private createActionQaTag(base: string, loading: boolean): string {
    return loading ? `${base}_loading` : base;
  }

  async retryFormLoad() {
    await this.repository.loadForm();
  }

  parseSections(
    grpOrItem: ApplicationFormGroup | ApplicationFormItem,
    dm: EnaFormDm,
    itemManualEntry?: boolean,
    itemName?: string
  ): ApplicationFormSectionWithHooks[] {
    return grpOrItem.sections.map(sec => {
      let sectionHidden = false;
      if (itemManualEntry !== undefined) {
        // deal with section display of device item
        if (itemManualEntry) {
          // Only display manual section(s)
          if (sec.id !== `${itemName}_manualSection`) {
            sectionHidden = true;
          }
        } else {
          // Do not display manual section(s)
          if (sec.id === `${itemName}_manualSection`) {
            sectionHidden = true;
          }
        }
      }
      return {
        name: sec.name,
        id: grpOrItem.key,
        hidden: sectionHidden,
        uuid: sec.uuid,
        qaEnaApplicationSectionHeader: sec.name
          ? `qaEnaApplicationSectionHeader_${sec.name}`
          : 'qaEnaApplicationSectionHeader',
        controls: sec.controls.map(control => ({
          name: control.name,
          label: this.isRequiredLabel(control) ? `${control.label} *` : control.label,
          type: control.type,
          value: control.value,
          validators: control.validators,
          isRequired: !!control.validators.find(x => x === 'required'),
          validationMessages: {
            required: dm.i18ns.valueRequired,
            minlength: dm.i18ns.valueMinLength,
            email: dm.i18ns.valueEmail,
            pattern: control.regexMessage ?? '',
            ngbDate_invalid: dm.i18ns.invalidDateFormat
          },
          hasRegex: !!control.regex,
          regexMessage: control.regexMessage,
          typeToSearchLabel: control.type === 'asyncDataLookup' ? dm.i18ns.typeToSearchDevice : null,
          tooltip: control.tooltip,
          displayIf: control.displayIf,
          data: control.data,
          maxLength: control.maxLength ?? null,
          minLength: control.minLength ?? null,
          min: control.min ?? null,
          max: control.max ?? null,
          options:
            control.type === 'boolean'
              ? [
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]
              : control.options ?? [],
          options$: control.options$ ?? null,
          optionInput$: control.optionInput$ ?? null,
          optionLoading$: control.optionLoading$ ?? null,
          optionSelectedData: control.optionSelectedData ?? null,
          qaFormFieldString: `qaEnaApplication`,
          qaEnaApplicationFormFieldLabel: `qaEnaApplicationFormFieldLabel_${control.name}`,
          qaEnaApplicationFormFieldInput: `qaEnaApplicationFormField_${control.name}`,
          qaEnaApplicationFormFieldError: `qaEnaApplicationFormFieldError_${control.name}`,
          qaEnaSelectedDeviceSearchOption: `qaEnaSelectedDeviceSearchOption_${control.name}`
        }))
      };
    });
  }

  duplicateDevice(i: number): void {
    this.repository.duplicateDevice(i);
  }

  addDevice(): void {
    this.repository.addDevice();
  }

  removeDevice(i: number): void {
    this.repository.removeDevice(i);
  }

  toggleDeviceManualEntry(item: ApplicationFormItemWithHooks, manualEntry: boolean): void {
    // ephemeral state
    item.sections[0].hidden = manualEntry;
    item.sections[1].hidden = manualEntry;
    item.sections[2].hidden = manualEntry;
    item.sections[3].hidden = !manualEntry;
    item.sections[4].hidden = !manualEntry;
    item.manualEntry = manualEntry;
    this.repository.updateItemManualEntry(item.name, manualEntry);
  }

  setSelectedDevice(deviceDetails: RegisteredDeviceData, itemIdx: number): void {
    this.repository.setSelectedDevice(deviceDetails, itemIdx);
  }

  getFormAt(grp, array, index): FormGroup {
    return (grp.form.get(array) as UntypedFormArray).at(index) as FormGroup;
  }

  getExternalStatus(dm: EnaFormDm): string {
    if (dm.externalStatus) {
      return `(${dm.externalStatus})`;
    }

    const latestTransitionEntryIndex = dm.manualApplicationTransition?.length - 1;

    if (
      dm.applicationStatus === 'APPROVED' &&
      dm.manualApplicationTransition?.at(latestTransitionEntryIndex).transitionTo === 'APPROVED'
    ) {
      return dm.i18ns.manuallyApprovedLabel;
    }

    if (
      dm.applicationStatus === 'REJECTED' &&
      dm.manualApplicationTransition?.at(latestTransitionEntryIndex).transitionTo === 'REJECTED'
    ) {
      return dm.i18ns.manuallyRejectedLabel;
    }

    return null;
  }
}
