@if (vm$ | async; as vm) {
  @if (vm.message) {
    <div class="project-audit-logs-error">
      <span [attr.data-qa]="vm.qaErrorMessage">{{ vm.message }}</span>
      <span (click)="clearErrors()" [attr.data-qa]="vm.qaClearErrorsButton">
        <jui-icon name="clear" color="current"></jui-icon>
      </span>
    </div>
  }
}
