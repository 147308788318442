import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LegacyEnaRepository } from '../legacy-ena.repository';
import { LegacyEnaErrorDm, LegacyEnaErrorVm } from '../legacy-ena.model';

@Injectable()
export class LegacyEnaApplicationErrorsPresenter {
  constructor(private repository: LegacyEnaRepository) {}

  load(vmSubject$: BehaviorSubject<LegacyEnaErrorVm>): void {
    this.repository.getErrors((dataDm: LegacyEnaErrorDm) => {
      const viewModel = { ...dataDm };

      vmSubject$.next(viewModel);
    });
  }

  clearErrors(): void {
    this.repository.clearErrors();
  }
}
