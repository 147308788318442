@if (vm$ | async; as vm) {
  <div class="legacy-ena-actions">
    @if (vm.displayManuallyApprove) {
      <div class="legacy-ena-actions__action">
        <jui-button
          size="sm"
          color="secondary"
          display="ghost"
          [disabled]="vm.disableManuallyApprove"
          (click)="manuallyApprove()"
          [attr.data-qa]="vm.qaManuallyApprove"
          [loading]="vm.approveInProgress"
        >{{ vm.i18nManuallyApproveLabel }}
        </jui-button>
      </div>
    }
    @if (vm.displayManuallyReject) {
      <div class="legacy-ena-actions__action">
        <jui-button
          size="sm"
          color="secondary"
          display="ghost"
          [disabled]="vm.disableManuallyReject"
          (click)="manuallyReject()"
          [attr.data-qa]="vm.qaManuallyReject"
          [loading]="vm.rejectInProgress"
        >{{ vm.i18nManuallyRejectLabel }}
        </jui-button>
      </div>
    }
    @if (vm.displayResubmit) {
      <div class="legacy-ena-actions__action">
        <jui-button
          size="sm"
          [disabled]="vm.disableResubmit"
          (click)="resubmitApplication()"
          [loading]="vm.resubmitInProgress"
          [attr.data-qa]="vm.qaResubmitButton"
        >{{ vm.i18nResubmitLabel }}
        </jui-button>
      </div>
    }
  </div>
}

