import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoModule } from '@ngneat/transloco';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-alert-modal',
    template: ` <div class="modal-wrapper modal--confirm">
    <div class="modal-header">
      <div>{{ title }}</div>
    </div>
    <div class="modal-body">
      <div *ngFor="let message of messages">{{ message }}</div>
    </div>
    <div class="modal-footer text-center">
      <jui-button
        [attr.data-qa]="'confirmModalBtn-cancel'"
        class="modal-footer__action-btn"
        color="low"
        size="sm"
        *ngIf="showCancel"
        (click)="close(false, $event)"
        >{{ 'common.cancel' | transloco }}</jui-button
      >
      <jui-button
        [attr.data-qa]="'confirmModalBtn-confirm'"
        class="modal-footer__action-btn"
        size="sm"
        (click)="close(true, $event)"
        >{{ okLabel }}</jui-button
      >
    </div>
  </div>`,
    styles: [``],
    imports: [NgFor, NgIf, CoreComponentsAngularModule, TranslocoModule]
})
export class AlertModalComponent {
  @Input() title: string;
  @Input() messages: string[];
  @Input() okLabel: string;
  @Input() showCancel: boolean;

  constructor(public activeModal: NgbActiveModal) {}

  close(result: boolean, event: MouseEvent) {
    event.preventDefault();
    this.activeModal.close(result);
  }
}
