import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { JuiButton } from '../../../../../libs/core-components-angular/src/generated/directives/proxies';

@Component({
  selector: 'app-sip-resubmission',
  standalone: true,
  template: ` <ng-container *ngIf="display">
    <div class="sip-tab__header">{{ 'project.sip.sipSubmission' | transloco }}</div>
    <div class="sip-tab__resubmit">
      <div class="sip-tab__resubmit-text">{{ 'project.sip.resubmissionMessage' | transloco }}</div>
      <jui-button [loading]="submitInProgress" [disabled]="submitInProgress" (click)="onResubmit()" size="sm">{{
        'project.sip.resubmit' | transloco
      }}</jui-button>
    </div>
  </ng-container>`,
  styleUrls: ['./project-detail.component.scss'],
  imports: [JuiButton, NgIf, TranslocoModule]
})
export class SipResubmissionComponent {
  @Output() resubmit = new EventEmitter<void>();

  onResubmit() {
    this.resubmit.emit();
  }
  @Input() display: boolean;
  @Input() submitInProgress: boolean;
}
