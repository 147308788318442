import { Component } from '@angular/core';
import { NgFor } from '@angular/common';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-tabs-loader',
    template: `
    <div id="loader-tabs" #tabSetContainer>
      <ul
        ngbNav
        #loaderTabSet="ngbNav"
        [activeId]="'loaderTab'"
        class="nav-tabs nav-tabs--shadow nav-tabs--active-bord flex-wrap"
      >
        <li [ngbNavItem]="'loaderTab'">
          <a ngbNavLink [attr.data-qa]="'loaderTab'">
            <jui-icon class="tab-icon" isButton="false" name="list_alt" size="xs" color="current"/>
            <b [attr.data-qa]="'activeLoaderTab'">
              <jui-skeleton-loader [inline]="true" width="38px" height="12px" color="primary"></jui-skeleton-loader>
            </b>
          </a>
          <ng-template ngbNavContent>
            <div class="loader-tab">
              <div class="card" *ngFor="let loader of loaders">
                <div class="card-header">
                  <div class="card-header-title card-header-title">
                    <jui-skeleton-loader [width]="loader.titleWidth"></jui-skeleton-loader>
                    <jui-skeleton-loader
                      class="card-header-title--icon"
                      width="18px"
                      color="primary"
                    ></jui-skeleton-loader>
                  </div>
                </div>
                <div class="card-body">
                  <jui-skeleton-loader [count]="loader.numLoaders" height="25px"></jui-skeleton-loader>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="'dummyTab'" [disabled]="true">
          <a ngbNavLink>
            <jui-icon class="tab-icon" isButton="false" name="photo_library" size="xs" color="current"/>
            <jui-skeleton-loader [inline]="true" width="38px" height="12px"></jui-skeleton-loader>
          </a>
        </li>

        <li [ngbNavItem]="'dummyTab2'" [disabled]="true">
          <a ngbNavLink>
            <jui-icon class="tab-icon" isButton="false" name="event_note" size="xs" color="current"/>
            <jui-skeleton-loader [inline]="true" width="38px" height="12px"></jui-skeleton-loader>
          </a>
        </li>
      </ul>
      <div class="tab-content--shadow" [ngbNavOutlet]="loaderTabSet"></div>
    </div>
  `,
    styleUrls: ['./project-detail.component.scss'],
    imports: [NgbNavModule, CoreComponentsAngularModule, NgFor]
})
export class TabsLoaderComponent {
  loaders = [
    { titleWidth: '60%', numLoaders: 5 },
    { titleWidth: '70%', numLoaders: 4 },
    { titleWidth: '45%', numLoaders: 5 },
    { titleWidth: '80%', numLoaders: 5 },
    { titleWidth: '70%', numLoaders: 7 },
    { titleWidth: '55%', numLoaders: 4 }
  ];
}
