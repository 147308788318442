import { AfterViewInit, Component, Input, OnChanges, OnDestroy, ViewChild, input } from '@angular/core';
import { UntypedFormGroup, ReactiveFormsModule } from '@angular/forms';
import { CardOption, QuestionBase } from '@jump-tech-frontend/domain';
import * as JmesPath from 'jmespath';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ImageAsset, ImageAssetFactory, ImageAssetType } from '../../domain/card';
import { Datasource } from '../../domain/datasource';
import { I18nKeys } from '../../domain/i18n-keys';
import { getDataSourceData } from '../list-data-source';
import { ScrollService } from '../scroll.service';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { ImageAssetComponent } from '../image-asset.component';
import { QuestionHintComponent } from '@jump-tech-frontend/question-components';
import { NgbButtonsModule } from '@ng-bootstrap/ng-bootstrap';
import { QuestionLabelContentComponent } from '@jump-tech-frontend/question-components';
import { InlineSpinnerComponent } from '../inline-spinner-component/inline-spinner.component';
import { NgIf, NgFor } from '@angular/common';
import { ReadOnlyDisableDirective } from '@jump-tech-frontend/angular-common';
import { BaseQuestionComponent } from '../question.component';

@Component({
    selector: `crds-question-button-group`,
    template: `
    <div class="crds-question-button-group" *ngIf="optionsPopulated && show">
      <div *ngIf="!initialised">
        <label *ngIf="question.label" class="form-label" [attr.for]="question.key">
          {{ question.label }}
          <span *ngIf="question.required"> *</span>
        </label>
        <crds-inline-spinner
          [name]="question.key"
          [qaHook]="question.loadingQaHook"
          [type]="question.loadingType"
          [message]="question.loadingMessage"
          [colour]="question.loadingColour"
          [bgColour]="question.loadingBgColour"
          [textColour]="question.loadingTextColour"
          [center]="question.loadingCenter"
        >
        </crds-inline-spinner>
      </div>
      <div *ngIf="initialised">
        <label *ngIf="question.label" class="form-label" [attr.for]="question.key">
          <question-label-content
            [question]="question"
            [isInvalid]="isInvalid"
            [i18ns]="i18ns"
          ></question-label-content>

          <div [formGroup]="form">
            <div class="jt-button-group" [attr.data-qa]="question.key" *ngIf="!question.vertical">
              <jui-button
                readOnlyDisable
                *ngFor="let opt of question.options"
                size="sm"
                [display]="form.get(question.key).value === opt.key ? 'contained' : 'ghost'"
                (click)="form.get(question.key).setValue(opt.key)"
                [attr.data-qa]="opt.key"
              >
                {{ opt.value }}
              </jui-button>
            </div>
            <div class="btn-group-vertical btn-group-toggle" *ngIf="question.vertical">
              <table>
                <tr *ngFor="let opt of question.options" [attr.data-qa]="question.key">
                  <td>
                    <jui-button
                      readOnlyDisable
                      size="sm"
                      [display]="form.get(question.key).value === opt.key ? 'contained' : 'ghost'"
                      (click)="form.get(question.key).setValue(opt.key)"
                      [attr.data-qa]="opt.key"
                    >
                      {{ opt.value }}
                    </jui-button>
                  </td>
                  <td>
                    <div class="vertical-description">
                      {{ opt.description ?? '' }}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </label>

        <!-- QUESTION HINT (PERMANENT) -->
        <question-hint [question]="question"></question-hint>

        <!-- QUESTION IMAGE -->
        <div class="image-wrapper">
          <crds-image-asset [images]="images" [key]="question.key"></crds-image-asset>
        </div>

        <p *ngIf="alert">
          <jui-alert-block iconName="info" size="sm">{{
            question.alert[form.get(question.key).value]
          }}</jui-alert-block>
        </p>
      </div>
    </div>
  `,
    styleUrls: ['./button-group-question.component.scss'],
    imports: [
        NgIf,
        InlineSpinnerComponent,
        QuestionLabelContentComponent,
        ReactiveFormsModule,
        NgbButtonsModule,
        NgFor,
        QuestionHintComponent,
        ImageAssetComponent,
        CoreComponentsAngularModule,
        ReadOnlyDisableDirective
    ]
})
export class ButtonGroupQuestionComponent extends BaseQuestionComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input() question: QuestionBase<string>;
  @Input() override form: UntypedFormGroup;
  @Input() i18ns: I18nKeys;
  @ViewChild('buttonGroupInput') input;
  type: string;
  valueWhenDisabled;
  images: ImageAsset[];
  imageAssetType = ImageAssetType;
  initialised = false;

  private _unsubscribe$ = new Subject();

  private sourceData = getDataSourceData();

  constructor(
    private imageAssetFactory: ImageAssetFactory,
    private scrollService: ScrollService,
    private spinnerService: NgxSpinnerService
  ) {
    super();
  }

  /**
   * Initialise the default form values.
   */
  async ngOnChanges() {
    this.question['options'] = (this.question['options'] || []).map(item => {
      if (item.usei18n) {
        const i18nkey = (item.key as string).toLowerCase() as keyof I18nKeys;
        item.value = this.i18ns[i18nkey] as string;
      }
      return item;
    });
    if (this.question.datasource) {
      this.question.datasource!.transform =
        this.question.datasource?.transform ||
        '[].{ text: join(`" - "`, [parent.name, name]), value: join(`" - "`, [parent.name, name]) }';

      await this.spinnerService.show(this.question.key);
      this.mapData(await this.sourceData.getData(this.question.datasource), this.question.datasource, this.question);
      this.render();
      await this.spinnerService.hide(this.question.key);
    } else {
      this.render();
    }
  }

  ngOnDestroy() {
    this._unsubscribe$.next(null);
    this._unsubscribe$.complete();
  }

  render() {
    this.initialised = true;
    this.scrollService.scrollObservable.pipe(takeUntil(this._unsubscribe$)).subscribe(documentId => {
      if (documentId === this.question.key) {
        this.input.nativeElement.scrollIntoView();
      }
    });
    this.images = this.imageAssetFactory.create(this.question.image, this.form);
  }

  public mapData(result: any[], datasource: Datasource, question?: QuestionBase<string>) {
    const options: CardOption[] = [];
    const transformed = JmesPath.search(result, datasource.transform ?? '');
    transformed
      .sort((a, b) => (typeof a.order === 'number' && typeof b.order === 'number' ? a.order - b.order : 0))
      .forEach(item => {
        const initialText = item.text;
        const initialValue = item.value;

        if (typeof initialValue === 'string') {
          options.push({
            key: initialValue,
            value: initialText
          });
        } else if (Array.isArray(initialValue)) {
          for (const child of initialValue) {
            options.push({
              key: `${initialText} - ${child}`,
              value: `${initialText} - ${child}`
            });
          }
        }
      });

    if (question) {
      if (options && options.length > 0) {
        question.options = options;
      } else {
        if (!question.options || !question.options?.length) {
          question.options = [];
        }
      }
    }
  }

  ngAfterViewInit() {
    this.form
      ?.get(this.question.key)
      ?.valueChanges.pipe(takeUntil(this._unsubscribe$))
      .subscribe(() => {
        this.updateDependantFields(this.formValue);
      });
    this.form
      ?.get(this.question.key)
      ?.valueChanges.pipe(debounceTime(1000))
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(value => {
        this.updateDependantFields(value);
      });
  }

  get optionsPopulated(): boolean {
    if (this.question.datasource) {
      return true;
    }
    return Array.isArray(this.question.options) && this.question.options.length > 0;
  }

  get isInvalid() {
    return this.form?.get(this.question.key)?.dirty && this.form?.get(this.question.key)?.invalid;
  }

  get show() {
    const showIfQuestionPopulated = this.question.showIf ? this.showIf : true;
    return (
      (this.question.showIfPopulated !== true || this.value) && showIfQuestionPopulated && this.question.show !== false
    );
  }

  get alert() {
    return this.question.alert && this.question.alert[this.form?.get(this.question.key)?.value];
  }

  get formValue() {
    return this.form?.get(this.question.key)?.value;
  }

  /**
   * Enable or disable any fields that need updating based on the value selected.
   * We temporarily store any values when disabling.
   * We restore any previously set values when enabling.
   * If no previously set value, then mark field as untouched to avoid form error on changes.
   * @param value
   */
  updateDependantFields(value: any) {
    const opt = this.question.options.filter(option => option.key === value)[0];
    if (typeof opt === 'undefined') {
      return;
    }
    let scrollToField = null;
    if (opt.enables) {
      opt.enables.forEach(enable => {
        const enableControl = this.form.get(enable);
        if (enableControl) {
          enableControl.enable();
          if (this.valueWhenDisabled) {
            enableControl.patchValue(this.valueWhenDisabled);
          } else {
            enableControl.markAsUntouched();
          }
          if (!scrollToField && opt.scrollTo) {
            scrollToField = enable;
          }
        }
      });
    }
    if (opt.disables) {
      opt.disables.forEach(disable => {
        const disableControl = this.form.get(disable);
        if (disableControl) {
          this.valueWhenDisabled = disableControl.value;
          disableControl.reset();
          disableControl.disable();
        }
      });
    }
    if (scrollToField !== null) {
      this.scrollService.next(scrollToField);
    }
  }
}
