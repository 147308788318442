import { Component, Input, OnInit } from '@angular/core';
import { ScheduleV2Component } from '../../schedule/schedule-v2/schedule.component';
import { ScheduleQuestion } from '../../schedule/utils/schedule-types';
import { Job } from '../domain/job';
import { ScheduleV3Component } from '../../schedule/schedule-v3/schedule.component';

@Component({
    selector: 'project-scheduler',
    templateUrl: 'project-scheduler.component.html',
    styleUrls: ['project-scheduler.component.scss'],
    imports: [ScheduleV2Component, ScheduleV3Component]
})
export class ProjectSchedulerComponent implements OnInit {
  @Input() project: { [key: string]: Job };
  @Input() question: ScheduleQuestion;
  @Input() v3Enabled: boolean;
  public selectedJob: Job;

  ngOnInit(): void {
    for (const jobKey of Object.keys(this.project.jobs)) {
      if (jobKey === this.question.jobType) {
        // build project shape here
        this.selectedJob = {
          ...this.project.jobs[jobKey],
          address: { ...this.project.address }
        };
      }
    }
  }
}
