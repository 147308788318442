import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { RouterLink } from '@angular/router';
import { CoreComponentsAngularModule } from '@jump-tech-frontend/core-components-angular';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-user-history',
    templateUrl: './user-history.component.html',
    styleUrls: ['./user-history.component.scss'],
    imports: [NgIf, CoreComponentsAngularModule, NgFor, RouterLink, AsyncPipe, TranslocoModule]
})
export class UserHistoryComponent {
  @Input() userHistory$;
  @Input() hasAppBooted;
  @Output() clearHistory = new EventEmitter();

  constructor() {}
}
