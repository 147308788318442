import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfig } from '../../domain/modal.config';
import { DomSanitizer } from '@angular/platform-browser';
import { NgIf } from '@angular/common';

const docFetching = `
<html>
    <body style="display: flex; align-items: center; justify-content: center; font-family: sans-serif">Fetching document...</body>
</html>`;

const docNoDoc = `
<html>
    <body style="display: flex; align-items: center; justify-content: center; font-family: sans-serif">No document</body>
</html>`;

@Component({
    selector: 'crds-question-doc-viewer-modal',
    template: `
    <div class="modal-wrapper">
      <div class="modal-header">
        <div>{{ config.title }}</div>
      </div>
      <div class="modal-body">
        <div class="iframe-container">
          <iframe id="modal-iframe" class="modal-iframe" [src]="this.src"></iframe>
        </div>
      </div>
      <div class="modal-footer text-center">
        <a class="btn btn-secondary btn-md" *ngIf="config.cancel" href="#" (click)="cancel($event)" role="button">{{
          config.cancel
        }}</a>
        <a class="btn btn-primary btn-md" *ngIf="config.confirm" href="#" (click)="confirm($event)" role="button">{{
          config.confirm
        }}</a>
      </div>
    </div>
  `,
    styles: [
        `
      .iframe-container {
        width: 100%;
        height: calc(100vh - 200px);
        position: relative;
      }

      .modal-iframe {
        border: none;
        left: 0;
        top: 0;
        background-color: #eeeeee;
        width: 100%;
        height: 100%;
      }

      .modal-body {
        padding: 0;
      }
    `
    ],
    imports: [NgIf]
})
export class DocumentViewerModalComponent implements OnInit {
  src = null;
  @Input() config: ModalConfig;
  @Input() set url(value: string) {
    try {
      if (value) {
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(value);
      } else {
        this.setIFrameContent(docNoDoc);
      }
    } catch (e) {
      console.log(`Unable to sanitize ${value}`, e);
    }
  }

  constructor(private activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {}

  confirm($event) {
    $event.preventDefault();
    this.activeModal.close();
  }

  cancel($event) {
    $event.preventDefault();
    this.activeModal.dismiss();
  }

  ngOnInit(): void {
    this.setIFrameContent(docFetching);
  }

  private setIFrameContent(contents) {
    (document.getElementById('modal-iframe') as HTMLIFrameElement).contentWindow.document.write(contents);
  }
}
