import { Directive, ElementRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { ValueAccessor } from './value-accessor';

@Directive({
  /* tslint:disable-next-line:directive-selector */
  selector: 'jui-input, jui-input[type=text], jui-input[type=email], jui-input[type=tel], jui-input[type=password], jui-textarea, jui-radio, jui-chip, jui-radio-group, jui-checkbox-group',
  host: {
    '(juiInput)': 'handleChangeEvent($event.target.value)',
    '(juiChecked)': 'handleChangeEvent($event.target.value)',
    '(juiChipChecked)': 'handleChangeEvent($event.target.value)',
    '(juiChange)': 'handleChangeEvent($event.target.value)'
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextValueAccessor,
      multi: true
    }
  ]
})
export class TextValueAccessor extends ValueAccessor {
  constructor(el: ElementRef) {
    super(el);
  }
}
