export type IfCheck = {
  $and?: IfDefinition[];
  $or?: IfDefinition[];
  $eq?: IfTarget;
  $ne?: IfTarget;
  $gt?: IfTarget;
  $lt?: IfTarget;
  $gte?: IfTarget;
  $lte?: IfTarget;
  $mr?: IfTarget;
  $nmr?: IfTarget;
  $in?: IfTarget;
  $nin?: IfTarget;
  $inc?: IfTarget;
  $aom?: IfDefinition;
  $aonm?: IfDefinition;
};

export type IfTarget = string | number | { property: string };

export type IfDefinition = {
  [key: string]: string | IfCheck | IfDefinition[] | IfTarget;
};
